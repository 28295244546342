.clt-account-screen {}

.clt-account-section-offcanvas-row {
    margin-left: 0px;
    margin-right: 0px;
    background-color: #eee;
    border-radius: 12px !important;
}

.clt_account_offcanvas_header {
    padding-top: 14px;
    padding-right: 24px;
    padding-bottom: 17px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_account_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_account_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_account_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_account_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_account_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_account_offcanvas_header .offcanvas-title {
    margin-left: 20px;
    font-size: 14px;
    color: #242424;
    font-family: "Inter";
    font-weight: 600;
    margin-bottom: 3px;
    letter-spacing: -.2px;
}

.clt-account-screen-title {
    font-size: 20px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 15px;
    letter-spacing: -0.2px;
}

.clt-account-screen-div {
    cursor: pointer;
    border: 1px solid #dedddd;
    border-radius: 12px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 12px;
    padding-left: 15px;
}

.clt-account-screen-div:hover {
    border: 1px solid #dedddd;
    background: #f6f6f6;
}

.clt-account-screen-div:active {
    border: 1px solid #dedddd;
    background: #eee;
}

.clt-account-screen-div2 {
    border: 1px solid #dedddd;
    border-radius: 12px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 12px;
    padding-left: 15px;
    margin-top: 24px;
}

.clt-account-screen-div2:hover {
    border: 1px solid #dedddd;
    background: #fff;
}

.clt-account-screen-div2:active {
    border: 1px solid #dedddd;
    background: #fff;
}

.clt-button-text {
    color: #242424;
    font-weight: 600;
    background: none;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    color: #242424;
    font-weight: 600;
    font-size: 18px;
}


.clt-button-text:hover {
    color: #828282 !important;
    background: #fff !important;
}

.clt-button-text:active {
    color: #b9b9b9 !important;
    background: #fff !important;
}

.clt-button-text45453 {
    color: #242424;
    font-weight: 600;
    background: none;
    border: none;
    box-shadow: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    color: #242424;
    font-weight: 600;
    font-size: 16px;
}


.clt-button-text45453:hover {
    color: #828282 !important;
    background: #fff !important;
}

.clt-button-text45453:active {
    color: #b9b9b9 !important;
    background: #fff !important;
}





.clt-account-screen-inner-div {
    width: 90%;
}


.clt-account-screen-inner-div-i {
    width: 5%;
    display: flex;
    justify-content: flex-end;
}

.clt-account-screen-inner-div-i .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-account-screen-inner-div-i .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50px;
}


.clt-account-screen-inner-div-img img {
    width: 18px;
    padding-bottom: 6px;
}

.clt-account-screen-inner-div-img .inner-div {
    width: 18px;
    height: 18px;
    background: #000;
    border-radius: 50px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-account-screen-inner-div-img .inner-div span {
    font-size: 11px;
    padding-right: 1px;
    padding-top: 1px;
    color: #fff;
    font-family: "Inter";
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: -.2px;
}

.clt-account-screen-inner-div-text {
    width: 90%;
}


.clt-account-screen-inner-div-text .link {
    text-decoration: underline;
    color: #000;
    width: fit-content;
}

.clt-account-screen-inner-div-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: "Inter";
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -.2px;
}

.clt-account-screen-inner-div-text p {
    font-size: 14px;
    color: #70757a;
    font-family: "Inter";
    font-weight: 400;
    margin-bottom: -2px;
    letter-spacing: -.2px;
    width: 100%;
}

.clt-account-screen-btn, .clt-account-screen-btn:active, .clt-account-screen-btn:focus, .clt-account-screen-btn:hover {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-bottom: -6px;
    margin-top: -6px;
    font-family: "Inter";
    font-size: 14px !important;
    letter-spacing: -.2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    width: auto;
    height: 42px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 3px;
}

.clt-account-screen-btn:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt-account-screen-btn:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt-account-screen-btn img {
    width: 17px;
    margin-right: 10px;
    padding-bottom: 2px;
}

.clt-header-trash-can-btn img {
    width: 13px;
    margin-right: 7px;
    padding-bottom: 0px;
}



.clt-account-profile-pic {
    width: 50px;
    border-radius: 10px;
}

.clt_payment_header_button_black:disabled {
    color: #fff;
    background-color: #999 !important;
    border-color: #999;
}

.clt-account-timer-msg {
    background: #eee;
    border-radius: 50px;
    padding: 8px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.clt-account-timer-msg:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt-account-timer-msg:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}



clt-header-trash-can-btn .clt-header-trash-can-btn {
    width: 25%;
}

.clt-header-trash-can-btn {
    width: 25%;
}

.clt-header-trash-can-icon {
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 0;
}

.clt-account-screen-div-mb {
    margin-bottom: 25px;
}



.clt_underline_text {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    text-decoration: underline;
}


@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_account_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_account_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt-account-screen-inner-div-img .inner-div {
        width: 22px;
        height: 22px;
        margin-top: 1px;
        padding-bottom: 1px;
        padding-right: 1px;
    }

    .clt-account-screen-inner-div-text h2 {
        font-size: 16px;
    }

    .clt-account-screen-inner-div-text p {
        font-size: 16px;
    }

    .clt-account-screen-btn, .clt-account-screen-btn:active, .clt-account-screen-btn:focus, .clt-account-screen-btn:hover {
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px !important;
        height: 48px;
        padding-bottom: 4px;
    }

    .clt-account-screen-title {
        font-size: 20px;
    }

    .clt-account-screen-btn img {
        width: 18px;
        margin-right: 10px;
    }

    .clt-account-screen-inner-div-img img {
        width: 20px;
        margin-left: 1px;
    }

    .clt-header-trash-can-btn img {
        width: 13px;
        margin-right: 7px;
        padding-bottom: 0px;
    }

    .clt_account_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt-account-timer-msg {
        font-size: 16px;
    }

    .clt_account_offcanvas_header button {
        font-size: 16px;
        opacity: 1;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 4px !important;
        padding-left: 0px !important;
    }

    .clt_underline_text {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-button-text {
        font-size: 16px;
    }

    .clt-button-text45453 {
        font-size: 16px;
    }

    
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_account_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_account_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt-account-screen-inner-div-img .inner-div {
        width: 22px;
        height: 22px;
        margin-top: 1px;
        padding-bottom: 1px;
        padding-right: 1px;
    }

    .clt-account-screen-inner-div-text h2 {
        font-size: 16px;
    }

    .clt-account-screen-inner-div-text p {
        font-size: 16px;
    }

    .clt-account-screen-btn, .clt-account-screen-btn:active, .clt-account-screen-btn:focus, .clt-account-screen-btn:hover {
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px !important;
        height: 48px;
        padding-bottom: 4px;
    }


    .clt-account-screen-title {
        font-size: 22px;
    }

    .clt-account-screen-btn img {
        width: 18px;
        margin-right: 10px;
    }

    .clt-account-screen-inner-div-img img {
        width: 20px;
        margin-left: 1px;
    }

    .clt-header-trash-can-btn img {
        width: 13px;
        margin-right: 7px;
        padding-bottom: 0px;
    }

    .clt_account_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_account_offcanvas_header button {
        font-size: 16px;
        opacity: 1;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 4px !important;
        padding-left: 0px !important;
    }

    .clt_underline_text {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-button-text {
        font-size: 16px;
    }

    .clt-button-text45453 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_account_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_account_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt_account_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt-account-screen-inner-div-img .inner-div {
        width: 22px;
        height: 22px;
        margin-top: 1px;
        padding-bottom: 1px;
        padding-right: 1px;
    }

    .clt-account-screen-inner-div-text h2 {
        font-size: 16px;
    }

    .clt-account-screen-inner-div-text p {
        font-size: 16px;
    }

    .clt-account-screen-btn, .clt-account-screen-btn:active, .clt-account-screen-btn:focus, .clt-account-screen-btn:hover {
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px !important;
        height: 48px;
        padding-bottom: 4px;
    }

    .clt-account-screen-title {
        font-size: 22px;
    }

    .clt-account-screen-btn img {
        width: 18px;
        margin-right: 10px;
    }

    .clt-account-screen-inner-div-img img {
        width: 20px;
        margin-left: 1px;
    }

    .clt-header-trash-can-btn img {
        width: 13px;
        margin-right: 7px;
        padding-bottom: 0px;
    }

    .clt_account_offcanvas_header button {
        font-size: 16px;
        opacity: 1;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 4px !important;
        padding-left: 0px !important;
    }

    .clt_underline_text {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-button-text {
        font-size: 16px;
    }

    .clt-button-text45453 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt-header-trash-can-btn img {
        width: 14px;
        margin-right: 9px;
        padding-bottom: 1px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt-header-trash-can-btn img {
        width: 14px;
        margin-right: 9px;
        padding-bottom: 1px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt-header-trash-can-btn img {
        width: 14px;
        margin-right: 9px;
        padding-bottom: 1px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt-header-trash-can-btn img {
        width: 14px;
        margin-right: 9px;
        padding-bottom: 1px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt-header-trash-can-btn img {
        width: 14px;
        margin-right: 9px;
        padding-bottom: 1px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt-header-trash-can-btn img {
        width: 14px;
        margin-right: 9px;
        padding-bottom: 1px;
    }

    .clt-button-text {
        font-size: 18px;
    }

    .clt-button-text45453 {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-account-section-offcanvas-row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt_account_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-account-screen-inner-div-img .inner-div {
        width: 20px;
        height: 20px;
    }

    .clt-account-screen-inner-div-text h2 {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .clt-account-screen-title {
        font-size: 22px;
    }

    .clt-account-screen-btn, .clt-account-screen-btn:active, .clt-account-screen-btn:focus, .clt-account-screen-btn:hover {
        font-size: 15px !important;
        height: 46px;
        padding-bottom: 4px;
    }

    .clt-account-screen-inner-div-img img {
        width: 18px;
        padding-bottom: 9px;
    }

    .clt-account-screen-inner-div-text p {
        font-size: 15px;
        margin-bottom: -2px;
    }

    .clt-account-screen-btn img {
        width: 18px;
        margin-right: 10px;
        padding-bottom: 1px;
    }

    .clt-header-trash-can-btn img {
        width: 13px;
        margin-right: 7px;
        padding-top: 0px;
    }

    .clt-account-screen-inner-div-img .inner-div span {
        font-size: 12px;
        margin-bottom: 0;
        padding-right: 2px;
        padding-right: 0px;
    }

    .clt-account-screen-div2 {
        padding: 17px 15px 13px;
    }

    .clt_account_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt-account-timer-msg {
        font-size: 15px;
        padding: 10px 15px;
    }

    .clt_account_offcanvas_header button {
        font-size: 15px;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 3px !important;
        padding-left: 0px !important;
        opacity: 1;
    }
    
    .clt_underline_text {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
    }
    
    .clt-button-text {
        font-size: 18px;
    }

    .clt-button-text45453 {
        font-size: 16px;
    }

}