.clt-homepage-container {
    max-width: 66% !important;
    padding-left: 0px;
}

.clt-homepage-container22345 {
    max-width: 100% !important;
}

.clt-homepage-container17445 {
    padding-top: 23px;
}

.clt-homepage-container3 {
    max-width: 66% !important;
}


.clt-homepage-container2 {
    max-width: 968px !important;
}

.notranslate {
    transform: none !important;
}

.clt-modal-body-review{
    padding: 12px;
}
.LoaderDots_dotThemeBrand__vYRZ\+ {
    background-color: #dbdbdb;
}

.LoaderDots_dotSizeMedium__5oU8J {
    width: 10px;
    height: 10px;
}

.LoaderDots_dotSizeMedium__5oU8J:nth-child(2) {
    margin-left: 6px;
    margin-right: 6px;
}

.clt-homepage-containerfezfdsfzeez {
    max-width: 100% !important;
    padding-left: 0px;
}

.faq-question-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .faq-text {
    flex-grow: 1;
  }
  
  .faq-arrow {
    margin-left: 10px; /* Adjust as needed */
    align-self: center;
  }
  
  .faq-section, .faq-question_subtitle {
    display: inline-block;
  }
  

element.style {
    flex: 1 1 auto;
    position: relative;
    width: 267px;
}

.dazdqsdzadazd {
    max-width: 100%;
    background: radial-gradient(circle at top, #f1f1f1 0%, rgba(255, 0, 0, 0) 100%);
    margin-top: 68px;
    padding-left: 0px;
    padding-right: 0px;
}

.fsvdssdx {
    border-bottom: 1px solid #dedddd;
    padding-bottom: 40px;
}

.clt-button-text-divabc {
    flex-direction: row;
}

.eapps-instagram-feed-header-user + .eapps-instagram-feed-header-stats {
    margin-left: 0px;
}

.eapps-instagram-feed-header-stats-item {
    text-align: center;
    margin-right: 6px;
}

.more-review-text {
    font-weight: 600;
    text-align: center;
}
.eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-text {
    font-size: 18px !important;
    line-height: 24px !important;
    display: inline-block !important;
    overflow: hidden !important;
    max-height: 90px !important;
    width: 100% !important;
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    color: #242424 !important;
}

.eapps-instagram-feed-posts-small .eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-likes-count-icon, .eapps-instagram-feed-posts-small .eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-comments-count-icon, .eapps-instagram-feed-posts-extra-small .eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-likes-count-icon, .eapps-instagram-feed-posts-extra-small .eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-comments-count-icon {
    width: 26px !important;
    height: 26px !important;
}

.text-left {

}

.zczdzzsce {
    font-size: 22px !important;
}

.eapps-instagram-feed-posts-item {
    border-radius: 14px;
}
/* CSS styles for screen width 1401px to 1599px */
@media (min-width: 1401px) and (max-width: 4000px) {

    .home-carousel li {
        margin: 0px 0px 0px 0px;
    }
}

@keyframes Animation-Abda {
    0% {
      transform: scale(0.8);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}

.Animation-Abdazaz {
    animation: Animation-Abda 1s cubic-bezier(0.22,1,0.36,1) 0.45s forwards !important;
}

.clt-section-1-div {
    height: 620px;
    margin-top: 59px;
    margin-bottom: 15px;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0px;
    border-radius: 24px;
    
    
}

.jhbdkjcbskjch {
    margin-left: 10px !important;
}
.zadazdqdqad {
    padding-left: 0px;
}

.djazdzas {
    flex-direction: row;
    display: flex;
}
.clt-detail-section-7-header.row {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align-last: center;
}

.clt-detail-section-7-header545354.row {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.btn-close2 {
    box-sizing: content-box;
    width: 0.75em;
    height: 0.75em;
    padding: 0.25em 0.25em;
    color: #000;
    border: 0;
    border-radius: 0.375rem;
    opacity: 1;
}

.announcement-text{
    margin-bottom: 12px;
    margin-top: 8px;
    font-weight: 500;
    font-family: 'inter';
    letter-spacing: 0.2px;
    color: #ffffff;
}

.clt-section-1-div video {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
}

.Typewriter__wrapper {
    margin-left: 5px;
}

.clt-section-1-div-inner {
    z-index: 1;
    position: relative;
    margin-top: 190px;
    margin-left: 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: -webkit-center;
}

.clt-section-1-h2 {
    font-size: 78px;
    font-weight: 900;
    font-family: 'Californian fb';
    color: #fff;
    margin-bottom: 8px;
    margin-right: 0px;
    margin-top: 0px;
}

.clt-section-1-h2-1 {
    font-size: 32px;
    font-weight: 300;
    font-family: 'inter';
    color: #fff;
    letter-spacing: -0.2px;
    margin-bottom: 29px;
    margin-right: 0px;
}

.clt-section-1-btn,
.clt-section-1-btn:focus {
    color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    border: 0px;
    font-family: 'Inter';
    font-size: 18px;
    padding: 24px 36px 26px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    height: 33px;
    position: relative;
    width: auto;
    letter-spacing: 0.1px;
    background-color: #ffffff;
    backdrop-filter: blur(6px) saturate(180%);
}

.clt-section-2-divs-secondary {
    margin-right: 22px;
    cursor: pointer;
    user-select: none;
    background: #ebebeb !important;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 7px;
}

.clt-section-2-divs-secondary h2 {
    font-size: 19px !important;
    margin-bottom: -3px !important;
    padding-bottom: 15px !important;
    margin-top: 10px !important;
}

.ababab {
    width: 100px;
    max-width: max-content;
}

.adadsaedea {
    width: 166px !important;
    max-width: max-content;
}
.ndziadzda {
    width: 26px !important;
}



.LoaderDots_dotThemeBrand__OksIO {
    background-color: #ffffff !important;
}

.clt-section-2-divs-secondary:hover {
    background: #e1e1e1 !important;
}

.clt-section-2-divs-secondary:active {
    background: #dbdada !important;
}

.clt-section-2-divs-secondary:focus {
    background: #e1e1e1 !important;
}

.clt-section-1-btn:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #d9dce0 !important;
}

.clt-section-1-btn:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #d2d2d3 !important;
}


.clt-search-home {
    border-top: 1px solid #dedddd;
    border-bottom: 1px solid #dedddd;
    padding: 20px 0px;
    margin: 0px;
}

.clt-search-div {
    position: relative;
}

.clt-search-btn-home {
    height: 52px;
    border-radius: 45px;
    background-color: #ffffff !important;
    padding-left: 46px;
    padding-bottom: 9px;
    color: #242424 !important;
    outline: none;
    border: 1px solid #dedddd !important;
    border-radius: 40px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05) !important;
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.1px;
    opacity: 1 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.clt-search-btn-home:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt-search-btn-home:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}



.clt-search-icon-div {
    position: absolute;
    bottom: 12px;
    left: 14px;
}

.clt-search-icon {
    width: 14.5px;
    height: 14.5px;
    margin-bottom: 8px;
    margin-left: 5px;
}

.dnedzkndze {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    margin-bottom: 32px;
    margin-top: 40px;
    padding-top: 11px;
    padding-left: 0px;
}
.dnedzkcsqcsqcndze {
    justify-content: start !important;
    display: flex !important;
    align-items: start !important;
    flex-direction: column !important;
    margin-bottom: 32px;
    margin-top: 44px;
    padding-top: 24px;
    padding-left: 14px;
    border-top: 1px solid #dedddd;
}

.dnedzkfesdndze{
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    margin-bottom: 32px;
    margin-top: 40px;
    padding-top: 11px;
    padding-left: 0px;
}
.dnedzkndfecze {

}
.dnedzsqfsqfqskndze {
    justify-content: center !important;
    display: flex !important;
    align-items: start !important;
    flex-direction: column !important;
    margin-bottom: 32px;
    margin-top: 40px;
    padding-top: 11px;
    padding-left: 0px;
}

.akdzkjda {
    font-size: 44px !important; 
}

.zdzaqdaz {
    font-size: 22px !important;
}

.dadzadas {
    justify-content: center;
    display: flex;
    place-self: center;
    margin-top: 0px !important;
    border-top: 0px solid #dedddd !important;
}
.clt-section-2-row {
    border-bottom: 1px solid #dedddd;
    border-top: 1px solid #dedddd;
    padding-top: 0px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
}

.clt-section-2-row::-webkit-scrollbar {
    display: none;
}

.clt-section-2-row-container {
    padding-right: 14px !important;
}

.clt-section-2-row-col {
    padding-right: 14px !important;
}

.clt-section-2-row-col-home-sticked {
    padding-left: 0px !important;
}

.clt-section-2-row.stickyBar.row.is-sticky {
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 2;
    box-shadow: 0 2px 16px rgba(0, 0, 0, .12) !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    animation: fadeIn 1.5s;
}

.clt-section-2-row.stickyBar.row {
    position: unset;
    width: unset;
    z-index: unset;
    background: #fff;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}


.clt-section-2-row-home {
    border-bottom: 1px solid #bdbdbd !important;
    padding-top: 0px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    margin-top: 22px;
    margin-bottom: 26px;
}

.clt-section-2-row-home::-webkit-scrollbar {
    display: none;
}

.clt-section-2-row-home-container {
    padding-left: 0px !important;
    padding-right: 14px !important;
}

.clt-section-2-row-home-col {
    padding-left: 14px !important;
    padding-right: 14px !important;
}

.clt-section-2-row-home.stickyBar.row.is-sticky {
    position: fixed;
    border-top: none;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 2;
    box-shadow: 0 2px 16px rgba(0, 0, 0, .12) !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    animation: fadeIn 1.5s;
}

.clt-prestataire-option-div {
    padding-left: 24px;
    padding-right: 24px;
}

.clt-detail-footer-overflow-hidden {
    overflow: hidden;
}

.clt-section-2-row-home.stickyBar.row {
    position: unset;
    width: unset;
    z-index: unset;
    background: #ebebeb;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.clt-home-visite-h2354653422kjczncdizdn {
    display: none;
}

div.eapps-widget {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
}

#eapps-instagram-feed-1 {
    width: 64% !important;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
}

.eapps-instagram-feed-posts-slider-inner {
    z-index: 1;
    position: relative;
    height: auto;
    display: flex;
    width: 100%;
    width: 1271px;
}

.eapps-instagram-feed-header-inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left !important;
    align-items: center;
    margin: 0 auto;
    max-width: 600px;
    margin-left: 0px !important;
}

.eapps-instagram-feed-header {
    background: #fff;
    padding: 20px;
    padding-left: 0px !important;
}

.fa, .fas {
    font-weight: 900;
    font-size: 15px;
    margin-right: 1px;
}

.clt-detail-left-section-4-card-item-rating{
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px;
}

.clt-detail-left-section-4-card-item-ratingkjdcnlksdn {
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px;
}
.icon-calendar-review {
    width: 15px;
    margin-right: 4px;
}
@keyframes fadeIn {
    0% {
        opacity: 0.5;
        background: #fff;
    }

    50% {
        opacity: 0.8;
        background: #fff;
    }

    100% {
        opacity: 1;
        background: #fff;
    }
}

.clt-section-2 div:last-child {
    margin-right: 0px;
    margin-bottom: -1px;
}




.clt-section-2-divs {
    margin-right: 22px;
    cursor: pointer;
    user-select: none;
    background: #fff;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 7px;
}

.clt-section-2-divs:hover {
    background: #f6f6f6 ;
}

.clt-section-2-divs:active {
    background: #eee;
}

.clt-section-2-divs img {
    width: 18px;
}

.clt-section-2-divs h2 {
    font-size: 14.5px;
    font-weight: 600;
    font-family: 'Inter';
    margin-bottom: 0px;
    padding-bottom: 9px;
    margin-top: 6px;
    color: #70757a;
    line-height: 16px;
}

.fixed-height {
    height: 200px; /* Adjust this value to suit your layout */
    overflow: hidden;
  }
  .fixed-height {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items */
    justify-content: center; /* Center align items vertically */
  }

  
.sticky-sectionefzef {
    position: sticky;
    top: 0; /* Adjust this value as needed */
    background: white; /* Ensure the background is set to avoid overlap issues */
    z-index: 10; /* Adjust z-index as needed */
}
.clt-section-3 {
    border-bottom: 1px solid #e0e0e0;
    padding-top: 23px;
    padding-right: 0px;
    padding-bottom: 49px;
    padding-left: 0px;
}

.inideznidnez {
    margin-top: 42px;
}

.aclt-section-3 {
    padding-top: 23px;
    padding-right: 0px;
    padding-bottom: 49px;
    padding-left: 0px;
    flex-direction: row;
    display: flex;
}

.clt-section-3-event {
    border-bottom: 0px solid #e0e0e0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 18px;
    padding-left: 0px;
    margin-top: 34px;
}

.cdcdscwxcxwcd {
    border-bottom: 1px solid #e0e0e0;
}

.carousel-arrow-buttons {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .carousel-arrow-buttons-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .disable {
    pointer-events: none;
    opacity: 0.5;
  }
  

.clt-home-visite-h2-header {
    position: relative;
  }
  
  .showMobile .clt-home-visite-h2-header {
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    padding: 10px 20px;
  }
  
  .disable {
    pointer-events: none;
    opacity: 0.5;
  }
  
.clt-section-Btn {
    width: 250px;
    height: 244px;
    border-radius: 0px;
    overflow: hidden;
    margin-right: 15px;
    cursor: default;
    user-select: none;
}

.clt-section-3-cardsImages {
    width: 100%;
    height: 244px;
    border-radius: 0px;
    overflow: hidden;
    margin-right: 15px;
    cursor: default;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.clt-section-3-row {
    /* padding-top: 20px; */
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-right: 0px;
    margin-left: 0px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.clt-section-3-row::-webkit-scrollbar {
    display: none;
}

.clt-section-3-row {
    -ms-overflow-style: none;
}

.clt-section-3-row-container {
    padding-left: 24px !important;
    padding-right: 0px !important;
}

.clt-section-3-row-col {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

/* .clt-section-3 div:last-child {
    margin-right: 0px;
    padding-right: 15px; 
}*/

.clt-section-3-cards-div div:last-child {
    margin-right: 0px;
    margin-bottom: 4px;
}

.clt-section-3-h2-mt {
    margin-top: 40px;
}

.clt-section-3-h2 {
    font-size: 48px;
    font-weight: 600;
    font-family: 'Lora';
    margin-bottom: 0px;
    color: #242424;
    line-height: 50px;
    letter-spacing: 0.2px;
}

.clt-section-3-h2-second {
    font-size: 70px;
    margin-top: 22px;
    margin-bottom: 24px;
    margin-right: 7px;
    font-weight: 700;
    font-family: 'Parisienne', Great Vibes;
    margin-bottom: 0px;
    color: #242424;
    line-height: 32px;
    letter-spacing: -0.2px;
}

.clt-section-3-cards-arrowBtn,
.clt-section-3-cards-arrowBtn:focus,
.clt-section-3-cards-arrowBtn:active:focus {
    width: 52px;
    height: 52px;
    border-radius: 500px;
    overflow: hidden;
    background: #eee;
    border: 1px solid #eee;
    box-shadow: none;
    color: #242424;
    opacity: 1;
}

.clt-section-3-cards-arrowBtn:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #eee;
}

.clt-section-3-cards-arrowBtn:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #d2d2d3;
}

.clt-section-3-cards-arrowBtn.disable,
.clt-section-3-cards-arrowBtn.disable:focus {
    opacity: 0.35;
    user-select: none;
    cursor: default;
}

.clt-section-3-cards-arrowBtn45354,
.clt-section-3-cards-arrowBtn45354:focus,
.clt-section-3-cards-arrowBtn45354:active:focus {
    width: 52px;
    height: 52px;
    border-radius: 500px;
    overflow: hidden;
    background: #eee;
    border: 1px solid #eee;
    box-shadow: none;
    color: #242424;
    opacity: 1;
}

.clt-section-3-cards-arrowBtn45354:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #eee;
}

.clt-section-3-cards-arrowBtn45354:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #d2d2d3;
}

.clt-section-3-cards-arrowBtn45354.disable,
.clt-section-3-cards-arrowBtn45354.disable:focus {
    opacity: 0.35;
    user-select: none;
    cursor: default;
}



.clt-section-3-cards {
    width: 255px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 15px;
    cursor: default;
    user-select: none;
    height: 100%;
    width: 100%;
    overflow: hidden;
    height: 307px;
}

.clt-section-3-cards-main {
    background-repeat: no-repeat;
    background-position: right 10px bottom;
    background-size: 30%;
    align-items: end !important;
}

.clt-section-3-cards-main h2 {
    font-size: 32px;
    letter-spacing: -.2px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 0;
    margin-top: 47px;
    padding: 0px 23px 38px;
    color: #fff;
    width: 80%;
    line-height: 32px;
}

.clt-section-3-cards-secondary {
    background: #ebebeb;
    border-bottom: 2px solid #bdbdbd;
    border-top: 1px solid #bdbdbd;
    padding: 0px;
    border-left: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
    align-items: end;
    justify-content: start !important;
}

.clt-section-3-cards-secondary h2 {
    font-size: 34px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 30px;
    color: #242424;
    line-height: 24px;
}

.clt-section-3-cards-secondary span {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -.2px;
    font-family: Mulish;
    color: #242424;
}

.clt-section-3-cards-secondary p {
    font-weight: 400;
    font-size: 18px;
    font-family: Inter;
    padding-right: 8px;
    color: #242424;
    margin-bottom: 0;
    line-height: 24px;
    margin-top: 2px;
}

.clt-section-3-cards-secondary a {
    font-weight: 600;
    font-size: 18px;
    font-family: Inter;
    padding-right: 8px;
    color: #242424;
    margin-bottom: 0;
    line-height: 19px;
    margin-top: 2px;
    text-decoration: underline !important;
    cursor: pointer;
}

.clt-section-3-cards-secondary a:hover {
    color: #828282 !important;
}

.clt-section-3-cards-secondary a:active {
    color: #b9b9b9 !important;
}

.clt-section-3-cards-end {
    padding: 30px 20px;
}

.clt-section-3-cards-end h2 {
    font-size: 27px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 30px;
    margin-top: 10px;
    color: #fff;
    line-height: 32px;
}

.clt-section-3-cards-button,
.clt-section-3-cards-button:focus {
    background: #fff;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: 'Mulish';
    font-size: 16.5px;
    font-weight: 700;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    padding: 0px 10px;
}

.clt-section-3-cards-button:active {
    background: #ddd !important;
    border: 1px solid #ddd !important;
    color: #242424 !important;
}

.clt-section-3-cards-button:hover {
    background: #eee !important;
    border: 1px solid #eee !important;
    color: #242424 !important;
}

.clt-section-3-cards-button i {
    font-family: fontawesome;
    margin-left: 5px;
}

.clt-section-4 {
    position: relative;
    background-color: #f7eee1;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px 0px;
    padding-top: 70px;
    padding-right: 40px;
    padding-bottom: 70px;
    padding-left: 40px;
}

.faq-section {
    width: 100%;
    border: 1px solid #dedddd;
    border-radius: 24px;
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding-top: 22px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
  }

  .faq-item:last-child {
    border-bottom: none;
}
  
  .faq-question-wrapper {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .faq-question_subtitle {
    font-size: 18px;
    font-family: inter;
    font-weight: 400;
    color: #818181;
    margin-bottom: 2px;
  }
  
  .faq-question {
    font-size: 18px;
    font-family: inter;
    font-weight: 500;
    color: #222;
    margin-bottom: 0px;
  }
  .faq-answer {
    padding-top: 14px;
    padding-right: 14px;
    padding-bottom: 1px;
    padding-left: 14px;
    border-radius: 14px;
    margin-top: 13px;
    background: #eee !important;
    font-size: 18px;
  }
  
  .faq-arrow {
    width: 20px;
    height: 10px;
    transition: transform 0.3s ease;
  }


.clt-section-4-img-desktop {
    width: 100%;
}

.clt-section-4-img-mbl {
    display: none;
    width: 100%;
}

.clt-section-4 h2 {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    font-size: 32px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'Mulish';
    margin-bottom: 0;
    width: 33%;
    text-align: center;
    line-height: 40px;
    color: #242424;
}

.clt-section-5 {
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 46px;
    padding-left: 0px;
}

.clt-section-5-h2 {
    font-size: 26px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'MULISH';
    line-height: 28px;
    color: #242424;
    margin-top: 18px;
    margin-right: 0px;
    margin-bottom: 34px;
    margin-left: 0px;
}

.clt-section-home-aide-h2 {
    font-size: 26px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'MULISH';
    line-height: 28px;
    color: #242424;
    margin-top: 31px;
    margin-right: 0px;
    margin-bottom: 2px;
    margin-left: 0px;
}


.clt-section-home-aide-h2b {
    font-size: 48px;
    justify-content: start;
    letter-spacing: 0.2px;
    display: flex;
    font-weight: 600;
    font-family: 'Lora';
    line-height: 28px;
    color: #242424;
    margin-top: 35px;
    margin-right: 0px;
    margin-bottom: 21px;
    margin-left: 0px;
    border: none;
    padding-top: 35px;
    border-top: 1px solid #dedddd;
}

.clt-section-home-aide-h2a {
    font-size: 26px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'MULISH';
    line-height: 28px;
    color: #242424;
    margin-top: 31px;
    margin-right: 0px;
    margin-bottom: 2px;
    margin-left: 0px;
    border-top: 1px solid #dedddd;
    padding-top: 29px;
    margin: 0px 0px 34px 0px;
}

.clt-section-home-aide-h2aa {
    font-size: 26px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'MULISH';
    line-height: 28px;
    color: #242424;
    margin-top: 31px;
    margin-right: 0px;
    margin-bottom: 2px;
    margin-left: 0px;
    border-top: 1px solid #dedddd;
    padding-top: 29px;
    margin: 0px 0px -37px 0px;
}

.clt-section-event-h2 {
    font-size: 26px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'MULISH';
    line-height: 28px;
    color: #242424;
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.clt-section-5-accordion-header button {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter';
    background: #f0f0f0 !important;
    padding: 26px 26px;
}

.clt-section-5-accordion-header button:active {
    background: rgb(204, 204, 204) !important;
}

.clt-section-5-accordion-header button:hover {
    background: #dbdbdb !important;
}

.clt-section-5-accordion-header button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    background: #f0f0f0 !important;
    color: #242424;
}

.clt-timeslots-accordion-header button {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter';
    background: #fff !important;
    padding: 26px 0px;
}

.clt-timeslots-accordion-header button:active {
    background: #fafafa !important;
}

.clt-timeslots-accordion-header button:hover {
    background: #f6f6f6 !important;
}

.clt-timeslots-accordion-header button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    background: #fcfcfc !important;
    color: #242424;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}

.clt-section-5-accordion-item:first-of-type {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    overflow: hidden;
    border-top: 1px solid #bdbdbd !important;
    border-left: 1px solid #bdbdbd !important;
    border-right: 1px solid #bdbdbd !important;
    border-bottom: 0px solid #bdbdbd !important;
}

.clt-section-5-accordion-item:not(:first-of-type) {
    border-left: 1px solid #bdbdbd !important;
    border-right: 1px solid #bdbdbd !important;
    border-top: 1px solid #bdbdbd !important;
}

.clt-section-5-accordion-item:last-of-type {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    overflow: hidden;
    border: 1px solid #bdbdbd !important;
}

.clt-section-5-accordion-body {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 400;
    font-family: 'inter';
    padding: 0 26px 26px;
    background: #f0f0f0;
}

.clt-section-5-accordion-header button:not(.collapsed) {
    color: #242424 !important;
    box-shadow: none !important;
}

.clt-timeslots-accordion-header button:not(.collapsed) {
    color: #242424 !important;
    box-shadow: none !important;
}

.clt-section-5-accordion-body p {
    margin-bottom: 25px;
}

.clt-section-5-accordion-body a {
    color: #242424 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.clt-section-5-accordion-body a:hover {
    color: #828282;
}

.clt-section-5-accordion-body a:active {
    color: #b9b9b9;
}

.clt-section-6 {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 400px;
    background-color: #a8c39c;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 60px;
    z-index: -2;
}

.clt-section-6 h2 {
    margin-top: -9px;
    margin-bottom: 4px;
    font-size: 62px;
    letter-spacing: -0.2px;
    font-weight: 500;
    font-family: 'Crimson text';
    color: #fff;
    text-align: left;
}

.overlay-img {
    width: 60%;
    height: 40%;
    object-fit: cover;
    border-radius: 18px;
}
.clt-home-visite-h2

.clt-section-6 img {
    width: 100%;
    margin-top: 0px;
    margin-right: 0px;
    object-fit: cover;
    margin-bottom: 0px;
    margin-left: 0px;
}

.clt-section-help-contact{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 255px;
    background-color: #24775b;
    border-radius: 20px;
    overflow: hidden;
    margin: 18px 0px;
    padding: 0px 0px;
}

.clt-section-help-contact h2 {
    margin-top: -9px;
    margin-bottom: 4px;
    font-size: 22px;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: 'Inter';
    color: #242424;
    margin-bottom: 19px;
}

.clt-section-help-contact c {
    font-size: 17px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 28px;
    color: #686868;
    margin-top: -4px;
    margin-right: 0px;
    margin-bottom: -4px;
    margin-left: 0px;
    padding-top: 6px;
    padding-bottom: 13px !important;
    border-top: 0px solid #dedddd;
}

.clt-section-help-contact p {
    margin-top: -12px;
    margin-bottom: 4px;
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 400;
    font-family: Inter;
    color: #242424;
    margin-bottom: 13px;    
}


.clt-section-help-contact img {
    width: 10%;
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 39px;
    margin-left: 0px;
}

.clt-section-6-btn,
.clt-section-6-btn:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
    outline: none;
    border: 1px solid #eee;
    position: relative;
    width: auto !important;
    height: 46px;
    letter-spacing: 0.1px;
    white-space: nowrap;
    padding: 10px 28px 15px;
}

.clt-section-6-btn:hover {
    background: #eee;
    border: 1px solid #eee !important;
    color: #242424 !important;
    white-space: nowrap;
}

.clt-section-6-btn:active {
    background: #ddd !important;
    border: 1px solid #ddd !important;
    color: #242424 !important;
    white-space: nowrap;
}

.clt-section-7 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 40px 0px;
}

.clt-section-7 h2 {
    font-size: 23px;
    letter-spacing: -.2px;
    font-weight: 700;
    font-family: Mulish;
    color: #242424;
    margin-bottom: 0;
}

.clt-section-7 p {
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    font-size: 15px;
    font-weight: 400;
    font-family: Inter;
    color: #242424;
}

.clt-section-7-btn,
.clt-section-7-btn:hover,
.clt-section-7-btn:focus,
.clt-section-7-btn:active {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: 600;
    height: 46px;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    padding-top: 10px;
    padding-right: 28px;
    padding-bottom: 15px;
    padding-left: 28px;
}

.clt-section-7-btn:hover {
    background: #d9dce0;
    color: #242424 !important;
    border: 1px solid #d9dce0 !important;
}

.clt-section-7-btn:active {
    background: #c0c0c0 !important;
    color: #242424 !important;
    border: 1px solid #c0c0c0 !important;
}

.clt-section-7-btn i {
    font-family: fontawesome;
    margin-left: 10px;
}

.clt-section-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: flex-start;
    background-color: none !important;
    color: #212529 !important;
    border-radius: 20px;
    margin: 6px 0px 40px 0px;
    padding: 30px 28px;
    border: 1px solid #d3d3d3;
}

.clt-section-8 a {
    font-size: 18px;
    color: #212529 !important;
    font-weight: 700;
    margin-bottom: 12px;
    letter-spacing: -0.2px;
    font-family: Mulish;
    text-decoration: none;
    cursor: pointer;
}

.clt-section-8 a:hover {
    color: #5e6369 !important;
}

.clt-section-8 a:active {
    color: #787c80 !important;
}


/* .clt-section-8 span {
    font-size: 14px;
    color: #212529 !important;
    font-weight: 700;
    margin-bottom: 12px;
    letter-spacing: -0.2px;
    font-family: Mulish;
    text-decoration: none;
    display: inline-block !important;
    cursor: pointer;

} */
.div-a-tag {
    font-size: 14px;
    color: #212529 !important;
    font-weight: 700;
    margin-bottom: 12px;
    letter-spacing: -0.2px;
    font-family: Mulish;
    text-decoration: none;
    display: inline-block !important;
    width: auto;

}

.clt-section-8 p {
    font-size: 17px;
    color: #585858 !important;
    font-weight: 500 !important;
    margin-bottom: 12px;
    font-family: Mulish;
}

.clt-section-8 span:hover {
    color: #b5b5b5;
}

/* .clt-section-8 p {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 200;
    font-family: Mulish;
    color: #fff;
    margin: 0px;
} */

.showMobile {
    display: none !important;
}

.clt-hr-mbl {
    display: none !important;
}

.clt-hr-mbl hr,
.clt-hr {
    color: #726c6c;
    height: 1px;
    margin: 0;
}

/* css starts new */
.clt-columns {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
}

/* .footer-container{
    background-color: #24775b;
    color: yellow;
    width: 100% !important;
} */

.clt_homepage_section_event_text {
    font-size: 18px !important;
    font-family: "Inter" !important;
    color: #242424 !important;
    text-transform: none !important;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt_homepage_section_event_text:hover {
    background-color: #f1f1f1 !important;
}

.clt_homepage_section_event_text:active {
    background-color: #f1f1f1 !important;
}

.clt_homepage_section_event_box {
    text-decoration: none;
    display: flex;
    padding: 10px 0px;
    margin: 2px 0px;
    cursor: pointer;
    border-radius: 9px;
}

.clt_homepage_section_event_box:hover {
    background-color: #f6f6f6;
}

.clt_homepage_section_event_box:active {
    background-color: #eee;
}

.clt_homepage_section_event_image {
    width: 108px;
    height: 108px;
    object-fit: cover;
    background-color: #f6f6f6;
    border-radius: 18px;
}

.clt-section-3-h2-div {
    flex-direction: column !important;
    display: flex;
    gap: 0px;
    margin-top: 29px;
    align-items: flex-start;
    justify-content: center;
}

.clt-home-visite-h2-header2321354344343 {
    border-top: 0px solid #dedddd;
}

.clt-home-visite-h2-header232135342154344343 {
    border-bottom: 1px solid #dedddd !important;
}

.dzajdnlziajd {
    flex-direction: column;
    display: flex;
}
.zadazdqdqad {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align-last: inherit;
    padding-top: 22px;
}

.zadazdqdzdzdqad {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align-last: center;
}


.slider-controls {
    margin-bottom: -126px;
    margin-right: 70px;
  }

  .es-header-user-full-name {
    font-size: 18px;
}

.es-header-user-username {
    font-size: 15px;
}

.eapps-instagram-feed-header-stats-item-label {
    font-size: 15px !important;
}

.eapps-instagram-feed-header-stats-item-value {
    font-size: 15px !important;
}

.fnjsnksjn {
    font-family: 'Inter';
    font-weight: 600 !important;
    text-decoration: underline !important;
    color: #222 !important;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-homepage-container {
        max-width: 100% !important;
    }

    .es-header-user-full-name {
        font-size: 16px;
    }

    .es-header-user-username {
        font-size: 13,5px;
    }

    .eapps-instagram-feed-header-stats-item-label {
        font-size: 13,5px !important;
    }
    
    .eapps-instagram-feed-header-stats-item-value {
        font-size: 13,5px !important;
    }

    .clt-detail-left-section-2-cards-div {
        padding-left: 32px !important;
    }
    

    .Typewriter__wrapper {
        margin-left: 0px;
    }

    .clt_homepage_section_event_text {
        font-size: 16px !important;
    }

    .clt-homepage-container3 {
        max-width: 100% !important;
    }

    .clt-modal-body-review{
        padding: 24px;
    }

    .clt-homepage-container2 {
        max-width: 100% !important;
    }

    .clt-section-nav-col {
        padding-left: 0px;
    }

    .eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-text {
        font-size: 16px !important;
    }
    
    

    .clt-navbar-row-col-pd-left {
        padding-left: 24px !important;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 10.5px;
        margin-left: 5px;
    }

    .clt-navbar-row-col-pd-right {
        padding-right: 22px !important;
    }

    .clt-section-row-col-pd {
        padding-left: 40px !important;
        padding-right: 30px !important;
        margin: 0px;
    }

    .clt-section-row-col-pddkslnksdkj {
        padding-left: 24px !important;
        padding-right: 24px !important;
        align-items: self-start !important;
        justify-content: flex-start !important;
        padding-bottom: 0px !important;
        margin-left: 14px !important;
    }

    .clt-section-row-col-pddksln436545ksdkj {
        align-items: self-start !important;
        justify-content: flex-start !important;
        padding-bottom: 35px !important;
        padding-left: 16px !important;
        margin-left: 24px !important;
        padding-right: 28px !important;
    }

    

    .clt-section-row-col-pddddd {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin: 0px !important;
    }


    .clt-search-btn-home,
    .clt-search-btn-home:hover,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:active {
        height: 57px;
        border-radius: 45px;
        background-color: #eee;
        padding-left: 46px;
        padding-bottom: 10px;
        color: #242424;
        outline: none;
        border: none;
        box-shadow: none;
        font-weight: 600;
        width: 100%;
        text-align: left;
        font-size: 16px;
    }

    .clt-hr-mbl {
        display: block !important;
    }

    .showMobile {
        display: flex !important;
    }

    .hideMobile {
        display: none !important;
    }


    .clt-section-2-divs img {
        width: 18px;
    }

    .clt-section-1-div {
        height: 430px;
        padding-top: 32px;
        padding-right: 20px;
        padding-bottom: 54px;
        padding-left: 20px;
        margin-top: 0px;
        margin-bottom: 2px;
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        margin-top: 24px;
        margin-right: 24px;
        margin-bottom: 38px;
        margin-left: 24px;
    }

    .clt-home-visite-h2-header {
        padding-top: 28px;
    }


    .clt-section-1-div-inner {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        margin-left: 0px;
    }

    .clt-section-1-h2 {
        font-size: 50px;
        font-weight: 500;
        font-family: Crimson Text;
        text-align: center;
        letter-spacing: 1px;
        padding: 0px 0px;
        margin-bottom: 16px;
        margin-right: 0px;
        margin-top: 16px;
        margin-left: 6px;
        line-height: 48px;
        color: #fff;
        width: 100%;
    }

    .clt-section-1-h2-1 {
        font-size: 20px;
        font-weight: 300;
        font-family: 'inter';
        text-align: center;
        padding: 0px 0px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-top: -7px;
        letter-spacing: 0.5px;
        margin-left: 6px;
        line-height: 25px;
        color: #fff;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:hover,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:active {
        border-radius: 55px;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 20px;
        padding-top: 18px;
        height: 16px;
        width: 40%;
        margin-left: 6px;
        margin-bottom: 76px;
    }

    .clt-section-2-divs {
        margin-right: 14px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        cursor: pointer;
        user-select: none;
    }

    .clt-section-2-divs h2 {
        padding-bottom: 18px;
        margin-top: 9px;
        letter-spacing: -0.2px;
        padding-bottom: 18px;
        margin-top: 9px;
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-section-2-row {
        border-bottom: 1px solid #dedddd;
        border-top: 1px solid #dedddd;
        padding-top: 0px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .clt-section-2-row-col {
        padding-left: 17px !important;
        padding-right: 12px !important;
    }

    .clt-section-3 {
        padding-top: 26px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
        border-bottom: none;
    }

    .aclt-section-3 {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
    }

    .clt-section-3-event {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 30px;
        border-bottom: 0px solid #e0e0e0;
        padding-left: 0px;
        margin-top: 0px;
    }


    .clt-section-3-h2-mt {
        margin-top: 35px;
    }

    .clt-section-3-h2 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 2px;
        margin-top: 29px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-h2-second {
        font-size: 44px;
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 10px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-cards-main h2 {
        font-size: 33px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 14px;
        margin-top: 22px;
        padding: 20px;
        width: 80%;
        line-height: 39px;
    }


    .clt-section-3-cards-end h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: Mulish;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #fff;
        line-height: 32px;
    }

    .clt-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 21px;
        margin-top: 5px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:hover,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:active {
        border-radius: 50px;
        font-size: 14px;
        font-weight: 700;
        padding: 0 10px 3px;
    }

    .clt-section-4 {
        position: relative;
        margin: 20px 0px;
        padding-top: 50px;
        padding-right: 0px;
        padding-bottom: 74px;
        padding-left: 0px;
    }

    .clt-section-4-img-desktop {
        display: none;
    }

    .clt-section-4-img-mbl {
        display: block;
    }

    .clt-section-4 h2 {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        width: 90%;
        text-align: left;
        line-height: 34px;
        padding: 0px 20px;
        margin-bottom: 50px;
    }

    .clt-section-5 {
        padding: 30px 0px;
    }

    .clt-section-5-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 34px 0px;
    }

    .clt-section-home-aide-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 28px 0px 34px 0px;
    }

    .clt-section-home-aide-h2b {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 2px 0px;
        border-top: 0px solid #dedddd;
        justify-content: start;
    }
    .clt-section-home-aide-h2a {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        border-top: 1px solid #dedddd;
        padding-top: 29px;
        margin: 0px 0px 34px 0px;
    }

    .clt-section-event-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 22px 0px 0px 0px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 20px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 0px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        padding: 0px 20px 25px 20px;
    }

    .clt-section-6 {
        height: 262px;
        border-radius: 20px;
        margin-top: 40px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        padding: 0px 20px;
        background-color: #a8c39c;
    }

    .clt-section-6 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 35px; 
        letter-spacing: -0.2px;
        font-weight: 500;
        text-align: left;
    }

    .clt-section-6 img {
    }

    .clt-section-help-contact {
        height: 231px;
        border-radius: 0px;
        margin-top: 15px;
        margin-right: 0px;
        margin-bottom: 122px;
        margin-left: 0px;
        padding: 0px 0px;
    }

    .clt-section-help-contact h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 22px;
    }

    .clt-section-help-contact c {
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: -4px;
        margin-right: 0px;
        margin-bottom: -4px;
        margin-left: 0px;
        padding-top: 6px;
        padding-bottom: 6px !important;
        border-top: 0px solid #dedddd;
    }

    .border_top_light {
        border-top: 1px solid #e0e0e0;
        margin-top: 28px;
    }

    .clt-section-help-contact p {
        margin-top: -9px;
        margin-bottom: 4px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        color: #242424;
        margin-top: 9px;
        margin-bottom: 7px;
    }

    .clt-section-help-contact img {
        width: 32%;
        margin: 30px 0px;
    }

    .clt-section-6-btn,
    .clt-section-6-btn:hover,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 52px;
    }

    .clt-section-7 {
        margin: 30px 0px;
    }

    .clt-section-7 h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-section-7 p {
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        line-height: 1.3;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:hover,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 14px;
        padding-left: 20px;
        width: 100%;
        height: 54px;
    }

    .clt-section-8 {
        border-radius: 20px;
        margin: 0px 0px 40px 0px;
        padding: 30px 28px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 15px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin: 0px;
    }

    .clt-search-home {
        padding: 20px 0px;
        margin: 0px 10px;
    }

    .clt-section-3-cards-button i {
        margin-left: 5px;
        font-size: 12px;
    }

    .clt-section-2 div:last-child {
        margin-right: 0px;
    }


    .clt-section-Btn {
        width: 220px;
        margin-right: 8px;
    }


    .clt-section-2-row-home {
        border-bottom: 1px solid #dedddd;
        padding-left: 24px !important;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 16px;
    }

    .clt-section-3-cards {
        white-space: normal;
        flex-shrink: 0;
        width: 235px;
    }

    #eapps-instagram-feed-1 {
        width: 1392px !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 25px;
    }


    .announcement-text {
        margin-bottom: 12px;
        margin-top: 8px;
        margin-left: 35px;
        letter-spacing: 0.2px;
        color: #ffffff;
    }

    .clt-section-3-row-col {
        padding-left: 0px !important;
        padding-right: 24px !important;
    }

    .clt-section-3-row-container {
        padding-left: 28px  !important;
        padding-right: 0px !important;
    }

    .clt_homepage_section_event_image {
        width: 86px;
        height: 86px;
        object-fit: cover;
        background-color: #f6f6f6;
        border-radius: 12px;
    }
    .clt-home-visite-h2-header2321354344343 {
        border-top: 0px solid #dedddd;
    }

    .slider-controls {
        margin-bottom: -26px;
        margin-right: 10px;
    }


    .clt_icon_play_pause {
        margin-left: 45px;
    }

    .clt-home-visite-h2-header9654852{
        justify-content: center !important;
    }

    .faq-question {
        font-size: 16px;
        font-family: inter;
        font-weight: 500;
        color: #222;
        margin-bottom: 0px;
    }

    .faq-question_subtitle {
        font-size: 15px;
        font-family: inter;
        font-weight: 400;
        color: #818181;
        margin-bottom: 2px;
    }

    .faq-section {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .faq-answer {
        font-size: 16px;
    }

    .dnedzkndze {
        justify-content: flex-start !important;
        display: flex !important;
        align-items: start !important;
        flex-direction: column !important;
        margin-bottom: -2px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    .dnedzkcsqcsqcndze {
        justify-content: flex-start !important;
        display: flex !important;
        align-items: start !important;
        flex-direction: column !important;
        margin-bottom: 32px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    .clt-detail-left-section-4-card-item-rating{
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
    }

    .clt-detail-left-section-4-card-item-ratingkjdcnlksdn {
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
    }

    .fa, .fas {
        font-weight: 900;
        font-size: 13px;
        margin-right: 1px;
    }

    .dazdqsdzadazd {
        background: radial-gradient(circle at top, #f1f1f1 0%, rgba(255, 0, 0, 0) 10%);
    }
    
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-homepage-container {
        max-width: 100% !important;
    }

    .clt-home-visite-h2-header2321354344343 {
        border-top: 0px solid #dedddd;
    }

    .clt_homepage_section_event_text {
        font-size: 16px !important;
    }

    .clt-detail-left-section-2-cards-div {
        padding-left: 32px !important;
    }

    .clt_homepage_section_event_image {
        width: 86px;
        height: 86px;
        object-fit: cover;
        background-color: #f6f6f6;
        border-radius: 12px;
    }

    .Typewriter__wrapper {
        margin-left: 0px;
    }

    .clt-homepage-container3 {
        max-width: 100% !important;
    }

    .clt-modal-body-review{
        padding: 24px;
    }
    .eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-text {
        font-size: 16px !important;
    }

    .clt-homepage-container2 {
        max-width: 100% !important;
    }

    .clt-section-nav-col {
        padding-left: 0px;
    }

    .clt-navbar-row-col-pd-left {
        padding-left: 24px !important;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 10.5px;
        margin-left: 5px;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:hover,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:active {
        height: 57px;
        border-radius: 45px;
        background-color: #eee;
        padding-left: 46px;
        padding-bottom: 10px;
        color: #242424;
        outline: none;
        border: none;
        box-shadow: none;
        font-weight: 600;
        width: 100%;
        text-align: left;
        font-size: 16px;
    }




    .clt-navbar-row-col-pd-right {
        padding-right: 22px !important;
    }

    .clt-section-row-col-pd {
        padding-left: 40px !important;
        padding-right: 30px !important;
    }

    .clt-section-row-col-pddkslnksdkj {
        padding-left: 24px !important;
        padding-right: 24px !important;
        align-items: self-start !important;
        justify-content: flex-start !important;
        padding-bottom: 0px !important;
        margin-left: 14px !important;
    }

    .clt-section-row-col-pddksln436545ksdkj {
        align-items: self-start !important;
        justify-content: flex-start !important;
        padding-bottom: 35px !important;
        padding-left: 16px !important;
        margin-left: 24px !important;
        padding-right: 28px !important;
    }

    .clt-hr-mbl {
        display: block !important;
    }

    .showMobile {
        display: flex !important;
    }

    .hideMobile {
        display: none !important;
    }


    .clt-section-2-divs img {
        width: 20px;
    }

    .clt-section-1-div {
        height: 430px;
        padding-top: 32px;
        padding-right: 20px;
        padding-bottom: 54px;
        padding-left: 20px;
        margin-top: 0px;
        margin-bottom: 2px;
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        margin-top: 24px;
        margin-right: 24px;
        margin-bottom: 38px;
        margin-left: 24px;
    }

    .clt-home-visite-h2-header {
        padding-top: 28px;
    }
    .clt-section-1-div-inner {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        margin-left: 0px;
    }

    .clt-section-1-h2 {
        font-size: 50px;
        font-weight: 500;
        font-family: Crimson Text;
        text-align: center;
        letter-spacing: 1px;
        padding: 0px 0px;
        margin-bottom: 16px;
        margin-right: 0px;
        margin-top: 16px;
        margin-left: 6px;
        line-height: 48px;
        color: #fff;
        width: 100%;
    }

    .clt-section-1-h2-1 {
        font-size: 20px;
        font-weight: 300;
        font-family: 'inter';
        text-align: center;
        padding: 0px 0px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-top: -7px;
        letter-spacing: 0.5px;
        margin-left: 6px;
        line-height: 25px;
        color: #fff;
    }


    .clt-section-1-btn,
    .clt-section-1-btn:hover,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:active {
        border-radius: 55px;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 20px;
        padding-top: 18px;
        height: 16px;
        width: 40%;
        margin-left: 6px;
        margin-bottom: 76px;
    }

    .clt-section-2-divs {
        margin-right: 14px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        cursor: pointer;
        user-select: none;
    }

    .clt-section-2-divs h2 {
        padding-bottom: 18px;
        margin-top: 9px;
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-section-2-row {
        border-bottom: 1px solid #dedddd;
        border-top: 1px solid #dedddd;
        padding-top: 0px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .clt-section-2-row-col {
        padding-left: 17px !important;
        padding-right: 12px !important;
    }

    .clt-section-3 {
        padding-top: 26px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
        border-bottom: none;
    }

    .aclt-section-3 {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
    }


    .clt-section-3-event {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 30px;
        border-bottom: 0px solid #e0e0e0;
        padding-left: 0px;
        margin-top: 0px;
    }

    .clt-section-3-cards {
        white-space: normal;
        flex-shrink: 0;
        width: 235px;
    }

    .clt-section-3-h2-mt {
        margin-top: 35px;
    }

    .clt-section-3-h2 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 2px;
        margin-top: 29px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }
    .clt-section-3-h2-second {
        font-size: 44px;
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 10px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-cards-main h2 {
        font-size: 33px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 14px;
        margin-top: 22px;
        padding: 20px;
        width: 80%;
        line-height: 39px;
    }

    .clt-section-3-cards-end h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: Mulish;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #fff;
        line-height: 32px;
    }

    .clt-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 21px;
        margin-top: 5px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:hover,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:active {
        border-radius: 50px;
        font-size: 14px;
        font-weight: 700;
        padding: 0 10px 3px;
    }

    .clt-section-4 {
        position: relative;
        margin: 20px 0px;
        padding-top: 50px;
        padding-right: 0px;
        padding-bottom: 74px;
        padding-left: 0px;
    }

    .clt-section-4-img-desktop {
        display: none;
    }

    .clt-section-4-img-mbl {
        display: block;
    }

    .clt-section-4 h2 {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        width: 90%;
        text-align: left;
        line-height: 34px;
        padding: 0px 20px;
        margin-bottom: 50px;
    }

    .clt-section-5 {
        padding: 30px 0px;
    }

    .clt-section-5-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 34px 0px;
    }


    .clt-section-home-aide-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 28px 0px 34px 0px;
    }

    .clt-section-home-aide-h2b {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 2px 0px;
        border-top: 0px solid #dedddd;
        justify-content: start;
    }

    .clt-section-home-aide-h2a {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        border-top: 1px solid #dedddd;
        padding-top: 29px;
        margin: 0px 0px 34px 0px;
    }


    .clt-section-event-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 22px 0px 0px 0px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 20px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 0px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        padding: 0px 20px 25px 20px;
    }

    .clt-section-6 {
        height: 262px;
        border-radius: 20px;
        margin-top: 40px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        padding: 0px 20px;
    }

    .clt-section-6 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 35px;
        letter-spacing: -0.2px;
        font-weight: 500;
        text-align: left;
    }

    .clt-section-6 img {
    }

    .clt-section-help-contact {
        height: 231px;
        border-radius: 0px;
        margin-top: 15px;
        margin-right: 0px;
        margin-bottom: 122px;
        margin-left: 0px;
        padding: 0px 0px;
    }

    .clt-section-help-contact h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 22px;
    }

    .clt-section-help-contact c {
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: -4px;
        margin-right: 0px;
        margin-bottom: -4px;
        margin-left: 0px;
        padding-top: 6px;
        padding-bottom: 6px !important;
        border-top: 0px solid #dedddd;
    }

    .border_top_light {
        border-top: 1px solid #e0e0e0;
        margin-top: 28px;
    }

    .clt-section-help-contact p {
        margin-top: -9px;
        margin-bottom: 4px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        color: #242424;
        margin-top: 9px;
        margin-bottom: 7px;
    }

    .clt-section-help-contact img {
        width: 32%;
        margin: 30px 0px;
    }

    .clt-section-6-btn,
    .clt-section-6-btn:hover,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 52px;
    }

    .clt-section-7 {
        margin: 30px 0px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-section-7 p {
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        line-height: 1.3;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:hover,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:active {
        border-radius: 40px;
        font-size: 16px;
        font-weight: 600;
        padding-top: 8px;
        padding-right: 20px;
        padding-bottom: 12px;
        padding-left: 20px;
        width: 256px;
        height: 54px;
    }

    .clt-section-8 {
        border-radius: 20px;
        margin: 0px 0px 40px 0px;
        padding: 30px 28px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 15px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin: 0px;
    }

    .clt-search-home {
        padding: 20px 0px;
        margin: 0px 10px;
    }

    .clt-section-3-cards-button i {
        margin-left: 5px;
        font-size: 12px;
    }

    .clt-section-2 div:last-child {
        margin-right: 0px;
    }

    .clt-section-Btn {
        width: 220px;
        margin-right: 8px;
    }

    .clt-section-2-row-home {
        border-bottom: 1px solid #dedddd;
        padding-left: 24px !important;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 16px;
    }

    #eapps-instagram-feed-1 {
        width: 1392px !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 25px;
    }

    .announcement-text {
        margin-bottom: 12px;
        margin-top: 8px;
        margin-left: 35px;
        letter-spacing: 0.2px;
        color: #ffffff;
    }

    .clt-section-3-row-col {
        padding-left: 0px !important;
        padding-right: 24px !important;
    }
    .clt-section-3-row-container {
        padding-left: 28px  !important;
        padding-right: 0px !important;
    }

    .slider-controls {
        margin-bottom: -26px;
        margin-right: 10px;
    }

    .clt_icon_play_pause {
        margin-left: 45px;
    }
    
    .es-header-user-full-name {
        font-size: 16px;
    }

    .es-header-user-username {
        font-size: 13,5px;
    }

    .eapps-instagram-feed-header-stats-item-label {
        font-size: 13,5px !important;
    }
    
    .eapps-instagram-feed-header-stats-item-value {
        font-size: 13,5px !important;
    }
    .clt-home-visite-h2-header9654852{
        justify-content: center !important;
    }

    .faq-question {
        font-size: 16px;
        font-family: inter;
        font-weight: 500;
        color: #222;
        margin-bottom: 0px;
    }
    
    .faq-question_subtitle {
        font-size: 15px;
        font-family: inter;
        font-weight: 400;
        color: #818181;
        margin-bottom: 2px;
    }


    .faq-section {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .faq-answer {
        font-size: 16px;
    }

    .dnedzkndze {
        justify-content: flex-start !important;
        display: flex !important;
        align-items: start !important;
        flex-direction: column !important;
        margin-bottom: -2px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    .dnedzkcsqcsqcndze {
        justify-content: flex-start !important;
        display: flex !important;
        align-items: start !important;
        flex-direction: column !important;
        margin-bottom: 32px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    .clt-detail-left-section-4-card-item-rating{
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
    }

    .clt-detail-left-section-4-card-item-ratingkjdcnlksdn {
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
    }

    .fa, .fas {
        font-weight: 900;
        font-size: 13px;
        margin-right: 1px;
    }

    .dazdqsdzadazd {
        background: radial-gradient(circle at top, #f1f1f1 0%, rgba(255, 0, 0, 0) 10%);
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt-homepage-container {
        max-width: 100% !important;
    }
    .slider-controls {
        margin-bottom: 3px;
        margin-right: 12px;
    }

    .clt_icon_play_pause {
        margin-left: 45px;
    }

    .faq-question {
        font-size: 16px;
        font-family: inter;
        font-weight: 500;
        color: #222;
        margin-bottom: 0px;
    }
    
    .faq-question_subtitle {
        font-size: 15px;
        font-family: inter;
        font-weight: 400;
        color: #818181;
        margin-bottom: 2px;
    }
    
    


    .clt-home-visite-h2-header2321354344343 {
        border-top: 0px solid #dedddd;
    }
    .clt_homepage_section_event_image {
        width: 86px;
        height: 86px;
        object-fit: cover;
        background-color: #f6f6f6;
        border-radius: 12px;
    }
    .clt_homepage_section_event_text {
        font-size: 16px !important;
    }

    .Typewriter__wrapper {
        margin-left: 0px;
    }

    .clt-homepage-container3 {
        max-width: 100% !important;
    }
    .clt-modal-body-review{
        padding: 24px;
    }

    .clt-section-row-col-pddddd {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin: 0px !important;
    }

    .clt-homepage-container2 {
        max-width: 100% !important;
    }

    .clt-section-nav-col {
        padding-left: 0px;
    }

    .clt-navbar-row-col-pd-left {
        padding-left: 24px !important;
    }

    .clt-section-2-row-container {
        padding-left: 0px !important;
        margin-left: 0px !important;
        padding-right: 14px !important;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 10.5px;
        margin-left: 5px;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:hover,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:active {
        height: 57px;
        border-radius: 45px;
        background-color: #eee;
        padding-left: 46px;
        padding-bottom: 10px;
        color: #242424;
        outline: none;
        border: none;
        box-shadow: none;
        font-weight: 600;
        width: 100%;
        text-align: left;
        font-size: 16px;
    }




    .clt-navbar-row-col-pd-right {
        padding-right: 22px !important;
    }

    .clt-section-row-col-pd {
        padding-left: 40px !important;
        padding-right: 30px !important;
    }

    .clt-section-row-col-pddkslnksdkj {
        padding-left: 24px !important;
        padding-right: 24px !important;
        align-items: self-start !important;
        justify-content: flex-start !important;
        padding-bottom: 0px !important;
        margin-left: 14px !important;
    }

    .clt-section-row-col-pddksln436545ksdkj {
        align-items: self-start !important;
        justify-content: flex-start !important;
        padding-bottom: 35px !important;
        padding-left: 16px !important;
        margin-left: 24px !important;
        padding-right: 28px !important;
    }

    .clt-hr-mbl {
        display: block !important;
    }

    .showMobile {
        display: flex !important;
    }

    .hideMobile {
        display: none !important;
    }


    .clt-section-2-divs img {
        width: 20px;
    }

    .clt-section-1-div {
        height: 430px;
        padding-top: 32px;
        padding-right: 20px;
        padding-bottom: 54px;
        padding-left: 20px;
        margin-top: 0px;
        margin-bottom: 2px;
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        margin-top: 24px;
        margin-right: 24px;
        margin-bottom: 38px;
        margin-left: 24px;
    }
    .clt-home-visite-h2-header {
        padding-top: 28px;
    }

    .clt-section-1-div-inner {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        margin-left: 0px;
    }

    .clt-section-1-h2 {
        font-size: 50px;
        font-weight: 500;
        font-family: Crimson Text;
        text-align: center;
        letter-spacing: 1px;
        padding: 0px 0px;
        margin-bottom: 16px;
        margin-right: 0px;
        margin-top: 16px;
        margin-left: 6px;
        line-height: 48px;
        color: #fff;
        width: 100%;
    }

    .clt-section-1-h2-1 {
        font-size: 20px;
        font-weight: 300;
        font-family: 'inter';
        text-align: center;
        padding: 0px 0px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-top: -7px;
        letter-spacing: 0.5px;
        margin-left: 6px;
        line-height: 25px;
        color: #fff;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:hover,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:active {
        border-radius: 55px;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 20px;
        padding-top: 18px;
        height: 16px;
        width: 40%;
        margin-left: 6px;
        margin-bottom: 76px;
    }

    .clt-section-2-divs {
        margin-right: 14px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        cursor: pointer;
        user-select: none;
    }

    .clt-section-2-divs h2 {
        padding-bottom: 18px;
        margin-top: 9px;
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-section-2-row {
        border-bottom: 1px solid #dedddd;
        border-top: 1px solid #dedddd;
        padding-top: 0px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .clt-section-2-row-col {
        padding-left: 17px !important;
        padding-right: 12px !important;
    }

    .clt-section-3 {
        padding-top: 26px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
        border-bottom: none;
    }

    .aclt-section-3 {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
    }

    .clt-section-3-event {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 30px;
        border-bottom: 0px solid #e0e0e0;
        padding-left: 0px;
        margin-top: 0px;
    }

    .clt-section-3-cards {
        white-space: normal;
        flex-shrink: 0;
        width: 235px;
    }

    .clt-section-3-h2-mt {
        margin-top: 35px;
    }

    .clt-section-3-h2 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 2px;
        margin-top: 29px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-h2-second {
        font-size: 44px;
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 10px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-cards-main h2 {
        font-size: 33px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 14px;
        margin-top: 22px;
        padding: 20px;
        width: 80%;
        line-height: 39px;
    }

    .clt-section-3-cards-end h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: Mulish;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #fff;
        line-height: 32px;
    }

    .clt-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 21px;
        margin-top: 5px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:hover,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:active {
        border-radius: 50px;
        font-size: 14px;
        font-weight: 700;
        padding: 0 10px 3px;
    }

    .clt-section-4 {
        position: relative;
        margin: 20px 0px;
        padding-top: 50px;
        padding-right: 0px;
        padding-bottom: 74px;
        padding-left: 0px;
    }

    .clt-section-4-img-desktop {
        display: none;
    }

    .clt-section-4-img-mbl {
        display: block;
    }

    .clt-section-4 h2 {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        width: 90%;
        text-align: left;
        line-height: 34px;
        padding: 0px 20px;
        margin-bottom: 50px;
    }

    .clt-section-5 {
        padding: 30px 0px;
    }

    .clt-section-5-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 34px 0px;
    }

    .clt-section-home-aide-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 28px 0px 34px 0px;
    }

    .clt-section-home-aide-h2b {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 2px 0px;
        justify-content: start;
        border-top: 0px solid #dedddd;
    }

    .clt-section-home-aide-h2a {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        border-top: 1px solid #dedddd;
        padding-top: 29px;
        margin: 0px 0px 34px 0px;
    }


    .clt-section-event-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 22px 0px 0px 0px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 20px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 0px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        padding: 0px 20px 25px 20px;
    }

    .clt-section-6 {
        height: 262px;
        border-radius: 20px;
        margin-top: 40px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        padding: 20px 20px;
    }

    .clt-section-6 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 35px;
        letter-spacing: -0.2px;
        font-weight: 500;
        text-align: left;
    }

    .clt-section-6 img {
    }

    .clt-section-help-contact {
        height: 231px;
        border-radius: 0px;
        margin-top: 15px;
        margin-right: 0px;
        margin-bottom: 122px;
        margin-left: 0px;
        padding: 0px 0px;
    }

    .clt-section-help-contact h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 22px;
    }

    .clt-section-help-contact c {
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: -4px;
        margin-right: 0px;
        margin-bottom: -4px;
        margin-left: 0px;
        padding-top: 6px;
        padding-bottom: 6px !important;
        border-top: 0px solid #dedddd;
    }

    .border_top_light {
        border-top: 1px solid #e0e0e0;
        margin-top: 28px;
    }

    .clt-section-help-contact p {
        margin-top: -9px;
        margin-bottom: 4px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        color: #242424;
        margin-top: 9px;
        margin-bottom: 7px;
    }

    .clt-section-help-contact img {
        width: 32%;
        margin: 30px 0px;
    }

    .clt-section-6-btn,
    .clt-section-6-btn:hover,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 60px;
    }

    .clt_home_footer_container {
        max-width: 633px !important;
    }

    .clt-section-7 {
        margin: 30px 0px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-section-7 p {
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        line-height: 1.3;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:hover,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:active {
        border-radius: 40px;
        font-size: 16px;
        font-weight: 600;
        padding-top: 8px;
        padding-right: 20px;
        padding-bottom: 12px;
        padding-left: 20px;
        width: 256px;
        height: 54px;
    }

    .clt-section-8 {
        border-radius: 20px;
        margin: 0px 0px 40px 0px;
        padding: 30px 28px;
    }
    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 15px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin: 0px;
    }

    .clt-search-home {
        padding: 20px 0px;
        margin: 0px 10px;
    }

    .clt-section-3-cards-button i {
        margin-left: 5px;
        font-size: 12px;
    }

    .clt-section-2 div:last-child {
        margin-right: 0px;
    }

    .clt-section-Btn {
        width: 220px;
        margin-right: 8px;
    }

    .clt-section-2-row-home {
        border-bottom: 1px solid #dedddd;
        padding-left: 24px !important;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 16px;
    }

    #eapps-instagram-feed-1 {
        width: 1392px !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 25px;
    }

    .announcement-text {
        margin-bottom: 12px;
        margin-top: 8px;
        margin-left: 35px;
        letter-spacing: 0.2px;
        color: #ffffff;
    }

    .clt-section-3-row-col {
        padding-left: 0px !important;
        padding-right: 24px !important;
    }

    .eapps-instagram-feed-posts-item-template-classic .eapps-instagram-feed-posts-item-text {
        font-size: 16px !important;
    }

    .clt-section-3-row-container {
        padding-left: 28px  !important;
        padding-right: 0px !important;
    }

    .es-header-user-full-name {
        font-size: 16px;
    }

    .es-header-user-username {
        font-size: 13,5px;
    }

    .eapps-instagram-feed-header-stats-item-label {
        font-size: 13,5px !important;
    }
    
    .eapps-instagram-feed-header-stats-item-value {
        font-size: 13,5px !important;
    }

    .clt-detail-left-section-2-cards-div {
        padding-left: 32px !important;
    }
    .clt-home-visite-h2-header9654852{
        justify-content: center !important;
    }

    .faq-section {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .faq-answer {
        font-size: 16px;
    }

    .dnedzkndze {
        justify-content: flex-start !important;
        display: flex !important;
        align-items: start !important;
        flex-direction: column !important;
        margin-bottom: -2px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    .dnedzkcsqcsqcndze {
        justify-content: flex-start !important;
        display: flex !important;
        align-items: start !important;
        flex-direction: column !important;
        margin-bottom: 32px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    .clt-detail-left-section-4-card-item-rating{
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
    }

    .clt-detail-left-section-4-card-item-ratingkjdcnlksdn {
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
    }

    .fa, .fas {
        font-weight: 900;
        font-size: 13px;
        margin-right: 1px;
    }

    .dazdqsdzadazd {
        background: radial-gradient(circle at top, #f1f1f1 0%, rgba(255, 0, 0, 0) 10%);
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad - vertical view*/
    .clt-homepage-container {
        max-width: 90% !important;
    }
    .clt-homepage-container3 {
        max-width: 90% !important;
    }

    .clt-homepage-container2 {
        max-width: 720px !important;
    }

    .clt-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-section-2-divs img {
        width: 20px;
    }

    .clt-section-2-row-container {
        margin-left: auto;
        padding-left: 0!important;
        padding-right: 14px!important;
    }

    .clt-section-1-div {
        height: 522px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 0px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: #00000096;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:active,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }


    .clt-section-1-h2 {
        font-size: 68px;
        font-weight: 600;
        margin-bottom: 8px;
        margin-right: 0px;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:active,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:hover {
        font-size: 18px;
        height: 46px;
        width: auto;
        padding: 14px 28px 15px;
    }

    .clt-home-visite-h2-header {
        margin-bottom: 20px;
        margin-top: 12px;
        padding-top: 15px;
    }

    .slider-controls {
        margin-bottom: -126px;
        margin-right: 73px;

        
    }

    .clt-section-6 h2 {
        margin-top: -9px;
        margin-bottom: 4px;
        font-size: 47px;
        letter-spacing: -0.2px;
        font-weight: 500;
        font-family: 'Crimson text';
        color: #fff;
        text-align: left;
    }


    .eapps-instagram-feed-posts-slider-inner {
        width: 865px;
    }
    

    .clt-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 112px;
        margin-left: 0px;
    }

    .clt-section-3-h2 {
        font-size: 36px;
        letter-spacing: -.2px;
    }

    .clt-section-3-h2-second {
        font-size: 70px;
    margin-top: 22px;
    margin-bottom: 24px;
    margin-right: 7px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-section-3-cards {
        width: 270px;
        border-radius: 20px;
    }

    .clt-section-3-cards-secondary span {
        font-size: 16px;
    }

    .clt-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-section-4 {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px 0;
        padding: 74px 15px;
    }

    .clt-section-4 h2 {
        font-size: 32px;
    }

    .clt-section-5-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2 {
        font-size: 27px;
    }
    .clt-section-home-aide-h2b {
        font-size: 36px;
    }

    .dnedzkfesdndze {
        justify-content: center !important;
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        margin-bottom: 0px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    .clt-section-home-aide-h2a {
        font-size: 27px;
    }

    .clt-section-event-h2 {
        font-size: 27px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
    }

    .clt-section-6-btn,
    .clt-section-6-btn:active,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-section-6 {
        height: 348px;
    }

    .clt-section-help-contact{
        height: 348px;
    }

    .clt-section-7 p {
        font-size: 17px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:active,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-search-home {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 18px;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 17px;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 16px;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad - horizontal view*/
    .clt-homepage-container {
        max-width: 66%  !important;
    }

    .clt-homepage-container2 {
        max-width: 720px !important;
    }

    .clt-homepage-container3 {
        max-width: 66% !important;
    }

    .clt-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-section-2-divs img {
        width: 20px;
    }

    .clt-section-1-div {
        height: 468px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 0px;
        border-radius: 12px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: #00000096;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:active,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-section-1-h2 {
        font-size: 44px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 8px;
        margin-right: 0px;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:active,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:hover {
        font-size: 18px;
        height: 46px;
        width: auto;
        padding: 14px 28px 15px;
    }

    .clt-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 106px;
        margin-left: 0px;
    }

    .slider-controls {
        margin-bottom: -126px;
        margin-right: 104px;
    }

    .clt-home-visite-h2-header {
        margin-bottom: 20px;
        margin-top: 8px !important;
        padding-top: 15px;
        border-top: 1px #dedddd solid;
        align-items: center;
    }

    .clt-section-1-h2-1 {
        font-size: 28px;
        font-weight: 300;
    }


    #eapps-instagram-feed-1 {
        width: 64% !important;
    }

    .clt-section-6 h2 {
        font-size: 47px;
    }

    .clt-section-3-h2 {
        font-size: 36px;
        letter-spacing: -.2px;
    }

    .clt-section-3-h2-second {
        font-size: 70px;
        margin-top: 22px;
        margin-bottom: 24px;
    margin-right: 7px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-section-3-cards {
        width: 200px;
        border-radius: 20px;
    }

    .clt-section-3-cards-secondary span {
        font-size: 18px;
    }

    .clt-section-3-cards-secondary p {
        font-size: 18px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:active,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:hover {
        font-size: 18px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-section-4 {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px 0;
        padding: 74px 15px;
    }

    .clt-section-4 h2 {
        font-size: 32px;
    }

    .clt-section-5-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2 {
        font-size: 27px;
    }
    .clt-section-home-aide-h2b {
        font-size: 36px;
    }

    .clt-section-home-aide-h2a {
        font-size: 27px;
    }

    .clt-section-event-h2 {
        font-size: 27px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-section-6-btn,
    .clt-section-6-btn:active,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-section-6 {
        height: 348px;
    }

    .clt-section-help-contact{
        height: 348px;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:active,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-section-7 p {
        font-size: 17px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
    }

    .clt-search-home {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 18px;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 17px;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 18px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro - vertical view */
    .clt-homepage-container {
        max-width: 66% !important;
    }

    .clt-homepage-container2 {
        max-width: 960px !important;
    }

    .clt-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-section-2-divs img {
        width: 20px;
    }

    #eapps-instagram-feed-1 {
        width: 64% !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 32px;
    }

    .clt-section-1-div {
        height: 348px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 0px;
        border-radius: 12px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: #00000096;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:active,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-section-1-h2 {
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 24px;
        margin-right: 0px;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:active,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:hover {
        font-size: 18px;
        height: 46px;
        width: auto;
        padding: 14px 28px 15px;
    }

    .clt-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }

    .clt-section-3-h2 {
        font-size: 48px;
        letter-spacing: -.2px;
    }

    .clt-section-3-h2-second {
        font-size: 70px;
    margin-top: 22px;
    margin-bottom: 24px;
    margin-right: 7px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-section-3-cards {
        width: 218px;
        border-radius: 20px;
    }

    .clt-section-3-cards-secondary span {
        font-size: 18px;
    }

    .clt-section-3-cards-secondary p {
        font-size: 18px;
    }

    .clt-section-4 {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px 0;
        padding: 74px 15px;
    }

    .clt-section-4 h2 {
        font-size: 32px;
    }

    .clt-section-5-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2b {
        font-size: 42px;
    }

    .clt-section-home-aide-h2a {
        font-size: 27px;
    }

    .clt-section-event-h2 {
        font-size: 27px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-section-6-btn,
    .clt-section-6-btn:active,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-section-6 {
        height: 348px;
    }

    .clt-section-help-contact{
        height: 348px;
    }


    .clt-section-7-btn,
    .clt-section-7-btn:active,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-section-7 p {
        font-size: 17px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
    }

    .clt-search-home {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 18px;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 17px;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 18px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro - horizontal view*/
    .clt-homepage-container {
        max-width: 66% !important;
    }

    .clt-homepage-container2 {
        max-width: 960px !important;
    }

    .clt-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-section-2-divs img {
        width: 20px;
    }

    .clt-section-1-div {
        height: 588px;
        width: 100%;
        margin-top: 40px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 0px;
        border-radius: 12px;
        background: #00000096;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:active,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-section-1-h2 {
        font-size: 64px;
        font-weight: 600;
        margin-bottom: 16px;
        margin-right: 0px;
    }

    .home-carousel li {
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:active,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:hover {
        font-size: 18px;
        padding: 24px 36px 26px;
        height: 30px;
        width: 26%;
    }

    .clt-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 153px;
    }

    .clt-section-3-h2 {
        font-size: 42px;
        letter-spacing: -.2px;
    }

    .clt-section-3-h2-second {
        font-size: 70px;
    margin-top: 22px;
    margin-bottom: 24px;
    margin-right: 7px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-section-3-cards {
        width: 218px;
        border-radius: 20px;
    }

    .clt-section-3-cards-secondary span {
        font-size: 18px;
    }

    .clt-section-3-cards-secondary p {
        font-size: 18px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:active,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:hover {
        font-size: 18px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-section-4 {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px 0;
        padding: 74px 15px;
    }

    .clt-section-4 h2 {
        font-size: 32px;
    }

    .clt-section-5-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2b {
        font-size: 42px;
    }

    .clt-section-home-aide-h2a {
        font-size: 27px;
    }

    .clt-section-event-h2 {
        font-size: 27px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-section-6-btn,
    .clt-section-6-btn:active,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-section-6 {
        height: 348px;
    }

    .clt-section-help-contact{
        height: 348px;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:active,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-section-7 p {
        font-size: 17px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
    }

    .clt-search-home {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 18px;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 17px;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .home-carousel li {
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
    }

    .clt-section-3-cards-secondary a {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro - vertical view */
    .clt-homepage-container {
        max-width: 66% !important;
    }

    .clt-homepage-container2 {
        max-width: 1140px !important;
    }

    .clt-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-section-2-divs img {
        width: 20px;
    }

    .clt-section-1-div {
        height: 588px;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 15px;
        overflow: hidden;
        position: relative;
        padding: 0px;
        border-radius: 12px;
        background: #00000096;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:active,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-section-1-h2 {
        font-size: 44px;
        margin-bottom: 24px;
        margin-right: 0px;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:active,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:hover {
        font-size: 18px;
        padding: 24px 36px 26px;
        height: 28px;
        width: auto;
    }

    .clt-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 250px;
    }

    .clt-section-3-h2 {
        font-size: 48px;
        letter-spacing: -.2px;
    }

    .clt-section-3-h2-second {
        font-size: 70px;
    margin-top: 22px;
    margin-bottom: 24px;
    margin-right: 7px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-section-3-cards {
        width: 250px;
        border-radius: 20px;
    }

    .clt-section-3-cards-secondary span {
        font-size: 18px;
    }

    .clt-section-3-cards-secondary p {
        font-size: 18px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:active,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:hover {
        font-size: 18px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-section-4 {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px 0;
        padding: 74px 15px;
    }

    .clt-section-4 h2 {
        font-size: 32px;
    }

    .clt-section-5-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2b {
        font-size: 48px;
    }

    .clt-section-home-aide-h2a {
        font-size: 27px;
    }

    .clt-section-event-h2 {
        font-size: 27px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-section-6-btn,
    .clt-section-6-btn:active,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-section-6 {
        height: 348px;
    }

    .clt-section-help-contact{
        height: 255px;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:active,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-section-7 p {
        font-size: 17px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
    }

    .clt-search-home {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 18px;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 17px;
    }
    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro - horizontal view*/
    .clt-homepage-container {
        max-width: 66% !important;
    }

    .clt-homepage-container2 {
        max-width: 900px !important;
    }

    .slider-controls {
        margin-bottom: -126px;
        margin-right: 70px;
    }


    .clt-section-2-divs h2 {
        font-size: 14px;
    }

    div.eapps-widget {
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    
    #eapps-instagram-feed-1 {
        width: 64% !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }
    .eapps-instagram-feed-posts-slider-inner {
        z-index: 1;
        position: relative;
        height: auto;
        width: 1010px !important;
        display: flex;
        width: 100%;
    }
    
    .eapps-instagram-feed-header-inner {
        display: flex;
        flex-wrap: nowrap;
        justify-content: left !important;
        align-items: center;
        margin: 0 auto;
        max-width: 600px;
        margin-left: 0px !important;
    }
    
    .eapps-instagram-feed-header {
        background: #fff;
        padding: 20px;
        padding-left: 0px !important;
    }

    .clt-section-2-divs img {
        width: 17px;
    }

    .clt-section-1-div {
        height: 620px;
        margin-top: 52px;
        margin-bottom: 15px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 0px;
        border-radius: 12px;
        background: #00000096;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-section-1-h2 {
        font-size: 78px;
        font-weight: 900;
        font-family: 'Californian fb';
        color: #fff;
        margin-bottom: 8px;
        margin-right: 0px;
        margin-top: 0px;
    }

    .clt-section-1-btn,
    .clt-section-1-btn:active,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:hover {
        font-size: 18px;
        padding: 24px 36px 26px;
        height: 42px;
        width: auto;
        padding: 14px 27px 15px;
    }

    .clt-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 189px;
    }

    .clt-section-3-h2 {
        font-size: 48px;
        letter-spacing: -.2px;
    }

    .clt-section-3-h2-second {
        font-size: 70px;
        margin-top: 22px;
        margin-bottom: 24px;
        margin-right: 7px;
        line-height: 34px;
        margin-right: 5px;
        letter-spacing: -.2px;
    }

    .clt-section-3-cards {
        width: 271px;
        border-radius: 20px;
    }

    


    .clt-section-3-cards-secondary p {
        font-size: 18px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:active,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:hover {
        font-size: 15px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-section-4 {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px 0;
        padding: 74px 15px;
    }

    .clt-section-4 h2 {
        font-size: 30px;
    }

    .clt-section-5-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2 {
        font-size: 27px;
    }

    .clt-section-home-aide-h2b {
        font-size: 48px;
    }

    .clt-section-home-aide-h2a {
        font-size: 27px;
    }

    .clt-section-event-h2 {
        font-size: 27px;
    }

    .clt-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 16px;
    }

    .clt-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-section-6-btn,
    .clt-section-6-btn:active,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-section-6 {
        height: 348px;
    }

    .clt-section-6 {
        height: 348px;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:active,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-section-7 p {
        font-size: 17px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
    }

    .clt-search-home {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-section-8 a,
    .clt-section-8 a:hover {
        font-size: 18px;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 16px;
    }

    .clt-section-8 p {
        font-size: 17px;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-2-row-col {
        padding-right: 14px !important;
    }
    .clt-section-3-cards-secondary a {
        font-size: 18px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-homepage-container {
        max-width: 66% !important;
    }

    

    .clt-homepage-container2 {
        max-width: 1100px !important;
    }


    .clt-section-1-btn,
    .clt-section-1-btn:active,
    .clt-section-1-btn:focus,
    .clt-section-1-btn:hover {
        font-size: 18px;
        padding: 24px 36px 26px;
        height: 32px;
        width: auto;
        letter-spacing: .2px;
    }

    .clt-section-2-row-col,
    .clt-section-2-row-container {
        padding-left: 5px !important;
        padding-right: 14px !important;
    }


    .clt-section-1-h2 {
        font-size: 78px;
        font-weight: 900;
        font-family: 'Californian fb';
        color: #fff;
        margin-bottom: 8px;
        margin-right: 0px;
        margin-top: 0px;
    }
    
    .clt-section-1-div {
        height: 620px;
        margin-top: 59px;
        margin-bottom: 15px;
        width: 100%;
    }

    .clt-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 186px;
        margin-left: 0px;
    }

    .clt-section-2-divs img {
        width: 18px;
    }

    .clt-section-2-divs {
        margin-right: 22px;
        cursor: pointer;
        user-select: none;
        background: #fff;
        border-radius: 12px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 8px;
    }

    .clt-section-2-divs h2 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 11px;
        margin-top: 5px;
        line-height: 16px;
    }

    .clt-search-btn-home,
    .clt-search-btn-home:active,
    .clt-search-btn-home:focus,
    .clt-search-btn-home:hover {
        font-size: 15px;
    }

    .clt-section-2 div:last-child {
        margin-right: 0;
    }

    .clt-section-3-h2 {
        font-size: 48px;
    }

    .clt-section-3-h2-second {
        font-size: 70px;
    margin-top: 22px;
    margin-bottom: 24px;
    margin-right: 7px;
        margin-right: 7px;
    }

    .clt-section-3-cards-secondary p {
        font-size: 18px;
    }

    .clt-section-3-cards-end h2 {
        font-size: 28px;
    }

    .clt-section-3-cards-button,
    .clt-section-3-cards-button:active,
    .clt-section-3-cards-button:focus,
    .clt-section-3-cards-button:hover {
        font-size: 15px;
    }

    .clt-section-5-h2 {
        font-size: 28px;
    }

    .clt-section-home-aide-h2 {
        font-size: 28px;
    }

    .clt-section-home-aide-h2b {
        font-size: 48px;
    }

    .clt-section-home-aide-h2a {
        font-size: 28px;
    }

    .clt-section-event-h2 {
        font-size: 28px;
    }

    .clt-section-5-accordion-header button {
        font-size: 15px;
    }

    .clt-timeslots-accordion-header button {
        font-size: 15px;
    }

    .clt-section-5-accordion-body {
        font-size: 15px;
    }

    .clt-section-6-btn,
    .clt-section-6-btn:active,
    .clt-section-6-btn:focus,
    .clt-section-6-btn:hover {
        font-size: 15px;
    }

    .clt-section-7 h2 {
        font-size: 26px;
    }

    .clt-section-7 p {
        font-size: 16px;
    }

    .clt-section-7-btn,
    .clt-section-7-btn:active,
    .clt-section-7-btn:focus,
    .clt-section-7-btn:hover {
        font-size: 15px;
    }

    .clt-section-8 span,
    .clt-section-8 span:hover {
        font-size: 15px;
    }

    .clt-section-3-cards-main h2 {
        font-size: 28px;
        margin-top: 47px;
        padding: 0 20px 20px;
        line-height: 34px;
    }

    .clt-section-2-row {
        padding-top: 0px;
    }

    .clt_homepage_section_event_image {
        width: 108px;
        height: 108px;
        object-fit: cover;
        background-color: #f6f6f6;
        border-radius: 12px;
    }

    .clt-home-visite-h2-cards {
        width: 336px;
        height: 340px;
    }


    .clt_homepage_section_event_text {
        font-size: 18px !important;
        font-family: "Inter" !important;
        color: #242424 !important;
        text-transform: none !important;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .clt-section-3-event {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 26px;
        padding-left: 0px;
    }

    .clt-section-8 p {
        font-size: 17px;
    }



    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    .clt-section-3-cards-secondary a {
        font-size: 18px;
    }


}

@media only screen and (max-width: 1600px) and (min-width: 1400px) {

    .clt-section-3-cards {
        width: 249px;
        border-radius: 20px;
        margin-bottom: 1px;
    }

    .clt-section-2-row-col-home-sticked {
        padding-left: 0px !important;
    }

    #eapps-instagram-feed-1 {
        width: 64% !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }

    .eapps-instagram-feed-posts-slider-inner {
        z-index: 1;
        position: relative;
        height: auto;
        display: flex;
        width: 100%;
        width: 1098px;
    }

    

}

@media only screen and (min-width: 1600px) {
    .clt-section-3-cards {
        width: 287px;
    }

    .slider-controls {
        margin-bottom: -126px;
        margin-right: 70px;
    }

    #eapps-instagram-feed-1 {
        width: 64% !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }
}