.pack-table-4562 {
    margin-top: -4px;
    margin-right: 0px;
    margin-bottom: 9px;
    margin-left: 0px;
}

.pack-table-4562 h2 {
    font-size: 24px;
}

.table-wrapper {
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
    margin-bottom: 16px;
    display: block; /* Ensure the wrapper behaves as a block element */
    white-space: nowrap; /* Prevent table content from wrapping */
}

.dezjdecsqccccqoziuczzhd {
    transform: translate(-2%, -109%);
}
table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    
}
th {
    white-space: normal; /* Allows text wrapping */
    overflow-wrap: break-word; /* Wrap long words */
    word-break: break-word; /* Break words if needed *
}

.jdenfddfzkejzk, .jdenzkejzkfssff, .jdenzkejzzzk {
    white-space: normal; /* Enables line breaks in header cells */
    word-wrap: break-word; /* Breaks long words if necessary */
}
th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
    vertical-align: middle;
    height: auto;
}

thead th {
    background-color: #f4f4f4;
    font-weight: bold;
}

tbody tr {
    display: table-row;
    height: 100%;
}

.service-cell {
    background-color: #f0f0f0;
    position: relative;
    word-wrap: break-word;
}

.service-cell {
    place-self: start;
    justify-content: start;
}

.check-4562 {
    padding: 9px;
    color: #4a9c2c;
    font-size: 24px;
    font-weight: 500;
}

.no-4562 {
    color: red;
}

.cdzkendezk {
    font-size: 15px;
    color: #424242;
}

td {
    font-size: 14px;
}
.dvsdvsdvsd {
    font-size: 18.5px;
}
.info-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.pack-table-4562,
.table-wrapper {
    overflow: visible !important; /* Ensure popups aren't clipped */
    position: relative; /* Ensure popups use this as a reference point */
}
.popup {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    width: 320px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    border-radius: 18px;
    transform-origin: top left; /* Anchor transformations */
    transition: transform 0.2s ease-in-out;
}

.popup img {
    max-width: 100%; /* Ensure the image doesn't exceed the popup width */
    max-height: 100%; /* Constrain the height as well */
    display: block; /* Prevent inline spacing issues */
    margin: 0 auto; /* Center the image if needed */
    object-fit: contain; /* Maintain aspect ratio */
}
.popup-pack-title {
    font-weight: 600;
    margin-bottom: 0px !important;
}
.climatiseur_image {
    width: 150px;
    
}
.table-wrapper {
    overflow: visible !important; /* Ensure nothing hides the popup */
    position: relative; /* Keep the positioning stable */
}
.dzkdnzdzk {
    margin-bottom: 0px;
}
table {
    overflow: visible !important; /* Ensure popup is not clipped */ 
    position: relative;
}
.ZEFZ {
    transform: translate(57%, -41%);
}

.cznekeeee {
    transform: translate(44%, -12%);
}

.cccdzdcz {
    transform: translate(44%, -31%);
}
.FAEFEALJNFKJ {
    right: 282px !important;
}
.dezjdeoziuhd {
    transform: translate(43%, -58%);
}

.dezjdecsqcqoziuhd {
    transform: translate(-2%, -138%);
}
.dezjdeozdnazdiuhd {
    transform: translate(57%, -100%);
}
.service-cell {
    position: relative;
}
.vdssdsdv {
    padding: 50px;
}
.dekzmamma {
    transform: translate(43%, -69%);
}

.ffzefxxxx {
    transform: translate(-2%, -121%);
}
.dezjdecsqccccqoziuhd {
    transform: translate(-2%, -121%);
}
.dezdzddjdecsqccccqoziuhd {
    transform: translate(-2%, -109%);
}
.befbfcxbcx {
    font-size: 16px;
    color: #222;
    font-family: inter;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 10px;
    opacity: 0;
}
.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.jdenzkejzk {
    opacity: 0;
    border: none;
}

.effzsfsdf {
    right: 286px;
}
.jdenfddfzkejzk {
    border-top-left-radius: 19px;
    border: none;
}

.jdenzkejzkfssff {
    border: none;
}

.jdenzkejzzzk {
    border-top-right-radius: 19px;
    border: none;
}

.zfezefzdezf {
    margin-bottom: 4px;
}


.edzeezdez {
    border-top-left-radius: 19px;
    border: none;
}

.czdcdzdzsc {
    border-bottom-left-radius: 19px;
    border: none;
}

.dkeldlek {
    border-left: 0px;
}

.dkeldlekfeefe {
    font-size: 15px;
    font-weight: 600;
    color: #222;
    line-height: 20px;
}

.fezjbfklezbfkz {
    flex-direction: row;
    display: flex;
}

.ezfzefe {
    color: #222 !important;
    cursor: pointer;
    text-decoration: underline;
}

.div1catalogue {
    width: 70%;
}
.div2catalogue {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: end;
    place-content: end;
}

.fezfezfezfze {
    place-content: end;
    display: flex;
}
.azfazsqfs {
    font-size: 18px !important;
}
.ezgmlnzegilv {
    letter-spacing: -0.2px;
    font-weight: 400;
    color: #70757a;
    margin-bottom: 0px;
    font-family: 'Inter';
}
h2.dskjndslds.azffazfsqf.azfazsqfs {
    color: #666;
    margin-bottom: 3px;
}

.fezfdvsd {
    font-size: 16px;
    color: #222;
    font-family: inter;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0px;
    margin-right: 20px;
}
.zkjnkjdnze {
    right: 271px !important;
    height: 60px !important;
}
img.close-icon.zkjnkjdnze {
    right: 28px;
    height: 48px;
}
.fafnzajbfaz {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
@media (min-width: 0px) and (max-width: 1024px) {
.rowazza{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    padding-left: 24px;
        padding-right: 24px;
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}

.fafnlakznflma {
    flex-direction: column;
    display: flex;
}
.ZEFZ {
    transform: none;
}
.uihdeizuhdiez {
    margin-left: 10px !important;
    margin-top: -20px !important;
    margin-bottom: 5px !important;
}
h2.dskjndslds.azffazfsqf.azfazsqfs {
    color: #666;
}
.lineheightpack4562{
    line-height: 18px;
}


.dskjndslds {
    FONT-SIZE: 18px !important;
    font-family: inter;
    color: #666;
    font-weight: 500;
    margin-top: -7px;
    line-height: 32px;
    letter-spacing: -0.2px;
}
.pack-table-4562 h2 {
    margin-left: 10px;
}
.gregergege {
    margin-left: 10px !important;
    margin-top: -20px !important;
    margin-bottom: 17px !important;
}

.zfezefzdezf {
    margin-bottom: 25px;
}
.FZEFEZFZE {
    margin-top: -10px;
}
h2.dskjndslds.azffazfsqf.azfazsqfs {
    margin-bottom: -1px;
}
.dvsdvsdvsd {
    font-size: 17px;
}
}

@media (min-width: 0px) and (max-width: 767px) {
    table {
        min-width: 750px; /* Minimum width to trigger horizontal scrolling */
        
    }

    .dvsdvsdvsd {
        font-size: 17px;
    }
}