.clt_admin_admin-dash-parent {
    width: 50%;
    /* height: calc(100vh - 67px); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    place-self: center;
}

.clt_admin_hr {
    border-bottom: 1px solid #dedddd;
    margin-top: 2rem;
}

.clt_admin_arrow {
    width: 6px;
    margin-bottom: 3px;
}


.clt_admin_admin-dash-div_2 {
    position: relative;
    width: 100%;
}
.clt_admin_admin-dash-parent2 {
    border-radius: 91px;
    margin-top: -35px;
    z-index: 90;
    background: #fff;
}
.clt_admin_admin-dash-div {
    overflow: hidden;
    border-radius: 15px;
    margin-top: 2rem;
    position: relative;
    width: 100%;
    height: 250px;
    background-color: #298EFB;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60%;
    background-image: url('../../../../assets/img/admin_panel_first_section_desktop.png');
}

.clt_admin_admin-dash-parent2edzzed {
    background-image: linear-gradient(0deg, #CCCCCC 0%, #FFFFFF 100%);
    border-bottom-right-radius: 00px !important;
    border-bottom-left-radius: 00px !important;
    z-index: 50;
}
.czededzedze {
    place-self: center;
    justify-content: center;
    text-align: center;
    font-family: 'Inter';
    color: #222;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 3px;
}

.czededzeddezdezze {
    place-self: center;
    justify-content: center;
    text-align: center;
    font-family: 'Inter';
    color: #6b6b6b;
    font-size: 16px;
    font-weight: 400;
}
.clt_admin_admin-dash-div_4 {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 60px;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60%;
}

.clt_admin_admin-dash-div_5 {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 75px;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60%;
}
p.clt_admin_dash-booking-show {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

p.clt_admin_dash-booking-show:hover {
    opacity: 70%;
}

p.clt_admin_dash-booking-show:active {
    opacity: 60%;
}
.clt_admin_admin-dash-div_3 {
    overflow: hidden;
    border-radius: 15px;
    margin-top: 2rem;
    position: relative;
    width: 100%;
    height: 250px;
    background-color: #fff;
    border: 0px solid #dedddd;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60%;
}

.clt_admin_img-admin-dash_desktop {
    border-radius: 15px;
    width: 100%;
    height: 250px;

}

.clt_admin_img-admin-dash_mobile {
    border-radius: 15px;
    width: 100%;
    height: 250px;
    display: none;
}

.clt_admin_dashboard-text-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #dedddd;
    padding-left: 32px;
}

.clt_admin_dashboard-text-containercdcsdc {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    padding-right: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #dedddd;
    padding-left: 32px;
}
.clt_admin_dashboard-text-containerrgregr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}


.clt_admin_dash-text-heading {
    color: white;
    font-weight: 600;
    line-height: 1.17;
    font-size: 32px;
}
.clt_admin_dashboard-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .clt_admin_dashboard_content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
.clt_admin_dash-text-headingeczczec {
    color: #222;
    font-weight: 600;
    line-height: 1.17;
    font-family: 'Inter';
    font-size: 26px;
}
/* Align 'Demande de contact' and 'Afficher' with space-between */
.clt_admin_dashboard_contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Ensure the stats row is displayed as columns in the same row */
  .clt_admin_contact-stats-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .clt_admin_contact-stat {
    background-color: #fff;
    padding: 1rem;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #dedddd;
    flex: 1;
    margin-right: 1rem;
  }
  
  .clt_admin_contact-stat:last-child {
    margin-right: 0; /* Remove the margin for the last item */
  }
  
  .clt_admin_contact-stat h4 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .clt_admin_contact-stat p {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
.clt_admin_next-booking {
    background-color: #2172c9;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.95rem;
    padding: 14.5px 17px;
    color: white;
    width: 60%;
    cursor: pointer;
}

.clt_admin_next-booking:hover {
    background: #185da7;
}

.clt_admin_next-booking-text-parent {
    display: flex;
    flex-direction: column;
}

.clt_admin_next-booking-text-parent span:nth-child(1) {
    font-weight: 600;
    font-size: 14px;
}

.clt_admin_next-booking-text-parent span:nth-child(2) {
    opacity: 0.7;
    font-size: 14px;
}

.clt_admin_opacity {
    opacity: 0.7;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_admin_admin-dash-div {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 288px;
        background-image: url('../../../../assets/img/admin_panel_first_section_mobile.png');
    }

    .clt_admin_admin-dash-div_3 {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 288px;
    }

    .clt_admin_dashboard-text-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 32px 24px;
    }

    .clt_admin_dashboard-text-containerrgregr {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0px 24px;
    }

    .clt_admin_dash-text-heading {
        font-size: 28px;
        margin-bottom: 18px;
    }
    .clt_admin_dash-text-headingeczczec {
        font-size: 16px;
        margin-bottom: 18px;
    }
    .clt_admin_next-booking {
        width: 100%;
        padding-top: 15px;
        padding-right: 17px;
        padding-bottom: 19px;
        padding-left: 17px;
    }

    .clt_admin_admin-dash-parent {
        width: 100%;
    }

    .clt_admin_hr {
        margin-top: 1.5rem;
    }

    .clt_admin_img-admin-dash_desktop {
        display: none;
    }

    .clt_admin_img-admin-dash_mobile {
        display: block;
    }
    
    .clt_admin_arrow {
        width: 8px;
        margin-bottom: 4px;
    }

    .clt_admin_next-booking-text-parent span:nth-child(1) {
        font-weight: 600;
        font-size: 16px;
    }
    
    .clt_admin_next-booking-text-parent span:nth-child(2) {
        opacity: 0.7;
        font-size: 16px;
    }
    
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_admin_admin-dash-div {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 298px;
        background-image: url('../../../../assets/img/admin_panel_first_section_mobile.png');
    }
    .clt_admin_admin-dash-div_3 {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 288px;
    }
    .clt_admin_dashboard-text-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 32px 24px;
    }

    .clt_admin_dashboard-text-containerrgregr {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0px 24px;
    }

    .clt_admin_dash-text-heading {
        font-size: 28px;
        margin-bottom: 18px;
    }
    .clt_admin_dash-text-headingeczczec {
        font-size: 16px;
        margin-bottom: 18px;
    }
    .clt_admin_next-booking {
        width: 100%;
        padding-top: 15px;
        padding-right: 17px;
        padding-bottom: 19px;
        padding-left: 17px;
    }

    .clt_admin_admin-dash-parent {
        width: 100%;
    }

    .clt_admin_hr {
        margin-top: 1.5rem;
    }

    .clt_admin_img-admin-dash_desktop {
        display: none;
    }

    .clt_admin_img-admin-dash_mobile {
        display: block;
    }
    
    .clt_admin_arrow {
        width: 8px;
        margin-bottom: 4px;
    }

    .clt_admin_next-booking-text-parent span:nth-child(1) {
        font-weight: 600;
        font-size: 16px;
    }
    
    .clt_admin_next-booking-text-parent span:nth-child(2) {
        opacity: 0.7;
        font-size: 16px;
    }
    
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_admin_admin-dash-div {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 298px;
        background-image: url('../../../../assets/img/admin_panel_first_section_mobile.png');
    }
    .clt_admin_admin-dash-div_3 {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 288px;
    }
    .clt_admin_dashboard-text-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 32px 24px;
    }

    .clt_admin_dashboard-text-containerrgregr {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0px 24px;
    }

    .clt_admin_dash-text-heading {
        font-size: 28px;
        margin-bottom: 18px;
    }
    .clt_admin_dash-text-headingeczczec {
        font-size: 16px;
        margin-bottom: 18px;
    }
    .clt_admin_next-booking {
        width: 100%;
        padding-top: 15px;
        padding-right: 17px;
        padding-bottom: 19px;
        padding-left: 17px;
    }

    .clt_admin_admin-dash-parent {
        width: 100%;
    }

    .clt_admin_hr {
        margin-top: 1.5rem;
    }

    .clt_admin_img-admin-dash_desktop {
        display: none;
    }

    .clt_admin_img-admin-dash_mobile {
        display: block;
    }
    
    .clt_admin_arrow {
        width: 8px;
        margin-bottom: 4px;
    }

    .clt_admin_next-booking-text-parent span:nth-child(1) {
        font-weight: 600;
        font-size: 16px;
    }
    
    .clt_admin_next-booking-text-parent span:nth-child(2) {
        opacity: 0.7;
        font-size: 16px;
    }
 
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_admin_admin-dash-div {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 298px;
        background-image: url('../../../../assets/img/admin_panel_first_section_mobile.png');
    }
    .clt_admin_admin-dash-div_3 {
        border-radius: 15px;
        margin-top: 0.7rem;
        position: relative;
        width: 90%;
        height: 288px;
    }
    .clt_admin_dashboard-text-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 32px 24px;
    }

    .clt_admin_dashboard-text-containerrgregr {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0px 24px;
    }

    .clt_admin_dash-text-heading {
        font-size: 28px;
        margin-bottom: 18px;
    }
    .clt_admin_dash-text-headingeczczec {
        font-size: 16px;
        margin-bottom: 18px;
    }
    .clt_admin_next-booking {
        width: 100%;
        padding-top: 15px;
        padding-right: 17px;
        padding-bottom: 19px;
        padding-left: 17px;
    }

    .clt_admin_admin-dash-parent {
        width: 100%;
    }

    .clt_admin_hr {
        margin-top: 1.5rem;
    }

    .clt_admin_img-admin-dash_desktop {
        display: none;
    }

    .clt_admin_img-admin-dash_mobile {
        display: block;
    }
    
    .clt_admin_arrow {
        width: 8px;
        margin-bottom: 4px;
    }

    .clt_admin_next-booking-text-parent span:nth-child(1) {
        font-weight: 600;
        font-size: 16px;
    }
    
    .clt_admin_next-booking-text-parent span:nth-child(2) {
        opacity: 0.7;
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/

    .clt_admin_next-booking-text-parent span:first-child {
        font-size: 15px;
    }
    .clt_admin_next-booking-text-parent span:nth-child(2) {
        font-size: 15px;
    }

    .clt_admin_admin-dash-div {
        margin-top: 1.4rem;
    }

    .clt_admin_admin-dash-div_3 {
        margin-top: 1.4rem;
    }
}