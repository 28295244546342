body {
    font-family: 'Inter', sans-serif;
}

.white_background {
    background: #fff !important;
}

.border_top_light {
    border-top: 1px solid #e0e0e0;
    margin-top: 34px;
}

.border_bottom_light {
    border-bottom: 1px solid #e0e0e0;
}

i {
    font-family: fontAwesome;
    font-size: 16px;
    margin-bottom: 2px;
    font-style: unset;
}

.cursor-pointer {
    cursor: pointer;
}

.black_btn {
    background: #000 !important;
    border: 1px solid #000 !important;
}

.black_btn.disabled {
    background: #333 !important;
    border: 1px solid #333 !important;
}

.border-bottom-none {
    border-bottom: none !important;
}

.w-80 {
    width: 80%;
}

.caret-color-transparent {
    caret-color: transparent;
}

.text-green {
    color: #70757a !important;
    font-weight: 400 !important;
}

.text-decoration-line-through {
    text-decoration: line-through!important;
    color: #adadad !important;
}

.text-red {
    color: #70757a !important;
    font-weight: 400 !important;
}