.clt_admin_dash_select_refund_booking_box {
    margin: 0px 24px;
}

.clt_admin_dash_select_refund_booking_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_admin_dash_select_refund_booking_offcanvas_header {
    padding: 15px 24px 16px 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_admin_dash_select_refund_booking_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0;
}

.clt_admin_dash_select_refund_booking_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_admin_dash_select_refund_booking_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_admin_dash_select_refund_booking_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_admin_dash_select_refund_booking_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}

.clt_admin_dash_select_refund_booking_offcanvas_body {
    padding: 0px;
    position: relative;
}

.clt_admin_dash_select_refund_booking_offcanvas_body_mainrow {
    margin-bottom: 40px;
    margin: 0 25px;
}

.clt_admin_dash_select_refund_booking_offcanvas_body .clt_admin_dash_select_refund_booking_offcanvas_body_mainrow:first-child {
    margin-top: 25px;
}

.clt_admin_dash_select_refund_booking_offcanvas_body_maindiv {
    margin-bottom: 20px;
}

.clt_admin_dash_select_refund_booking_offcanvas_body_secdiv {
    margin-bottom: 30px;
    cursor: pointer;
}

.clt_admin_dash_select_refund_booking_box_field {
    margin: 20px 24px;
    position: relative;
    min-height: calc(100% - 130px);
}

.clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:hover, .clt_admin_dash_select_refund_booking_formButton:focus {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    margin: 0px 22px;
    border-radius: 12px;
    font-size: 13.5px;
    padding-top: 2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    height: 48px;
    position: sticky;
    margin-top: 20px;
    margin-bottom: 20px;
    bottom: 0px;
}

.clt_admin_dash_select_refund_booking_formButton:disabled {
    opacity: .4;
    background: #000000;
}

.css-1nlmifp.Mui-checked + .MuiSwitch-track {
    background-color: rgb(172 210 247) !important;
}

.css-1ju1kxc {
    height: 100%;
    width: 100%;
    border-radius: 7px;
    z-index: -1;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(219 219 219) !important;
    opacity: 1 !important;
}



@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 100%;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

}



@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 100%;
    }

    .clt_admin_dash_select_refund_booking_item_title {
        font-size: 16px;
        font-weight: 500;
        color: #242424;
        margin: 0 0 -4px;
        padding: 0;
        /* border-bottom: 1px solid black; */
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 100%;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .offcanvas-title {
        font-size: 16px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:hover, .clt_admin_dash_select_refund_booking_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        margin-top: 26px;
        bottom: 0px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_admin_dash_select_refund_booking_item_title {
        font-size: 16px;
    }

    .clt_admin_dash_select_refund_booking_item_detail {
        font-size: 16px;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:focus, .clt_admin_dash_select_refund_booking_formButton:hover {
        height: 52px;
        font-size: 16px;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 400px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:hover, .clt_admin_dash_select_refund_booking_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        margin-top: 26px;
        bottom: 0px;
    }

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:hover, .clt_admin_dash_select_refund_booking_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        margin-top: 26px;
        bottom: 0px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 400px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:hover, .clt_admin_dash_select_refund_booking_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        margin-top: 26px;
        bottom: 0px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:hover, .clt_admin_dash_select_refund_booking_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        margin-top: 26px;
        bottom: 0px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 400px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:hover, .clt_admin_dash_select_refund_booking_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        margin-top: 26px;
        bottom: 0px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/

    .clt_admin_dash_select_refund_booking_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas {
        width: 400px;
    }

    .clt_admin_dash_select_refund_booking_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_admin_dash_select_refund_booking_item_detail {
        font-size: 15px;
    }

    .clt_admin_dash_select_refund_booking_formButton, .clt_admin_dash_select_refund_booking_formButton:focus, .clt_admin_dash_select_refund_booking_formButton:hover {
        font-size: 15px;
    }
}