.clt_inputFloatDetailDiv {
    position: relative;
    margin-bottom: 0px;
}
/* Parent container to wrap the description and image */
.clt_products_offcanvas_body_wrapper {
    position: relative; /* Make the wrapper a positioned parent */
    width: 100%;
    height: auto; /* Adjust as needed for your layout */
  }
  
  /* Styling for the image container */
  .clt_products_offcanvas_body_img {
    width: 100%;
    height: 200px; /* Example height; adjust as necessary */
    background-size: cover;
    background-position: center;
    border-radius: 10px; /* Optional for rounded corners */
    position: relative;
  }
  
  /* Positioning the description at the top-left corner */
  .clt_products_offcanvas_body_desc {
    
    top: 10px; /* Adjust for spacing from the top */
    left: 10px; /* Adjust for spacing from the left */
    z-index: 10; /* Ensure it appears above the image */
    
    border-radius: 5px; /* Rounded corners */
    font-size: 15px; /* Adjust text size */
    color: #222; /* Text color */
    font-weight: 500;
  }
  .kndkendezkdnz {
    position: absolute;
    padding: 0px 5px;/* Add some padding */
  }

  .clt_products_offcanvas_body_desc.kndkendezkdnz {
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  }
.clt_inputFloatDetailDiv .clt_input_icon_angle_down {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.clt_inputFloatDetail .form-control:focus~label{
    color: #242424 !important;
}

.ndejdnzjekd {
    POSITION: absolute;
    z-index: 999;
    margin-top: 51px !important;
    background: #fff !important;
}
.dejzndkzendlzddd {
    display: flex;
    gap: 5px;
}
.jndkjnzeckln {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 14px !important;
    width: fit-content !important;
    padding-top: 2px !important;
    border-radius: 20px !important;
    padding-bottom: 2px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    color: #222 !important;
    font-size: 17px !important;
    margin-top: 5px !important;
    border: 1px solid #dedddd !important;
}

.fejfjeefnfenccc {
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding-top: 4px !important;
    border-radius: 10px !important;
    padding-bottom: 4px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    color: #222 !important;
    font-size: 15px !important;
    margin-top: 5px !important;
    background: #f4f4f4;
    font-weight: 500 !important;
}








.clt_inputFloatDetail input {
    height: 56px !important;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    padding-top: 11px !important;
    padding-right: 17.3px !important;
    padding-bottom: 0px !important;
    padding-left: 17.3px !important;
    border-radius: 0px;
    border: 1px solid #dedddd;
    border-top: none;
    outline: none;
    box-shadow: none;
}


.clt_inputFloatDetail input:hover {
    background: #f6f6f6;
}

.clt-detail-left-section-2-col674677 {
    padding: 0px !important;
}

.clt_inputFloatDetail input:active {
    background: #eee !important;
}

.clt_inputFloatDetail input:focus {
    background: #fafafa !important;
}

.clt-detail-section-1 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.clt-detail-section-1 h2 {
    font-size: 46.5px;
    font-weight: 500;
    font-family: 'Dancing script';
    color: #242424;
    margin-bottom: 6px;
}

.clt-detail-section-1-subdiv img {
    width: 11px;
}

.clt-detail-section-1-subdiv span {
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;
    font-family: 'Inter';
    color: #242424;
    letter-spacing: -0.2px;
}

.clt-detail-sections-div {
    margin-top: 30px;
}

.clt-detail-slider-main {
    border-radius: 10px;
}

.clt-detail-slider-main .react-multiple-carousel__arrow {
    background: rgb(255 255 255) !important;
    min-width: 36px !important;
    min-height: 36px !important;
    z-index: 0;
}

.clt-detail-slider-main .react-multiple-carousel__arrow:hover {
    background: #eee !important;
}

.clt-detail-slider-main .react-multiple-carousel__arrow:active {
    background: #bdbdbd !important;
}

.clt-detail-slider-main .react-multiple-carousel__arrow::before {
    font-size: 16px;
    margin-bottom: 1px;
    font-weight: 900;
    color: #242424 !important;
}

.clt-detail-slider-main .react-multiple-carousel__arrow--left {
    left: 3%;
}

.clt-detail-slider-main .react-multiple-carousel__arrow--right {
    right: 3%;
}

.clt-detail-slider-main-imgdiv {
    background-size: cover;
    cursor: pointer !important;
    width: 100%;
    height: 458px;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.clt-detail-slider-main-imgdiv:hover {
    opacity: 0.7;
}

.clt-detail-slider-main-imgdiv:active {
    opacity: 0.6;
}

.cursor-zoom-in:hover {
    cursor: zoom-in;
}

.clt-detail-slider-main-imgdiv span {
    color: #fff;
    background: rgba(0, 0, 0, .6313725490196078);
    padding: 4px 9px;
    border-radius: 10px;
    position: absolute;
    bottom: 20px;
    right: 23px;
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter";
}

.clt-detail-slider-gallery {
    border-radius: 10px;
}

.clt-detail-slider-gallery .react-multiple-carousel__arrow {
    background: rgb(255 255 255) !important;
    min-width: 36px !important;
    min-height: 36px !important;
    z-index: 0;
}

.clt-detail-slider-gallery .react-multiple-carousel__arrow:hover {
    background: #f5f5f5 !important;
}

.clt-detail-slider-gallery .react-multiple-carousel__arrow:active {
    background: #c9c8c8 !important;
}

.clt-detail-slider-gallery .react-multiple-carousel__arrow::before {
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 1000;
    color: #242424 !important;
}

.clt-detail-slider-gallery .react-multiple-carousel__arrow--left {
    left: 3%;
}

.clt-detail-slider-gallery .react-multiple-carousel__arrow--right {
    right: 3%;
}

.double-column {
    width: 50%; /* Adjust the width based on your layout preferences */
  }

.clt-detail-slider-gallery-imgdiv {
    background-size: cover;
    width: 100%;
    height: 536px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.clt-detail-slider-gallery-imgdiv span {
    color: #fff;
    background: rgba(0, 0, 0, .6313725490196078);
    padding: 5px 11px;
    border-radius: 8px;
    position: absolute;
    bottom: 20px;
    right: 23px;
    font-size: 15px;
    font-weight: 500;
    font-family: "Inter";
}

.clt-section-hr-pd {
    padding: 30px 15px;
}

.clt-section-hr-pd22 {
    padding: 0px 0px;
    opacity: 0;
}

.clt-section-hr-pd221 {
    padding: 0px 0px;
    opacity: 0;
}

.clt-section-hr-pddddd {
    padding: 30px 0px;
}

.clt-detail-left-section-1 {

}


.clt-detail-left-section-1-heading {
    font-size: 25px;
    font-weight: 600;
    color: #242424;
    font-family: 'Lora';
    margin-bottom: 0;
    letter-spacing: 0.2px;
}


.clt-detail-left-section-1-headinga {
    font-size: 22px;
    font-weight: 600;
    color: #242424;
    font-family: 'Lora';
    margin-bottom: 0;
    letter-spacing: 0.2px;
}

.clt-detail-left-section-subdiv-1 {
    margin-top: 20px;
}

.clt-detail-left-section-subdiv-1-div {
    margin-bottom: 26px;
    height: 40px;
    display: flex;
}

.clt-detail-left-section-subdiv-1-div-img {
    width: 38px;
    height: 38px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #dedddd;
    border-radius: 8px;
}

.clt-detail-left-section-subdiv-1-div-img img {
    width: 24px;
    margin-bottom: 2px;
}

.clt-detail-left-section-subdiv-1-div-txt {}

.clt-detail-left-section-subdiv-1-div-txt h3 {
    font-size: 15px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 2.8px;
    margin-top: 7px;
    font-family: 'Inter';
}

.clt-detail-left-section-subdiv-1-div-txt p {
    font-size: 15px;
    font-weight: 400;
    color: #242424;
    font-family: 'Inter';
    margin-bottom: 0;
}

.clt-detail-left-section-subdiv-2 {
    flex-wrap: wrap;
}

.clt-detail-left-section-subdiv-2 span {
    font-size: 13.5px;
    font-weight: 700;
    color: #242424;
    font-family: "Inter";
    background: #eee;
    padding: 3px 10px;
    border-radius: 10px;
    margin-right: 13px;
    margin-top: 0px;
    margin-bottom: 14px;
    letter-spacing: -0.2px;
}

.clt-detail-left-section-2 {}

.clt-detail-left-section-2-row {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.clt-detail-left-section-2-row::-webkit-scrollbar {
    display: none;
}

.clt-detail-left-section-2-row {
    -ms-overflow-style: none;
}

.clt-detail-left-section-2-row-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.clt-detail-left-section-2-row-col {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.clt-detail-left-section-2 div:last-child {
    flex-direction: column;
}

.clt-detail-left-section-2-cards-div {}

.clt-detail-left-section-2-cards-div div:last-child {
    margin-right: 0px;
    cursor: pointer;
    flex-direction: column;
}

.clt-detail-left-section-2-card-container:hover {
    background-color: #f6f6f6;
}

.clt-detail-left-section-2-card-container:active {
    background-color: #eee;
}
.clt-detail-left-section-2-card-container:focus {
    background-color: #eee;
}

.clt-detail-left-section-2-h2-mt {
    margin-top: 0px;
}

.clt-detail-left-section-2-h2 {
    font-size: 25px;
    font-weight: 600;
    font-family: 'Lora';
    margin-bottom: 0px;
    color: #242424;
    line-height: 32px;
    letter-spacing: -0.2px;
}

.clt-home-visite-h2{
    font-size: 48px;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-family: 'Lora';
    line-height: 50px;
    color: #242424;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
}

.clt-home-visite-h2124{
    font-size: 48px;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-family: 'Lora';
    line-height: 28px;
    color: #242424;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 8px;
}

.clt-home-visite-h23546534{
    font-size: 26px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 28px;
    color: #686868;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 6px;
    margin-left: 0px;
    padding-top: 56px;
}








.my-carousel .react-multi-carousel-item {
    width: 318px !important;
    margin-top: 14px;
    flex: 1 1 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.clt-detail-left-section-2-cards-main {
    background: #EEE;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 24px;
    position: relative;
    user-select: none;
    overflow: hidden;
}

.react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
}

.my-carousel .react-multi-carousel-track {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none 0s ease 0s;
    overflow: unset;
}

.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}














.clt-home-visite-h2354653423558{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: -webkit-center;
    align-items: center;
    font-size: 85px;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-family: 'Lora';
    color: #242424;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 40px;
    margin-left: 0px;
    padding-top: 77px;
    line-height: 78px;
}

.clt-home-visite-h2354653412313456{
    font-size: 26px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 28px;
    color: #686868;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-top: 46px;
}

.clt-home-visite-h2354653422{
    font-size: 26px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 28px;
    color: #686868;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-left: 0px;
    padding-top: 6px;
}



.clt-home-visite-h22123 {
    font-size: 26px;
    letter-spacing: 0.2px;
    font-weight: 700;
    font-family: 'Lora';
    line-height: 28px;
    color: #242424;
    margin-top: 16px;
    margin-right: 0px;
    margin-bottom: -16px;
    margin-left: 0px;
    padding-top: 26px;
}

.clt-home-visite-h2174 {
    font-size: 48px;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-family: 'Lora';
    line-height: 28px;
    color: #242424;
    margin-top: 45px;
    margin-right: 0px;
    margin-bottom: -16px;
    margin-left: 0px;
    padding-top: 38px;
    padding-bottom: 23px;
    border-top: 1px solid #dedddd;
}

.clt-detail-left-section-2-cards-arrowBtn:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #ddd;
}

.clt-detail-left-section-2-cards-arrowBtn:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #ddd;
}


.clt-detail-left-section-2-cards-arrowBtn, .clt-detail-left-section-2-cards-arrowBtn:hover, .clt-detail-left-section-2-cards-arrowBtn:active, .clt-detail-left-section-2-cards-arrowBtn:focus, .clt-detail-left-section-2-cards-arrowBtn:active:focus {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    padding: 3px;
    overflow: hidden;
    background: #eee;
    border: 1px solid #eee;
    box-shadow: none;
    color: #242424;
    opacity: 1;
}

.clt-detail-left-section-2-cards-arrowBtn.disable, .clt-detail-left-section-2-cards-arrowBtn.disable:focus {
    opacity: 0.35;
    user-select: none;
    cursor: default;
}

.clt-detail-left-section-2-cards {
    width: 386px;
    height: 231px;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 10px;
    cursor: default;
    user-select: none;
}

.clt-home-visite-h2-cards {
    width: 324px;
    height: 337px;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 10px;
    cursor: default;
    user-select: none;
}

.clt-home-visite-h3-cards {
    width: 219px!important;
    height: 200px !important;
    border-radius: 15px !important;
    overflow: hidden !important;
    margin-right: 10px !important;
    cursor: default !important;
    user-select: none !important;
}

.clt-detail-left-section-2-cards-main {
    background: #EEE;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 24px;
    position: relative;
}

.clt-detail-left-section-3-cards-main {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    border-radius: 13px !important;
    position: relative !important;
    cursor: pointer !important;
}

.clt-detail-left-section-3-cards-main span {
    color: #242424 !important;
    background: none !important;
    padding: 4px 8px !important;
    border-radius: 8px !important;
    font-size: 40px !important;
    font-weight: 500 !important;
    font-family: 'Parisienne', Great Vibes !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    white-space: nowrap !important;
}

.clt-detail-left-section-2-cards-main:hover {
    opacity: .7;
}

.clt-detail-left-section-2-cards-main:active {
    opacity: .6;
}

.clt-detail-left-section-2-cards-main.cursor-zoom-in:hover {
    cursor: zoom-in;
}

.clt-detail-left-section-2-cards-main span {
    color: #242424;
    background: rgba(255, 255, 255, 1);
    padding: 4px 8px;
    border-radius: 8px;
    position: absolute;
    top: 14px;
    left: 15px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Inter";
}

.clt-detail-left-section-2-cards-main h2 {
    font-size: 27px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 0;
    margin-top: 40px;
    padding-top: 8px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    color: #fff;
    width: 80%;
    line-height: 32px;
}



.clt-detail-left-section-2-cards-secondary {
    background: #fff;
    border: 1px solid #e0e0e0;
    padding: 20px;
}

.clt-detail-left-section-2-cards-secondary h2 {
    font-size: 36px;
    font-weight: 800;
    font-family: Mulish;
    margin-bottom: 30px;
    color: #242424;
    line-height: 24px;
}

.clt-detail-left-section-2-cards-secondary span {
    font-weight: 700;
    font-size: 18px;
    font-family: Mulish;
    color: #242424;
}

.clt-detail-left-section-2-cards-secondary p {
    font-weight: 400;
    font-size: 18px;
    font-family: Inter;
    color: #242424;
    margin-bottom: 0px;
    line-height: 20px;
    margin-top: 5px;
}

.clt-detail-left-section-2-cards-end {
    background: #24775b;
    padding: 30px 20px;
}

.clt-detail-left-section-2-cards-end h2 {
    font-size: 27px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 30px;
    margin-top: 10px;
    color: #fff;
    line-height: 32px;
}

.clt-detail-left-section-2-cards-button, .clt-detail-left-section-2-cards-button:hover, .clt-detail-left-section-2-cards-button:focus, .clt-detail-left-section-2-cards-button:active {
    background: #fff;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: 'Mulish';
    font-size: 16.5px;
    font-weight: 700;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    padding: 0px 10px;
}

.jhhdbkjcbsdk {
    padding-left: 40px !important;
}

.denzidnez {
    padding-right: 0px !important;
    padding-left: 0px !important;
}


.clt-detail-left-section-11.col {
    padding-left: 0px;
    padding-right: 18px;
}

.clt-detail-left-section-2-cards-button i {
    font-family: fontawesome;
    margin-left: 5px;
}

.clt-detail-left-section-3 {
    border-radius: 15px;
    border: 1px solid #dedddd;
    padding: 24px 20px 30px;
}

.clt-detail-left-section-3-subdiv {}

.clt-detail-left-section-3-subdiv img {
    width: 18px;
    margin-right: 12px;
    margin-left: 5px;
}

.clt-detail-left-section-3-subdiv h2 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #242424;
    margin-bottom: 1px;
    font-family: 'Inter';
}

.clt-detail-left-section-3 p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.2px;
    color: #242424;
    font-family: 'Inter';
    margin-bottom: 0;
    margin-top: 12px;
    padding-left: 5px;
}

.clt-detail-left-section-2-header {
    margin-bottom: 25px;
}

.clt-home-visite-h2-header {
    margin-bottom: 20px;
    margin-top: 49px;
    padding-top: 15px;
    border-top: 1px #dedddd solid;
    align-items: center;
}


.clt-home-visite-h2-header2 {
    margin-bottom: -6px;
    margin-top: 35px;
    padding-top: 26px;
    ALIGN-ITEMS: CENTER;
    display: flex !important;
    border-top: 1px solid #dedddd; 
}

.clt-home-visite-h2-header23535454 {
    margin-bottom: -6px;
    margin-top: 0px;
    padding-top: 0px;
    ALIGN-ITEMS: CENTER;
    display: flex !important;
    border-top: 0px solid #dedddd;
}

.clt-home-visite-h2-header23 {
    margin-bottom: -7px;
    margin-top: -5px;
    padding-top: 20px;
    align-items: center;
}
 
.clt-home-visite-h2-header2321354 {
    margin-bottom: 20px;
    padding-top: 15px;
    align-items: center;
    padding-bottom: 18px;
    justify-content: center !important;
    display: flex !important;
    border-bottom: 0px solid #dedddd;

}


.clt-detail-right-main {
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    padding: 26px 30px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 14px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px !important;
}

.clt-sidebar-forms.stickySideBar {
    top: 0;
    width: 447px;
}

.clt-sidebar-forms.stickySideBar.is-sticky {
    position: fixed;
    top: 12%;
    background: #fff;
    width: 447px;
    z-index: 2;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    animation: fadeIn 1.5s;
}

.clt-detail-right-main.stickySideBar {
    position: unset;
    width: unset;
    z-index: unset;
    background: #fff;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.clt-detail-right-toggle {
    border-radius: 50px;
    background: #eee;
    padding: 3px 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
}

.clt-detail-right-toggle-tab {
    background: #eee;
    padding: 5px 7px 6.5px;
    border-radius: 60px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: 400;
    font-family: "Inter";
    color: #656464;
}


.clt-detail-right-toggle-tab:hover {
    background: #e7e7e7;
}

.clt-detail-right-toggle-tab:active {
    background: #dddddd;
}

.clt-detail-right-toggle-tab.active {
    background: #fff;
    font-weight: 600;
    color: #242424;
}

.clt-detail-right-toggle-tab.active:hover {
    background: #fff;
    opacity: 0.75;
}

.clt-detail-right-head-div-input {
    margin: -3px 0 10px;
}

.clt-detail-right-head-heading {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-family: 'Mulish';
    color: #242424;
    margin-bottom: -2px;
    margin-top: 14px;
    text-transform: uppercase;
}

.clt-detail-right-head-heading-input {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-family: 'Mulish';
    color: #5f5f5f;
    margin-bottom: -2px;
    margin-top: 0px;
}



.clt-detail-right-head-sub-div {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-size: 13.5px;
    letter-spacing: -0.2px;
    color: #242424;
    font-weight: 400;
    font-family: 'Mulish';
}

.clt-detail-right-head-sub-div span {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.2;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 12px;
}

.clt-detail-right-head-sub-diva {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-size: 13.5px;
    letter-spacing: -0.2px;
    color: #242424;
    font-weight: 400;
    font-family: 'Mulish';
}

.clt-detail-right-head-sub-diva span {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.2;
    padding-right: 5px;
    font-family: 'Inter';
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 8px;
    letter-spacing: 0px;
    color: #242424;
}



.clt_inputFloatDetail.form-floating>.form-control:focus~label, .clt_inputFloatDetail.form-floating>.form-control:not(:placeholder-shown)~label, .clt_inputFloatDetail.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.8) translateY(-0.28rem) translateX(0.28rem);
    padding-top: 0.5rem;
    padding-right: 1.1rem;
    padding-bottom: 0.5rem;
    padding-left: 1.1rem;
    margin-top: 1.1px;
    font-size: 14px;
}

.clt_inputFloatDetail textarea, .clt_inputFloatDetail textarea:active, .clt_inputFloatDetail textarea:focus {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    padding-left: 17.3px !important;
    padding-right: 17.3px !important;
    padding-top: 25px !important;
    border-radius: 12px !important;
    border: 1px solid #dedddd;
    outline: none;
    box-shadow: none;
}

.clt_inputFloatDetail_left input, .clt_inputFloatDetail_left input:focus, .clt_inputFloatDetail_left input:active {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0;
}

.clt_inputFloatDetail_left_reserve input, .clt_inputFloatDetail_left_reserve input:focus, .clt_inputFloatDetail_left_reserve input:active {
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 12px !important;
    border-right: 0;
}

.clt_inputFloatDetail_top input, .clt_inputFloatDetail_top input:focus, .clt_inputFloatDetail_top input:active {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
    border-top: 1px solid #dedddd !important ;
}

.clt_inputFloatDetail_bottom input, .clt_inputFloatDetail_bottom input:focus, .clt_inputFloatDetail_bottom input:active {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

.clt_inputFloatDetail_right input, .clt_inputFloatDetail_right input:focus, .clt_inputFloatDetail_right input:active {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.clt_inputFloatDetail_right_reserve input, .clt_inputFloatDetail_right_reserve input:focus, .clt_inputFloatDetail_right_reserve input:active {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.clt-detail-right-form-contact>div:first-of-type .clt_inputFloatDetail input {
    border-top: 1px solid #dedddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}



.clt-detail-right-form-contact>div .clt_inputFloatDetail:last-of-type textarea {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.clt-detail-right-form-reserve>div:first-of-type .clt_inputFloatDetail input {
    border-top: 1px solid #dedddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.clt-detail-right-form-reserve>div:first-of-type:hover .clt_inputFloatDetail input {
    background: #f6f6f6 !important;
}

.clt-detail-right-form-reserve>div:first-of-type:active .clt_inputFloatDetail input {
    background: #eee !important;
}

.clt-detail-right-form-reserve>div:first-of-type:focus .clt_inputFloatDetail input {
    background: #eee !important;
}

.clt-detail-div .form-floating>label {
    padding-left: 18px !important;
    padding-top: 13.2px !important;
    font-size: 14px;
    font-weight: 400;
    color: #242424;
}

.clt_formButton, .clt_formButton:hover, .clt_formButton:focus, .clt_formButton:active {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    padding-top: 2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #ffffff !important;
    height: 44px;
    position: relative;
    margin-top: 22px;
    letter-spacing: 0.1px;
}

.clt_formButton:hover {
    opacity: 0.8;
}

.clt_formButton:active {
    opacity: 0.7;
}

.clt-gallery-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 9999;
    background: rgb(255, 255, 255);
}

.clt-gallery-container-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #dedddd;
    height: 7.4vh;
}

.clt-gallery-container-header-col {
    padding-top: 0px;
    padding-right: 24px;
    padding-bottom: 0px;
    padding-left: 18px;
}

.clt-gallery-container-header-col button, .clt-gallery-container-header-col button:focus {
    background: transparent !important;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 6px;
    box-shadow: none !important;
    outline: none !important;
    color: rgb(0, 0, 0);
    padding-top: 0.4rem !important;
    padding-right: 0.9rem !important;
    padding-bottom: 0.4rem !important;
    padding-left: 0.8rem !important;
    border-radius: 100px;
}

.clt-gallery-container-header-col button:hover {
    background: #eee !important;
    color: rgb(0, 0, 0) !important;
}

.clt-gallery-container-header-col button:active {
    background: #d9dce0 !important;
    color: rgb(0, 0, 0) !important;
}

.clt-gallery-container-header-col span {
    align-items: center;
    color: #242424;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
    margin-left: 15.4px;
}

.clt-gallery-container-body {
    display: flex;
    align-items: center;
    height: 80vh;
}

.clt-gallery-container-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    height: 1vh;
}

.clt-gallery-container-footer span {
    align-items: center;
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: rgb(0, 0, 0);
}

.clt-detail-left-section-2 {
    padding-top: 23px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.clt-detail-left-section-21745{
    padding-top: 10px !important;
}
.clt-detail-footer-mbl {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #eee;
    background: #fff;
    padding: 12px 24px;
}

.clt-detail-footer-mblButton, .clt-detail-footer-mblButton:hover, .clt-detail-footer-mblButton:focus, .clt-detail-footer-mblButton:active {
    background: #28b07d;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    font-size: 16px;
    padding-top: 2px;
    font-weight: 600;
    letter-spacing: -0.2px;
    box-shadow: none;
    outline: none;
    border: 1px solid #29b07d;
    line-height: 20px;
    height: 50px;
    position: relative;
}

.clt-detail-footer-mblButton i {
    font-size: 13px;
    margin-left: 8px;
}

.clt-detail-footer-h-div h2 {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter';
    color: #242424;
    margin-bottom: -1px;
    letter-spacing: -0.2px;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;

}

.clt-detail-footer-h-div span {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.2px;
    font-family: "INTER";
    color: #242424;
    margin-bottom: 0;
    text-decoration-line: underline;
}

.clt_footer_bottom_up_modal {
    top: unset;
    bottom: 0;
    height: auto;
}

.clt_footer_bottom_up_modal.show .clt_footer_bottom_up_modal_dailog {
    transform: translateY(0%) !important;
    transition: all 0.5s ease-in-out !important;
}

.clt_footer_bottom_up_modal_dailog {
    transform: translateY(100%) !important;
    transition: all 0.5s ease-in-out !important;
}

.clt_footer_bottom_up_modal_content {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}


.clt-detail-footer-bottom-up-h2 {
    font-size: 17px;
    font-weight: 600;
    font-family: 'inter';
    color: #2f2323;
    text-align: center;
    letter-spacing: -0.2px;
    padding: 24px 0;
    margin: 0;
    border-bottom: 1px solid #dedddd;
}

.clt-detail-footer-bottom-up-menus img {
    width: 20px;
    margin: 20px;
}

.clt-detail-footer-bottom-up-menus span {
    font-size: 17px;
    font-weight: 600;
    font-family: 'inter';
    color: #2b2929;
    border-bottom: 1px solid #dedddd;
    width: 100%;
    letter-spacing: -0.2px;
    padding: 24px 0;
}

.clt-detail-footer-bottom-up-menus-div .clt-detail-footer-bottom-up-menus:last-child span {
    border: none;
}

.clt-custom-modal-header .btn-close, .clt-custom-modal-header .btn-close:focus {
    padding: 0;
    margin: 0;
    color: #242424;
    background-size: 9px;
    width: 20px;
    height: 20px;
    opacity: 1;
    outline: none;
    box-shadow: none;
}

.clt-custom-modal-header .btn-back, .clt-custom-modal-header .btn-back:focus {
    background: #fff url('../../../assets/img/back_arrow.png') center/1em auto no-repeat;
    background-size: 7px;
}

.clt-custom-modal-title {
    font-size: 16px;
    font-weight: 600;
    font-family: 'inter';
    color: #242424;
    letter-spacing: -.2px;
}

.clt-custom-modal-footer-btn {
    position: fixed;
    bottom: 2%;
    left: 5%;
    right: 5%;
}

.clt-detail-custom-modal-content-class .modal-body {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 0px;
    padding-left: 24px;
}

.clt-detail-custom-modal-content-class .modal-header {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 18px;
    padding-top: 18px;
}

.clt-form-space-bottom {
    height: 30vh;
}

.clt-button-text-duration2124{
    margin-top: 7px;
    opacity: 70%;
    font-size: 18px;
}
.clt-detail-footer-mb {
    margin-bottom: 30px;
}

.clt-detail-left-section-4-cards-div {}

.clt-detail-left-section-4-cards-div div:last-child {
    margin-right: 0px;
}

.clt-detail-left-section-4-card-item {
    width: 236px;
    height: 260px;
    border-radius: 18px;
    overflow: hidden;
    margin-right: 10px;
    cursor: default;
    -webkit-user-select: none;
    text-wrap: wrap;
    user-select: none;
    border: 1px solid #dedddd;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
}

.clt-detail-left-section-4-card-item:hover {
    background: #fbfafa;
}

.clt-detail-left-section-4-card-item:active {
    background: #eee;
}

.clt-detail-left-section-4-card-item174 {
    width: 236px !important;
    height: 240px !important;
    border-radius: 18px;
    overflow: hidden;
    margin-right: 10px;
    cursor: default;
    -webkit-user-select: none;
    text-wrap: wrap;
    user-select: none;
    border: 1px solid #dedddd;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    min-width: min-content;
}

.clt-detail-left-section-4-card-item174:hover {
    background: #fbfafa;
}

.clt-detail-left-section-4-card-item174:active {
    background: #eee;
}

.clt-detail-left-section-4-card-item-div {
    background: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 13px;
    position: relative;
    width: 100px;
    height: 100px;
}

.clt-detail-left-section-4-card-item-div174 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 13px;
    position: relative;
    border-radius: 138px;
    width: 46px;
    height: 46px;
}

.clt-detail-left-section-4-card-item-h2 {
    font-size: 18px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
    margin-top: 10px;
}

.clt-detail-left-section-4-card-item-h25345453 {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
    margin-top: 10px;
}

.clt-detail-left-section-4-card-item-h2-sub {
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: 400;
    color: #70757a;
    margin-bottom: 5px;
    font-family: 'Inter';
    margin-top: -5px;
}

.clt-detail-left-section-4-card-item-h2-subfjknlekv {
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 400;
    color: #70757a;
    margin-bottom: 5px;
    font-family: 'Inter';
    margin-top: -5px;
}

.clt-detail-left-section-4-card-item-secondary {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 500;
    color: #242424;
    margin-bottom: 3px;
    font-family: 'Inter';
}

.clt-detail-left-section-4-card-item-desc {
    font-size: 18px;
    letter-spacing: -0.2px;
    font-weight: 400;
    color: #70757a;
    margin-bottom: 0px;
    font-family: 'Inter';
}

.clt-detail-left-section-4-card-item-desc31351 {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 400;
    color: #70757a;
    margin-bottom: 0px;
    font-family: 'Inter';
}


.jnelkdjnzljdnze {
    padding-left: 40px !important;
    padding-right: 24px !important;
    padding-bottom: 24px !important;
}

.clt-detail-left-section-4-row {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.clt-detail-left-section-4-row::-webkit-scrollbar {
    display: none;
}

.clt-detail-left-section-4-row {
    -ms-overflow-style: none;
}

.clt-detail-left-section-4-row-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.clt-detail-left-section-4-row-col {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.clt-detail-left-section-4-h2-mt {
    margin-top: 0px;
}

.clt-detail-left-section-4-h2 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Lora";
    margin-bottom: 0px;
    color: #242424;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.clt-detail-left-section-4 {
    padding-top: 23px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.clt-detail-left-sub-section-div-main {
    margin-bottom: 40px;
}

.clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
    margin-bottom: 0px;
}

.clt-detail-left-sub-section-div-p p {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: 'Inter';
    margin-bottom: 14px;
}

.clt-detail-left-sub-section-div {
    margin-bottom: 23px;
}

.clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
    margin-bottom: 0px;
}

.clt-detail-left-sub-section-div img {
    width: 20px;
    margin-right: 15px;
}

.clt-detail-left-sub-section-div h3 {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 0px;
    font-family: 'Inter';
}

.clt_products_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_products_offcanvas2 {
    width: 35% !important;
    z-index: 1055 !important;
}

.clt_products_offcanvas_header {
    padding-top: 15px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_products_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0;
}

.clt_products_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_products_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_products_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_products_offcanvas_header .btn-back img {
    width: 7px;
}

.jdnlcnldzincid {
    max-width: 100% !important;
}



.clt_products_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}

.clt_products_offcanvas_body {
    padding-right: 24px !important;
    padding-left: 24px !important;
    padding-top: 24px !important;
}

.aclt_products_offcanvas_body {
    padding-right: 28px !important;
    padding-left: 28px !important;
    padding-top: 28px !important;
}

.clt_products_offcanvas_body-p-0 {
    padding: 24px !important;
}

.clt_products_offcanvas_body_mainrow {
    margin-bottom: 40px;
    margin: 0 0px;
}

.clt_products_offcanvas_body_maindiv {
    margin-bottom: 14px;
}

.clt-section-row-col-pddzkjdkjzn {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 0px;
}



.clt_products_offcanvas_body_secdiv {
    margin-bottom: 0px;
    cursor: pointer;
    padding-top: 18px;
    padding-bottom: 18px;
    border-radius: 15px;
    padding-left: 3px;
}

.clt_products_offcanvas_body_secdiv:hover {
    margin-bottom: 0px;
    cursor: pointer;
    background: #f6f6f6;
    padding-top: 18px;
    padding-bottom: 18px;
    border-radius: 15px;
}

.clt_products_offcanvas_body_category {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt_products_offcanvas_body_product {
    font-size: 15px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 4px;
    font-family: 'Inter';
}

.clt_products_offcanvas_body_secondary {
    font-size: 15px;
    letter-spacing: -0.2px;
    font-weight: 500;
    color: #242424;
    margin-bottom: 1px;
    font-family: 'Inter';
}

.clt_products_offcanvas_body_desc {
    font-size: 15px;
    letter-spacing: -0.2px;
    font-weight: 400;
    color: #70757a;
    margin-bottom: 0px;
    font-family: 'Inter';
}

.clt_products_offcanvas_body_img {
    background: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100px;
}

.clt_product_detail_offcanvas_body .image_div {
    background: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 0px;
    position: relative;
    width: 100%;
    height: 230px;
}

.clt_product_detail_offcanvas_body .header_text_div {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
}

.clt_product_detail_offcanvas_body_eventrecent .header_text_div {
    padding-left: 0px !important;
    padding-right: 0px;
    padding-top: 6px;
    padding-bottom: 18px;
}

.clt_product_detail_offcanvas_body .header_text_div h2 {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Dancing Script';
    color: #242424;
    margin-bottom: 5px;
}

.clt_product_detail_offcanvas_body .header_text_div p {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Inter';
    color: #3b3b3b;
    margin-bottom: 0px;
}

.clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
}

.clt_product_detail_offcanvas_body .sub_text_div:last-child {
    padding-bottom: 25px;
}

.clt_product_detail_offcanvas_body .sub_text_div h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter';
    color: #242424;
    margin-bottom: 5px;
}

.clt_product_detail_offcanvas_body .sub_text_div p {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Inter';
    color: #3b3b3b;
    margin-bottom: 0px;
}

.clt-detail-section-6-header h2 {
    font-size: 25px;
    font-weight: 600;
    color: #242424;
    font-family: 'Lora';
    margin-bottom: 8px;
    letter-spacing: 0.2px;
}

.clt-detail-section-6-header p {
    font-size: 18.5px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    margin-top: 8px;
}

.clt-detail-section-6-body {
    margin-top: 15px !important;
    border: 1px solid #dedddd;
    border-radius: 15px;
}

.clt-detail-section-6-body .row:last-child .clt-detail-section-6-body-col {
    border-bottom: 0px;
}

.clt-detail-section-6-body-col {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 17px;
    padding-left: 20px;
    border-bottom: 1px solid #dedddd;
}

.clt-detail-section-6-body-col.border-right {
    border-right: 1px solid #dedddd;
}

.clt-detail-section-6-body h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-detail-section-6-body p {
    font-size: 15px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-top: 1px;
    margin-bottom: 0;
    line-height: 23px;
    letter-spacing: -0.2px;
}

.clt-detail-section-6-body button, .clt-detail-section-6-body button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    height: 46px;
    position: relative;
    letter-spacing: 0.1px;
}

.clt-detail-section-6-body button:hover {
    background: #d9dce0 !important;
    border: 1px solid #d9dce0 !important;
    color: #242424 !important;
}

.clt-detail-section-6-body button:active {
    background: #d2d2d3 !important;
    border: 1px solid #d2d2d3 !important;
    color: #242424 !important;
}


.clt-detail-section-6-offcanvas-row {
    margin-left: 0px;
    margin-right: 0px;
}

.clt-detail-section-6-offcanvas-row-2 {
    margin-left: 0px;
    margin-right: 0px;
}

.clt-detail-section-6-offcanvas-cat-h2 {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-detail-section-6-offcanvas-cat-h2.margin-top {
    margin-top: 45px;
}

.clt-detail-section-6-offcanvas-data-row {
    margin-top: 20px !important;
    border: 1px solid #dedddd;
    border-radius: 12px;
    margin-bottom: 54px !important;
}

.clt-detail-section-6-offcanvas-data-row-col {
    padding: 15px 20px;
    border-bottom: 1px solid #dedddd;
}

.clt-detail-section-6-offcanvas-data-row-col:hover {
    background: #f6f6f6;
}

.clt-detail-section-6-offcanvas-data-row-col:active {
    background: #eee;
}

.clt-detail-section-6-offcanvas-data-row .col .row:last-child .clt-detail-section-6-offcanvas-data-row-col {
    border-bottom: 0px;
}

.clt-detail-section-6-offcanvas-data-row-col.border-right {
    border-right: 1px solid #dedddd;
}

.clt-detail-section-6-offcanvas-data-row-col.border-bottom-none {
    border-bottom: none;
}

.clt-detail-section-6-offcanvas-data-row-col h2 {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
}

.clt-detail-section-6-offcanvas-data-row-col p {
    font-size: 14px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-top: 0px;
    margin-bottom: 0;
    line-height: 20px;
    letter-spacing: -0.2px;
}

.clt-detail-section-7 h2 {
    font-size: 25px;
    font-weight: 600;
    color: #242424;
    font-family: 'Lora';
    margin-bottom: 0;
    letter-spacing: 0.2px;
}

.clt-detail-section-7142 {
    font-size: 25px;
    font-weight: 600;
    color: #242424;
    font-family: 'Lora';
    margin-bottom: 0;
    letter-spacing: 0.2px;
}


.clt-detail-section-7 p {
    font-size: 16px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0px;
    margin-top: 6px;
}

.clt-detail-section-77 h2 {
    font-size: 48px !important;
    letter-spacing: 0.2px !important;
    font-weight: 600 !important;
    font-family: 'Lora' !important;
    line-height: 28px !important;
    color: #242424 !important;
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 2px !important;
    margin-left: 0px !important;
    padding-top: 4px !important;
}

.clt-detail-section-77 p {
    font-size: 25px !important;
    line-height: 25px !important;
    padding: 0 !important;
    margin-top: 20px !important;
    font-weight: 400 !important;
    font-family: inter !important;
    margin-bottom: -2px !important;
    margin-left: 0px !important;
}

.clt-detail-section-7-img-row {
    margin-top: 20px;
    margin-bottom: 32px !important;
}

.clt-detail-section-77-img-row {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
}

.clt-detail-section-7-img-div {
    background: #eee;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 24px;
    width: 100%;
    height: 440px;
        margin-bottom: 34px;
}

.clt-detail-section-7-img-div5462 {
    margin-top: 16px;
}

.clt-datepicker-sidebar {
    position: relative;
    padding: 24px !important;
    border-bottom: 1px solid #dedddd;
}

.clt-datepicker-sidebar-h2 {
    font-size: 18px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-datepicker-sidebar-h3 {
    font-size: 18px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-top: 3px;
    margin-bottom: -4px;
    letter-spacing: -0.2px;
}

.clt-datepicker-sidebar-footer-space {
    margin-bottom: 20vh;
}

.clt-datepicker-sidebar-footer {
    background: #fff;
}

.clt-datepicker-sidebar-footer .inner-div {
    margin-top: 20px;
    margin-right: 24px;
    margin-bottom: 20px;
    margin-left: 24px;
}

.clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:hover, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:active {
    background: #28b07d;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    padding-top: 1.5px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #29b07d;
    height: 48px;
    position: relative;
    margin-top: 26px;
}

.clt-datepicker-sidebar-footer-btn:disabled {
    opacity: 0.32;
}

.clt-datepicker-sidebar-info {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dedddd;
    border-radius: 12px;
    padding: 20px 15px 23px;
    margin-top: 16px;
}

.clt-datepicker-sidebar-info i {
    font-size: 16px;
    color: #242424;
}

.clt-datepicker-sidebar-info span {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    line-height: 22px;
    font-family: "Inter";
    margin-left: 15px;
}

.clt-datepicker-sidebar-timeslot-mainDiv {
    border: 1px solid #ddd;
    padding-top: 18px;
    padding-right: 20px;
    padding-bottom: 19px;
    padding-left: 20px;
    border-radius: 12px;
    margin-top: 4px;
    cursor: pointer;
    margin-bottom: 16px;
}

.clt-datepicker-sidebar-timeslot-mainDiv:hover {
    background: #fbfafa !important;
}

.clt-datepicker-sidebar-timeslot-mainDiv:active {
    background: #eee;
    border: 1px solid #000;
}


.clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 5px;
    letter-spacing: -0.2px;
}

.clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 1px;
    letter-spacing: -0.2px;
}

.clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-top: 4px;
    margin-bottom: 5px;
    letter-spacing: -0.2px;
    display: none;
}

.clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    margin-right: 5px;
}

.clt-datepicker-sidebar-timeslot-mainDiv .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-datepicker-sidebar-timeslot-mainDiv .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}

.pswp__scroll-wrap {
    background: #242424;
}

.pswp__item {
    z-index: 1;
    overflow: hidden;
    background: #242424;
}

button.pswp__button.pswp__button--zoom {
    opacity: 0 !important;
}

.pswp__button {
    display: block;
    width: 50px;
    height: 60px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    background: none;
    border: 0;
    box-shadow: none;
    opacity: 1 !important;
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.pswp__counter {
    height: 30px;
    font-family: inter;
    font-weight: 500;
    margin-top: 15pfx;
    margin-inline-start: 20px;
    font-size: 16px !important;
    line-height: 30px;
    color: #fff;
    text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
    opacity: 1 !important;
}

.pswp--has_mouse .pswp__button--arrow {
    width: 50px !important;
    height: 50px !important;
    padding: 10px;
    fill: #fff;
    background: rgba(0,0,0,.3);
    box-sizing: content-box;
    /* border: 1px solid #a29c9c !important; */
    /* border-radius: 255px !important; */
}

.pswp__button--arrow .pswp__icn {
    top: 56% !important;
    margin-top: -30px !important;
    width: 50px o !important;
    height: 50px !important;
    background: none;
    border-radius: 0;
}

.pswp__button--arrow--next .pswp__icn {
    left: auto;
    right: 14px !important;
    transform: scale(-1, 1);
}


.pswp__icn-shadow {
    stroke: #242424 !important;
    stroke-width: var(--pswp-icon-stroke-width);
    fill: none;
}

svg.pswp__icn {
    margin-left: 4px;
}

.clt_detail_reserve_form_separate_line {
    margin: 1.5rem 0;
    color: #726c6c;
}

.clt_detail_reserve_form_text_link_box {
    color: #242424;
    font-weight: 600;
    font-size: 15px;
}

.clt_detail_reserve_form_text_link_box:hover {
    color: #828282;
}

.clt_detail_reserve_form_text_link_box:active {
    color: #b9b9b9;
}

.clt_detail_reserve_form_box_p {
    margin: 0;
    color: #242424;
    font-size: 15px;
    font-family: 'inter';
    font-weight: 600;
}

.clt_detail_reserve_form_box_p2 {
    margin: 0;
    color: #242424;
    font-size: 15px;
    font-family: 'inter';
    font-weight: 400;
}

.clt_detail_reserve_form_box_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    border-radius: 15px;
    border: 1px solid #dedddd;
    gap: 14px;
    padding: 20px 16px;
}

.clt-detail-right-main::-webkit-scrollbar {
    width: 0.45rem !important;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #6969dd #070505;
    padding: 1rem;
    overflow-y: auto;
    scrollbar-gutter: stable;
}


.clt-detail-right-main::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: #e0e0e0;
}

.clt-detail-right-main::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgb(169, 169, 169);
}

.pfr_dropdown_menu_month_year::-webkit-scrollbar {
    width: 0.45rem;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #6969dd #070505;
    padding: 1rem;
    overflow-y: none;
    scrollbar-gutter: stable;
}

.pfr_dropdown_menu::-webkit-scrollbar {
    width: 0.45rem;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #6969dd #070505;
    padding: 1rem;
    overflow-y: none;
    scrollbar-gutter: stable;
}


.pfr_dropdown_menu_month_year::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: #e0e0e0;
}

.pfr_dropdown_menu::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: #e0e0e0;
}

.pfr_dropdown_menu_month_year::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgb(169, 169, 169);
}

.pfr_dropdown_menu::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgb(169, 169, 169);
}

.form-control:disabled {
    background-color: #eee;
    opacity: 1;
    color: #525252;
}

.my-carousel .react-multi-carousel-item {
    width: 351px !important;
}

.my-carousel2 .react-multi-carousel-item {
    width: 265px !important;
}

.my-carousel3 .react-multi-carousel-item {
    width: 260px !important;
}

.my-carousel4 .react-multi-carousel-item {
    width: 238px !important;
}

.my-carousel174 .react-multi-carousel-item {
    width: 256px !important;
}

.my-carousel175343544 .react-multi-carousel-item {
    width: 318px !important;
}


.aab {
    background: #fff;
    max-width: 100%;
    border-radius: 20px;
    padding-left: 22px;
    max-width: 66% !important;
    margin-top: 31px;
}

.aaaaaab {
    background: #fff5d9;
    max-width: 100%;  
}

.aaaaaab1 {
    width: 100%;
}

.aaaaaab3 {
    width: 118%;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    align-content: center;

}

.aaaaaab4 {
    width: auto;
    height: 43px;
    margin-top: -2px;
    align-items: center;
    justify-content: center;
    display: flex;
    align-content: center;
}

.aaaaaab2 {
    background: #f7eee1;
    max-width: 100%;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: -18px
}


.slick-initialized .slick-slide:nth-child(1) {
    margin-left: -110px;
}

.slick-slider  {
    /* padding: 0px 50px; */
    width: 100%;
}

 .slick-prev {
    left: 0px !important;
    z-index: 111 !important;
    width: 27px;
    height: 27px;
    /* background: black !important; */
    /* border: 1px solid grey; */
}

.slick-next {
    right: 0px !important;
    z-index: 111 !important;
    width: 27px;
    height: 27px;
    /* background: black !important; */
    /* border: 1px solid grey; */
}

.slick-next svg {
    padding-bottom: 0px;
    padding-left: 3px;
    font-size: 25px;
    color: #000;
}

.slick-prev svg {
    padding-bottom: 0px;
    padding-left: 0px;
    font-size: 24px;
    color: #000;
}

.slick-prev:before {
    content: none !important;
}

.slick-next:before {
    content: none !important;
}

.aabb {
    background: #ebebeb;
    max-width: 100%; 
    padding: 0px; 
}

.aabbb {
    background: #ebebeb;
    max-width: 100%;
    padding: 0px;
    margin-left: 24px;
    margin-right: 24px;
    width: auto;
}
.aabbbb {
    background: #ebebeb;
    max-width: 100%;
    padding: 0px;
    margin-left: 24px;
    margin-right: 24px;
    width: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    justify-content: center;
    align-items: center;
}

.aabbbbb {
    background: #ebebeb;
    max-width: 100%;
    padding: 0px;
    margin-left: 24px;
    margin-right: 24px;
    width: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ababab {
    margin-right: 6px !important;
}

.clt-product-prestaire-border-bottom {
    border-bottom: 1px solid #dedddd;
}

.clt-detail-form-sidebar-diva {
    flex-direction: column;
    display: flex;
}

.eapps-widget-toolbar {
    position: absolute;
    top: -32px;
    left: 0;
    right: 0;
    display: block;
    z-index: 99999;
    padding-bottom: 4px;
    transition: all .3s ease;
    pointer-events: none;
    opacity: 0 !important;
}
.border-bottomxx {
    border-bottom: 0px solid #e0e0e0;
}

.clt-detail-left-section-2-row-col .clt-detail-left-section-2-cards .clt-detail-left-section-4-card-item-h2 .clt-detail-left-section-4-card-item-h2-sub {
    text-decoration: none !important;
}

.clt-button-map-afficher-carte {
    background: #242424 !important;
    border-radius: 50px;
    font-family: 'Inter';
    border: 0px;
    font-weight: 500;
}

.clt-button-map-afficher-carte:hover {
    background: #2d2d2d !important;
}

.clt-button-map-afficher-carte:active {
    background: #5c5c5c !important;
}

.eapps-instagram-feed-header-small .eapps-instagram-feed-header-inner {
    flex-direction: row !important;
}

#eapps-instagram-feed-1 .eapps-instagram-feed-header, #eapps-instagram-feed-1 .eapps-instagram-feed-header a {
    color: #242424 !important;
}

.eapps-instagram-feed-header-user + .eapps-instagram-feed-header-stats {
    margin-left: 4px !important;
}

.clt-detail-page-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.clt-detail-left-section-4-card-item-desc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4; /* Adjust the number of lines as needed */
    margin-bottom: 0;
}

.clt-detail-left-section-4-card-item-desc31351 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4; /* Adjust the number of lines as needed */
    margin-bottom: 0;
}

.clt-detail-left-section-4-card-item-desc174 {
    -webkit-line-clamp: inherit !important; /* Adjust the number of lines as needed */
    text-overflow: unset !important;
}

.see-more-btn {
    background-color: #000000; /* Blue color, you can change it */
    color: #fff;
    padding: 5px 10px; /* Adjust padding as needed */
    cursor: pointer; /* Ensure it looks clickable */
    /* Add any other necessary styles */
}

.mbadzdaz-2{
    margin-bottom: -0.8rem!important;
}


.modal-content {
    position: relative;
    display: flex;
    border-radius: 22px !important;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.clt-margin-bottom-section-recent-event {
    margin-bottom: 20px !important;
}
@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-detail-slider-maindiv {
        padding: 0;
    }

    .clt-button-text-duration2124{
        margin-top: 7px;
        font-size: 15px;
        letter-spacing: -0.2px;
        opacity: 70%;
    }

    .ababab {
        margin-right: 14px !important;
    }

    .eapps-instagram-feed-posts-slider-inner {
        width: 1059px;
    }
    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:hover, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:active {
        background: #28b07d;
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        border: 1px solid #29b07d;
        height: 50px;
        margin-top: 26px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-section-hr-pd {
        padding: 28px 22px;
    }

    .clt-section-hr-pd22 {
        padding-top: 28px;
        padding-right: 36px;
        padding-bottom: 4px;
        padding-left: 36px;
        opacity: 1;
    }

    .clt-section-hr-pd221 {
        padding-top: 2px;
        padding-right: 36px;
        padding-bottom: 0px;
        padding-left: 36px;
        margin-top: 30px;
        opacity: 1;
    }

    .clt-section-hr-pddddd {
        padding: 28px 0px;
    }

    .clt-detail-slider-main {
        border-radius: 0;
    }
    

    .clt-detail-slider-main-imgdiv {
        height: 195px;
        border-radius: 0px;
    }

    .clt-detail-slider-main-imgdiv span {
        padding: 5px 12px;
        bottom: 20px;
        right: 20px;
        font-size: 13px;
        border-radius: 8px;
    }

    .clt-detail-slider-gallery {
        border-radius: 0;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 242px;
        border-radius: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .clt-detail-slider-gallery-imgdiv span {
        padding: 5px 12px;
        bottom: 20px;
        right: 20px;
        font-size: 13px;
        border-radius: 8px;
    }

    .clt-detail-sections-div {
        margin-top: 25px;
    }

    .clt-detail-section-1 {
        margin-top: 16px;
        margin-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .clt-detail-section-1-subdiv img {
        width: 12px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 16px;
        font-weight: 400;
        margin-left: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-1 {
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 23px;
    }

    .clt-detail-left-section-subdiv-1 {
        margin-top: 12px;
        margin-bottom: -5px;
        flex-direction: column !important;
        display: flex;
    }

    .clt-detail-left-section-subdiv-1-div {
        margin-bottom: 18px;
        height: 50px;
        margin-right: 50px;
        display: -webkit-inline-box;
        flex-direction: column !important;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 40px;
        height: 40px;
        margin-right: 14px;
        border-radius: 8px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 24px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-txt {}

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-detail-left-section-subdiv-2 {
        flex-wrap: wrap;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13.5px;
        letter-spacing: -0.1px;
        font-weight: 600;
        padding: 2.5px 12px;
        border-radius: 6px;
        margin-right: 10px;
        margin-top: 14px;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-2-col {
        padding: 0px !important;
    }

    .clt-detail-left-section-2-h2-mt {
        margin-top: 0px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-home-visite-h2{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 26px 0px 0px 0px;
    }
    .clt-home-visite-h2knkjbjkbh{
        font-size: 50px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 54px !important;
        margin: 26px 0px 0px 0px;
        font-family: Lora;
        text-align: -webkit-center;
        padding-bottom: 30px;
        line-height: 54px;
        color: #222;
    }

    .clt-home-visite-h2124{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 22px 0px 0px 0px;
    }

    .clt-home-visite-h22123 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
        margin: 36px 0px 0px 0px;
    }

    .clt-home-visite-h2174 {
        font-size: 32px;
        font-weight: 700;
        justify-content: start;
        display: flex;
        letter-spacing: -0.2px;
        line-height: 34px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
        margin: 36px 0px -6px 0px;
        padding-bottom: 2px;
    }
    .clt-detail-left-section-2-cards {
        width: 283px;
        height: 283px;
        border-radius: 15px;
        margin-right: 15px;
    }

    .clt-home-visite-h2-cards {
        width: 284px;
        height: 322px;
        border-radius: 16px;
        margin-right: 15px;
    }

    .clt-detail-left-section-2-cards-main span {
        top: 13px;
        left: 13px;
        font-size: 13px;
        padding: 2px 10px;
    }

    .clt-detail-left-section-3 {
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 27px;
        padding-right: 24px;
        padding-bottom: 36px;
        padding-left: 24px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 22px;
    }

    .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-left-section-3 p {
        font-size: 16px;
    }

    .clt-detail-footer-mb {
        margin-bottom: 20vh;
    }

    .clt-detail-footer-mb-col {
        padding: 0px 22px;
    }

    .clt-gallery-container-header {
        height: 8vh;
    }

    .clt-gallery-container-header-col span {
        font-size: 16px;
        letter-spacing: -.2px;
        margin-bottom: 8px;
        margin-left: 11px;
    }

    .clt-gallery-container-header-col button, .clt-gallery-container-header-col button:hover {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 8px;
    }

    .clt-gallery-container-footer {
        margin-top: 0px;
    }

    .clt-gallery-container-footer span {
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #242424;
    }

    .clt-gallery-container-header-col {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 14px;
    }

    .clt-detail-footer-mbl {
        display: block;
    }

    .clt-detail-left-section-4-col {
        padding: 0;
    }

    .clt-detail-left-section-4-h2-mt {
        margin-top: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4-card-item {
        width: 242px;
        height: 270px;
        border-radius: 15px;
        margin-right: 16px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 242px !important;
        height: 270px !important;
        border-radius: 15px;
        margin-right: 16px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 100px;
        height: 100px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 103px;
        width: 45px;
        height: 45px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-top: -4px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-top: -4px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 14px;
        font-weight: 300;
        color: #70757a;
        font-family: 'Inter';
        margin-bottom: 14px;
    }

    .clt_products_offcanvas {
        width: 100%;
    }

    .clt_products_offcanvas2 {
        width: 100% !important;
        z-index: 1055 !important;
    }

    .clt_products_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }

    .aclt_products_offcanvas_body {
        padding-right: 28px !important;
        padding-left: 28px !important;
    }



    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 20px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 6px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 21px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
        height: 50px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
        margin-bottom: 54px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 22px;
        letter-spacing: -0.2px;
        margin-bottom: 7px;
        margin-top: 33px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
    }
    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 7px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        border-radius: 14px;
        width: 100%;
        height: 279px;
        margin-bottom: 0px;
    }

    .clt-detail-div .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
        font-weight: 400;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt_formButton, .clt_formButton:hover, .clt_formButton:focus {
        font-size: 16px;
        height: 52px;
        padding-top: 1px;
    }

    .clt-detail-right-head-sub-div span {
        font-weight: 700;
        font-size: 25px;
        line-height: 1.2;
        padding-right: 5px;
        margin-top: 8px;
        margin-bottom: 28px;
    }

    .clt-detail-right-head-sub-diva span {
        font-size: 25px;
        line-height: 1.2;
        padding-right: 5px;
        margin-top: 30px;
        margin-bottom: 8px;
    }


    .clt_inputFloatDetail.form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
    }

    .clt_inputFloatDetail input, .clt_inputFloatDetail input:active, .clt_inputFloatDetail input:focus {
        height: 64px !important;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 18px 0 !important;
        border-radius: 0px;
    }

    .clt_inputFloatDetail.form-floating>.form-control:focus~label, .clt_inputFloatDetail.form-floating>.form-control:not(:placeholder-shown)~label, .clt_inputFloatDetail.form-floating>.form-select~label {
        font-size: 15px;
        opacity: .7;
        padding: 1.02rem 1.01rem 0.95rem 1.09rem !important;
        -webkit-transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
        transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
    }

    .clt_inputFloatDetail textarea, .clt_inputFloatDetail textarea:active, .clt_inputFloatDetail textarea:focus {
        font-size: 16px;
        font-weight: 500;
        padding: 25px 18px 0 !important;
    }

    .clt_inputFloatDetailDiv .clt_input_icon_angle_down {
        right: 18px;
    }

    .clt-detail-custom-modal-content-class .modal-header {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-left: 19px;
    }

    .clt-custom-modal-title {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 2px;
        margin-left: 16px;
        margin-bottom: 3px;
    }

    .clt-custom-modal-header .btn-back, .clt-custom-modal-header .btn-back:focus {
        background-size: 8px;
        margin-bottom: 2px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt-detail-right-head-heading-input {
        font-size: 13px;
    }

    .aaaaaab4 {
        width: auto;
        height: 34px;
        margin-top: 8px;
        margin-left: 8px;
        align-items: center;
        justify-content: center;
        display: flex;
        align-content: center;
    }

    .clt-detail-left-section-3-cards {
        width: 284px  !important;
        height: 322px !important;
        border-radius: 18px !important;
        margin-right: 15px !important;
    }

    .clt-detail-section-77 h2 {
        font-size: 32px !important;
        font-weight: 700 !important;
        letter-spacing: -0.2px !important;
        line-height: 34px !important ;
        margin: 0px 0px 2px 0px !important;
    }
    
    .clt-detail-section-77 p {
        font-size: 19.5px !important;
        line-height: 28px !important;
        margin: 10px 0 0px !important;
        margin-top: 8px !important;
        padding: 0 !important;
        justify-content: center !important;
        display: flex !important;
        text-align: -webkit-center !important;
    }

    .clt-button-map-afficher-carte {
        height: 48px;
    }

    .aab {
        margin-top: 0px;
    }

    .clt-home-visite-h23546534 {
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: 11px;
        margin-right: 0px;
        margin-bottom: -28px;
        margin-left: 0px;
        padding-top: 6px;
    }

    .clt-home-visite-h2354653412313456 {
        font-size: 20px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: 11px;
        margin-right: 0px;
        margin-bottom: -4px;
        margin-left: 0px;
        padding-top: 6px;
        border-top: 0px solid #dedddd;
    }

    .clt-home-visite-h2354653422 {
        font-size: 20px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: -17px;
        margin-right: 0px;
        margin-bottom: -26px;
        margin-left: 0px;
        padding-top: 0px;
    }

    .clt-home-visite-h2-header2321354 {
        padding-bottom: 0px;
    }
    .clt-detail-left-section-2 {
        padding-top: 19px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .my-carousel .react-multi-carousel-item {
        width: 295px !important;
        margin-top: 14px;
    }

    .my-carouseldcskdjndslkcj .react-multi-carousel-item {
        width: 295px !important;
        margin-top: 14px;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-detail-slider-maindiv {
        padding: 0;
    }

    .clt-button-text-duration2124{
        margin-top: 7px;
        font-size: 15px;
        opacity: 70%;
        letter-spacing: -0.2px;
    }
    .my-carouseldcskdjndslkcj .react-multi-carousel-item {
        width: 295px !important;
        margin-top: 14px;
    }
    .clt-detail-left-section-2 {
        padding-top: 19px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .ababab {
        margin-right: 14px !important;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:hover, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:active {
        background: #28b07d;
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        border: 1px solid #29b07d;
        height: 50px;
        margin-top: 26px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt-detail-slider-main {
        border-radius: 0;
    }

    .clt-section-hr-pd {
        padding: 28px 22px;
    }

    .clt-section-hr-pd22 {
        padding-top: 28px;
        padding-right: 36px;
        padding-bottom: 4px;
        padding-left: 36px;
        opacity: 1;
    }

    .clt-section-hr-pd221 {
        padding-top: 2px;
        padding-right: 36px;
        margin-top: 30px;
        padding-bottom: 0px;
        padding-left: 36px;
        opacity: 1;
    }

    .clt-section-hr-pddddd {
        padding: 28px 0px;
    }

    .clt-detail-slider-main-imgdiv {
        height: 205px;
        border-radius: 0px;
    }

    .clt-detail-slider-main-imgdiv span {
        padding: 5px 12px;
        bottom: 20px;
        right: 20px;
        font-size: 13px;
        border-radius: 8px;
    }

    .clt-detail-slider-gallery {
        border-radius: 0;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 242px;
        border-radius: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .clt-detail-slider-gallery-imgdiv span {
        padding: 5px 12px;
        bottom: 20px;
        right: 20px;
        font-size: 13px;
        border-radius: 8px;
    }

    .clt-detail-sections-div {
        margin-top: 25px;
    }

    .clt-detail-section-1 {
        margin-top: 16px;
        margin-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .clt-detail-section-1-subdiv img {
        width: 12px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 16px;
        font-weight: 400;
        margin-left: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-1 {
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 23px;
    }

    .clt-detail-left-section-subdiv-1 {
        margin-top: 12px;
        margin-bottom: -5px;
        flex-direction: column !important;
        display: flex;
    }

    .clt-detail-left-section-subdiv-1-div {
        margin-bottom: 18px;
        height: 50px;
        margin-right: 50px;
        display: -webkit-inline-box;
        flex-direction: column !important;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 40px;
        height: 40px;
        margin-right: 14px;
        border-radius: 8px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 24px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-txt {}

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-detail-left-section-subdiv-2 {
        flex-wrap: wrap;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13.5px;
        letter-spacing: -0.1px;
        font-weight: 600;
        padding: 2.5px 12px;
        border-radius: 6px;
        margin-right: 10px;
        margin-top: 14px;
        margin-bottom: 0px
    }

    .clt-detail-left-section-2-col {
        padding: 0px;
    }

    .clt-detail-left-section-2-h2-mt {
        margin-top: 0px;
        margin-left: -16px !important;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-77 h2 {
        font-size: 32px !important;
        font-weight: 700 !important;
        letter-spacing: -0.2px !important;
        line-height: 34px !important ;
        margin: 0px 0px 2px 0px !important;
    }

    .clt-home-visite-h2{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 26px 0px 0px 0px;
    }

    .clt-home-visite-h2knkjbjkbh{
        font-size: 50px;
        padding-bottom: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        color: #222;
        line-height: 54px !important;
        margin: 26px 0px 0px 0px;
        font-family: Lora;
        text-align: -webkit-center;
    }

    .clt-home-visite-h2124{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 22px 0px 0px 0px;
    }

    .clt-home-visite-h22123 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
        margin: 36px 0px 0px 0px;
    }

    .clt-home-visite-h2174 {
        font-size: 32px;
        font-weight: 700;
        justify-content: start;
        display: flex;
        letter-spacing: -0.2px;
        line-height: 34px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
        margin: 36px 0px -6px 0px;
        padding-bottom: 2px;
    }

    .clt-detail-left-section-2-cards {
        width: 283px;
        height: 212px;
        border-radius: 15px;
        margin-right: 15px;
    }

    .clt-home-visite-h2-cards {
        width: 284px;
        height: 322px;
        border-radius: 16px;
        margin-right: 15px;
    }

    .clt-detail-left-section-2-cards-main span {
        top: 13px;
        left: 13px;
        font-size: 13px;
        padding: 2px 10px;
    }

    .clt-detail-left-section-3 {
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 27px;
        padding-right: 24px;
        padding-bottom: 36px;
        padding-left: 24px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 22px;
    }

    .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-left-section-3 p {
        font-size: 16px;
    }

    .clt-detail-footer-mb {
        margin-bottom: 20vh;
    }

    .clt-detail-footer-mb-col {
        padding: 0px 22px;
    }

    .clt-gallery-container-header {
        height: 8vh;
    }

    .clt-gallery-container-footer {
        margin-top: 0px;
    }

    .clt-gallery-container-footer span {
        align-items: center;
        font-size: 16px;
        font-weight: 600;
    }

    .clt-gallery-container-header-col span {
        font-size: 16px;
        letter-spacing: -.2px;
        margin-bottom: 8px;
        margin-left: 11px;
    }

    .clt-gallery-container-header-col button, .clt-gallery-container-header-col button:hover {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 8px;
    }


    .clt-gallery-container-header-col {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 14px;
    }

    .clt-detail-footer-mbl {
        display: block;
    }

    .clt-detail-left-section-4-col {
        padding: 0;
    }

    .clt-detail-left-section-4-h2-mt {
        margin-top: 0px;
        margin-left: -16px !important;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4-card-item {
        width: 242px;
        height: 270px;
        border-radius: 15px;
        margin-right: 16px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 242px !important;
        height: 270px !important;
        border-radius: 15px;
        margin-right: 16px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 100px;
        height: 100px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 103px;
        width: 45px;
        height: 45px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-top: -4px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-top: -4px;
    }
    .clt-detail-left-section-4-card-item-secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        color: #70757a;
        font-family: 'Inter';
        margin-bottom: 14px;
    }

    .clt_products_offcanvas {
        width: 100%;
    }

    .clt_products_offcanvas2 {
        width: 100% !important;
        z-index: 1055 !important;
    }

    .clt_products_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }

    .aclt_products_offcanvas_body {
        padding-right: 28px !important;
        padding-left: 28px !important;
    }



    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 20px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 6px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 21px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
        height: 50px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
        margin-bottom: 54px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 22px;
        letter-spacing: -0.2px;
        margin-bottom: 7px;
        margin-top: 33px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 7px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        border-radius: 14px;
        width: 100%;
        margin-bottom: 0px;
        height: 279px;
    }

    .clt-detail-div .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
        font-weight: 400;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt_formButton, .clt_formButton:hover, .clt_formButton:focus {
        font-size: 16px;
        height: 52px;
        padding-top: 1px;
    }

    .clt-detail-right-head-sub-div span {
        font-weight: 700;
        font-size: 25px;
        line-height: 1.2;
        padding-right: 5px;
        margin-top: 8px;
        margin-bottom: 28px;
    }

    .clt-detail-right-head-sub-diva span {
        font-size: 25px;
        line-height: 1.2;
        padding-right: 5px;
        margin-top: 30px;
        margin-bottom: 8px;
    }

    .clt_inputFloatDetail.form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
    }

    .clt_inputFloatDetail input, .clt_inputFloatDetail input:active, .clt_inputFloatDetail input:focus {
        height: 64px !important;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 18px 0 !important;
        border-radius: 0px;
    }

    .clt_inputFloatDetail.form-floating>.form-control:focus~label, .clt_inputFloatDetail.form-floating>.form-control:not(:placeholder-shown)~label, .clt_inputFloatDetail.form-floating>.form-select~label {
        font-size: 15px;
        opacity: .7;
        padding: 1.02rem 1.01rem 0.95rem 1.09rem !important;
        -webkit-transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
        transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
    }

    .clt_inputFloatDetail textarea, .clt_inputFloatDetail textarea:active, .clt_inputFloatDetail textarea:focus {
        font-size: 16px;
        font-weight: 500;
        padding: 25px 18px 0 !important;
    }

    .clt_inputFloatDetailDiv .clt_input_icon_angle_down {
        right: 18px;
    }

    .clt-detail-custom-modal-content-class .modal-header {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-left: 19px;
    }

    .clt-custom-modal-title {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 2px;
        margin-left: 16px;
        margin-bottom: 3px;
    }

    .clt-custom-modal-header .btn-back, .clt-custom-modal-header .btn-back:focus {
        background-size: 8px;
        margin-bottom: 2px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt-detail-right-head-heading-input {
        font-size: 13px;
    }

    .aaaaaab4 {
        width: auto;
        height: 34px;
        margin-top: 8px;
        margin-left: 8px;
        align-items: center;
        justify-content: center;
        display: flex;
        align-content: center;
    }

   
    .clt-detail-left-section-3-cards {
        width: 284px  !important;
        height: 322px !important;
        border-radius: 18px !important;
        margin-right: 15px !important;
    }

    .clt-detail-section-77 p {
        font-size: 19.5px !important;
        line-height: 28px !important;
        margin: 10px 0 0px !important;
        margin-top: 8px !important;
        padding: 0 !important;
        justify-content: center !important;
        display: flex !important;
        text-align: -webkit-center !important;
    }

    .clt-button-map-afficher-carte {
        height: 48px;
    }

    .aab {
        margin-top: 0px;
    }

    .clt-home-visite-h23546534 {
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: 11px;
        margin-right: 0px;
        margin-bottom: -28px;
        margin-left: 0px;
        padding-top: 6px;
    }

    .clt-home-visite-h2354653412313456 {
        font-size: 20px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: 11px;
        margin-right: 0px;
        margin-bottom: -4px;
        margin-left: 0px;
        padding-top: 6px;
        border-top: 0px solid #dedddd;
    }

    .clt-home-visite-h2354653422 {
        font-size: 20px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: -17px;
        margin-right: 0px;
        margin-bottom: -26px;
        margin-left: 0px;
        padding-top: 0px;
    }

    .clt-home-visite-h2-header2321354 {
        padding-bottom: 0px;
    }

    .my-carousel .react-multi-carousel-item {
        width: 295px !important;
        margin-top: 14px;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt-detail-slider-maindiv {
        padding: 0;
    }

    .ababab {
        margin-right: 20px !important;
    }

    .clt-gallery-container {
        background: #ffffff;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:hover, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:active {
        background: #28b07d;
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        border: 1px solid #29b07d;
        height: 50px;
        margin-top: 26px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt-detail-slider-main {
        border-radius: 0;
    }

    .clt-section-hr-pd {
        padding: 28px 22px;
    }

    .clt-section-hr-pd22 {
        padding-top: 28px;
        padding-right: 36px;
        padding-bottom: 4px;
        padding-left: 36px;
        opacity: 1;
    }

    .clt-section-hr-pd221 {
        padding-top: 2px;
        padding-right: 36px;
        padding-bottom: 0px;
        padding-left: 36px;
        opacity: 1;
        margin-top: 30px;
    }

    .clt-section-hr-pddddd {
        padding: 28px 0px;
    }

    .clt-detail-slider-main-imgdiv {
        height: 205px;
        border-radius: 0px;
    }

    .clt-detail-slider-main-imgdiv span {
        padding: 5px 12px;
        bottom: 20px;
        right: 20px;
        font-size: 13px;
        border-radius: 8px;
    }

    .clt-detail-slider-gallery {
        border-radius: 0px;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 365px;
        border-radius: 0px;
        margin-top: 50px;
        margin-bottom: 0px;
    }

    .clt-detail-slider-gallery-imgdiv span {
        padding: 5px 12px;
        bottom: 20px;
        right: 20px;
        font-size: 13px;
        border-radius: 8px;
    }

    .clt-detail-sections-div {
        margin-top: 25px;
    }

    .my-carouseldcskdjndslkcj .react-multi-carousel-item {
        width: 295px !important;
        margin-top: 14px;
    }
    .clt-detail-section-1 {
        margin-top: 16px;
        margin-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .clt-detail-section-1-subdiv img {
        width: 12px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 16px;
        font-weight: 400;
        margin-left: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-1 {
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 23px;
    }


    .clt-detail-left-section-subdiv-1 {
        margin-top: 12px;
        margin-bottom: -5px;
        flex-direction: column !important;
        display: flex;
    }

    .clt-detail-left-section-subdiv-1-div {
        margin-bottom: 18px;
        height: 50px;
        margin-right: 50px;
        display: -webkit-inline-box;
        flex-direction: column !important;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 40px;
        height: 40px;
        margin-right: 14px;
        border-radius: 8px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 24px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-txt {}

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-top: 8px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-detail-left-section-subdiv-2 {
        flex-wrap: wrap;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13.5px;
        letter-spacing: -0.1px;
        font-weight: 600;
        padding: 2.5px 12px;
        border-radius: 6px;
        margin-right: 10px;
        margin-top: 14px;
        margin-bottom: 0px
    }

    .clt-detail-left-section-2-col {
        padding: 0px;
    }

    .clt-detail-left-section-2-h2-mt {
        margin-top: 0px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-home-visite-h2{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 26px 0px 0px 0px;
    }

    .clt-home-visite-h2knkjbjkbh{
        font-size: 50px;
        color: #222;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        padding-bottom: 30px;
        margin: 26px 0px 0px 0px;
        font-family: Lora;
        line-height: 54px !important;
        text-align: -webkit-center;
    }

    .clt-home-visite-h2124{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 22px 0px 0px 0px;
    }

    .clt-home-visite-h22123 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
        margin: 36px 0px 0px 0px;
    }

    .clt-home-visite-h2174 {
        font-size: 32px;
        font-weight: 700;
        justify-content: start;
        display: flex;
        letter-spacing: -0.2px;
        line-height: 34px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
        margin: 36px 0px -6px 0px;
        padding-bottom: 2px;
    }

    .clt-detail-section-77 h2 {
        font-size: 32px !important;
        font-weight: 700 !important;
        letter-spacing: -0.2px !important;
        line-height: 34px !important ;
        margin: 0px 0px 2px 0px !important;
    }

    .clt-detail-left-section-2-cards {
        width: 283px;
        height: 283px;
        border-radius: 15px;
        margin-right: 15px;
    }

    .clt-home-visite-h2-cards {
        width: 284px;
        height: 322px;
        border-radius: 16px;
        margin-right: 15px;
    }

    .clt-detail-left-section-2-cards-main span {
        top: 13px;
        left: 13px;
        font-size: 13px;
        padding: 2px 10px;
    }

    .clt-detail-left-section-3 {
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 27px;
        padding-right: 24px;
        padding-bottom: 36px;
        padding-left: 24px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 22px;
    }

    .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-left-section-3 p {
        font-size: 16px;
    }

    .clt-detail-custom-modal-content-class .modal-header {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-left: 19px;
    }

    .clt-detail-footer-mb {
        margin-bottom: 20vh;
    }

    .clt-detail-footer-mb-col {
        padding: 0px 22px;
    }

    .clt-gallery-container-header {
        height: 17vh;
        border-bottom: 1px solid #dedddd;
    }

    .clt-gallery-container-footer {
        margin-top: 51px;
    }

    .clt-gallery-container-footer span {
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
    }

    .clt-gallery-container-header-col span {
        font-size: 16px;
        letter-spacing: -.2px;
        margin-bottom: 8px;
        margin-left: 11px;
    }

    .clt-gallery-container-header-col button, .clt-gallery-container-header-col button:hover {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 8px;
    }

    .clt-gallery-container-header-col {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 14px;
    }

    .clt-detail-footer-mbl {
        display: block;
    }

    .clt-detail-left-section-4-col {
        padding: 0;
    }

    .clt-detail-left-section-4-h2-mt {
        margin-top: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4-card-item {
        width: 242px;
        height: 270px;
        border-radius: 15px;
        margin-right: 16px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 242px !important;
        height: 270px !important;
        border-radius: 15px;
        margin-right: 16px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 100px;
        height: 100px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 103px;
        width: 45px;
        height: 45px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-bottom: 5px;
    }
    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: 0px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        color: #70757a;
        font-family: 'Inter';
        margin-bottom: 14px;
    }

    .clt_products_offcanvas {
        width: 100%;
    }

    .clt_products_offcanvas2 {
        width: 100% !important;
        z-index: 1055 !important;
    }

    .clt_products_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }

    .aclt_products_offcanvas_body {
        padding-right: 28px !important;
        padding-left: 28px !important;
    }



    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 6px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 21px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
        height: 50px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
        margin-bottom: 54px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 22px;
        letter-spacing: -0.2px;
        margin-bottom: 7px;
        margin-top: 33px;
        padding-top: 23px;
        border-top: 1px solid #dedddd;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 7px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        width: 100%;
        margin-bottom: 0px;
        height: 279px;
        border-radius: 14px;
    }

    .clt-detail-div .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
        font-weight: 400;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt_formButton, .clt_formButton:hover, .clt_formButton:focus {
        font-size: 16px;
        height: 52px;
        padding-top: 1px;
    }

    .clt-detail-right-head-sub-div span {
        font-weight: 700;
        font-size: 25px;
        line-height: 1.2;
        padding-right: 5px;
        margin-top: 8px;
        margin-bottom: 28px;
    }

    .clt-detail-right-head-sub-diva span {
        font-size: 25px;
        line-height: 1.2;
        padding-right: 5px;
        margin-top: 30px;
        margin-bottom: 8px;
    }

    .clt_inputFloatDetail.form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
    }

    .clt_inputFloatDetail input, .clt_inputFloatDetail input:active, .clt_inputFloatDetail input:focus {
        height: 64px !important;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 18px 0 !important;
        border-radius: 0px;
    }

    .clt_inputFloatDetail.form-floating>.form-control:focus~label, .clt_inputFloatDetail.form-floating>.form-control:not(:placeholder-shown)~label, .clt_inputFloatDetail.form-floating>.form-select~label {
        font-size: 15px;
        opacity: .7;
        padding: 1.02rem 1.01rem 0.95rem 1.09rem !important;
        -webkit-transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
        transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
    }

    .clt_inputFloatDetail textarea, .clt_inputFloatDetail textarea:active, .clt_inputFloatDetail textarea:focus {
        font-size: 16px;
        font-weight: 500;
        padding: 25px 18px 0 !important;
    }

    .clt_inputFloatDetailDiv .clt_input_icon_angle_down {
        right: 18px;
    }

    .clt-detail-custom-modal-content-class .modal-header {
        padding: 19px 24px;
    }

    .clt-custom-modal-title {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 2px;
        margin-left: 16px;
        margin-bottom: 3px;
    }

    .clt-custom-modal-header .btn-back, .clt-custom-modal-header .btn-back:focus {
        background-size: 8px;
        margin-bottom: 2px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt-detail-right-head-heading-input {
        font-size: 13px;
    }

    .aaaaaab4 {
        width: auto;
        height: 34px;
        margin-top: 8px;
        margin-left: 8px;
        align-items: center;
        justify-content: center;
        display: flex;
        align-content: center;
    }

    .clt-detail-left-section-3-cards {
        width: 214px !important;
        height: 144px !important;
        border-radius: 18px !important;
        margin-right: 15px !important;
    }

    .clt-detail-section-77 p {
        font-size: 19.5px !important;
        line-height: 28px !important;
        margin: 10px 0 0px !important;
        margin-top: 8px !important;
        padding: 0 !important;
        justify-content: center !important;
        display: flex !important;
        text-align: -webkit-center !important;
    }

    .clt-button-map-afficher-carte {
        height: 48px;
    }

    .aab {
        margin-top: 0px;
    }

    .clt-home-visite-h23546534 {
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: 11px;
        margin-right: 0px;
        margin-bottom: -28px;
        margin-left: 0px;
        padding-top: 6px;
    }

    .clt-home-visite-h2354653412313456 {
        font-size: 20px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: 11px;
        margin-right: 0px;
        margin-bottom: -4px;
        border-top: 0px solid #dedddd;
        margin-left: 0px;
        padding-top: 6px;
    }

    .clt-home-visite-h2354653422 {
        font-size: 20px;
        letter-spacing: 0px;
        font-weight: 500;
        font-family: 'Inter';
        line-height: 28px;
        color: #686868;
        margin-top: -17px;
        margin-right: 0px;
        margin-bottom: -26px;
        margin-left: 0px;
        padding-top: 0px;
    }

    .clt-home-visite-h2-header2321354 {
        padding-bottom: 0px;
    }

    .clt-detail-left-section-2 {
        padding-top: 19px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-button-text-duration2124{
        margin-top: 7px;
        font-size: 15px;
        letter-spacing: -0.2px;
        opacity: 70%;
    }


    .my-carousel .react-multi-carousel-item {
        width: 295px !important;
        margin-top: 14px;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) and (orientation:portrait) {
    .clt-detail-footer-mbl {
        display: block;
    }

}

@media only screen and (max-width: 992px) {
    .hideTablet {
        display: none;
    }
    
    .clt-detail-footer-mbl {
        display: block;
    }

    
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {

    .my-carousel .react-multi-carousel-item {
        width: 330px !important;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 295px !important;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 223px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 223px !important;
    }

    .clt_products_offcanvas2 {
        width: 65% !important;
        z-index: 1055 !important;
    } 
    #eapps-instagram-feed-1 {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-left: 1px;
        width: 708px !important;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
    #eapps-instagram-feed-1 {
        width: 665px !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }

    .clt-detail-section-77 p {
        font-size: 19.5px !important;
        line-height: 22px !important;
        margin: 10px 0 0px !important;
        margin-top: 17px !important;
        padding: 0 !important;
    }
    
    .dnedzkfesdndze {
        justify-content: center !important;
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        margin-bottom: 3px;
        margin-top: 40px;
        padding-top: 11px;
        padding-left: 0px;
    }

    
}

@media only screen and (max-width: 1200px) and (min-width: 1024px) {

    .clt-sidebar-forms.stickySideBar.is-sticky {
        width: 350px;
        top: 12.4%;
    }

    .clt-sidebar-forms.stickySideBar {
        top: 0;
        width: 350px;
    }

    .clt-detail-right-main {
        border: 1px solid #e5e5e5;
        max-height: 482px !important;
        border-radius: 15px;
        padding: 26px 30px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .clt-detail-right-main::-webkit-scrollbar {
        width: 0.3rem;
    }

    #eapps-instagram-feed-1 {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-left: 1px;
        width: 776px!important;
    }

    
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .clt-sidebar-forms.stickySideBar {
        top: 0;
        width: 400px;
    }

    .clt-sidebar-forms.stickySideBar.is-sticky {
        width: 400px;
        top: 10%;
        width: 380px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt-detail-slider-maindiv {
        padding: 0px 15px;
    }

    .clt-gallery-container-footer span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
    }


    .clt-detail-left-section-subdiv-1-div-img img {
        width: 22px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 38px;
        height: 38px;
    }

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
    }

    .clt-home-visite-h2{
        font-size: 36px;
    }

    .clt-home-visite-h2124{
        font-size: 36px;
        line-height: 42px;
    }

    .overlay-img {
        width: 60%;
        height: 40%;
        object-fit: cover;
        border-radius: 18px;
    }

    .clt-home-visite-h2-header {
        margin-bottom: 20px;
        margin-top: 8px;
        padding-top: 15px;
        border-top: 1px #dedddd solid;
        align-items: center;
    }

    .clt-home-visite-h22123{
        font-size: 27px;
    }

    .clt-home-visite-h2174{
        font-size: 36px;
    }

    .clt-detail-section-77 h2 {
        font-size: 36px !important;
    }

    .clt-detail-left-section-2-cards-main span {
        font-size: 13px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 20px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-left-section-2-cards-arrowBtn, .clt-detail-left-section-2-cards-arrowBtn:hover, .clt-detail-left-section-2-cards-arrowBtn:active, .clt-detail-left-section-2-cards-arrowBtn:focus, .clt-detail-left-section-2-cards-arrowBtn:active:focus {
        width: 36px;
        height: 36px;
        border-radius: 50px;
        overflow: hidden;
        background: #ddd;
        border: 1px solid #ddd;
        box-shadow: none;
        color: #242424;
        opacity: 1;
        margin-right: 0rem !important;
        margin-left: 10px;
    }

    .clt-detail-section-1 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32.5px;
        margin-bottom: 6px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 17px;
    }

    .clt-detail-section-1-subdiv img {
        width: 13px;
    }

    .clt-gallery-container-header {
        height: 5.4vh;
    }

    .clt-detail-slider-gallery {
        border-radius: 0;
    }


    .clt-detail-left-section-2-cards {
        width: 320px;
        height: 210px;
    }

    .clt-home-visite-h2-cards {
        width: 315px;
        height: 292px
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 400px;
        margin-top: 0px;
        margin-bottom: 0px;
    }


    .clt-detail-slider-main-imgdiv {
        height: 370px;
    }


    .clt-detail-left-section-4-card-item {
        width: 210px;
        height: 288px;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 210px !important;
        height: 268px !important;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 110px;
        height: 110px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 138px;
        width: 46px;
        height: 46px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt-gallery-container-header-col span {
        margin-left: 21px;
        font-size: 16px;
        margin-bottom: 4px;
        color: rgb(0, 0, 0);
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        padding-bottom: 2px;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body {
        padding: 0px;
    }

    .aclt_products_offcanvas_body {
        padding: 0px;
    }

    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        padding-bottom: 10px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        width: 100%;
        height: 360px;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:active, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_products_offcanvas {
        width: 425px;
    }

    .clt_products_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt-detail-slider-maindiv {
        padding: 0px 15px;
    }

    .clt-gallery-container-header-col button, .clt-gallery-container-header-col button:hover {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .clt_products_offcanvas2 {
        width: 65% !important;
    }

    .clt-gallery-container {
        background: #ffffff;
    }

    .clt-detail-slider-gallery {
        border-radius: 0px;
    }

    .clt-gallery-container-footer span {
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
    }

    .my-carousel3 .react-multi-carousel-item {
        width: 296px !important;
    }

    .clt-gallery-container-footer span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-1 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32.5px;
        margin-bottom: 6px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 22px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 38px;
        height: 38px;
    }

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
    }

    .clt-home-visite-h2{
        font-size: 36px;
    }

    .clt-home-visite-h2124{
        font-size: 36px;
        line-height: 48px;
        padding-top: 2px;
    }

    .clt-button-text2125 {
        margin-top: 5px;
        line-height: 35px;
        margin-bottom: 11px;
        letter-spacing: -0.2px;
        font-size: 22px;
    }

    .clt-home-visite-h22123{
        font-size: 27px;
    }
    .clt-home-visite-h2174{
        font-size: 36px;
    }

    .clt-detail-section-77 h2 {
        font-size: 36px !important;
    }

    .clt-detail-left-section-2-cards-main span {
        font-size: 13px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 20px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 17px;
    }

    .clt-detail-section-1-subdiv img {
        width: 13px;
    }

    .clt-detail-left-section-2-cards {
        width: 325px;
        height: 210px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .clt-home-visite-h2-cards {
        width: 314px;
    height: 294px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .my-carousel .react-multi-carousel-item {
        width: 335px !important;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 209px !important;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 224px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 224px !important;
    }

    .clt-button-text {
        font-size: 18px;
    }

    .clt-gallery-container-header {
        height: 17vh;
        border-bottom: 1px solid #dedddd;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 365px;
        border-radius: 0px;
        margin-top: 50px;
        margin-bottom: 0px;
    }

    .clt-detail-slider-main-imgdiv {
        height: 370px;
    }

    .clt-detail-left-section-4-card-item {
        width: 216px;
        height: 290px;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 216px !important;
        height: 270px !important;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 110px;
        height: 110px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 138px;
    width: 46px;
    height: 46px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-gallery-container-header-col span {
        margin-left: 21px;
        font-size: 16px;
        margin-bottom: 4px;
        color: #242424;
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .clt-gallery-container-header-col button:hover {
        background: #2d2d2d !important;
    }

    .clt-gallery-container-header-col button:active {
        background: #5c5c5c !important;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        padding-bottom: 2px;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body {
        padding: 0px;
    }

    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        padding-bottom: 10px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        width: 100%;
        height: 380px;
    }

    .overlay-img {
        width: 66%;
        height: 50%;
        object-fit: cover;
        border-radius: 18px;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:active, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_products_offcanvas {
        width: 400px;
    }

    .clt_products_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-detail-slider-maindiv {
        padding: 0px 15px;
    }

    .clt-gallery-container-footer span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-1 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32.5px;
        margin-bottom: 6px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 22px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 38px;
        height: 38px;
    }

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
    }

    .clt-home-visite-h2{
        font-size: 42px;
    }

    .clt-home-visite-h2124{
        font-size: 42px;
        line-height: 48px;
    }

    .clt-home-visite-h22123{
        font-size: 27px;
    }

    .clt-home-visite-h2174{
        font-size: 42px;
    }

    .clt-detail-section-77 h2 {
        font-size: 48px !important;
    }

    .clt-detail-left-section-2-cards-main span {
        font-size: 13px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 20px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 17px;
    }

    .clt-detail-section-1-subdiv img {
        width: 13px;
    }

    .clt-detail-left-section-2-cards {
        width: 315px;
        height: 210px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .clt-home-visite-h2-cards {
        width: 323px;
        height: 313px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    #eapps-instagram-feed-1 {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-left: 1px;
        width: 934px!important;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 234px !important;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 234px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 318px !important;
    }

    .clt-gallery-container-header {
        height: 5.4vh;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 400px;
        margin-top: 0px;
        margin-bottom: 0px;
    }


    .clt-detail-slider-main-imgdiv {
        height: 370px;
    }

    .clt-detail-left-section-4-card-item {
        width: 216px;
        height: 290px;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 216px !important;
        height: 270px !important; 
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 110px;
        height: 110px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 138px;
    width: 46px;
    height: 46px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-gallery-container-header-col span {
        margin-left: 21px;
        font-size: 16px;
        margin-bottom: 4px;
        color: rgb(0, 0, 0);
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        padding-bottom: 2px;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body {
        padding: 0px;
    }

    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        padding-bottom: 10px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        width: 100%;
        height: 410px;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:active, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_products_offcanvas {
        width: 425px;
    }

    .clt_products_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .my-carousel .react-multi-carousel-item {
        width: 340px !important;
    }

    
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-detail-slider-maindiv {
        padding: 0px 15px;
    }

    .clt_products_offcanvas2 {
        width: 70% !important;
    }

    .clt-gallery-container-footer span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }

    .my-carousel3 .react-multi-carousel-item {
        width: 278px !important;
    }

    .clt-detail-section-1 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .clt-homepage-container3 {
        max-width: 960px !important;
    }

    .aab {
        background: #ebebeb;
        max-width: 100%;
        border-radius: 20px;
        padding-left: 22px;
        max-width: 66% !important;
        margin-top: 31px;
    }
    
    .clt-detail-section-1 h2 {
        font-size: 32.5px;
        margin-bottom: 6px;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 238px !important;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 226px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 318px !important;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 22px;
        margin-bottom: 2px;
    }


    .clt-detail-left-section-subdiv-1-div-img {
        width: 38px;
        height: 38px;
    }

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
    }

    .clt-home-visite-h2{
        font-size: 42px;
    }

    .clt-section-6 h2 {
        font-size: 42px;
    }

    .clt-home-visite-h2124{
        font-size: 42px;
        line-height: 48px;
    }

    .slider-controls {
        margin-bottom: -152px;
        margin-right: 76px;
    }

    .overlay-img {
        width: 70%;
        height: 50%;
        object-fit: cover;
        border-radius: 18px;
    }

    .eapps-instagram-feed-1 {
        width: 939px !important;
    }

    .clt-home-visite-h22123{
        font-size: 27px;
    }
    .clt-home-visite-h2174{
        font-size: 42px;
    }

    .clt-detail-section-77 h2 {
        font-size: 42px !important;
    }
    .clt-detail-left-section-2-cards-main span {
        font-size: 13px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 20px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 17px;
    }

    .clt-detail-section-1-subdiv img {
        width: 13px;
    }

    .clt-detail-left-section-2-cards {
        width: 285px;
        height: 210px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .clt-home-visite-h2-cards {
        width: 342px;
        height: 333px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .clt-gallery-container-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #dedddd;
        height: 8.4vh;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 400px;
        margin-top: 0px;
        margin-bottom: 0px;
    }


    .clt-detail-slider-main-imgdiv {
        height: 370px;
    }

    .clt-detail-left-section-4-card-item {
        width: 200px;
        height: 290px;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 200px !important;
        height: 270px !important;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 110px;
        height: 110px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 138px;
    width: 46px;
    height: 46px;
    }
    .clt-detail-left-section-4-card-item-h2 {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-gallery-container-header-col span {
        margin-left: 21px;
        font-size: 16px;
        margin-bottom: 4px;
        color: rgb(0, 0, 0);
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        padding-bottom: 2px;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body {
        padding: 0px;
    }

    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        padding-bottom: 10px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        width: 100%;
        height: 384px;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:active, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_products_offcanvas {
        width: 400px;
    }

    .clt_products_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .my-carousel .react-multi-carousel-item {
        width: 357px !important;
    }

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-detail-slider-maindiv {
        padding: 0px 15px;
    }

    .clt-gallery-container-footer span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }

    .my-carousel .react-multi-carousel-item {
        width: 340px !important;
    }

    .clt-detail-section-1 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32.5px;
        margin-bottom: 6px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
    }


    .clt-detail-left-section-subdiv-1-div-img img {
        width: 22px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 38px;
        height: 38px;
    }

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
    }


    .clt-home-visite-h2{
        font-size: 48px;
    }

    .clt-home-visite-h2124{
        font-size: 48px;
        line-height: 52px;
    }

    .clt-home-visite-h22123{
        font-size: 27px;
    }
    .clt-home-visite-h2174{
        font-size: 48px;
    }

    .clt-detail-section-77 h2 {
        font-size: 48px !important;
    }

    .clt-detail-left-section-2-cards-main span {
        font-size: 13px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 20px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 17px;
    }

    .clt-detail-section-1-subdiv img {
        width: 13px;
    }

    .clt-detail-left-section-2-cards {
        width: 315px;
        height: 210px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 284px !important;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 284px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 318px !important;
    }

    .clt-home-visite-h2-cards {
        width: 315px;
        height: 210px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }
    .clt-gallery-container-header {
        height: 5.4vh;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 400px;
        margin-top: 0px;
        margin-bottom: 0px;
    }


    .clt-detail-slider-main-imgdiv {
        height: 370px;
    }

    .clt-detail-left-section-4-card-item {
        width: 216px;
        height: 290px;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 216px !important;
        height: 270px !important;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 110px;
        height: 110px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 138px;
        width: 46px;
        height: 46px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }
    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }


    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-gallery-container-header-col span {
        margin-left: 21px;
        font-size: 16px;
        margin-bottom: 4px;
        color: rgb(0, 0, 0);
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        padding-bottom: 2px;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body {
        padding: 0px;
    }

    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        padding-bottom: 10px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        width: 100%;
        height: 260px;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:active, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_products_offcanvas {
        width: 425px;
    }

    .clt_products_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-detail-slider-maindiv {
        padding: 0px 15px;
    }

    .aab {
        border-radius: 20px;
        padding-left: 22px;
        max-width: 66% !important;
        margin-top: 31px;
    }

    .clt_products_offcanvas2 {
        width: 70% !important;
    }

    .clt-gallery-container-footer span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }

    .my-carousel3 .react-multi-carousel-item {
        width: 260px !important;
    }

    .clt-detail-section-1 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .clt-detail-section-1 h2 {
        font-size: 32.5px;
        margin-bottom: 6px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 17px;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 223px !important;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 223px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 318px !important;
    }

    .clt-detail-left-section-1-heading {
        font-size: 22px;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 22px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 22px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 38px;
        height: 38px;
    }

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 22px;
    }

    .clt-home-visite-h2{
        font-size: 48px;
    }

    .clt-home-visite-h2124{
        font-size: 48px;
        line-height: 52px;
    }

    .clt-homepage-container3 {
        max-width: 82% !important;
    }

    .clt-home-visite-h22123{
        font-size: 27px;
    }
    .clt-home-visite-h2174{
        font-size: 48px;
    }

    .clt-detail-section-77 h2 {
        font-size: 48px !important;
    }

    .clt-detail-left-section-2-cards-main span {
        font-size: 13px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 20px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-section-1-subdiv img {
        width: 13px;
    }

    .clt-detail-left-section-2-cards {
        width: 315px;
        height: 210px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .clt-home-visite-h2-cards {
        width: 326px;
        height: 332px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 17px !important;
        cursor: default;
        -webkit-user-select: none;
        user-select: none;
    }

    .clt-home-visite-h2-header2321354 {
        padding-bottom: 10px !important;
    }

    .clt_homepage_section_event_image {
        width: 76px;
        height: 76px;
        object-fit: cover;
        background-color: #f6f6f6;
        border-radius: 12px;
    }

    .clt-gallery-container-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #dedddd;
        height: 8.4vh;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 400px;
        margin-top: 0px;
        margin-bottom: 0px;
    }


    .clt-detail-slider-main-imgdiv {
        height: 370px;
    }

    .clt-detail-left-section-4-card-item {
        width: 216px;
        height: 290px;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 216px !important;
        height: 270px !important;
        border-radius: 15px;
        margin-right: 10px;
        padding: 20px 20px 20px 20px;
    }

    .clt-detail-left-section-4-card-item-div {
        border-radius: 13px;
        width: 110px;
        height: 110px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 138px;
        width: 46px;
        height: 46px;
    }
    .clt-detail-left-section-4-card-item-h2 {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }



    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-secondary {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }
    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }


    .clt-detail-left-section-4-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 32px;
        letter-spacing: -0.2px;
    }

    .clt-detail-left-section-4 {
        padding-top: 23px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .clt-detail-left-sub-section-div-main {
        margin-bottom: 38px;
    }

    .clt-detail-left-section-subdiv-1 .clt-detail-left-sub-section-div-main:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div-p p {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .clt-gallery-container-header-col span {
        margin-left: 21px;
        font-size: 16px;
        margin-bottom: 4px;
        color: rgb(0, 0, 0);
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 22px;
    }

    .clt-detail-left-sub-section-div-main .clt-detail-left-sub-section-div:last-child {
        margin-bottom: 0px;
    }

    .clt-detail-left-sub-section-div img {
        width: 22px;
        margin-right: 15px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 16px;
    }

    .clt_products_offcanvas_header .btn-back {
        background: none;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        padding-bottom: 2px;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body {
        padding: 0px;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 291px !important;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 227px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 318px !important;
    }

    .clt_products_offcanvas_body_mainrow {
        margin-bottom: 40px;
        margin: 0 0px;
    }

    .clt_products_offcanvas_body_maindiv {
        margin-bottom: 14px;
    }

    .clt_products_offcanvas_body_secdiv {
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_category {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: -0.2px;
    }

    .clt_products_offcanvas_body_product {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .clt_products_offcanvas_body_secondary {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_products_offcanvas_body_desc {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_products_offcanvas_body_img {
        width: 100%;
        height: 100px;
    }

    .clt_product_detail_offcanvas_body .image_div {
        width: 100%;
        height: 205px;
    }

    .clt_product_detail_offcanvas_body .header_text_div {
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 18px;
    }

    .clt_product_detail_offcanvas_body .header_text_div h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .header_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div, .clt_product_detail_offcanvas_hr_pd {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 16px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div:last-child {
        padding-bottom: 25px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .clt_product_detail_offcanvas_body .sub_text_div p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-header p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .clt-detail-section-6-body {
        margin-top: 16px !important;
        border-radius: 15px;
    }

    .clt-detail-section-6-body-col {
        padding: 20px 22px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:hover, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:active {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        padding-bottom: 10px;
    }

    .clt-detail-section-6-offcanvas-row {
        margin-top: 0Px;
        margin-left: 25px;
        margin-right: 25px;
    }

    .clt-detail-section-6-offcanvas-row-2 {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-cat-h2.margin-top {
        margin-top: 45px;
    }

    .clt-detail-section-6-offcanvas-data-row {
        margin-top: 20px !important;
        margin-bottom: 54px !important;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }
    .clt-detail-section-7142 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-7 p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .clt-detail-section-7-img-row {
        margin-top: 15px !important;
    }

    .clt-detail-section-7-img-div {
        width: 100%;
        height: 434px;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 22px;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:active, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 16px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_products_offcanvas {
        width: 400px;
    }

    .clt_products_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt-detail-right-main {
        border: 1px solid #e5e5e5;
        max-height: 565px !important;
        border-radius: 15px;
        padding: 26px 30px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .clt-detail-right-main::-webkit-scrollbar {
        width: 0.3rem;
    }

    .my-carousel .react-multi-carousel-item {
        width: 345px !important;
    }
    .clt-homepage-container3 {
        max-width: 1020px !important;
    }
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_products_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-detail-section-77 p {
        font-size: 25px !important;
        line-height: 28px !important;
        margin: 20px 0 0px !important;
        padding: 0 !important;
    }

    .clt-home-visite-h2-header2321354 {
        align-items: center;
    }

    .clt-detail-slider-gallery-imgdiv {
        height: 670px;
    }

    .clt-gallery-container-footer span {
        font-size: 16px;
    }

    .aab {
        background: #ebebeb;
        max-width: 100%;
        border-radius: 20px;
        padding-left: 22px;
        max-width: 66% !important;
        margin-top: 31px;
    }

    .clt_detail_reserve_form_box_container {
        display: flex;
        padding-top: 20px;
        padding-right: 22px;
        padding-bottom: 20px;
        padding-left: 18px;
        justify-content: center;
        flex-direction: row;
        border-radius: 15px;
        border: 1px solid #dedddd;
        gap: 15px;
    }

    .my-carousel3 .react-multi-carousel-item {
        width: 260px !important;
    }

    .clt-gallery-container-header {
        height: 6.2vh;
    }

    .clt-datepicker-sidebar-info span {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 15px;
    }

    .my-carousel2 .react-multi-carousel-item {
        width: 300px !important ;
    }

    .my-carousel174 .react-multi-carousel-item {
        width: 268px !important;
    }

    .my-carousel175343544 .react-multi-carousel-item {
        width: 235px !important;
    }

    .clt-datepicker-sidebar-info i {
        font-size: 20px;
    }

    .clt-detail-section-1 h2 {
        font-size: 52.5px;
        margin-bottom: 6px;
    }

    .clt-detail-section-1-subdiv span {
        font-size: 16px;
        margin-left: 10px;
        letter-spacing: -.2px;
    }

    .clt-detail-slider-gallery .react-multiple-carousel__arrow {
        min-width: 40px !important;
        min-height: 40px !important;
    }

    .clt-datepicker-sidebar-info {
        padding-top: 22px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: 15px;
        margin-top: 20px;
    }

    .clt-detail-section-1-subdiv img {
        width: 12px;
    }

    .clt-detail-slider-main-imgdiv span {
        bottom: 20px;
        right: 23px;
        font-size: 13px;
    }

    .clt-detail-left-section-1-heading {
        font-size: 25px;
    }

    .clt-detail-left-section-1-headinga {
        font-size: 20px;
    }

    .clt-detail-left-section-subdiv-1-div-txt h3 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-2-h2 {
        font-size: 25px;
    }

    .clt-home-visite-h2{
        font-size: 48px;
    }

    .clt-home-visite-h2124{
        font-size: 48px;
        line-height: 52px;
    }

    .clt-home-visite-h22123{
        font-size: 28px;
    }
    .clt-home-visite-h2174{
        font-size: 48px;
    }

    .clt-detail-section-77 h2 {
        font-size: 48px !important;
    }
    .clt-detail-left-section-2-cards-main span {
        font-size: 13px;
    }

    .clt-detail-left-section-3-subdiv img {
        width: 20px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 16px;
    }

    .clt-detail-left-section-subdiv-1-div-txt p {
        font-size: 15px;
    }

    .clt-detail-left-section-subdiv-1-div-img {
        width: 37px;
        height: 37px;
    }

    .clt-detail-left-section-subdiv-1-div-img img {
        width: 22px;
        margin-bottom: 2px;
    }

    .clt-detail-left-section-subdiv-2 span {
        font-size: 13px;
    }

    .clt-detail-left-section-3 p, .clt-detail-left-section-3-subdiv h2 {
        font-size: 15px;
    }

    .clt-detail-left-section-4-card-item {
        width: 256px;
        height: 286px;
        border-bottom: 2px solid #dedddd !important;
    }

    .clt-detail-left-section-4-card-item174 {
        width: 256px !important;
        height: 266px !important;
        border-bottom: 2px solid #dedddd !important;
    }

    .clt-detail-left-section-4-card-item-div {
        width: 110px;
        height: 110px;
    }

    .clt-detail-left-section-4-card-item-div174 {
        border-radius: 138px;
        width: 46px;
        height: 46px;
    }

    .clt-detail-left-section-4-card-item-h2 {
        font-size: 18px;
    }

    .clt-detail-left-section-4-card-item-h2KCDJNLKDJ {
        font-size: 16px;
    }

    .clt-detail-left-section-4-card-item-h2-sub {
        font-size: 18px;
    }

    .clt-detail-left-section-4-card-item-h2-subfjknlekv {
        font-size: 16px;
    }

    .clt-detail-left-section-4-card-item-h25345453 {
        font-size: 16px;
    }
    .clt-detail-left-section-4-card-item-secondary {
        font-size: 18px;
    }

    .clt-detail-left-section-4-card-item-desc {
        font-size: 18px;
    }
    .clt-detail-left-section-4-card-item-desc31351 {
        font-size: 16px;
    }
    .clt-homepage-container3 {
        max-width: 66% !important;
    }
    .clt-detail-left-sub-section-div-p p {
        font-size: 14px;
    }

    .clt-detail-left-sub-section-div h3 {
        font-size: 15px;
    }

    .clt-detail-left-sub-section-div {
        margin-bottom: 26px;
    }

    .clt-detail-section-6-header h2 {
        font-size: 25px;
        font-weight: 600;
    }

    .clt-detail-section-6-header p {
        font-size: 19px;
    }

    .clt-detail-section-6-body h2 {
        font-size: 15px;
    }

    .clt-detail-section-6-body p {
        font-size: 15px;
    }

    .clt-detail-section-6-body button, .clt-detail-section-6-body button:active, .clt-detail-section-6-body button:focus, .clt-detail-section-6-body button:hover {
        font-size: 15px;
    }

    .clt-detail-section-7 h2 {
        font-size: 25px;
    }
    .clt-detail-section-7142 {
        font-size: 25px;
    }

    .clt-detail-section-7 p {
        font-size: 19px;
    }

    .clt_formButton, .clt_formButton:focus, .clt_formButton:hover {
        font-size: 15px;
    }

    .clt-detail-right-toggle-tab {
        font-size: 13px;
    }

    .clt-detail-right-head-sub-div span {
        font-size: 26.5px;
    }

    .clt-detail-right-head-sub-div {
        font-size: 15px;
    }

    .clt-detail-right-head-sub-diva span {
        font-size: 23.5px;
    }

    .clt-detail-right-head-sub-diva {
        font-size: 15px;
    }
    

    .clt-detail-right-head-heading {
        font-size: 13px;
        margin-top: 19px;
    }

    .clt_products_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt-datepicker-sidebar-h2 {
        font-size: 20px;
    }

    .clt-datepicker-sidebar-h3 {
        font-size: 20px;
        margin-top: 4px;
        margin-bottom: 0px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 15px;
    }

    .clt-datepicker-sidebar-footer-btn, .clt-datepicker-sidebar-footer-btn:active, .clt-datepicker-sidebar-footer-btn:focus, .clt-datepicker-sidebar-footer-btn:hover {
        font-size: 15px;
    }

    .clt-booking-cb-div2-text h2, .clt-booking-cb-div2-text p {
        font-size: 15px;
    }

    .clt_products_offcanvas {
        width: 400px;
        z-index: 1055;
    }

    .clt_inputFloatDetail input, .clt_inputFloatDetail input:active, .clt_inputFloatDetail input:focus {
        height: 59px !important;
        font-size: 15px;
        font-weight: 500;
        color: #242424;
        padding-top: 12px !important;
        padding-right: 17px !important;
        padding-bottom: 0px !important;
        padding-left: 17px !important;
        border-radius: 0px;
    }

    .clt_inputFloatDetail.form-floating>.form-control:focus~label, .clt_inputFloatDetail.form-floating>.form-control:not(:placeholder-shown)~label, .clt_inputFloatDetail.form-floating>.form-select~label {
        opacity: .65;
        transform: scale(.8) translateY(-0.38rem) translateX(0.2rem);
        padding: 0.5rem 1.1rem;
        margin-top: 1.1px;
        font-size: 15px;
    }


    

    .clt_inputFloatDetail textarea, .clt_inputFloatDetail textarea:active, .clt_inputFloatDetail textarea:focus {
        font-size: 15px;
        font-weight: 500;
        color: #242424;
        padding-left: 17.6px !important;
        padding-right: 18px !important;
        padding-top: 27px !important;
        border-radius: 0;
        border: 1px solid #dedddd;
        outline: none;
        box-shadow: none;
    }

    .clt-detail-div .form-floating>label {
        padding-left: 18px !important;
        padding-top: 15px !important;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
    }

    .clt_products_offcanvas_header .btn-back img {
        width: 7px;
    }

    .clt-detail-section-6-offcanvas-data-row-col h2 {
        font-size: 15px;
        letter-spacing: -0.2px;
        margin-bottom: 0;
    }

    .clt-detail-section-6-offcanvas-data-row-col p {
        font-size: 15px;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 22px;
        letter-spacing: -0.2px;
    }

    .clt-detail-section-6-offcanvas-data-row-col {
        padding: 15px 20px;
    }

    .clt-detail-section-6-offcanvas-cat-h2 {
        font-size: 22px;
        letter-spacing: -0.2px;
    }

    .clt-gallery-container-header-col {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 19px;
    }

    .my-carousel .react-multi-carousel-item {
        width: 340px !important;
    }

    .clt-gallery-container-header-col span {
        font-size: 15px;
        letter-spacing: -0.2px;
        margin-bottom: 4px;
        margin-left: 18px;
    }

    .clt-gallery-container-header-col button, .clt-gallery-container-header-col button:hover {
        margin-bottom: 8px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 15px;
    }

    .clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h4 {
        font-size: 15px;
    }

    .clt-sidebar-forms.stickySideBar {
        width: 450px;
    }
    
    .clt-sidebar-forms.stickySideBar.is-sticky {
        width: 450px;
    }

    .clt-detail-right-main {
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        padding: 28px 30px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 14px;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 658px !important;
    }

    


}