.clt-admin-booking-section-1-div {
    height: 420px;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 52px;
    border-radius: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
    background: #000000b5;
}

.clt-admin-booking-section-1-div video {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
}

.clt-admin-booking-section-1-div-inner {
    z-index: 1;
    position: relative;
    margin-top: 55px;
}

.clt-admin-booking-section-1-h2 {
    font-size: 36px;
    font-weight: 600;
    font-family: 'inter';
    color: #fff;
    margin-bottom: 24px;
    margin-right: 817px;
}

.clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:focus {
    background: #fff;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    height: 30px;
    position: relative;
    width: 7.8%;
    padding-top: 13px;
    padding-right: 28px;
    padding-bottom: 15px;
    padding-left: 28px;
    letter-spacing: 0.1px;
}

.clt-admin-booking-section-1-btn:hover {
    background: #eee !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}

.clt-admin-booking-section-1-btn:active {
    background: #bdbdbd !important;
    color: #242424 !important;
    border: 1px solid #bdbdbd !important;
}


.clt-search {
    border-top: 1px solid #dedddd;
    padding: 18px 0px;
    margin: 0px;
}

.clt-search-div {
    position: relative;
}

.clt-search-btn, .clt-search-btn:focus {
    height: 52px;
    border-radius: 45px;
    background-color: #eee !important;
    padding-left: 46px;
    padding-bottom: 9px;
    color: #242424 !important;
    outline: none;
    border: none;
    box-shadow: none;
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.1px;
    opacity: 1 !important;
}

.clt-search-btn:hover {
    background: #d9dce0;
    color: #242424;
}

.clt-search-btn:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}



.clt-search-icon-div {
    position: absolute;
    bottom: 12px;
    left: 14px;
}

.clt-search-icon {
    width: 14.5px;
    height: 14.5px;
    margin-bottom: 8px;
    margin-left: 5px;
}

.clt-admin-booking-section-2-row {
    border-bottom: 1px solid #dedddd;
    border-top: 1px solid #dedddd;
    padding-top: 0px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.clt-admin-booking-section-2-row::-webkit-scrollbar {
    display: none;
}

.clt-admin-booking-section-2-row {
    -ms-overflow-style: none;
}

.clt-admin-booking-section-2-row-container {
    padding-left: 0px !important;
    padding-right: 14px !important;
}

.clt-admin-booking-section-2-row-col {
    padding-left: 14px !important;
    padding-right: 14px !important;
}

.clt-admin-booking-section-2-row.stickyBar.row.is-sticky {
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 2;
    box-shadow: 0 2px 16px rgba(0, 0, 0, .12) !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    animation: fadeIn 1.5s;
}

.clt-admin-booking-section-2-row.stickyBar.row {
    position: unset;
    width: unset;
    z-index: unset;
    background: #fff;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0.5;
        background: #fff;
    }

    50% {
        opacity: 0.8;
        background: #fff;
    }

    100% {
        opacity: 1;
        background: #fff;
    }
}

.clt-admin-booking-section-2 div:last-child {
    margin-right: 0px;
    padding-right: 12px;
}

.clt-admin-booking-section-2-divs {
    margin-right: 22px;
    cursor: pointer;
    user-select: none;
    background: #fff;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 7px;
}

.clt-admin-booking-section-2-divs:hover {
    background: #f6f6f6 !important;
}

.clt-admin-booking-section-2-divs:active {
    background: #eee !important;
}

.clt-admin-booking-section-2-divs img {
    width: 16px;
}

.clt-admin-booking-section-2-divs h2 {
    font-size: 13.5px;
    font-weight: 600;
    font-family: 'Inter';
    margin-bottom: 0px;
    padding-bottom: 9px;
    margin-top: 6px;
    color: #70757a;
    line-height: 16px;
}

.clt-admin-booking-section-3 {
    border-bottom: 1px solid #e0e0e0;
    padding-top: 24px;
    padding-right: 0px;
    padding-bottom: 24px;
    padding-left: 0px;
}

.clt-admin-booking-section-booking-list {
    /* padding-top: 23px; */
    padding-right: 0px;
    padding-bottom: 49px;
    padding-left: 0px;
}

.clt-admin-booking-section-3-row {
    /* padding-top: 20px; */
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.clt-admin-booking-section-3-row::-webkit-scrollbar {
    display: none;
}

.clt-admin-booking-section-3-row {
    -ms-overflow-style: none;
}

.clt-admin-booking-section-3-row-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.clt-admin-booking-section-3-row-col {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.clt-admin-booking-section-3 div:last-child {
    /* margin-right: 0px;
    padding-right: 15px; */
}

.clt-admin-booking-section-3-cards-div div:last-child {
    margin-right: 0px;
}

.clt-admin-booking-section-3-h2-mt {
    margin-top: 40px;
}

.clt-admin-booking-section-3-h2 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Inter';
    margin-bottom: 0px;
    color: #242424;
    line-height: 32px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-section-h2-subtitle-2 {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter';
    margin-bottom: 10px;
    color: #242424;
    line-height: 32px;
    letter-spacing: -0.2px;
}



.clt-admin-booking-section-31-h2 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: -8px;
    margin-top: 6px;
    line-height: 32px;
    letter-spacing: -0.2px;
    padding-bottom: 16px;
    text-decoration: underline;
}
.clt-admin-booking-section-h2-subtitle {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: -8px;
    margin-top: 6px;
    line-height: 32px;
    letter-spacing: -0.2px;
    padding-bottom: 16px;
    text-decoration: underline ;
}

.clt-admin-booking-section-3-cards-arrowBtn, .clt-admin-booking-section-3-cards-arrowBtn:focus, .clt-admin-booking-section-3-cards-arrowBtn:active:focus {
    width: 38px;
    height: 38px;
    border-radius: 50pc;
    overflow: hidden;
    background: #eee;
    border: 1px solid #eee;
    box-shadow: none;
    color: #242424;
    opacity: 1;
}

.clt-admin-booking-section-3-cards-arrowBtn:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #eee;
}

.clt-admin-booking-section-3-cards-arrowBtn:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #d2d2d3;
}

.clt-admin-booking-section-3-cards-arrowBtn.disable, .clt-admin-booking-section-3-cards-arrowBtn.disable:focus {
    opacity: 0.35;
    user-select: none;
    cursor: default;
}

.clt-admin-booking-section-cards {
    /* width: 300px; */
    /* height: 300px; */
    width: 100%;
    height: 100%;
    min-height: 166px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
}

.clt-admin-booking-section-cards:hover {
    background: #f6f6f6;
}

.clt-admin-booking-section-cards:active {
    background: #eee;
}

.clt-admin-booking-section-3-cards {
    /* width: 300px; */
    /* height: 300px; */
    width: 100%;
    height: 100%;
    min-height: AUTO;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
}

.clt-admin-booking-section-3-cards:hover {
    background: #f6f6f6;
}

.clt-admin-booking-section-3-cards:active {
    background: #eee;
}

.clt-admin-booking-section-3-cards-main {
    background: #24775b;
    background-repeat: no-repeat;
    background-position: right 10px bottom;
    background-size: 30%;
    background-image: url(../../../../../assets/img/icon-card-1.png);
}

.clt-admin-booking-section-3-cards-main h2 {
    font-size: 28px;
    letter-spacing: -.2px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 0;
    margin-top: 47px;
    padding: 0px 20px 20px;
    color: #fff;
    width: 80%;
    line-height: 32px;
}

.clt-admin-booking-section-3-cards-secondary {
    background: #fff;
    border: 1px solid #e0e0e0;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 6px;
    padding-left: 24px;

    border-bottom: 2px solid #e0e0e0 !important;
}

.clt-admin-slide-document-require-section-3-cards-secondary {
    background: #fff;
    border: 1px solid #e0e0e0;
    padding-top: 18px;
    padding-right: 22px;
    padding-bottom: 0px;
    padding-left: 20px;
    border-bottom: 2px solid #e0e0e0 !important;
}

.clt-admin-booking-section-3-cards-secondary h2 {
    font-size: 34px;
    font-weight: 500;
    font-family: Inter;
    margin-bottom: 30px;
    color: #242424;
    line-height: 24px;
}

.clt-admin-booking-section-3-cards-secondary span {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: -.2px;
    font-family: Inter;
    color: #242424;
}

.clt-admin-booking-section-3-cards-secondary span {
    font-weight: 500;
    font-size: 12px !important;
    letter-spacing: -.2px;
    font-family: Inter;
    color: #ee2626 !important;
}

.clt-admin-booking-section-3-cards-secondary p {
    font-weight: 400;
    font-size: 14px;
    font-family: Inter;
    padding-right: 46px;
    color: #535455;
    margin-bottom: 0;
    line-height: 20px;
    margin-top: 0px;
}

.clt-admin-slide-document-require-section-3-cards-secondary h2 {
    font-size: 34px;
    font-weight: 500;
    font-family: Inter;
    margin-bottom: 30px;
    color: #242424;
    line-height: 24px;
}

.clt-admin-slide-document-require-section-3-cards-secondary span {
    font-weight: 500;
    font-size: 12px !important;
    letter-spacing: -.2px;
    font-family: Inter;
    overflow: hidden;
    white-space: nowrap;
    overflow-wrap: break-word;
}

.clt-admin-slide-document-require-section-3-cards-secondary p {
    font-weight: 400;
    font-size: 14px;
    font-family: Inter;
    padding-right: 46px;
    color: #535455;
    margin-bottom: 0;
    line-height: 20px;
    margin-top: 0px;
}

.clt-booking-request-card {
    background: #fff;
    border: 1px solid #e0e0e0;
    padding-top: 18px;
    padding-right: 24px;
    padding-bottom: 22px;
    padding-left: 24px;
    border-bottom: 2px solid #e0e0e0 !important;
}

.clt-booking-request-card h2 {
    font-size: 34px;
    font-weight: 500;
    font-family: Inter;
    margin-bottom: -5px !important;
    color: #242424;
    line-height: 24px;
}

.clt-booking-request-card span {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: -.2px;
    font-family: Inter;
    color: #242424;
}

.clt-booking-request-card p {
    font-weight: 400;
    font-size: 14px;
    font-family: Inter;
    padding-right: 46px;
    color: #535455;
    margin-bottom: 0;
    line-height: 19px;
    margin-top: 0px;
}

.clt-admin-booking-section-3-cards-end {
    background: #24775b;
    padding: 30px 20px;
}

.clt-admin-booking-section-3-cards-end h2 {
    font-size: 27px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 30px;
    margin-top: 10px;
    color: #fff;
    line-height: 32px;
}

.clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: 'Inter';
    font-size: 13.5px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    position: relative;
    padding-top: 0px;
    padding-right: 14px;
    padding-bottom: 2px;
    padding-left: 14px;
    border: 0px solid #f6f6f6;
    height: 35px;
}

.clt-admin-booking-section-3-cards-button:hover {
    background: #d9d9d9 !important;
    border: 0px solid #fff !important;
    color: #242424 !important;
}

.clt-admin-booking-section-3-cards-button:active {
    background: #bbbbbb !important;
    border: 0px solid #fff !important;
    color: #242424 !important;
}

.clt-admin-booking-section-3-cards-button i {
    font-family: fontawesome;
    margin-left: 5px;
}

.clt-inbox-list-confirm-edit-request-button {
    background: none;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: 'Inter';
    font-size: 11.6px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    position: relative;
    padding: 0.4px 9px;
    border: 1px solid #dedddd;
    height: 25px;
    margin-top: 5px;
    margin-bottom: 2px;
    padding-bottom: 3px;
}

.clt-inbox-list-confirm-edit-request-button:focus {
    background: none !important;
    border: 1px solid #dedddd !important;
    color: #242424 !important;
}

.clt-inbox-list-confirm-edit-request-button:hover {
    background: #eee !important;
    border: 1px solid #dedddd !important;
    color: #242424 !important;
}

.clt-inbox-list-confirm-edit-request-button:active {
    background: #dedddd !important;
    border: 1px solid #dedddd !important;
    color: #242424 !important;
}



.clt-admin-booking-section-4 {
    position: relative;
    background-color: #000;
    border-radius: 20px;
    overflow: hidden;
    margin: 40px 0px;
    padding-top: 70px;
    padding-right: 40px;
    padding-bottom: 70px;
    padding-left: 40px;
}

.clt-admin-booking-section-4-img-desktop {
    width: 100%;
}

.clt-admin-booking-section-4-img-mbl {
    display: none;
    width: 100%;
}

.clt-admin-booking-section-4 h2 {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    font-size: 32px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'Mulish';
    margin-bottom: 0;
    width: 25%;
    text-align: center;
    line-height: 40px;
    color: #fff;
}

.clt-admin-booking-section-5 {
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 46px;
    padding-left: 0px;
    border-bottom: 1px solid #e0e0e0;
}

.clt-admin-booking-section-5-h2 {
    font-size: 26px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: 'MULISH';
    line-height: 28px;
    color: #242424;
    margin-top: 18px;
    margin-right: 0px;
    margin-bottom: 34px;
    margin-left: 0px;
}

.clt-admin-booking-section-5-accordion-header button {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter';
    background: #fff !important;
    padding: 26px 26px;
}

.clt-admin-booking-section-5-accordion-header button:active {
    background: #eee !important;
}

.clt-admin-booking-section-5-accordion-header button:hover {
    background: #fbfafa !important;
}

.clt-admin-booking-section-5-accordion-header button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    color: #242424;
}

.clt-admin-booking-section-5-accordion-item:first-of-type {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    overflow: hidden;
}

.clt-admin-booking-section-5-accordion-item:last-of-type {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    overflow: hidden;
}

.clt-admin-booking-section-5-accordion-body {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 400;
    font-family: 'inter';
    padding: 0 26px 26px;
}

.clt-admin-booking-section-5-accordion-header button:not(.collapsed) {
    color: #242424 !important;
    box-shadow: none !important;
}

.clt-admin-booking-section-5-accordion-body p {
    margin-bottom: 25px;
}

.clt-admin-booking-section-5-accordion-body a {
    color: #242424;
}

.clt-admin-booking-section-5-accordion-body a:hover {
    color: #828282;
}

.clt-admin-booking-section-5-accordion-body a:active {
    color: #b9b9b9;
}

.clt-admin-booking-section-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    background-color: #24775b;
    border-radius: 20px;
    overflow: hidden;
    margin: 40px 0px;
    padding: 0px 20px;
}

.clt-admin-booking-section-6 h2 {
    margin-top: -9px;
    margin-bottom: 4px;
    font-size: 32px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: Mulish;
    color: #fff;
    text-align: center;
}

.clt-admin-booking-section-6 img {
    width: 10%;
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 39px;
    margin-left: 0px;
}

.clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:focus {
    background: #fff;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    width: 18%;
    height: 46px;
    letter-spacing: 0.1px;
    padding: 10px 28px 15px;
}

.clt-admin-booking-section-6-btn:hover {
    background: #eee;
    border: 1px solid #eee !important;
    color: #242424 !important;
}

.clt-admin-booking-section-6-btn:active {
    background: #ddd !important;
    border: 1px solid #ddd !important;
    color: #242424 !important;
}

.clt-admin-booking-section-7 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 40px 0px;
}

.clt-admin-booking-section-7 h2 {
    font-size: 23px;
    letter-spacing: -.2px;
    font-weight: 700;
    font-family: Mulish;
    color: #242424;
    margin-bottom: 0;
}

.clt-admin-booking-section-7 p {
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    font-size: 15px;
    font-weight: 400;
    font-family: Inter;
    color: #242424;
}

.clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:hover, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:active {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: 600;
    height: 46px;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    padding-top: 10px;
    padding-right: 28px;
    padding-bottom: 15px;
    padding-left: 28px;
}

.clt-admin-booking-section-7-btn:hover {
    background: #d9dce0;
    color: #242424 !important;
    border: 1px solid #d9dce0 !important;
}

.clt-admin-booking-section-7-btn:active {
    background: #c0c0c0 !important;
    color: #242424 !important;
    border: 1px solid #c0c0c0 !important;
}

.clt-admin-booking-section-7-btn i {
    font-family: fontawesome;
    margin-left: 10px;
}

.clt-admin-booking-section-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #000;
    border-radius: 20px;
    margin: 40px 0px 100px 0px;
    padding: 30px 28px;
}

.clt-admin-booking-section-8 a {
    font-size: 15px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 12px;
    letter-spacing: -0.2px;
    font-family: Mulish;
    text-decoration: none;
}

.clt-admin-booking-section-8 a:hover {
    color: #b5b5b5 !important;
}

.clt-admin-booking-section-8 a:active {
    color: #656565 !important;
}

.clt-admin-booking-section-8 span {
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 12px;
    letter-spacing: -0.2px;
    font-family: Mulish;
    text-decoration: none;
    cursor: pointer;
}

.clt-admin-booking-section-8 span:hover {
    color: #b5b5b5;
}

.clt-admin-booking-section-8 p {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 200;
    font-family: Mulish;
    color: #fff;
    margin: 0px;
}

.showMobile {
    display: none !important;
}

.clt-hr-2 {
    border-top: 5px solid #878787;
}

.clt-hr-3 {
    border-top: 1px solid #878787;
    margin-left: 24px !important;
    margin-right: 24px !important;
    margin-top: 32px !important;
    margin-bottom: 9px !important;
}

.clt-hr-mbl {
    display: none !important;
}

.clt-hr-mbl hr, .clt-hr {
    color: #726c6c;
    height: 1px;
    margin: 0;
}

.back-button .back-button-img {
    width: 7px !important;
    height: 12px !important;
}

.carouselItem {
    padding-right: 26px;
    width: 330px !important;
}



.clt-admin-dashboard-booking-edit-request .react-multi-carousel-list {
    /* max-width: 900px; */
}


.user-edit-request-list
.clt_admin_dash_booking_avatar_container_request {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: white;
    width: 18px;
    font-size: 12px;
    height: 18px;
    border-radius: 50%;
    margin-top: 6px;
    font-weight: 500;
}

.clt_admin_dash_booking_avatar_container_booking {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: white;
    width: 24px;
    font-size: 12px;
    height: 25px;
    margin-right: 5px;
    border-radius: 50%;
    margin-top: 2px;
    font-weight: 500;
    
}

.clt_admin_dash_booking_avatar_container_booking_img img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    font-size: 12px;
    height: 25px;
    margin-right: 5px;
    border-radius: 50%;
    margin-top: 2px;
    font-weight: 500;
}

.clt_admin_dash_booking_avatar_container_request2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: white;
    width: 30px;
    font-size: 16px;
    margin-right: 19px;
    height: 30px;
    border-radius: 50%;
    margin-top: 0px;
    font-weight: 500;
}

.clt_admin_dash_booking_container_box_name_container_request {
    font-size: 14px;
    margin-left: 11px;
    letter-spacing: -0.2px;
    line-height: 20px;
    margin-top: 4px;
    font-weight: 500;
}

.clt-admin-booking-section-1-div li.react-multi-carousel-item {
    width: 100px !important;
}

.clt-admin-booking-section-3 .react-multi-carousel-item {
    width: 347px !important;
}

.clt-admin-booking-section-booking-list .react-multi-carousel-item {
    width: 300px !important;
}


@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-navbar-row-col-pd-left {
        padding-left: 24px !important;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 10.5px;
        margin-left: 5px;
    }

    .clt-navbar-row-col-pd-right {
        padding-right: 22px !important;
    }

    .clt-admin-booking-section-row-col-pd {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }


    .clt-search-btn, .clt-search-btn:hover, .clt-search-btn:focus, .clt-search-btn:active {
        height: 57px;
        border-radius: 45px;
        background-color: #eee;
        padding-left: 46px;
        padding-bottom: 10px;
        color: #242424;
        outline: none;
        border: none;
        box-shadow: none;
        font-weight: 600;
        width: 100%;
        text-align: left;
        font-size: 16px;
    }

    .clt-hr-mbl {
        display: block !important;
    }

    .showMobile {
        display: flex !important;
    }

    .hideMobile {
        display: none !important;
    }


    .clt-admin-booking-section-2-divs img {
        width: 18px;
    }

    .clt-admin-booking-section-1-div {
        height: 222px;
        padding-top: 30px;
        padding-right: 18px;
        padding-bottom: 18px;
        padding-left: 18px;
        margin-top: 22px;
        margin-bottom: 22px;
    }

    .clt-admin-booking-section-1-div-inner {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 24px;
        font-weight: 600;
        font-family: 'inter';
        text-align: left;
        padding: 0px 0px;
        margin-bottom: 16px;
        margin-right: 10px;
        margin-top: 2px;
        margin-left: 6px;
        line-height: 33px;
    }

    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:hover, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:active {
        border-radius: 90px;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 12px;
        padding-top: 11px;
        height: 16px;
        width: 36%;
        margin-left: 6px;
        margin-bottom: 18px;
    }

    .clt-admin-booking-section-2-divs {
        margin-right: 14px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        cursor: pointer;
        user-select: none;
    }

    .clt-admin-booking-section-2-divs h2 {
        padding-bottom: 18px;
        margin-top: 9px;
        letter-spacing: -0.2px;
        padding-bottom: 18px;
        margin-top: 9px;
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-2-row {
        border-bottom: 1px solid #dedddd;
        border-top: 1px solid #dedddd;
        padding-top: 0px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .clt-admin-booking-section-2-row-col {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .clt-admin-booking-section-3 {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 14px;
        padding-left: 24px;
        border-bottom: none;
    }

    .clt-admin-booking-section-booking-list {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 14px;
        padding-left: 0px;
        border-bottom: none;
    }

    .clt-admin-booking-section-3-h2-mt {
        margin-top: 35px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: -8px;
        margin-top: 6px;
        line-height: 32px;
        letter-spacing: -0.2px;
        padding-bottom: 16px;
    }

    .clt-admin-booking-section-3-cards {
        white-space: normal;
        flex-shrink: 0;
    }

    .clt-admin-booking-section-3-cards-main h2 {
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 22px;
        padding: 20px;
        width: 80%;
        line-height: 34px;
    }


    .clt-admin-booking-section-3-cards-end h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: Mulish;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #fff;
        line-height: 32px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-booking-request-card span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-booking-request-card p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-booking-request-card span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-booking-request-card p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:hover, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:active {
        border-radius: 15px;
        font-size: 16px;
        font-weight: 500;
        padding: 0px 13px 4px;
        height: 42px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        margin: 40px 0px;
        padding-top: 50px;
        padding-right: 0px;
        padding-bottom: 74px;
        padding-left: 0px;
    }

    .clt-admin-booking-section-4-img-desktop {
        display: none;
    }

    .clt-admin-booking-section-4-img-mbl {
        display: block;
    }

    .clt-admin-booking-section-4 h2 {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        width: 90%;
        text-align: left;
        line-height: 34px;
        padding: 0px 20px;
        margin-bottom: 50px;
    }

    .clt-admin-booking-section-5 {
        padding: 30px 0px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 34px 0px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 20px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        padding: 0px 20px 25px 20px;
    }

    .clt-admin-booking-section-6 {
        height: 400px;
        border-radius: 20px;
        margin: 40px 0px;
        padding: 0px 20px;
    }

    .clt-admin-booking-section-6 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        text-align: center;
    }

    .clt-admin-booking-section-6 img {
        width: 32%;
        margin: 30px 0px;
    }

    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:hover, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 52px;
    }

    .clt-admin-booking-section-7 {
        margin: 30px 0px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-section-7 p {
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        line-height: 1.3;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:hover, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 14px;
        padding-left: 20px;
        width: 100%;
        height: 54px;
    }

    .clt-admin-booking-section-8 {
        border-radius: 20px;
        margin: 40px 0px 100px 0px;
        padding: 30px 28px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
        letter-spacing: -0.2px;
        font-weight: 300;
        margin: 0px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px 10px;
    }

    .clt-admin-booking-section-3-cards-button i {
        margin-left: 5px;
        font-size: 12px;
    }

    .clt-admin-booking-section-2 div:last-child {
        margin-right: 0px;
        padding-right: 12px;
    }

    .clt-admin-booking-section-3-cards-secondary {
        padding-top: 24px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .clt-admin-slide-document-require-section-3-cards-secondary {
        padding-top: 24px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .clt-booking-request-card {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .carouselItem {
        padding-right: 12px;
         /* width: 20px !important; */
    }

    .clt_admin_dash_booking_container_box_name_container_request {
        font-size: 16px;
        margin-left: 10px;
        margin-top: 0px;
    }

    .clt_admin_dash_booking_avatar_container_request {
        width: 22px;
        font-size: 13px;
        height: 22px;
        border-radius: 50%;
        margin-top: 2px;
    }

    .clt_admin_dash_booking_avatar_container_booking {
        width: 22px;
        font-size: 13px;
        height: 22px;
        border-radius: 50%;
        margin-top: 2px;
    }

    .clt-inbox-list-confirm-edit-request-button {
        border-radius: 9px;
        font-size: 13.6px;
        position: relative;
        padding: 0.4px 15px;
        height: 33px;
        margin-top: 5px;
        margin-bottom: 2px;
        padding-bottom: 3px;
    }

    .clt-booking-request-card h2 {
        margin-bottom: 0px !important;
    }


}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-navbar-row-col-pd-left {
        padding-left: 24px !important;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 10.5px;
        margin-left: 5px;
    }

    .clt-search-btn, .clt-search-btn:hover, .clt-search-btn:focus, .clt-search-btn:active {
        height: 57px;
        border-radius: 45px;
        background-color: #eee;
        padding-left: 46px;
        padding-bottom: 10px;
        color: #242424;
        outline: none;
        border: none;
        box-shadow: none;
        font-weight: 600;
        width: 100%;
        text-align: left;
        font-size: 16px;
    }




    .clt-navbar-row-col-pd-right {
        padding-right: 22px !important;
    }

    .clt-admin-booking-section-row-col-pd {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .clt-hr-mbl {
        display: block !important;
    }

    .showMobile {
        display: flex !important;
    }

    .clt-admin-booking-section-3-cards {
        /* width: calc(100% - 30px); */
    }

    .hideMobile {
        display: none !important;
    }


    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 224px;
        padding-top: 32px;
        padding-right: 20px;
        padding-bottom: 32px;
        padding-left: 20px;
        margin-top: 22px;
        margin-bottom: 22px;
    }

    .clt-admin-booking-section-1-div-inner {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 26px;
        font-weight: 600;
        font-family: 'inter';
        text-align: left;
        padding: 0px 0px;
        margin-bottom: 16px;
        margin-right: 60px;
        margin-top: 2px;
        margin-left: 6px;
        line-height: 33px;
    }


    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:hover, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:active {
        border-radius: 90px;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 12px;
        padding-top: 11px;
        height: 16px;
        width: 29%;
        margin-left: 6px;
        margin-bottom: 18px;
    }

    .clt-admin-booking-section-2-divs {
        margin-right: 14px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        cursor: pointer;
        user-select: none;
    }

    .clt-admin-booking-section-2-divs h2 {
        padding-bottom: 18px;
        margin-top: 9px;
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-2-row {
        border-bottom: 1px solid #dedddd;
        border-top: 1px solid #dedddd;
        padding-top: 0px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .clt-admin-booking-section-2-row-col {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .clt-admin-booking-section-3 {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 14px;
        padding-left: 24px;
        border-bottom: none;
    }

    .clt-admin-booking-section-booking-list {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 14px;
        padding-left: 0px;
        border-bottom: none;
    }

    .clt-admin-booking-section-3-cards {
        white-space: normal;
        flex-shrink: 0;
    }

    .clt-admin-booking-section-3-h2-mt {
        /* margin-top: 35px; */
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: -8px;
        margin-top: 6px;
        line-height: 32px;
        letter-spacing: -0.2px;
        padding-bottom: 16px;
    }

    .clt-admin-booking-section-3-cards-main h2 {
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 22px;
        padding: 20px;
        width: 80%;
        line-height: 34px;
    }

    .clt-admin-booking-section-3-cards-end h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: Mulish;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #fff;
        line-height: 32px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-booking-request-card span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-booking-request-card p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:hover, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:active {
        border-radius: 15px;
        font-size: 16px;
        font-weight: 500;
        padding: 0px 13px 4px;
        height: 42px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        margin: 40px 0px;
        padding-top: 50px;
        padding-right: 0px;
        padding-bottom: 74px;
        padding-left: 0px;
    }

    .clt-admin-booking-section-4-img-desktop {
        display: none;
    }

    .clt-admin-booking-section-4-img-mbl {
        display: block;
    }

    .clt-admin-booking-section-4 h2 {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        width: 90%;
        text-align: left;
        line-height: 34px;
        padding: 0px 20px;
        margin-bottom: 50px;
    }

    .clt-admin-booking-section-5 {
        padding: 30px 0px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 34px 0px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 20px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        padding: 0px 20px 25px 20px;
    }

    .clt-admin-booking-section-6 {
        height: 400px;
        border-radius: 20px;
        margin: 40px 0px;
        padding: 0px 20px;
    }

    .clt-admin-booking-section-6 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        text-align: center;
    }

    .clt-admin-booking-section-6 img {
        width: 32%;
        margin: 30px 0px;
    }

    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:hover, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 52px;
    }

    .clt-admin-booking-section-7 {
        margin: 30px 0px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-section-7 p {
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        line-height: 1.3;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:hover, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 14px;
        padding-left: 20px;
        width: 100%;
        height: 54px;
    }

    .clt-admin-booking-section-8 {
        border-radius: 20px;
        margin: 40px 0px 100px 0px;
        padding: 30px 28px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
        letter-spacing: -0.2px;
        font-weight: 300;
        margin: 0px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px 10px;
    }

    .clt-admin-booking-section-3-cards-button i {
        margin-left: 5px;
        font-size: 12px;
    }

    .clt-admin-booking-section-2 div:last-child {
        margin-right: 0px;
        padding-right: 12px;
    }
    .clt-admin-booking-section-3-cards-secondary {
        padding-top: 24px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .clt-admin-slide-document-require-section-3-cards-secondary {
        padding-top: 24px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .clt-booking-request-card {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .carouselItem {
        padding-right: 12px;
          /* width: 20px !important; */
    }


    .clt_admin_dash_booking_container_box_name_container_request {
        font-size: 16px;
        margin-left: 10px;
        margin-top: 0px;
    }

    .clt_admin_dash_booking_avatar_container_request {
        width: 22px;
        font-size: 13px;
        height: 22px;
        border-radius: 50%;
        margin-top: 2px;
    }

    .clt_admin_dash_booking_avatar_container_booking {
        width: 22px;
        font-size: 13px;
        height: 22px;
        border-radius: 50%;
        margin-top: 2px;
    }

    .clt-inbox-list-confirm-edit-request-button {
        border-radius: 9px;
        font-size: 13.6px;
        position: relative;
        padding: 0.4px 15px;
        height: 33px;
        margin-top: 5px;
        margin-bottom: 2px;
        padding-bottom: 3px;
    }

    .clt-booking-request-card h2 {
        margin-bottom: 0px !important;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt-navbar-row-col-pd-left {
        padding-left: 24px !important;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 10.5px;
        margin-left: 5px;
    }

    .clt-search-btn, .clt-search-btn:hover, .clt-search-btn:focus, .clt-search-btn:active {
        height: 57px;
        border-radius: 45px;
        background-color: #eee;
        padding-left: 46px;
        padding-bottom: 10px;
        color: #242424;
        outline: none;
        border: none;
        box-shadow: none;
        font-weight: 600;
        width: 100%;
        text-align: left;
        font-size: 16px;
    }




    .clt-navbar-row-col-pd-right {
        padding-right: 22px !important;
    }

    .clt-admin-booking-section-row-col-pd {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .clt-hr-mbl {
        display: block !important;
    }

    .showMobile {
        display: flex !important;
    }

    .hideMobile {
        display: none !important;
    }


    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 224px;
        padding-top: 32px;
        padding-right: 20px;
        padding-bottom: 32px;
        padding-left: 20px;
        margin-top: 22px;
        margin-bottom: 22px;
    }

    .clt-admin-booking-section-1-div-inner {
        margin-top: 0px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 26px;
        font-weight: 600;
        font-family: 'inter';
        text-align: left;
        padding: 0px 0px;
        margin-bottom: 16px;
        margin-right: 60px;
        margin-top: 2px;
        margin-left: 6px;
        line-height: 33px;
    }


    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:hover, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:active {
        border-radius: 90px;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 12px;
        padding-top: 11px;
        height: 16px;
        width: 29%;
        margin-left: 6px;
        margin-bottom: 18px;
    }

    .clt-admin-booking-section-2-divs {
        margin-right: 14px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        cursor: pointer;
        user-select: none;
    }

    .clt-admin-booking-section-2-divs h2 {
        padding-bottom: 18px;
        margin-top: 9px;
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-2-row {
        border-bottom: 1px solid #dedddd;
        border-top: 1px solid #dedddd;
        padding-top: 0px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .clt-admin-booking-section-2-row-col {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .clt-admin-booking-section-3 {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 14px;
        padding-left: 24px;
        border-bottom: none;
    }

    .clt-admin-booking-section-booking-list {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 14px;
        padding-left: 0px;
        border-bottom: none;
    }

    .clt-admin-booking-section-3-cards {
        white-space: normal;
        flex-shrink: 0;
    }

    .clt-admin-booking-section-3-h2-mt {
        margin-top: 35px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: -8px;
        margin-top: 6px;
        line-height: 32px;
        letter-spacing: -0.2px;
        padding-bottom: 16px;
    }

    .clt-admin-booking-section-3-cards-main h2 {
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 22px;
        padding: 20px;
        width: 80%;
        line-height: 34px;
    }

    .clt-admin-booking-section-3-cards-end h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: Mulish;
        margin-bottom: 30px;
        margin-top: 10px;
        color: #fff;
        line-height: 32px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-booking-request-card span {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.2px;
    }

    .clt-booking-request-card p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 0px;
        line-height: 22px;
        margin-top: -2px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:hover, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:active {
        border-radius: 15px;
        font-size: 16px;
        font-weight: 500;
        padding: 0px 13px 4px;
        height: 42px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        margin: 40px 0px;
        padding-top: 50px;
        padding-right: 0px;
        padding-bottom: 74px;
        padding-left: 0px;
    }

    .clt-admin-booking-section-4-img-desktop {
        display: none;
    }

    .clt-admin-booking-section-4-img-mbl {
        display: block;
    }

    .clt-admin-booking-section-4 h2 {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-bottom: 0;
        width: 90%;
        text-align: left;
        line-height: 34px;
        padding: 0px 20px;
        margin-bottom: 50px;
    }

    .clt-admin-booking-section-5 {
        padding: 30px 0px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.2px;
        line-height: 34px;
        margin: 0px 0px 34px 0px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 600;
        padding: 25px 20px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 400;
        padding: 0px 20px 25px 20px;
    }

    .clt-admin-booking-section-6 {
        height: 400px;
        border-radius: 20px;
        margin: 40px 0px;
        padding: 0px 20px;
    }

    .clt-admin-booking-section-6 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
        text-align: center;
    }

    .clt-admin-booking-section-6 img {
        width: 32%;
        margin: 30px 0px;
    }

    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:hover, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 52px;
    }

    .clt-admin-booking-section-7 {
        margin: 30px 0px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-section-7 p {
        margin-top: 5px;
        margin-right: 0px;
        margin-bottom: 20px;
        margin-left: 0px;
        font-size: 17px;
        letter-spacing: -0.2px;
        font-weight: 400;
        line-height: 1.3;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:hover, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:active {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding-top: 10px;
        padding-right: 20px;
        padding-bottom: 14px;
        padding-left: 20px;
        width: 100%;
        height: 54px;
    }

    .clt-admin-booking-section-8 {
        border-radius: 20px;
        margin: 40px 0px 100px 0px;
        padding: 30px 28px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
        letter-spacing: -0.2px;
        font-weight: 300;
        margin: 0px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px 10px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
    }

    .clt-admin-booking-section-3-cards-button i {
        margin-left: 5px;
        font-size: 12px;
    }

    .clt-admin-booking-section-2 div:last-child {
        margin-right: 0px;
        padding-right: 12px;
    }
    .clt-admin-booking-section-3-cards-secondary {
        padding-top: 24px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .clt-admin-slide-document-require-section-3-cards-secondary {
        padding-top: 24px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .clt-booking-request-card {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
        margin-right: 20px;
        /* width: 304px !important; */
    }

    .carouselItem {
        padding-right: 12px;
          /* width: 20px !important; */ 
    }

    .clt-admin-dashboard-booking-edit-request .react-multi-carousel-list {
        /* max-width: 500px; */
    }

    .clt_admin_dash_booking_container_box_name_container_request {
        font-size: 16px;
        margin-left: 10px;
        margin-top: 0px;
    }

    .clt_admin_dash_booking_avatar_container_request {
        width: 22px;
        font-size: 13px;
        height: 22px;
        border-radius: 50%;
        margin-top: 2px;
    }

    .clt_admin_dash_booking_avatar_container_booking {
        width: 22px;
        font-size: 13px;
        height: 22px;
        border-radius: 50%;
        margin-top: 2px;
    }

    .clt-inbox-list-confirm-edit-request-button {
        border-radius: 9px;
        font-size: 13.6px;
        position: relative;
        padding: 0.4px 15px;
        height: 33px;
        margin-top: 5px;
        margin-bottom: 2px;
        padding-bottom: 3px;
    }
    .clt-booking-request-card h2 {
        margin-bottom: 0px !important;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt-admin-booking-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 348px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 52px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(0, 0, 0, .7098039215686275);
    }

    .clt-search-btn, .clt-search-btn:active, .clt-search-btn:focus, .clt-search-btn:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }


    .clt-admin-booking-section-1-h2 {
        font-size: 40px;
        font-weight: 600;
        font-family: "inter";
        color: #fff;
        margin-bottom: 24px;
        margin-right: 211px;
    }

    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:active, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:hover {
        font-size: 15px;
        height: 28px;
        width: 17%;
        padding: 14px 28px 15px;
    }

    .clt-admin-booking-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 25px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-3-cards {
        border-radius: 20px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-size: 16px;
    }


    .clt-booking-request-card span {
        font-size: 17px;
    }

    .clt-booking-request-card p {
        font-size: 16px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        background-color: #000;
        border-radius: 20px;
        overflow: hidden;
        margin: 40px 0;
        padding: 74px 15px;
    }

    .clt-admin-booking-section-4 h2 {
        font-size: 32px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 27px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
    }

    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:active, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-admin-booking-section-6 {
        height: 348px;
    }

    .clt-admin-booking-section-7 p {
        font-size: 17px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:active, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
    }

    .clt-admin-dashboard-booking-edit-request .react-multi-carousel-list {
        /* max-width: 500px; */
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt-admin-booking-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 348px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 52px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(0, 0, 0, .7098039215686275);
    }

    .clt-search-btn, .clt-search-btn:active, .clt-search-btn:focus, .clt-search-btn:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 40px;
        font-weight: 600;
        font-family: "inter";
        color: #fff;
        margin-bottom: 24px;
        margin-right: 211px;
    }

    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:active, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:hover {
        font-size: 15px;
        height: 28px;
        width: 17%;
        padding: 14px 28px 15px;
    }

    .clt-admin-booking-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 25px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-3-cards {
        border-radius: 20px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-booking-request-card span {
        font-size: 17px;
    }

    .clt-booking-request-card p {
        font-size: 16px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:active, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:hover {
        font-size: 15px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        background-color: #000;
        border-radius: 20px;
        overflow: hidden;
        margin: 40px 0;
        padding: 74px 15px;
    }

    .clt-admin-booking-section-4 h2 {
        font-size: 32px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 27px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:active, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-admin-booking-section-6 {
        height: 348px;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:active, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-admin-booking-section-7 p {
        font-size: 17px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
    }

    .clt-admin-dashboard-booking-edit-request .react-multi-carousel-list {
        /* max-width: 500px; */
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-admin-booking-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 348px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 52px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(0, 0, 0, .7098039215686275);
    }

    .clt-search-btn, .clt-search-btn:active, .clt-search-btn:focus, .clt-search-btn:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 40px;
        font-weight: 600;
        font-family: "inter";
        color: #fff;
        margin-bottom: 24px;
        margin-right: 475px;
    }

    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:active, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:hover {
        font-size: 15px;
        height: 26px;
        width: 12%;
        padding: 14px 27px 15px;
    }

    .clt-admin-booking-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 27px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-3-cards {
        border-radius: 20px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-booking-request-card span {
        font-size: 17px;
    }

    .clt-booking-request-card p {
        font-size: 16px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        background-color: #000;
        border-radius: 20px;
        overflow: hidden;
        margin: 40px 0;
        padding: 74px 15px;
    }

    .clt-admin-booking-section-4 h2 {
        font-size: 32px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 27px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:active, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-admin-booking-section-6 {
        height: 348px;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:active, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-admin-booking-section-7 p {
        font-size: 17px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
    }


    .clt-admin-dashboard-booking-edit-request .react-multi-carousel-list {
        /* max-width: 500px; */
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-admin-booking-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 348px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 52px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(0, 0, 0, .7098039215686275);
    }

    .clt-search-btn, .clt-search-btn:active, .clt-search-btn:focus, .clt-search-btn:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 40px;
        font-weight: 600;
        font-family: "inter";
        color: #fff;
        margin-bottom: 24px;
        margin-right: 475px;
    }

    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:active, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:hover {
        font-size: 15px;
        height: 26px;
        width: 12%;
        padding: 14px 27px 15px;
    }

    .clt-admin-booking-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 27px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-3-cards {
        border-radius: 20px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-booking-request-card span {
        font-size: 17px;
    }

    .clt-booking-request-card p {
        font-size: 16px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:active, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:hover {
        font-size: 15px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        background-color: #000;
        border-radius: 20px;
        overflow: hidden;
        margin: 40px 0;
        padding: 74px 15px;
    }

    .clt-admin-booking-section-4 h2 {
        font-size: 32px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 27px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:active, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-admin-booking-section-6 {
        height: 348px;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:active, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-admin-booking-section-7 p {
        font-size: 17px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
    }

    .clt-admin-dashboard-booking-edit-request .react-multi-carousel-list {
        /* max-width: 500px; */
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-admin-booking-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 348px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 52px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(0, 0, 0, .7098039215686275);
    }

    .clt-search-btn, .clt-search-btn:active, .clt-search-btn:focus, .clt-search-btn:hover {
        height: 52px;
        border-radius: 45px;
        padding-left: 46px;
        padding-bottom: 11px;
        font-size: 16px;
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 40px;
        margin-bottom: 24px;
        margin-right: 661px;
    }

    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:active, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:hover {
        font-size: 15px;
        height: 28px;
        width: 10%;
        padding: 14px 27px 15px;
    }

    .clt-admin-booking-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 27px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-3-cards {
        border-radius: 20px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-booking-request-card span {
        font-size: 17px;
    }

    .clt-booking-request-card p {
        font-size: 16px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:active, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:hover {
        font-size: 15px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        background-color: #000;
        border-radius: 20px;
        overflow: hidden;
        margin: 40px 0;
        padding: 74px 15px;
    }

    .clt-admin-booking-section-4 h2 {
        font-size: 32px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 27px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:active, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-admin-booking-section-6 {
        height: 348px;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:active, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-admin-booking-section-7 p {
        font-size: 17px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-admin-booking-section-2-divs h2 {
        font-size: 14px;
    }

    .clt-admin-booking-section-2-divs img {
        width: 20px;
    }

    .clt-admin-booking-section-1-div {
        height: 348px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding: 52px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(0, 0, 0, .7098039215686275);
    }

    .clt-search-icon {
        width: 15px;
        height: 16px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 40px;
        margin-bottom: 24px;
        margin-right: 661px;
    }

    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:active, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:hover {
        font-size: 15px;
        height: 28px;
        width: 10%;
        padding: 14px 27px 15px;
    }

    .clt-admin-booking-section-1-div-inner {
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -.2px;
    }

    .clt-admin-booking-section-3-cards {
        border-radius: 20px;
    }

    .clt-admin-booking-section-3-cards-secondary span {
        font-size: 17px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary span {
        font-size: 14px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-size: 16px;
    }

    .clt-booking-request-card span {
        font-size: 17px;
    }

    .clt-booking-request-card p {
        font-size: 16px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:active, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:hover {
        font-size: 15px;
        padding-top: 0px;
        padding-right: 10px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .clt-admin-booking-section-4 {
        position: relative;
        background-color: #000;
        border-radius: 20px;
        overflow: hidden;
        margin: 40px 0;
        padding: 74px 15px;
    }

    .clt-admin-booking-section-4 h2 {
        font-size: 30px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 27px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 16px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 16px;
    }


    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:active, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:hover {
        width: 28%;
        height: 52px;
        font-size: 16px;
    }

    .clt-admin-booking-section-6 {
        height: 348px;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:active, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:hover {
        font-size: 16px;
        height: 50px;
    }

    .clt-admin-booking-section-7 p {
        font-size: 17px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
    }

    .clt-search {
        padding: 18px 0px;
        margin: 0px;
    }

    .clt-admin-booking-section-8 a, .clt-admin-booking-section-8 a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 16px;
    }

    .clt-admin-booking-section-8 p {
        font-size: 15px;
    }


}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-admin-booking-section-1-btn, .clt-admin-booking-section-1-btn:active, .clt-admin-booking-section-1-btn:focus, .clt-admin-booking-section-1-btn:hover {
        font-size: 15px;
        border: 1px solid #fff;
        height: 32px;
        width: 9%;
        padding: 13px 28px 15px;
        letter-spacing: .2px;
    }

    .clt-admin-booking-section-2-row-col, .clt-admin-booking-section-2-row-container {
        padding-left: 5px !important;
        padding-right: 14px !important;
    }

    .clt-admin-booking-section-1-h2 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 24px;
        margin-right: 817px;
    }

    .clt-admin-booking-section-2-divs img {
        width: 18px;
    }

    .clt-admin-booking-section-2-divs {
        margin-right: 22px;
        cursor: pointer;
        user-select: none;
        background: #fff;
        border-radius: 12px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 8px;
    }

    .clt-admin-booking-section-2-divs h2 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 11px;
        margin-top: 5px;
        line-height: 16px;
    }

    .clt-search-btn, .clt-search-btn:active, .clt-search-btn:focus, .clt-search-btn:hover {
        font-size: 15px;
    }

    .clt-admin-booking-section-2 div:last-child {
        margin-right: 0;
        padding-right: 12px;
    }

    .clt-admin-booking-section-3-h2 {
        font-size: 22px;
    }

    .clt-admin-booking-section-3-cards-secondary p {
        font-size: 15px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary p {
        font-size: 15px;
    }

    .clt-booking-request-card p {
        font-size: 15px;
    }

    .clt-admin-booking-section-3-cards-end h2 {
        font-size: 28px;
    }

    .clt-admin-booking-section-3-cards-button, .clt-admin-booking-section-3-cards-button:active, .clt-admin-booking-section-3-cards-button:focus, .clt-admin-booking-section-3-cards-button:hover {
        font-size: 15px;
    }

    .clt-admin-booking-section-5-h2 {
        font-size: 28px;
    }

    .clt-admin-booking-section-5-accordion-header button {
        font-size: 15px;
    }

    .clt-admin-booking-section-5-accordion-body {
        font-size: 15px;
    }

    .clt-admin-booking-section-6-btn, .clt-admin-booking-section-6-btn:active, .clt-admin-booking-section-6-btn:focus, .clt-admin-booking-section-6-btn:hover {
        font-size: 15px;
    }

    .clt-admin-booking-section-7 h2 {
        font-size: 26px;
    }

    .clt-admin-booking-section-7 p {
        font-size: 16px;
    }

    .clt-admin-booking-section-7-btn, .clt-admin-booking-section-7-btn:active, .clt-admin-booking-section-7-btn:focus, .clt-admin-booking-section-7-btn:hover {
        font-size: 15px;
    }

    .clt-admin-booking-section-8 span, .clt-admin-booking-section-8 span:hover {
        font-size: 15px;
    }

    .clt-admin-booking-section-3-cards-main h2 {
        font-size: 28px;
        margin-top: 47px;
        padding: 0 20px 20px;
        line-height: 34px;
    }

    .clt-admin-booking-section-2-row {
        padding-top: 0px;
    }


    .clt-admin-booking-section-3 {
        padding-right: 0px;
        padding-bottom: 24px;
        padding-left: 0px;
    }

    .clt-admin-booking-section-booking-list {
        padding-right: 0px;
        padding-bottom: 42px;
        padding-left: 0px;
    }

    .clt-admin-booking-section-3-cards-secondary {
        padding-top: 24px;
        padding-right: 24px;
        padding-bottom: 6px;
        padding-left: 24px;
    }

    .clt-admin-slide-document-require-section-3-cards-secondary {
        padding-top: 18px;
        padding-right: 22px;
        padding-bottom: 0px;
        padding-left: 20px;
    }

    .clt-booking-request-card {
        padding-top: 18px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
    }

    .clt-admin-dashboard-booking-edit-request .react-multi-carousel-list {
        max-width: 1200px;
    }

    .clt_admin_dash_booking_container_box_name_container_request {
        font-size: 15px;
        margin-top: 5px;
    }

    .clt-inbox-list-confirm-edit-request-button {
        border-radius: 9px;
        font-size: 11.6px;
        font-weight: 500;
        padding: 0.4px 9px;
        height: 25px;
        margin-top: 5px;
        margin-bottom: 2px;
        padding-bottom: 3px;
    }

    .clt-admin-booking-section-3 .react-multi-carousel-item {
        width: 320px !important;
    }

    .clt-admin-booking-section-booking-list .react-multi-carousel-item {
        width: 290px !important;
    }

}