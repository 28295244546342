.clt_message_inbox_container {
    height: 100%;
    width: 100%;
}

.clt_message_inbox_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.clt_message_accountDetail_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
    padding-top: 1.4px;
    padding-right: 18px;
    padding-bottom: 4px;
    padding-left: 18px;
    height: 53px;
    min-height: 53px;
    border-bottom: 1px solid #dedddd;
}

.clt_message_accountDetail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    grid-gap: 10px;
}

.clt_message_accountDetail img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.clt_message_accountDetail img:hover {
    opacity: 0.7;
}

.clt_message_accountDetail img:active {
    opacity: 0.6;
}

.clt_messages_container_detail_icon_header {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}


.clt_messages_container_detail_icon {
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
}

.clt_messages_container_detail_icon:hover {
    opacity: 0.7;
}

.clt_messages_container_detail_icon:active {
    opacity: 0.6;
}

.clt_message_accountSubDetail {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 3px;
}

.clt_message_accountSubDetail h2 {
    font-size: 13.5px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin: 0px;
    letter-spacing: -0.2px;
    text-align: left;
}

.clt_message_accountSubDetail p {
    font-size: 12.5px;
    font-family: 'Inter';
    margin-bottom: 2.5px;
    letter-spacing: -0.2px;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    color: #5b5b5b;
}

.pfr_dropdown_menu_inbox {
    position: absolute;
    top: 6px !important;
    right: 0px !important;
    min-width: 40px !important;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    border-radius: 12px;
    padding: 0.3rem 0;
    margin: 0;
    font-size: 1rem;
    color: #242424;
    text-align: left;
    list-style: none;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px !important;
    border: 0px solid rgba(0, 0, 0, .15);
    background-clip: padding-box;
    border-radius: 12px;
}

.clt_messages_container {
    /* flex-grow: 1; */
    overflow-y: scroll;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
    padding-top: 12px;
    height: 100%;
    width: 100%;
}

.clt_messages_container> :first-child {
    margin-top: auto !important;
    /* use !important to prevent breakage from child margin settings */
}

.clt_message_navbarRightTxt {
    color: #fff !important;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
}

.clt_message_navbarRightTxt span {
    font-weight: 500;
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;
    /* background: #eee; */
    border-radius: 50px;
    color: #242424 !important;
    padding-top: 6px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.2px;
}

.clt_message_navbarRightTxt i {
    font-size: 9px;
    color: #242424;
    padding-left: 5px;
    padding-top: 1px;
}

.clt_message_input_component {
    position: sticky;
    bottom: 0px;
    background-color: white;
    width: 100%;
    align-self: flex-end;
}

.clt_messages_container_wrapper_date_p {
    background: #eee;
    margin-bottom: 0px;
}

.clt_message_input_component_inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    gap: 28px;
    margin: 0px 28px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.clt_message_input_component_inner_upload {
    position: relative;
    bottom: 12px;
}

.clt_message_input_component_inner_upload_icon {
    cursor: pointer;
}

.clt_message_input_component_inner_upload:active {
    opacity: 0.6 !important;
}

.clt_message_input_component_inner_upload:hover {
    opacity: 0.7 !important;
}

.clt_message_input_component_tag_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 12px;
    margin-left: 24px;
    padding-top: 5px;
}

.clt_message_input_component_tag_image {
    width: 14px;
    height: 15px;
}

.clt_message_input_component_tag {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 6px;
    background-color: #eee;
    border-radius: 20px;
    padding-top: 5px;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
    cursor: pointer;
    color: #242424;
    text-decoration: none;
    width: fit-content;
}

.clt_message_input_component_tag:hover {
    background-color: #d9dce0 !important;
    color: #242424 !important;
}

.clt_message_input_component_tag:active {
    background-color: #d2d2d3 !important;
    color: #242424 !important;
}

.clt_message_input_component_tag p {
    font-size: 13px;
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.clt_message_textarea {
    border: 1px solid #dedddd;
    border-radius: 22px;
    width: calc(100% - 8px);
    overflow-y: hidden;
    /* display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row; */
    padding-right: 10px;
    padding-left: 10px;
}

.clt_message_textarea_images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-x: hidden;
    gap: 7px;
    margin: 7px 0px;
    margin-bottom: 0px;
}

.clt_message_textarea_images_wrapper {
    position: relative;
    margin: 4px;
    margin-top: 10px;
}

.clt_message_textarea_images_wrapper_del {
    position: absolute;
    right: -11px;
    top: -8px;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    padding: 0px 8px 2px 8px;
    border-radius: 100%;
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px !important;
}

.clt_message_textarea_images_wrapper_del:hover {
    background-color: #f6f6f6;
}

.clt_message_textarea_images_wrapper_del:active {
    background: #eeeeee;
}

.clt_message_textarea_images_wrapper_del_icon {
    width: 9px;
    height: 9px;
}

.clt_message_textarea_images_wrapper_del img {}

.clt_message_textarea .clt_message_textarea_send {
    position: relative;
    bottom: 10px;
    display: inline;
}

.clt_message_textarea .clt_message_textarea_send_btn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -25px;
    bottom: 4px;
}

.clt_message_textarea .disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: #048848;
}

.clt_message_textarea_send_btn_active {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.clt_message_textarea_send_btn_active:hover {
    opacity: 0.7;
}

.clt_message_textarea_send_btn_active:active {
    opacity: 0.6;
}

.clt_message_textarea_send_btn_not_active {
    width: 20px;
    height: 20px;
}

.clt_message_textarea .form-control:focus .clt_message_textarea_send_btn {
    background-image: url("../../../../assets/img/icon_send_active.png") !important;
}

.clt_message_textarea textarea, .clt_message_textarea textarea:hover, .clt_message_textarea .form-control:focus {
    border: none !important;
    outline: none !important;
    background-color: white !important;
    padding: 4px 10px !important;
    padding-left: 4px !important;
    font-size: 14px !important;
    color: #242424 !important;
    font-weight: 400 !important;
    width: calc(100% - 30px);
    resize: none !important;
    margin: 4px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: inline;
}

/* .clt_message_textarea textarea .form-control {
    min-height: 100px;
    max-height: 170px;
}  */



/* messages types preview  */

.clt_messages_container_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}

.clt_messages_container_wrapper_messages {
    display: flex;
    flex-direction: column;
}

.clt_messages_container_wrapper_text {
    margin-top: 14px;
    margin-right: 40px;
    margin-bottom: 14px;
    margin-left: 14px;
    display: block;
}

.clt_messages_container_detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0px 10px 10px 10px;
    width: 90%;
}

.clt_messages_container_detail img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.clt_messages_container_detail img:hover {
    opacity: 0.7;
}

.clt_messages_container_detail img:active {
    opacity: 0.6;
}

.clt_messages_container_detail img:active {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.clt_messages_container_detail_contact_request {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0px;
    width: 90%;
}

.clt_messages_container_detail_contact_request img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.clt_messages_container_detail_contact_request img:hover {
    opacity: 0.7;
}

.clt_messages_container_detail_contact_request img:active {
    opacity: 0.6;
}

.clt_messages_container_detail_contact_request img:active {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}


.clt_messages_container_accountSubDetail {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: 12px;
    width: 70%;
}

.clt_messages_container_message_detail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 3px;
}

.clt_messages_container_message_detail h2 {
    font-size: 13.5px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin: 0px;
    letter-spacing: -0.2px;
    text-align: left;
}

.clt_messages_container_message_detail p {
    font-size: 11px;
    font-family: "Inter";
    letter-spacing: -.2px;
    text-align: left;
    line-height: 100%;
    margin-top: 2px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 4px;
    color: #5b5b5b;
}

.clt_messages_container_message_text {
    font-size: 13.5px;
    line-height: 21px;
    text-align: left;
    margin: 0px;
    font-weight: 400;
    color: #242424;
    letter-spacing: 0.2px;
    word-break: break-word;
    white-space: break-spaces;
}

.clt_messages_container_images_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 7px;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 2px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
}

.clt_messages_container_images_container img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}



/* request pending types  */

.clt_message_accountDetail .booking {}

.clt_message_accountDetail_wrapper .booking span {
    background-color: #ffeee3 !important;
    color: #f99153 !important;
    /* font-size: 13.3px !important; */
    padding-top: 5px !important;
    padding-bottom: 7px !important;
}

.clt_message_accountDetail_wrapper .booking span i {
    color: #ff9759 !important;
}

.clt_message_accountDetail_wrapper .information span {
    background-color: #169b0a12 !important;
    color: #048848 !important;
    padding-top: 5px !important;
    padding-bottom: 7px !important;
}

.clt_message_accountDetail_wrapper .information span i {
    color: #048848 !important;
}

/* Contact request */

.clt_messages_container_request_message {
    border: 1px solid #dedddd;
    padding-top: 16px;
    padding-right: 18px;
    padding-bottom: 18px;
    padding-left: 18px;
    border-radius: 12px;
    width: 40%;
    margin: 10px 0;
    cursor: pointer;
}

.clt_messages_container_request_message:hover {
    background: #f6f6f6;
    border: 1px solid #dedddd;
}

.clt_messages_container_request_message:active {
    background: #eee;
    border: 1px solid #dedddd;
}

.clt_messages_container_request_message_header .back-button img {
    width: 10px !important;
    height: 16px !important;
    margin-bottom: 9px !important;
}

.clt_messages_container_request_message img {
    border-radius: 0 !important;
    width: 17px !important;
    height: 17px !important;
}

.clt_messages_container_request_message_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 14px;
    margin-top: 4px;
}

.clt_messages_container_request_message_header h5 {
    font-size: 18px;
    font-weight: 600;
}

.clt_messages_container_contact_message {
    border: 1px solid #dedddd;
    border-radius: 12px;
    width: 40%;
    margin: 10px 0px;
}

.clt_messages_container_contact_message_type .form-check-input {
    cursor: pointer !important;
    border-radius: 5px !important;
}


.clt_messages_container_contact_message_type .form-check-input:checked {
    background-color: black !important;
    border-color: black !important;
    cursor: pointer !important;
    border-radius: 5px !important;
}

.clt_messages_container_contact_message_type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 13px;
    cursor: pointer;
}

.clt_messages_container_contact_message_type:hover {
    background: #f6f6f6;
}

.clt_messages_container_contact_message_type:active {
    background: #eee;
}

.clt_messages_container_contact_message_type:first-child {
    border-bottom: 1px solid #dedddd;
}

.clt_messages_container_contact_message_type:last-child {
    border-top: 1px solid #dedddd;
}

.clt_messages_container_contact_message_type p {
    font-weight: 600;
    font-size: 13.5px;
    margin-bottom: 0px !important;
}

.clt_messages_container_wrapper_date {
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #282828;
}

.clt_messages_container_wrapper_date_p {
    background: #eee !important;
    padding-top: 2px;
    padding-right: 7px;
    padding-bottom: 3px;
    padding-left: 7px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.clt_messages_container_wrapper_date_p:hover {
    background: #eee !important;
}

.clt_messages_container_wrapper_date {
    padding: 0px;
    font-size: 10.5px;
}

.clt_messages_container_booking_confirm {
    border: 1px solid #dedddd;
    padding-top: 16px;
    padding-right: 18px;
    padding-bottom: 12px;
    padding-left: 18px;
    border-radius: 18px;
    width: 40%;
    margin: 10px 0;
    cursor: pointer;
    font-weight: 400;
}

.clt_messages_container_booking_confirm:hover {
    background: #f6f6f6;
    border: 1px solid #dedddd;
}

.clt_messages_container_booking_confirm:active {
    background: #eee;
    border: 1px solid #dedddd;
}


.clt_messages_container_booking_confirm_tag_text {
    font-size: 13px;
    color: #242424;
    font-weight: 500;
    margin-bottom: 6px;
}

.clt_messages_container_booking_confirm_header .back-button img {
    width: 8px !important;
    height: 13px !important;
    margin-bottom: 9px !important;
}

.clt_messages_container_booking_confirm img {
    border-radius: 0 !important;
    width: 17px !important;
    height: 17px !important;
}

.clt_messages_container_booking_confirm_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 4px;
}

.clt_messages_container_booking_confirm_header h5 {
    color: #242424;
    font-size: 13.5px;
    margin-bottom: 3px;
    font-weight: 500;
}

.clt_messages_container_booking_confirm_header p {
    font-size: 13.5px;
    line-height: 100%;
    color: #5b5b5b;
    padding: 0;
    margin: 0;
}

.clt_messages_container_booking_confirm_amount {
    margin-top: -4px !important;
    font-size: 35px;
    color: #242424;
    margin-bottom: 8px !important;
    font-weight: 600;
    margin: 0;
}

.clt_messages_container_booking_confirm_tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.clt_inbox_checkmark {
    display: inline-block;
    width: 18px;
    height: 18px;
    -ms-transform: rotate(45deg);
    /* IE 9 */
    -webkit-transform: rotate(45deg);
    /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.clt_inbox_checkmark_circle {
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: green;
    border-radius: 11px;
    left: 0;
    top: 0;
}

.clt_inbox_checkmark_stem {
    position: absolute;
    background-color: #fff;
    width: 1.5px;
    height: 6.5px;
    left: 7px;
    top: 3px;
}

.clt_inbox_checkmark_kick {
    position: absolute;
    background-color: #fff;
    width: 5px;
    height: 1.5px;
    left: 4px;
    top: 8px;
}

.clt_messages_container_request_state {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin: 8px 0px 22px 0px;
}

.clt_messages_container_request_state2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin: 26px 0px 0px 0px;
}

.clt_messages_container_request_state3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 0px 0px 0px 0px;
    padding-bottom: 5px;
}
.clt_messages_container_request_state h2 {
    font-size: 13.5px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin: 0px;
    letter-spacing: -0.2px;
    text-align: left;
}

.clt_slider_edit_request_container_request_state {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    margin: 0px 0px 0px 0px;
}

.clt_slider_edit_request_container_request_state h2 {
    font-size: 13.5px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin: 0px;
    margin-bottom: -1px;
    letter-spacing: -0.2px;
    text-align: left;
}

.check-container {
    background-color: #248c0f;
    width: fit-content;
    padding: 4.5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.check-container .check {
    display: inline-block;
    transform: rotate(45deg);
    height: 10px;
    width: 5px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
}

.clt_check_box_1 {
    cursor: pointer !important;
}


.clt_message_input_component_inner3 {
    margin-top: 0px;
    margin-right: 28px;
    margin-bottom: 12px;
    margin-left: 28px;
}

.clt_messages_container_detail2 {
    flex-direction: row;
}
@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_messages_container_request_message {
        width: 100%;
    }

    .clt_messages_container_booking_confirm {
        padding-top: 14px;
        padding-right: 18px;
        padding-bottom: 17px;
        padding-left: 18px;
        border-radius: 18px;
        width: 100%;
    }

    .clt_messages_container_booking_confirm_tag_text {
        font-size: 15px;
        color: #222222;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_messages_container_booking_confirm_header .back-button img {
        width: 8px !important;
        height: 14px !important;
        margin-bottom: 9px !important;
    }

    .clt_messages_container_contact_message {
        width: 100%;
    }

    .clt_message_accountSubDetail {
        grid-gap: 3px;
        gap: 2.82px;
    }

    .clt_messages_container_message_detail h2 {
        font-size: 16px;
    }

    .clt_message_input_component_tag p {
        font-size: 14.5px;
    }

    .clt_message_input_component_tag_wrapper {
        display: flex;
        margin-left: 24px;
        margin-top: 10px;
        gap: 4px;
    }

    .clt_message_accountDetail {
        gap: 14px;
        grid-gap: 14px;
    }

    .clt_message_accountDetail_wrapper {
        height: 84px;
        padding: 2.8px 24px 5.8px;
    }

    .clt_message_accountDetail img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .clt_message_accountSubDetail h2 {
        font-size: 16px;
    }

    .clt_message_accountSubDetail p {
        font-size: 14px;
        line-height: 100%;
        color: #5b5b5b;
    }

    .clt_messages_container_message_text {
        font-size: 16px;
        line-height: 21px;
        text-align: left;
    }

    .clt_messages_container_contact_message_type p {
        font-size: 16px;
    }

    .clt_messages_container_contact_message_type {
        padding-top: 14px;
        padding-right: 13px;
        padding-bottom: 20px;
        padding-left: 13px;
    }

    .clt_messages_container_message_detail p {
        font-size: 13px;
        margin-top: 0px;
    }

    .clt_messages_container_wrapper_text {
        margin-top: 4px;
        margin-right: 14px;
        margin-bottom: 14px;
        margin-left: 14px;
    }

    .clt_messages_container_booking_confirm_header h5 {
        font-size: 16px;
        margin-bottom: 3px;
        font-weight: 600;
    }

    .clt_messages_container_booking_confirm_header p {
        font-size: 16px;
        line-height: 100%;
        padding: 0px;
        margin: 0px;
    }

    .clt_messages_container_booking_confirm_amount {
        margin-top: -4px !important;
        font-size: 40px;
        margin-bottom: 10px !important;
        font-weight: 600;
        margin: 0;
    }

    .clt_inbox_checkmark {
        display: inline-block;
        width: 18px;
        height: 18px;
        -ms-transform: rotate(45deg);
        /* IE 9 */
        -webkit-transform: rotate(45deg);
        /* Chrome, Safari, Opera */
        transform: rotate(45deg);
    }

    .clt_inbox_checkmark_circle {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: green;
        border-radius: 11px;
        left: 0;
        top: 0;
    }

    .clt_inbox_checkmark_stem {
        position: absolute;
        background-color: #fff;
        width: 1.5px;
        height: 7.5px;
        left: 8px;
        top: 3px;
    }

    .clt_inbox_checkmark_kick {
        position: absolute;
        background-color: #fff;
        width: 3.5px;
        height: 2px;
        left: 4.5px;
        top: 9px;
    }

    .clt_messages_container_detail_icon {
        min-width: 26px;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        color: #fff;
        font-size: 13px;
    }

    .clt_messages_container_detail img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .clt_messages_container_detail_contact_request img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
    }

    .clt_messages_container_wrapper_date {
        font-size: 13px;
    }

    .clt_messages_container_detail_icon_header {
        width: 37.5px;
        height: 36.5px;
        font-size: 15px !important;
    }

    .clt_message_textarea .form-control:focus, .clt_message_textarea textarea, .clt_message_textarea textarea:hover {
        padding: 4px 10px 4px 4px !important;
        font-size: 15px !important;
        margin: 4px 4px 0 0;
    }

    .clt_messages_container_request_message_header h5 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .clt_message_navbarRightTxt span {
        font-size: 14px;
        border-radius: 50px;
        padding-top: 6px;
        padding-right: 16px;
        padding-bottom: 8px;
        padding-left: 16px;
    }

    .clt_message_input_component_tag {
        padding: 5px 12px;
        gap: 4px;
    }

    .clt_message_input_component_tag_image {
        width: 15px;
        height: 15px;
    }

    .clt_messages_container_request_state h2 {
        font-size: 16px;
    }

    .clt_slider_edit_request_container_request_state h2 {
        font-size: 16px;
    }

    .clt_messages_container_accountSubDetail {
        width: 100%;
    }

    .clt_message_input_component_inner3 {
        margin-top: 0px;
        margin-right: 28px;
        margin-bottom: 32px;
        margin-left: 28px;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_messages_container_request_message {
        width: 100%;
    }

    .clt_messages_container_booking_confirm {
        padding-top: 14px;
        padding-right: 18px;
        padding-bottom: 17px;
        padding-left: 18px;
        border-radius: 18px;
        width: 100%;
    }

    .clt_messages_container_booking_confirm_tag_text {
        font-size: 15px;
        color: #222222;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_messages_container_booking_confirm_header .back-button img {
        width: 8px !important;
        height: 14px !important;
        margin-bottom: 9px !important;
    }

    .clt_messages_container_contact_message {
        width: 100%;
    }

    .clt_message_accountSubDetail {
        grid-gap: 3px;
        gap: 2.82px;
    }

    .clt_messages_container_message_detail h2 {
        font-size: 16px;
    }

    .clt_message_input_component_tag p {
        font-size: 14.5px;
    }

    .clt_message_input_component_tag_wrapper {
        margin-left: 24px;
        margin-top: 10px;
        gap: 4px;
    }

    .clt_message_accountDetail {
        gap: 14px;
        grid-gap: 14px;
    }

    .clt_message_accountDetail_wrapper {
        /* height: 84px; */
        padding: 2.8px 24px 5.8px;
    }

    .clt_message_accountDetail img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .clt_message_accountSubDetail h2 {
        font-size: 16px;
    }

    .clt_message_accountSubDetail p {
        font-size: 14px;
        line-height: 100%;
        color: #5b5b5b;
    }

    .clt_messages_container_message_text {
        font-size: 16px;
        line-height: 21px;
        text-align: left;
    }

    .clt_messages_container_contact_message_type p {
        font-size: 16px;
    }

    .clt_messages_container_contact_message_type {
        padding-top: 14px;
        padding-right: 13px;
        padding-bottom: 20px;
        padding-left: 13px;
    }

    .clt_messages_container_message_detail p {
        font-size: 13px;
        margin-top: 0px;
    }

    .clt_messages_container_wrapper_text {
        margin-top: 4px;
        margin-right: 14px;
        margin-bottom: 14px;
        margin-left: 14px;
    }

    .clt_messages_container_booking_confirm_header h5 {
        font-size: 16px;
        margin-bottom: 3px;
        font-weight: 600;
    }

    .clt_messages_container_booking_confirm_header p {
        font-size: 16px;
        line-height: 100%;
        padding: 0px;
        margin: 0px;
    }

    .clt_messages_container_booking_confirm_amount {
        margin-top: -4px !important;
        font-size: 40px;
        margin-bottom: 10px !important;
        font-weight: 600;
        margin: 0;
    }

    .clt_inbox_checkmark {
        display: inline-block;
        width: 18px;
        height: 18px;
        -ms-transform: rotate(45deg);
        /* IE 9 */
        -webkit-transform: rotate(45deg);
        /* Chrome, Safari, Opera */
        transform: rotate(45deg);
    }

    .clt_inbox_checkmark_circle {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: green;
        border-radius: 11px;
        left: 0;
        top: 0;
    }

    .clt_inbox_checkmark_stem {
        position: absolute;
        background-color: #fff;
        width: 1.5px;
        height: 7.5px;
        left: 8px;
        top: 3px;
    }

    .clt_inbox_checkmark_kick {
        position: absolute;
        background-color: #fff;
        width: 3.5px;
        height: 2px;
        left: 4.5px;
        top: 9px;
    }

    .clt_messages_container_detail_icon {
        min-width: 26px;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        color: #fff;
        font-size: 13px;
    }

    .clt_messages_container_detail img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .clt_messages_container_detail_contact_request img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
    }

    .clt_messages_container_wrapper_date {
        font-size: 13px;
    }

    .clt_messages_container_detail_icon_header {
        width: 37.5px;
        height: 36.5px;
        font-size: 15px !important;
    }

    .clt_message_textarea .form-control:focus, .clt_message_textarea textarea, .clt_message_textarea textarea:hover {
        padding: 4px 10px 4px 4px !important;
        font-size: 15px !important;
        margin: 4px 4px 0 0;
    }

    .clt_messages_container_request_message_header h5 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .clt_message_navbarRightTxt span {
        font-size: 14px;
        border-radius: 50px;
        padding-top: 6px;
        padding-right: 16px;
        padding-bottom: 8px;
        padding-left: 16px;
    }

    .clt_message_input_component_tag {
        padding: 5px 12px;
        gap: 4px;
    }

    .clt_message_input_component_tag_image {
        width: 15px;
        height: 15px;
    }

    .clt_messages_container_request_state h2 {
        font-size: 16px;
    }

    .clt_slider_edit_request_container_request_state h2 {
        font-size: 16px;
    }

    .clt_messages_container_accountSubDetail {
        width: 100%;
    }

    .clt_message_input_component_inner3 {
        margin-top: 0px;
        margin-right: 28px;
        margin-bottom: 32px;
        margin-left: 28px;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_messages_container_request_message {
        width: 100%;
    }

    .clt_messages_container_booking_confirm {
        padding-top: 14px;
        padding-right: 18px;
        padding-bottom: 17px;
        padding-left: 18px;
        border-radius: 18px;
        width: 100%;
    }

    .clt_messages_container_booking_confirm_header .back-button img {
        width: 8px !important;
        height: 14px !important;
        margin-bottom: 9px !important;
    }

    .clt_messages_container_booking_confirm_tag_text {
        font-size: 15px;
        color: #222222;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .clt_messages_container_contact_message {
        width: 100%;
    }

    .clt_message_accountSubDetail {
        grid-gap: 3px;
        gap: 2.82px;
    }

    .clt_messages_container_message_detail h2 {
        font-size: 16px;
    }

    .clt_message_input_component_tag p {
        font-size: 14.5px;
    }

    .clt_message_input_component_tag_wrapper {
        margin-left: 24px;
        margin-top: 10px;
        gap: 4px;
    }

    .clt_message_accountDetail {
        gap: 14px;
        grid-gap: 14px;
    }

    .clt_message_accountDetail_wrapper {
        height: 84px;
        padding: 2.8px 24px 5.8px;
    }

    .clt_message_accountDetail img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .clt_message_accountSubDetail h2 {
        font-size: 16px;
    }

    .clt_message_accountSubDetail p {
        font-size: 14px;
        line-height: 100%;
        color: #5b5b5b;
    }

    .clt_messages_container_message_text {
        font-size: 16px;
        line-height: 21px;
        text-align: left;
    }

    .clt_messages_container_contact_message_type p {
        font-size: 16px;
    }

    .clt_messages_container_contact_message_type {
        padding-top: 14px;
        padding-right: 13px;
        padding-bottom: 20px;
        padding-left: 13px;
    }

    .clt_messages_container_message_detail p {
        font-size: 13px;
        margin-top: 0px;
    }

    .clt_messages_container_wrapper_text {
        margin-top: 4px;
        margin-right: 14px;
        margin-bottom: 14px;
        margin-left: 14px;
    }

    .clt_messages_container_booking_confirm_header h5 {
        font-size: 16px;
        margin-bottom: 3px;
        font-weight: 600;
    }

    .clt_messages_container_booking_confirm_header p {
        font-size: 16px;
        line-height: 100%;
        padding: 0px;
        margin: 0px;
    }

    .clt_messages_container_booking_confirm_amount {
        margin-top: -4px !important;
        font-size: 40px;
        margin-bottom: 10px !important;
        font-weight: 600;
        margin: 0;
    }

    .clt_inbox_checkmark {
        display: inline-block;
        width: 18px;
        height: 18px;
        -ms-transform: rotate(45deg);
        /* IE 9 */
        -webkit-transform: rotate(45deg);
        /* Chrome, Safari, Opera */
        transform: rotate(45deg);
    }

    .clt_inbox_checkmark_circle {
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: green;
        border-radius: 11px;
        left: 0;
        top: 0;
    }

    .clt_inbox_checkmark_stem {
        position: absolute;
        background-color: #fff;
        width: 1.5px;
        height: 7.5px;
        left: 8px;
        top: 3px;
    }

    .clt_inbox_checkmark_kick {
        position: absolute;
        background-color: #fff;
        width: 3.5px;
        height: 2px;
        left: 4.5px;
        top: 9px;
    }

    .clt_messages_container_detail_icon {
        min-width: 26px;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        color: #fff;
        font-size: 13px;
    }

    .clt_messages_container_detail img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .clt_messages_container_detail_contact_request img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
    }

    .clt_messages_container_wrapper_date {
        font-size: 13px;
    }

    .clt_messages_container_detail_icon_header {
        width: 37.5px;
        height: 36.5px;
        font-size: 15px !important;
    }

    .clt_message_textarea .form-control:focus, .clt_message_textarea textarea, .clt_message_textarea textarea:hover {
        padding: 4px 10px 4px 4px !important;
        font-size: 15px !important;
        margin: 4px 4px 0 0;
    }

    .clt_messages_container_request_message_header h5 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .clt_message_navbarRightTxt span {
        font-size: 14px;
        border-radius: 50px;
        padding-top: 6px;
        padding-right: 16px;
        padding-bottom: 8px;
        padding-left: 16px;
    }

    .clt_message_input_component_tag {
        padding: 5px 12px;
        gap: 4px;
    }

    .clt_message_input_component_tag_image {
        width: 15px;
        height: 15px;
    }

    .clt_messages_container_request_state h2 {
        font-size: 16px;
    }

    .clt_slider_edit_request_container_request_state h2 {
        font-size: 16px;
    }

    .clt_messages_container_accountSubDetail {
        width: 100%;
    }

    .clt_message_input_component_inner3 {
        margin-top: 0px;
        margin-right: 28px;
        margin-bottom: 32px;
        margin-left: 28px;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_message_input_component_tag {
        background-color: #eee;
        border-radius: 50px;
        gap: 4px;
        padding-top: 5px;
        padding-right: 13px;
        padding-bottom: 9px;
        padding-left: 13px;
    }

    .clt_message_input_component_tag p {
        font-size: 14px;
        margin: 0;
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 2px;
    }

    .clt_message_input_component_tag_wrapper {
        gap: 12px;
        margin-left: 20px;
        padding-top: 5px;
    }

    .clt_messages_container_request_message {
        border-radius: 12px;
        width: 46%;
    }

    .clt_message_accountSubDetail h2 {
        font-size: 14px;
        color: #242424;
        font-weight: 600;
    }

    .clt_message_accountSubDetail p {
        font-size: 13.5px;
        line-height: 100%;
        color: #5b5b5b;
    }

    .clt_message_accountSubDetail {
        grid-gap: 3px;
        gap: 2px;
    }

    .clt_messages_container_message_detail h2 {
        font-size: 14px;
        letter-spacing: -.2px;
    }

    .clt_messages_container_message_detail p {
        font-size: 12px;
        margin-top: 0px;
    }

    .clt_messages_container_message_text {
        font-size: 14px;
        line-height: 19px;
    }

    .clt_messages_container_request_message_header h5 {
        font-size: 19px;
        width: 100%;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .clt_message_textarea .form-control:focus, .clt_message_textarea textarea, .clt_message_textarea textarea:hover {
        font-size: 14px !important;
    }

    .clt_messages_container_contact_message_type p {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0 !important;
    }

    .clt_messages_container_booking_confirm_header h5 {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .clt_messages_container_booking_confirm_header p {
        font-size: 15px;
    }

    .clt_messages_container_booking_confirm {
        padding: 16px 18px 12px;
        border-radius: 18px;
        width: 32%;
        margin: 10px 0;
    }

    .clt_messages_container_wrapper_date {
        padding: 0px;
        font-size: 12px;
    }

    .clt_messages_container_detail_icon_header {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #000;
        color: #fff;
        font-size: 15px;
    }

    .clt_messages_container_detail_icon {
        min-width: 22px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        font-size: 13px;
        padding-bottom: 0px;
        padding-right: 1px;
    }

    .clt_messages_container_detail img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    .clt_messages_container_detail_contact_request img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .clt_message_accountDetail img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }

    .clt_messages_container_booking_confirm_amount {
        margin-top: -4px !important;
        font-size: 38px;
        color: #242424;
        margin-bottom: 8px !important;
        font-weight: 600;
        margin: 0;
    }

    .clt_messages_container_booking_confirm_tag_text {
        font-size: 14px;
        color: #242424;
        font-weight: 500;
        margin-bottom: 6px;
    }

    .clt_inbox_checkmark_kick {
        position: absolute;
        background-color: #fff;
        width: 4.5px;
        height: 2px;
        left: 3.5px;
        top: 8px;
    }

    .clt_messages_container_booking_confirm_header .back-button img {
        width: 10px !important;
        height: 14px !important;
        margin-bottom: 9px !important;
    }

    .clt_messages_container_contact_message {
        border-radius: 12px;
        margin: 10px 0;
        width: 46%;
    }

    .clt_messages_container_contact_message_type {
        padding-top: 10px;
        padding-right: 13px;
        padding-bottom: 15px;
        padding-left: 13px;
    }

    .clt_message_navbarRightTxt span {
        font-size: 14px;
    }

    .clt_messages_container_wrapper_date_p {
        padding-top: 3px;
        padding-right: 8px;
        padding-bottom: 4px;
        padding-left: 8px;
    }

    .clt_message_input_component_tag_image {
        width: 15px;
        height: 15px;
    }

    .clt_message_accountDetail_wrapper .information span {
        padding-bottom: 9px !important;
    }

    .clt_messages_container_request_state h2 {
        font-size: 15px;
    }

    .clt_slider_edit_request_container_request_state h2 {
        font-size: 15px;
    }

    .clt_message_textarea_images_wrapper_del {
        right: -11px;
        top: -8px;
        padding: 0px 8px 2px 8px;
    }
    
    .clt_message_textarea_images_wrapper_del_icon {
        width: 9px;
        height: 9px;
    }

    .clt_messages_container_images_container {
        grid-gap: 7px;
        gap: 8px;
        flex-wrap: wrap;
        margin-top: 4px;
        margin-right: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
    }
    
    .clt_messages_container_wrapper_text {
        margin: 14px;
        display: block;
    }
    

}