.clt_admin_dash_booking_speeddial_container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.clt_admin_dash_booking_speeddial_container .speeddial-btn {
    align-items: center;
    background-color: #000;
    border: none;
    border-radius: 18px;
    bottom: 32.2px;
    color: #fff;
    display: flex;
    gap: 4px;
    justify-content: center;
    min-height: 58px;
    min-width: 63px;
    outline: none;
    overflow: hidden;
    /* padding: 0 10px; */
    padding-right: 10px;
    padding-bottom: 3px;
    padding-left: 10px;
    position: fixed;
    right: 17%;
    white-space: nowrap;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.72) !important;
    z-index: 5;
}

.speeddial-btn:hover {
    background-color: #2d2d2d !important;
}

.speeddial-btn:active {
    background-color: #5c5c5c !important;
}

.speeddial-btn span:first-child {
    font-size: 34px;
    margin-bottom: 8px;
    margin-left: 3px;
}

.speeddial-btn span:last-child {
    transition: width 0.6s;
    font-size: 16.5px;
    font-weight: 500;
    overflow: hidden;
    margin-bottom: 3px;
}

.pagination {
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .border-bottom {
        height: 70px;
    }

    .clt_admin_dash_booking_speeddial_container .speeddial-btn {
        border-radius: 18px;
        gap: 0px;
        min-height: 50px;
        min-width: 62px;
        padding-right: 13px;
        padding-bottom: 3px;
        padding-left: 10px;
        bottom: 75px;
        right: 24px;
    }

    .speeddial-btn span:last-child {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .border-bottom {
        height: 70px;
    }

    .clt_admin_dash_booking_speeddial_container .speeddial-btn {
        border-radius: 18px;
        gap: 0px;
        min-height: 50px;
        min-width: 62px;
        padding-right: 13px;
        padding-bottom: 3px;
        padding-left: 10px;
        bottom: 75px;
        right: 24px;
    }

    .speeddial-btn span:last-child {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .border-bottom {
        height: 70px;
    }

    .clt_admin_dash_booking_speeddial_container .speeddial-btn {
        border-radius: 18px;
        gap: 0px;
        min-height: 50px;
        min-width: 62px;
        padding-right: 13px;
        padding-bottom: 3px;
        padding-left: 10px;
        bottom: 75px;
        right: 24px;
    }

    .speeddial-btn span:last-child {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_admin_dash_booking_speeddial_container .speeddial-btn {
        background-color: #000;
        border-radius: 16px;
        bottom: 34px;
        color: #fff;
        gap: 5px;
        min-height: 71px;
        min-width: 73px;
        padding-right: 10px;
        padding-bottom: 3px;
        padding-left: 10px;
        right: 17%;
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.72) !important;
    }

    .speeddial-btn span:first-child {
        font-size: 32px;
        margin-bottom: 7px;
        margin-left: 10px;
    }

    .speeddial-btn span:last-child {
        transition: width 0.5s;
        font-size: 17px;
        font-weight: 500;
        overflow: hidden;
        margin-bottom: 2px;
        margin-right: 7px;
    }


}