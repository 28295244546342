.clt-helpcenter-fade-in-bottom {
  animation: fade-in-bottom 0.8s;
}

.clt_help_article_text_section_p {
  margin-bottom: 0;
}

.clt_help_article_text_section_p p {
  margin-bottom: 0;
  font-family: 'Inter' !important;
}
@keyframes clt-helpcenter-fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.container.py-4 {
  padding-top: 24px !important;
  padding-right: 200px;
  padding-bottom: 0px !important;
  padding-left: 200px;
}

.clt-helpcenter-title-1 {
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 5px;
  color: #242424;
  font-weight: 600;
}

.clt-helpcenter-min-h-100 {
  min-height: calc(100vh - 75px);
}
.clt-helpcenter-searchBar {
  background-color: #eee;
  padding: 9px 15px;
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}
.clt-helpcenter-searchBar > input {
  border: 0;
  background-color: transparent;
  padding-top: 0px;
  padding-right: 15px;
  padding-bottom: 3px;
  padding-left: 15px;
  height: 32px;
  font-size: 14px;
}
.clt-helpcenter-searchBar > input:focus {
  border: 0;
  background-color: transparent;
  outline: 0;
}
.clt-helpcenter-searchBar > i {
  color: gray;
}
.clt-helpcenter-searchBar > .myDataList {
  display: none;
  top: 120%;
  left: 0%;
  position: absolute;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 8px;
  max-height: 300px;
  overflow: auto;
  background-color: white;
}
.clt-helpcenter-searchBar > input:focus + .myDataList,
.clt-helpcenter-searchBar > .myDataList:hover {
  display: unset;
}
.clt-helpcenter-searchBar > .myDataList:active {
  display: none;
}
.clt-helpcenter-searchBar > .myDataList > .myItem {
  display: flex;
  align-items: center;
}
.clt-helpcenter-searchBar > .myDataList > .myItem:hover {
  background-color: rgb(240, 240, 240);
}
.clt-helpcenter-searchBar > .myDataList > .myItem > i {
  margin: 15px;
  background-color: rgb(240, 240, 240);
  border-radius: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}
.clt-helpcenter-searchBar > .myDataList > .myItem > .listText {
  flex: 1;
  padding: 20px 0;
  border-bottom: 1px solid lightgrey;
}
.clt-helpcenter-searchBar > .myDataList > .myItem > .listText > .txt1 {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -2.5px;
}
.clt-helpcenter-searchBar > .myDataList > .myItem > .listText > .txt2 {
  font-size: 14px;
  color: grey;
}
.clt-helpcenter-tabBar {
  border-bottom: 1px solid lightgray;
  margin-top: 15px;
  margin-bottom: 25px;
}
.clt-helpcenter-tabBar > .nav-item > .btn {
  border-bottom: 3px solid #1a73e8;
  border-radius: 0;
  padding: 11px 0;
  border-bottom: 3px solid transparent;
  font-weight: 600;
  font-size: 14px;
  color: gray;
  margin-right: 20px;
  border-color: #fff;
}
.clt-helpcenter-tabBar > .nav-item > .btn.active {
  border-bottom: 3px solid #1a73e8;
  color: #1a73e8;
  border-left-color: #fff;
  border-top-color: #fff;
  border-right-color: #fff;
}
.clt-helpcenter-pathList {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 14px;
  overflow: hidden;
}
.clt-helpcenter-pathList > .myItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  border-bottom: 1px solid rgb(230, 230, 230);
  margin-bottom: -1px;
  cursor: pointer;
  text-decoration: none;
  color: #242424;
}
.clt-helpcenter-pathList > .myItem:hover {
  background-color: rgb(240, 240, 240);
}
.clt-helpcenter-pathList > .myItem.disabled {
  background-color: transparent;
  color: lightgrey;
}

.clt-helpcenter-spliter {
  border-bottom: 1px solid rgb(230, 230, 230);
}

.clt-helpcenter-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  padding-left: 24px !important;
  padding-bottom: 24px !important;
}

.clt-helpcenter-rounded-pill {
  border-radius: 50rem !important;
}

.clt-helpcenter-btn-light {
  color: #242424;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.clt-helpcenter-right-arrow {
  width: 6px;
}

.clt-helpcenter-h3 {
  font-size: calc(1.3rem + 0.6vw);
  margin-block-start: 1em;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.clt-helpcenter-h4 {
  font-size: 22px;
  font-weight: 600;
}

.clt-helpcenter-h4-c {
  font-size: 17px;
  letter-spacing: 0px;
  font-weight: 500;
  font-family: 'Inter';
  line-height: 28px;
  color: #686868;
  margin-top: 11px;
  margin-right: 0px;
  margin-bottom: -4px;
  margin-left: 0px;
  padding-top: 6px;
  padding-bottom: 6px !important;
  border-top: 0px solid #dedddd;
}
.clt-helpcenter-h5 {
  font-size: 22px;
  font-weight: 600;
}

.clt-helpcenter-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #242424;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #eee;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.clt-helpcenter-text-dark {
  --bs-text-opacity: 1;
  color: #242424 !important;
  font-size: 15px;
  font-weight: 400;
}

.clt_help_article_offcanvas_body {
  padding-top: 0px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}

.clt_help_article_chip {
  font-size: 14px !important;
  font-family: inter !important;
  background-color: #ecf3fe !important;
  font-weight: 400;
  letter-spacing: 0.4px;
  border-radius: 12px !important;
  padding-bottom: 2px !important;
  border: 0px solid #fff !important;
  height: 30px !important;
  margin-bottom: 16px;
  margin-top: 16px;
}

.clt_help_article_text_section {
  margin-bottom: 0px !important;
  font-family: "Inspiration", cursive;
  
}

.clt-helpcenter-section-contact {
  display: flex;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-left: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 12px;
}

@media only screen and (max-width: 600px) {
  /*mobile*/
  .clt-helpcenter-h4 {
    font-size: 22px;
    margin-bottom: 4px;
  }

  .clt-helpcenter-pathList {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 14px;
    overflow: hidden;
    margin-top: 8px !important;
}

  .clt-helpcenter-h4-c {
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 28px;
    color: #686868;
    margin-top: 11px;
    margin-right: 0px;
    margin-bottom: -4px;
    margin-left: 0px;
    padding-top: 6px;
    padding-bottom: 6px !important;
    border-top: 0px solid #dedddd;
  }

  .clt-helpcenter-h5 {
    font-size: 22px;
  }

  .clt-helpcenter-text-dark {
    font-size: 16px;
  }

  .clt-helpcenter-tabBar > .nav-item > .btn {
    padding-top: 12px;
    padding-right: 0px;
    padding-bottom: 16px;
    padding-left: 0px;
    font-size: 16px;
  }

  .clt-helpcenter-title-1 {
    font-size: 32px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #242424;
    font-weight: 600;
  }

  .clt-helpcenter-pathList > .myItem {
    font-size: 16px;
    padding-top: 22px;
    padding-right: 20px;
    padding-bottom: 26px;
    padding-left: 20px;
  }

  .container.py-4 {
    padding-top: 24px !important;
    padding-right: 24px;
    padding-bottom: 0px !important;
    padding-left: 24px;
  }

  .clt-helpcenter-tabBar {
    border-bottom: 1px solid lightgray;
    margin-top: 14px;
    margin-bottom: 30px;
  }

  .clt-helpcenter-right-arrow {
    width: 8px;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  /*mobile*/
  .clt-helpcenter-h4 {
    font-size: 22px;
    margin-bottom: 4px;
  }
  .clt-helpcenter-pathList {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 14px;
    overflow: hidden;
    margin-top: 8px !important;
}

  .clt-helpcenter-h4-c {
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 28px;
    color: #686868;
    margin-top: 11px;
    margin-right: 0px;
    margin-bottom: -4px;
    margin-left: 0px;
    padding-top: 6px;
    padding-bottom: 6px !important;
    border-top: 0px solid #dedddd;
  }

  .clt-helpcenter-h5 {
    font-size: 22px;
  }

  .clt-helpcenter-text-dark {
    font-size: 17px;
  }

  .clt-helpcenter-tabBar > .nav-item > .btn {
    padding-top: 12px;
    padding-right: 0px;
    padding-bottom: 16px;
    padding-left: 0px;
    font-size: 16px;
  }

  .clt-helpcenter-title-1 {
    font-size: 32px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #242424;
    font-weight: 600;
  }

  .clt-helpcenter-pathList > .myItem {
    font-size: 16px;
    padding-top: 22px;
    padding-right: 20px;
    padding-bottom: 26px;
    padding-left: 20px;
  }

  .container.py-4 {
    padding-top: 24px !important;
    padding-right: 24px;
    padding-bottom: 0px !important;
    padding-left: 24px;
  }

  .clt-helpcenter-tabBar {
    border-bottom: 1px solid lightgray;
    margin-top: 14px;
    margin-bottom: 30px;
  }

  .clt-helpcenter-right-arrow {
    width: 8px;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  /*mobile*/
  .clt-helpcenter-h4 {
    font-size: 22px;
    margin-bottom: 4px;
  }

  .clt-helpcenter-pathList {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 14px;
    overflow: hidden;
    margin-top: 8px !important;
}

  .clt-helpcenter-h4-c {
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 28px;
    color: #686868;
    margin-top: 11px;
    margin-right: 0px;
    margin-bottom: -4px;
    margin-left: 0px;
    padding-top: 6px;
    padding-bottom: 6px !important;
    border-top: 0px solid #dedddd;
  }

  .clt-helpcenter-h5 {
    font-size: 22px;
  }

  .clt-helpcenter-text-dark {
    font-size: 17px;
  }

  .clt-helpcenter-tabBar > .nav-item > .btn {
    padding-top: 12px;
    padding-right: 0px;
    padding-bottom: 16px;
    padding-left: 0px;
    font-size: 16px;
  }

  .clt-helpcenter-title-1 {
    font-size: 32px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: #242424;
    font-weight: 600;
  }

  .clt-helpcenter-pathList > .myItem {
    font-size: 16px;
    padding-top: 22px;
    padding-right: 20px;
    padding-bottom: 26px;
    padding-left: 20px;
  }

  .container.py-4 {
    padding-top: 24px !important;
    padding-right: 24px;
    padding-bottom: 0px !important;
    padding-left: 24px;
  }

  .clt-helpcenter-tabBar {
    border-bottom: 1px solid lightgray;
    margin-top: 14px;
    margin-bottom: 30px;
  }

  .clt-helpcenter-right-arrow {
    width: 8px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  /*ipad*/
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  /*ipad*/
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation: portrait) {
  /*ipad pro*/
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation: landscape) {
  /*ipad pro*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation: portrait) {
  /*ipad pro*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation: landscape) {
  /*ipad pro*/
}

@media only screen and (min-width: 1600px) {
  /*large desktop*/
  .clt-helpcenter-h4 {
    font-size: 22px;
  }

  .clt-helpcenter-h5 {
    font-size: 22px;
  }

  .clt-helpcenter-text-dark {
    font-size: 17px;
  }

  .clt-helpcenter-tabBar > .nav-item > .btn {
    font-size: 16px;
    padding-top: 12px;
    padding-right: 0px;
    padding-bottom: 14px;
    padding-left: 0px;
  }

  .clt-helpcenter-pathList > .myItem {
    font-size: 15px;
  }
}
