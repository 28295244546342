.clt-price-simulator-section-1 {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 200px;
    background-color: #242424;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 6px;
    margin-left: 0px;
    padding: 20px 20px;
}

/* src/components/Overlay/Overlay.css */

/* src/components/Overlay/priceSimulatorModel.css */
/* src/components/Overlay/priceSimulatorModel.css */

/* src/components/Overlay/priceSimulatorModel.css */

/* src/components/Overlay/priceSimulatorModel.css */

/* src/components/Overlay/priceSimulatorModel.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.82);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    z-index: 1040;
    transition: opacity 0.3s ease-in-out;
    opacity: 1; /* Start with full opacity */
  }

  .clt_icon_play_pause {
    margin-left: 40px;
  }
  .clt_icon_counter_slider {
    color: #fff;
    margin-left: 45px;
    margin-right: 45px;
  }
  .overlay.hidden {
    opacity: 0;
    pointer-events: none;
  }

  
  
  .overlay.visible {
    opacity: 1;
    transition-delay: 0.3s; /* Add a delay for the initial appearance */
  }
  .overlay-box {
    border-radius: 24px; /* Remove border-radius to make the box square */
    text-align: center;
    align-items: center;
    z-index: 1040;
    justify-content: center;
    display: flex;
    width: 100%; /* Take the full width of the screen */
    height: 100%; /* Take the full height of the screen */
    box-sizing: border-box; /* Include padding and border in the box's total width and height */
    position: fixed; /* Fixed position to cover the entire screen */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.00), 0 4px 12px rgba(0, 0, 0, 0.00) !important;
  }
  .overlay-img {
    width: 45%;
    height: 50%;
    object-fit: cover;
    border-radius: 18px; /* Or adjust the border-radius as needed */
  }
  
  
  
  
  
  

.clt-price-simulator-section-1 h2 {
    margin-top: -9px;
    margin-bottom: 4px;
    font-size: 32px;
    letter-spacing: -0.2px;
    font-weight: 700;
    font-family: Mulish;
    color: #fff;
}

.clt-price-simulator-section-2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 6px;
    margin-right: 0px;
    margin-bottom: 24px;
    margin-left: 0px;
    padding: 24px 24px;
}

.border_light {
    border: 1px solid #e0e0e0;
}

.clt_price_simulator_detail_price {
    font-weight: 600;
    font-size: 42px;
    font-family: 'Inter';
}

.clt_price_simulator_detail_price2 {
    font-weight: 400;
    font-size: 15px;
    padding-left: 4px;
    padding-top: 26px;
    font-family: 'Inter';
}

.clt_price_simulator_detail_price_div{
    flex-direction: row;
    display: flex;
    align-self: center;
}


@media only screen and (max-width: 600px) {
    .clt-price-simulator-section-1 {
        height: 200px;
        border-radius: 20px;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 6px;
        margin-left: 0px;
        padding: 20px 20px;
    }

    .clt-price-simulator-section-1 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 36px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-price-simulator-section-2 {
        border-radius: 20px;
        margin-top: 6px;
        margin-right: 0px;
        margin-bottom: 24px;
        margin-left: 0px;
        padding: 24px 24px;
    }

    .overlay-box {
        width: 89%;
        height: 88%;
    }
    .overlay-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 18px;
    }
}


@media only screen and (max-width: 767px) and (orientation:portrait) {

    .clt-price-simulator-section-1 {
        height: 200px;
        border-radius: 20px;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 6px;
        margin-left: 0px;
        padding: 24px 24px;
    }

    .clt-price-simulator-section-1 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 36px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-price-simulator-section-2 {
        border-radius: 20px;
        margin-top: 6px;
        margin-right: 0px;
        margin-bottom: 24px;
        margin-left: 0px;
        padding: 24px 24px;
    }

    .overlay-box {
        width: 89%;
        height: 88%;
    }
    .overlay-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 18px;
    }
}


@media only screen and (max-width: 767px) and (orientation:landscape) {
    .clt-price-simulator-section-1 {
        height: 200px;
        border-radius: 20px;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 6px;
        margin-left: 0px;
        padding: 20px 20px;
    }
    .overlay-box {
        width: 89%;
        height: 88%;
    }

    .overlay-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 18px;
    }
    .clt-price-simulator-section-1 h2 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 30px;
        letter-spacing: -0.2px;
        font-weight: 700;
    }

    .clt-price-simulator-section-2 {
        border-radius: 20px;
        margin-top: 6px;
        margin-right: 0px;
        margin-bottom: 24px;
        margin-left: 0px;
        padding: 20px 20px;
    }
}