.clt_inbox_container  {
    height: calc(100vh - 70px);
}

.clt_inbox_container_list {
    overflow-y: scroll;
    height: calc(100% - 86px);
}

.clt_inbox_container_searchbar {
    width: 100%;
    padding-top: 16px;
    padding-right: 43px;
    padding-bottom: 18px;
    padding-left: 22px;

}

.clt_inbox_container_detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 16px;
    padding-right: 20px;
    padding-bottom: 16px;
    padding-left: 22px;
    border-top: 1px solid #dedddd;
    width: 100%;
    cursor: pointer;
}

.clt_inbox_container_detail_admin {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 16px;
    padding-right: 20px;
    padding-bottom: 16px;
    padding-left: 22px;
    border-top: 1px solid #dedddd;
    width: 100%;
    cursor: pointer;
}

.clt_inbox_container_detail:hover, .clt_inbox_container_list .selected-inbox {
    background-color: #f6f6f6;
}

.clt_inbox_container_list .selected-inbox {
    background-color: #f6f6f6;
}

.clt_inbox_container_detail.unread-inbox {
    background-color: #ffffff;
}

.clt_inbox_searchbar_h2 {
    font-size: 0px;
    font-weight: 600;
    color: #242424;
    padding: 0;
    margin: 0 0 0px;
}

.clt_inbox_container_detail img {
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.clt_inbox_container_accountSubDetail {
    width: calc(100% - 40px);
}

.clt_inbox_container_accountSubDetail_acc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    gap: 3px;
}

.clt_inbox_container_accountSubDetail_acc h2 {
    font-size: 12px;
    color: #4c4c4c;
    font-family: "Inter";
    font-weight: 400;
    margin: 0;
    letter-spacing: -.2px;
    text-align: left;
}

.clt_inbox_container_accountSubDetail_acc p {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
    margin-bottom: 2.5px;
    letter-spacing: -0.2px;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    color: #4c4c4c;
}

.clt_inbox_container_text_message {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    color: #4c4c4c;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    margin-bottom: 6px;
}

.clt_inbox_container_contact_list {
    width: 100%;
}

.clt_inbox_container_contact_list p {
    font-size: 11px;
    line-height: 1.36;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: "";
    font-weight: 400;
    color: #242424;
}

.contact_request {
    color: #4c4c4c !important
}

.clt_inbox_container_contact_list .eventDetail {
    padding-top: 6px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    border-radius: 12px;
}

.clt_inbox_container_contact_list .booking {
    background-color: #fff3ec;
}

.clt_inbox_container_contact_list .booking span {
    color: #f99153;
}

.clt_inbox_container_contact_list .information {
    background-color: #169b0a12;
}

.clt_inbox_container_contact_list .information span {
    color: #048848;
}

.clt_inbox_container_contact_list .eventDetail_user {
    padding-top: 6px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    border-radius: 12px;
}

.clt_inbox_container_contact_list .booking_user {
    background-color: #eaf0f9;
}

.overflow-wrap-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
}

.overflow-wrap {
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: break-word;
}

.clt_inbox_container_contact_list .eventDetail_user span {
    color: #298efb;
}

.boldMsg {
    font-weight: 600 !important;
}

.readMsg {
    font-weight: 400 !important;
}

.crossedoutMsg {
    color: #525252 !important;
    text-decoration: line-through;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_inbox_searchbar_h2 {
        font-size: 25px;
        font-weight: 600;
        padding: 0;
        margin: 0 0 0px;
    }

    .clt_inbox_container_accountSubDetail_acc h2 {
        font-size: 16px;
    }

    .clt_inbox_container_text_message {
        font-size: 16px;
    }

    .clt_inbox_container_contact_list p {
        font-size: 15px;
    }

    .clt_inbox_container_accountSubDetail_acc p {
        font-size: 15px;
    }

    .clt_inbox_container_detail {
        padding-top: 20px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
    }

    .clt_inbox_container_detail_admin {
        padding-top: 20px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
    }

    .clt_inbox_container_searchbar {
        width: 100%;
        padding-top: 8px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 24px;
    }

    .clt_inbox_container_contact_list .eventDetail {
        padding-top: 6px;
        padding-right: 10px;
        padding-bottom: 8px;
        padding-left: 10px;
        border-radius: 12px;
    }

    .clt_inbox_container_detail img {
        min-width: 26px;
        width: 26px;
        height: 26px;
        border-radius: 100%;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_inbox_searchbar_h2 {
        font-size: 25px;
        font-weight: 600;
        padding: 0;
        margin: 0 0 0px;
    }

    .clt_inbox_container_accountSubDetail_acc h2 {
        font-size: 16px;
    }

    .clt_inbox_container_text_message {
        font-size: 16px;
    }

    .clt_inbox_container_contact_list p {
        font-size: 15px;
    }

    .clt_inbox_container_accountSubDetail_acc p {
        font-size: 15px;
    }

    .clt_inbox_container_detail {
        padding-top: 20px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
    }

    .clt_inbox_container_detail_admin {
        padding-top: 20px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
    }

    .clt_inbox_container_searchbar {
        padding-top: 8px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 24px;
    }

    .clt_inbox_container_contact_list .eventDetail {
        padding-top: 6px;
        padding-right: 10px;
        padding-bottom: 8px;
        padding-left: 10px;
        border-radius: 12px;
    }

    .clt_inbox_container_detail img {
        min-width: 26px;
        width: 26px;
        height: 26px;
        border-radius: 100%;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_inbox_searchbar_h2 {
        font-size: 25px;
        font-weight: 600;
        padding: 0;
        margin: 0 0 0px;
    }
    
    .clt_inbox_container_accountSubDetail_acc h2 {
        font-size: 16px;
    }

    .clt_inbox_container_text_message {
        font-size: 16px;
    }

    .clt_inbox_container_contact_list p {
        font-size: 15px;
    }

    .clt_inbox_container_accountSubDetail_acc p {
        font-size: 15px;
    }

    .clt_inbox_container_detail {
        padding-top: 20px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
    }

    .clt_inbox_container_detail_admin {
        padding-top: 20px;
        padding-right: 24px;
        padding-bottom: 22px;
        padding-left: 24px;
    }

    .clt_inbox_container_searchbar {
        padding-top: 8px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 24px;
    }

    .clt_inbox_container_contact_list .eventDetail {
        padding-top: 6px;
        padding-right: 10px;
        padding-bottom: 8px;
        padding-left: 10px;
        border-radius: 12px;
    }

    .clt_inbox_container_detail img {
        min-width: 26px;
        width: 26px;
        height: 26px;
        border-radius: 100%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_inbox_container_accountSubDetail_acc h2 {
        font-size: 14px;
    }
    
    .clt_inbox_container_accountSubDetail_acc p {
        font-size: 14px;
    }

    .clt_inbox_container_searchbar {
        width: 100%;
        padding-top: 22px;
        padding-right: 43px;
        padding-bottom: 24px;
        padding-left: 22px;
    }

    .clt_inbox_container_text_message {
        font-size: 14px;
    }

    .clt_inbox_container_contact_list p {
        font-size: 12.3px;
        line-height: 1.40;
    }

    .clt_inbox_container_detail img {
        min-width: 22px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .clt_inbox_container_detail {
        grid-gap: 10px;
        gap: 10px;
        padding: 16px 24px;
    }

    .clt_inbox_container_detail_admin {
        gap: 10px;
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        padding-left: 24px;
    }
    
}