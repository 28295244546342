.clt_mybooking_offcanvas {
    width: 385px !important;
    z-index: 1055;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.clt_mybooking_offcanvas_header {
    padding-top: 14px;
    padding-right: 24px;
    padding-bottom: 17px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_mybooking_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_mybooking_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_mybooking_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_mybooking_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_mybooking_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_mybooking_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}


.clt_mybooking_offcanvas_body {
    padding: 24px;
}

.clt_mybooking_offcanvas_body2 {
    padding: 0px;
    

}



.clt_mybooking_offcanvas_screen1_div {}

.clt_mybooking_offcanvas_screen1_div h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt_mybooking_offcanvas_screen1_div p {
    font-size: 16px;
    color: #5b5b5b;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 14px;
    margin-top: 7px;
    letter-spacing: -0.2px;
}

.clt_mybooking_offcanvas_screen1_div button {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-bottom: 10px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 0px solid #fff;
    width: 170px;
    height: 48px;
}

.clt_mybooking_offcanvas_screen1_div button:hover {
    color: #242424 !important;
    background: #d9dce0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-bottom: 10px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 0px solid #fff;
    width: 100px;
    height: 48px;
}

.clt_mybooking_offcanvas_screen1_div button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-bottom: 10px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 0px solid #fff;
    width: 100px;
    height: 48px;
}



.clt_mybooking_RightTxt {
    width: 150px;
}

.clt_mybooking_RightTxt span {
    text-decoration: none;
    font-size: 13px;
    margin-left: 68px !important;
    color: #242424;
    font-family: "Inter";
    padding: 6px 10px 10px;
    font-weight: 500 !important;
    margin-bottom: -8px;
    margin-top: -8px;
}

.clt_mybooking_box {
    width: 100%;
}

.clt_mybooking_bookingImage {
    background-color: #242424;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    position: relative;
}

.clt_mybooking_bookingImage img {
    height: 100px;
}

.clt_mybooking_bookingImage .text {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: white;
    color: black;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 5px;
}

.clt_mybooking_title_category {
    font-size: 18px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 20px;
    letter-spacing: -.2px;
}

.clt_mybooking_detail {
    margin-top: 6px;
    margin-bottom: 35px;
}

.clt_mybooking_detail .detail_text {
    padding: 0;
    margin: 0px;
    margin-top: 1px;
}

.clt_mybooking_detail .title {
    font-size: 14px;
    font-weight: 500;
}

.clt_mybooking_detail .subtitle {
    font-size: 14px;
    font-weight: 600;
}

.clt_mybooking_detail .secondary {
    font-size: 13px;
    color: #70757a;
}

.clt-booking-btn-white, .clt-booking-btn-white:hover, .clt-booking-btn-white:focus, .clt-booking-btn-white:active {
    background: #fff !important;
    color: #242424 !important;
    border: 1px solid #000 !important;
}

.clt-booking-btn-red, .clt-booking-btn-red:hover, .clt-booking-btn-red:focus, .clt-booking-btn-red:active {
    background: #000 !important;
    color: #fff !important;
}

.clt-booking-btn-gray, .clt-booking-btn-gray:focus {
    background: #eee !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}

.clt-booking-btn-gray:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt-booking-btn-gray:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:hover, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:active {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 14px;
    padding-top: 1.5px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    position: relative;
    letter-spacing: 0.1px;
    height: 48px;
    width: 100%;
}

.clt-booking-cp-pay-now button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #d9dce0 !important;
}

.clt-booking-cp-pay-now button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #d2d2d3 !important;
}

.clt-cancel-booking i {
    border: 4px solid #9a9a9a;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-bottom: 20px;
    color: #9a9a9a;
}

.clt-cancel-booking h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-cancel-booking p {
    font-size: 14px;
    color: #70757a;
    font-family: "Inter";
    font-weight: 400;
    margin-bottom: 101px;
    margin-top: 8px;
    letter-spacing: -.2px;
}

.clt-cancel-booking a, .clt-cancel-booking a:hover {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    display: contents;
    align-items: center;
    letter-spacing: -0.2px;
    text-decoration: underline;
}

.clt-mybooking-list-h2 {
    font-size: 20px;
    font-family: "Inter";
    letter-spacing: -.2px;
    color: #242424;
    font-weight: 600;
    text-transform: capitalize;
}

.clt-mybooking-list-div {
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}

.clt-mybooking-list-div {
    background: #fff;
    border-radius: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    margin-bottom: 10px;
    margin-left: -12px;
    margin-right: -12px;
}

.clt-mybooking-list-div:hover {
    background: #f6f6f6;
    border-radius: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    margin-left: -12px;
    margin-right: -12px;
}

.clt-mybooking-list-div:active {
    background: #eee;
    border-radius: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    margin-left: -12px;
    margin-right: -12px;
}

.clt-mybooking-list-div:last-child {
    background: #fff;
    border-radius: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    margin-bottom: 50px;
    margin-left: -12px;
    margin-right: -12px;
}

.clt-mybooking-list-div:last-child:hover {
    background: #f6f6f6;
    border-radius: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    margin-left: -12px;
    margin-right: -12px;
}

.clt-mybooking-list-div:last-child:active {
    background: #eee;
    border-radius: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    margin-left: -12px;
    margin-right: -12px;
}

.clt-mybooking-list-div img {
    width: 78px;
    height: 78px;
    border-radius: 12px;
}

.clt-mybooking-list-div .inner-div {
    margin-left: 20px;
}

.clt-mybooking-list-div .inner-div h2 {
    font-size: 14px;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -.2px;
    color: #242424;
    font-weight: 500;
}

.clt-mybooking-list-div .inner-div p {
    font-size: 14px;
    font-family: "Inter";
    margin-bottom: -3px;
    letter-spacing: -.2px;
    color: #70757a;
    font-weight: 400;
}

.clt-mybooking-list-span-div {
    margin-top: 4px;
}

.clt-mybooking-list-div .inner-div span {
    border-radius: 8px;
    cursor: pointer;
    font-size: 12.2px;
    font-weight: 500;
    margin-right: 8px;
    padding-top: 3px;
    padding-right: 7px;
    padding-bottom: 5px;
    padding-left: 7px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: rgba(0, 166, 255, .169);
    color: #006da7;
}

.clt_mybooking_header_dropdown {
    font-weight: 600;
    width: max-content !important;
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;
    background: #eee;
    border-radius: 50px;
    color: #242424 !important;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 5px 16px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt_mybooking_header_dropdown:hover {
    background: #d9dce0;
    color: #242424 !important;
}

.clt_mybooking_header_dropdown:active {
    background: #d2d2d3;
    color: #242424 !important;
}

.clt_mybooking_header_dropdown:focus {
    background: #d2d2d3;
    color: #242424 !important;
}


.clt_mybooking_header_dropdown i {
    font-size: 9.6px;
    margin-left: 8px;
    margin-top: 3px;
}

.clt_mybooking_header_dropdown_option {
    color: #242424;
    padding: 0.7rem 1rem;
    border-radius: 12px;
}

.clt-mybooking-title {
    font-size: 18px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 16px;
    letter-spacing: -0.2px;
}



.clt-mybooking-title2 {
    font-size: 18px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}


.clt_payment_footer button:disabled {
    background: #535353;
    border: #535353;
    color: #fff;
}


@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_mybooking_offcanvas {
        width: 100% !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }


    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 162px;
        height: 50px;
    }


    .clt_mybooking_header_button, .clt_mybooking_header_button:focus, .clt_mybooking_header_button:active, .clt_mybooking_header_button:hover {
        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_mybooking_RightTxt {
        width: 150px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15.5px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
    }

    .clt_mybooking_bookingImage .text {
        font-size: 14px;
    }

    .clt_mybooking_RightTxt span {
        font-size: 14px;
        margin-left: 45px !important;
        padding: 6px 10px 10px;
        font-weight: 500 !important;
    }

    .clt_mybooking_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-mybooking-list-div img {
        width: 88px;
        height: 88px;
        border-radius: 12px;
    }

    .clt-mybooking-list-div .inner-div h2 {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-mybooking-list-div .inner-div p {
        font-size: 16px;
        margin-top: -1px;
    }

    .clt-mybooking-list-div .inner-div span {
        font-size: 14.5px;
        padding: 2px 7px;
    }

    .clt_mybooking_header_dropdown {
        font-size: 14.5px;
        margin-top: -6px;
        margin-bottom: -7px;
    }

    .clt-mybooking-list-div {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .clt_mybooking_header_dropdown i {
        font-size: 10px;
        margin-left: 8px;
        margin-top: 0px;
    }

    .clt-mybooking-title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 24px;
    }

    .clt-mybooking-title2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .clt-mybooking-list-div:last-child {
        margin-bottom: 40px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:hover, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }
}

@media only screen and (max-width: 767px) {

    /*mobile*/
    .clt_mybooking_offcanvas {
        width: 100% !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 162px;
        height: 50px;
    }

    .clt_mybooking_header_button, .clt_mybooking_header_button:focus, .clt_mybooking_header_button:active, .clt_mybooking_header_button:hover {

        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_mybooking_RightTxt {
        width: 130px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15.5px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
    }

    .clt_mybooking_bookingImage .text {
        font-size: 14px;
    }

    .clt_mybooking_RightTxt span {
        font-size: 14px;
        margin-left: 45px !important;
        padding: 6px 10px 10px;
        font-weight: 500 !important;
    }

    .clt_mybooking_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-mybooking-list-div img {
        width: 88px;
        height: 88px;
        border-radius: 12px;
    }

    .clt-mybooking-list-div .inner-div h2 {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-mybooking-list-div .inner-div p {
        font-size: 16px;
        margin-top: -1px;
    }

    .clt-mybooking-list-div .inner-div span {
        font-size: 14.5px;
        padding: 2px 7px;
    }

    .clt-mybooking-list-div {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .clt_mybooking_header_dropdown i {
        font-size: 10px;
        margin-left: 8px;
        margin-top: 0px;
    }

    .clt-mybooking-title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 24px;
    }

    .clt-mybooking-title2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .clt_mybooking_header_dropdown {
        font-size: 14.5px;
        margin-top: -6px;
        margin-bottom: -7px;
    }

    .clt-mybooking-list-div:last-child {
        margin-bottom: 40px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:hover, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_mybooking_offcanvas {
        width: 100% !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 162px;
        height: 50px;
    }

    .clt_mybooking_header_button, .clt_mybooking_header_button:focus, .clt_mybooking_header_button:active, .clt_mybooking_header_button:hover {
        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15.5px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
    }

    .clt_mybooking_bookingImage .text {
        font-size: 14px;
    }

    .clt_mybooking_RightTxt span {
        font-size: 14px;
        margin-left: 45px !important;
        padding: 6px 10px 10px;
        font-weight: 500 !important;
    }

    .clt_mybooking_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt-mybooking-list-div img {
        width: 88px;
        height: 88px;
        border-radius: 12px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-mybooking-list-div .inner-div h2 {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-mybooking-list-div .inner-div p {
        font-size: 16px;
        margin-top: -1px;
    }

    .clt-mybooking-list-div .inner-div span {
        font-size: 14.5px;
        padding: 2px 7px;
    }

    .clt_mybooking_header_dropdown {
        font-size: 14.5px;
        margin-top: -6px;
        margin-bottom: -7px;
    }

    .clt-mybooking-list-div {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .clt_mybooking_header_dropdown i {
        font-size: 10px;
        margin-left: 8px;
        margin-top: 0px;
    }

    .clt-mybooking-title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 24px;
    }

    .clt-mybooking-title2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .clt_mybooking_header_dropdown {
        font-size: 14.5px;
        margin-top: -4px;
        margin-bottom: -4px;
    }

    .clt-mybooking-list-div:last-child {
        margin-bottom: 40px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:hover, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_mybooking_offcanvas {
        width: 425px !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 2px;
        padding-bottom: 2px;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_mybooking_bookingImage .text {
        left: 10px;
        top: 10px;
        font-size: 13px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
        font-weight: 500;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
        font-weight: 600;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:active, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:hover {
        font-size: 16px;
        height: 52px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_mybooking_offcanvas {
        width: 400px !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_mybooking_bookingImage .text {
        left: 10px;
        top: 10px;
        font-size: 13px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
        font-weight: 500;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
        font-weight: 600;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }

    .clt-cancel-booking a, .clt-cancel-booking a:hover {
        font-size: 16px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:active, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:hover {
        font-size: 16px;
        height: 52px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_mybooking_offcanvas {
        width: 425px !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_mybooking_bookingImage .text {
        left: 10px;
        top: 10px;
        font-size: 13px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
        font-weight: 500;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
        font-weight: 600;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }

    .clt-cancel-booking a, .clt-cancel-booking a:hover {
        font-size: 16px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:active, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:hover {
        font-size: 16px;
        height: 52px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_mybooking_offcanvas {
        width: 400px !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_mybooking_bookingImage .text {
        left: 10px;
        top: 10px;
        font-size: 13px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
        font-weight: 500;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
        font-weight: 600;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }

    .clt-cancel-booking a, .clt-cancel-booking a:hover {
        font-size: 16px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:active, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:hover {
        font-size: 16px;
        height: 52px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_mybooking_offcanvas {
        width: 425px !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_mybooking_bookingImage .text {
        left: 10px;
        top: 10px;
        font-size: 13px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
        font-weight: 500;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
        font-weight: 600;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }

    .clt-cancel-booking a, .clt-cancel-booking a:hover {
        font-size: 16px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:active, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:hover {
        font-size: 16px;
        height: 52px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_mybooking_offcanvas {
        width: 400px !important;
    }

    .clt_mybooking_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_mybooking_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2.4px;
    }

    .clt_mybooking_bookingImage .text {
        left: 10px;
        top: 10px;
        font-size: 13px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 15px;
    }

    .clt_mybooking_detail .title {
        font-size: 16px;
        font-weight: 500;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 16px;
        font-weight: 600;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }

    .clt-cancel-booking a, .clt-cancel-booking a:hover {
        font-size: 16px;
    }

    .clt-mybooking-list-h2 {
        font-size: 22px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:active, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:hover {
        font-size: 16px;
        height: 52px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_mybooking_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_mybooking_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_mybooking_offcanvas_screen1_div button, .clt_mybooking_offcanvas_screen1_div button:active, .clt_mybooking_offcanvas_screen1_div button:focus, .clt_mybooking_offcanvas_screen1_div button:hover {
        font-size: 15px;
    }

    .clt_mybooking_RightTxt span {
        font-size: 14px;
        margin-left: 49px !important;
    }

    .clt_mybooking_bookingImage .text {
        font-size: 13px;
    }

    .clt_mybooking_title_category {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt_mybooking_detail .secondary {
        font-size: 14.5px;
        color: #70757a;
    }

    .clt_mybooking_detail .title {
        font-size: 15px;
    }

    .clt_mybooking_detail .subtitle {
        font-size: 15px;
    }

    .clt_mybooking_offcanvas {
        width: 400px !important;
        z-index: 1055;
    }

    .clt-cancel-booking p {
        font-size: 15px;
    }

    .clt-mybooking-list-div .inner-div h2 {
        font-size: 15px;
    }

    .clt-mybooking-list-div .inner-div p {
        font-size: 15px;
    }

    .clt-mybooking-list-div .inner-div span {
        font-size: 13px;
    }

    .clt-mybooking-list-div img {
        width: 86px;
        height: 86px;
        border-radius: 12px;
    }

    .clt_mybooking_header_dropdown {
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;
        background: #eee;
        border-radius: 50px;
        color: #242424 !important;
        font-size: 14px;
        padding-top: 5px;
        padding-right: 16px;
        padding-bottom: 9px;
        padding-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.2px;
    }

    .clt_mybooking_header_dropdown i {
        font-size: 10px;
        margin-left: 8px;
    }

    .clt-mybooking-title {
        font-size: 20px;
    }

    .clt-mybooking-title2 {
        font-size: 20px;
    }

    .clt-booking-cp-pay-now button, .clt-booking-cp-pay-now button:active, .clt-booking-cp-pay-now button:focus, .clt-booking-cp-pay-now button:hover {
        font-size: 15px;
    }

}