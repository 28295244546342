.clt_search_chat_user_container {
    width: 100%;
}

.clt_search_chat_user_offcanvas_body{
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
}

.clt_search_chat_user_field {
    position: relative;
    width: 100%;
}

.clt_search_booking_field {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}


.clt_search_chat_user_icon {
    width: 14px;
    height: 15px;
    margin-bottom: 13px;
    margin-left: 5px;
}

.clt_search_booking_icon {
    width: 14px;
    height: 15px;
    margin-bottom: 13px;
    margin-left: 5px;
}


.clt_search_chat_user_container input {
    height: 46px;
    border-radius: 45px;
    background-color: #eee;
    padding-left: 46px;
    padding-bottom: 11px;
    color: #70757a;
    outline: none;
    font-weight: 500;
    width: 100%;
    text-align: left;
    font-size: 13px;
    border: #eee;
    box-shadow: none;
}

.clt_search_chat_user_container input:hover {
    background: #d9dce0 !important;
}

.clt_search_chat_user_container input:focus {
    background: #eee !important;
}

.clt_search_chat_user_container input:active {
    background: #eee !important;
}

.clt_search_helpcenter_container input {
    height: 46px;
    border-radius: 14px;
    background-color: #eee;
    padding-left: 46px;
    padding-bottom: 11px;
    color: #6a6a6a !important;
    outline: none;
    font-weight: 500;
    width: 100%;
    text-align: left;
    font-size: 13px;
    border: #eee;
    box-shadow: none;
}

.clt_search_helpcenter_container input:hover {
    background: #d9dce0 !important;
}

.clt_search_helpcenter_container input:focus {
    background: #eee !important;
}

.clt_search_helpcenter_container input:active {
    background: #eee !important;
}

.clt_search_chat_user_datalist {
    background-color: white;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    width: inherit;
    top: 66px;
    z-index: 1200;
}

.clt_search_chat_user_result_box {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.clt_search_chat_user_result_icon {
    background-color: #eee;
    padding: 7px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clt_search_chat_user_result_icon img {
    width: 14px;
    height: 14px;
}


.clt_search_chat_user_result {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
}

.clt_search_chat_user_result_detail {
    width: 100%;
}

.clt_search_chat_user_container_detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3px;
    margin-left: 0px;
    padding: 20px 0px 20px 0px;
}

.clt_search_chat_user_hr {
    border-bottom: 1.5px solid #dedddd;
    margin-left: 71px;
}

.clt_search_chat_user_container_detail .name {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.2px;
    margin-bottom: -4px;
    padding-bottom: 0px;
}

.clt_search_chat_user_container_detail .subtitle {
    font-size: 13.999px;
    font-weight: 500;
    border-radius: 6px;
    letter-spacing: -0.3px;
    padding-top: 0px;
    color: #70757a;
    padding-bottom: 0px;
    margin: 0;
}

.clt_search_chat_user_field_2 {
    padding: 0 12px;
}

.clt_search_chat_user_field_2 input {
    height: 42px;
    border-radius: 12px;
    background-color: #eee;
    padding-left: 14px;
    padding-bottom: 10px;
    color: #242424;
    outline: none;
    border: none;
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.2px;
    border-color: #eee;
    margin-top: 6px;
    margin-right: 0px;
    margin-bottom: 7px;
    margin-left: 0px;
    box-shadow: none !important;
}

.clt_search_chat_user_field_2 input:hover {
    height: 42px;
    border-radius: 12px;
    background-color: #eee;
    padding-left: 14px;
    padding-bottom: 10px;
    color: #242424;
    outline: none;
    border: none;
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.2px;
    border-color: #eee;
    box-shadow: none !important;
}

.clt_search_chat_user_field_2 input:focus {
    height: 42px;
    border-radius: 12px;
    background-color: #eee;
    padding-left: 14px;
    padding-bottom: 10px;
    color: #242424;
    outline: none;
    border: none;
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.2px;
    border-color: #eee;
    box-shadow: none !important;
}

.clt_search_chat_user_field_2 input:active {
    height: 42px;
    border-radius: 12px;
    background-color: #eee;
    padding-left: 14px;
    padding-bottom: 10px;
    color: #242424;
    outline: none;
    border: none;
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.2px;
    border-color: #eee;
    box-shadow: none !important;
}

.form-control {
    border-color: #ffffff;
    border: none;
}

.clt_search_chat_user_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_search_chat_user_offcanvas_header {
    padding: 0px 0px 0px 16px;
    border-bottom: 1px solid #dedddd;
}


.clt_search_chat_user_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_search_chat_user_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_search_chat_user_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_search_chat_user_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_search_chat_user_offcanvas_header .btn-back img {
    width: 7px;
    margin-bottom: 7px;
    margin-right: 7px;
}

.clt_search_chat_user_datalist_2 {}

.clt_search_chat_user_input {
    display: block;
}
.form-control-search {
    border-color: #ffffff !important;
}

.form-control-search:focus {
    border-color: #ffffff !important;
}

.form-control-search:hover {
    border-color: #ffffff !important;
}

.form-control-search:active {
    border-color: #ffffff !important;
}

.clt_search_chat_user_field_2 .form-control:active {
    background: #d2d2d3 !important;
}

.clt_admin_dash_booking_container_arrow {
    /* padding-right: 17px; */
}

.clt_search_chat_user_container button {
    height: 46px;
    border-radius: 45px;
    background-color: #eee !important;
    padding-left: 48px;
    padding-bottom: 10px;
    color: #70757a !important;
    outline: none;
    border: none;
    font-weight: 500;
    width: 100%;
    text-align: left;
    font-size: 13.5px;
    letter-spacing: .2px;
    box-shadow: none;
    cursor: text;
}

.clt_search_chat_user_container button:hover {
    background: #d9dce0 !important;
}

.clt_search_chat_user_container button:focus {
    background: #eee !important;
}

.clt_search_chat_user_container button:active {
    background: #eee !important;
}

.clt_search_helpcenter_container button {
    height: 46px;
    border-radius: 14px;
    background-color: #eee !important;
    padding-left: 48px;
    padding-bottom: 10px;
    color: #6a6a6a !important;
    outline: none;
    border: none;
    font-weight: 400;
    width: 100%;
    text-align: left;
    font-size: 13.5px;
    letter-spacing: .2px;
    box-shadow: none;
    cursor: text;
}

.clt_search_helpcenter_container button:hover {
    background: #d9dce0 !important;
}

.clt_search_helpcenter_container button:focus {
    background: #eee !important;
}

.clt_search_helpcenter_container button:active {
    background: #eee !important;
}

.clt_search_chat_user_icon_div {
    position: absolute;
    bottom: 5px;
    left: 14px;
}

.clt_search_booking_icon_div {
    position: absolute;
    bottom: 5px;
    left: 14px;
}

.clt_search_sidebar_container_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding-left: 18px!important;
    padding-bottom: 30px !important;
    padding-top: 22px !important;
    border-radius: 20px;
    border-radius: 20px;
}


.clt_search_sidebar_container_box:hover {
    background: #f6f6f6;
}

.clt_search_sidebar_container_box:active {
    background: #eee;
}

.clt_search_sidebar_container_box_icon {
    background-repeat: no-repeat;
    height: 17px;
    margin-top: 2.5px;
    width: 17px;
    background-size: contain;
}

.clt_search_sidebar_container_box_name {
    font-weight: 600;
    font-size: 13.3999px;
    letter-spacing: -0.1px;
    margin-bottom: -2.5px;
}

.clt_search_avatar_wrapper {
    align-self: flex-start;
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clt_search_sidebar_avatar_wrapper {
    align-self: flex-start;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_search_chat_user_offcanvas {
        width: 100%;
    }

    .clt_search_chat_user_input {
        display: none;
    }

   

    .clt_search_chat_user_container_detail .name {
        font-size: 16px;
    }

    .clt_search_chat_user_container_detail .subtitle {
        font-size: 16px;
    }

    .clt_search_chat_user_result_icon {
        background-color: #eee;
        padding: 8px;
        border-radius: 50%;
    }

    .clt_search_chat_user_result_icon img {
        width: 16px;
        height: 16px;
    }

    .clt_search_chat_user_container_detail {
        padding-top: 20px;
        padding-right: 0px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .clt_search_chat_user_hr {
        border-bottom: 1px solid #dedddd;
        margin-left: 48px;
    }

    .clt_search_chat_user_offcanvas_header {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_search_chat_user_result_box {
        width: 32px;
    }

    .clt_search_chat_user_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_search_chat_user_offcanvas_header .btn-back {
        background: none;
        margin-bottom: 2px;
        margin-top: 0px;
        margin-right: 10px;
        padding: 0.5rem 1rem 1.2rem 0.9rem !important;
    }

    .clt_search_sidebar_container_box_name {
        font-size: 16px;
        margin-bottom: -5px;
    }

    .clt_search_sidebar_container_box_icon {
        background-repeat: no-repeat;
        height: 22px;
        margin-top: 4px;
        width: 20px;
        background-size: contain;
    }

    .clt_search_chat_user_icon {
        width: 18px;
        height: 18px;
        margin-bottom: 34.2px;
        margin-left: 5px;
    }

    .clt_search_booking_icon {
        width: 18px;
        height: 18px;
        margin-bottom: 34.2px;
        margin-left: 5px;
    }

    .clt_search_chat_user_container button, .clt_search_chat_user_container button:hover {
        height: 54px;
        border-radius: 45px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
        color: #70757a !important;
        outline: none;
        border: none;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 16px;
        letter-spacing: .2px;
        border-color: #eee !important;
        box-shadow: none;
    }

    .clt_search_helpcenter_container button, .clt_search_helpcenter_container button:hover {
        height: 58px;
        border-radius: 14px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
        outline: none;
        border: none;
        text-align: left;
        font-size: 16px;
        letter-spacing: .2px;
        border-color: #eee !important;
        box-shadow: none;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_search_chat_user_offcanvas {
        width: 100%;
    }

    .clt_search_chat_user_input {
        display: none;
    }

    .clt_search_chat_user_button {
        display: block;
    }

    .clt_search_chat_user_field_2 {
        padding: 0 ;
        }

    .clt_search_chat_user_container button, .clt_search_chat_user_container button:hover {
        height: 54px;
        border-radius: 45px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
        color: #70757a !important;
        outline: none;
        border: none;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 16px;
        letter-spacing: .2px;
        border-color: #eee !important;
        box-shadow: none;
    }

    .clt_search_helpcenter_container button, .clt_search_helpcenter_container button:hover {
        height: 58px;
        border-radius: 14px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 12px;
        margin-top: 16px;
        margin-bottom: 16px;
        outline: none;
        border: none;
        text-align: left;
        font-size: 16px;
        letter-spacing: .2px;
        border-color: #eee !important;
        box-shadow: none;
    }

    .clt_search_chat_user_icon_div {
        position: absolute;
        bottom: 5px;
        left: 14px;
    }

    .clt_search_booking_icon_div {
        position: absolute;
        bottom: 5px;
        left: 14px;
    }


    .clt_search_chat_user_icon {
        width: 18px;
        height: 18px;
        margin-bottom: 34.2px;
        margin-left: 5px;
    }

    .clt_search_booking_icon {
        width: 18px;
        height: 18px;
        margin-bottom: 34.2px;
        margin-left: 5px;
    }

    .clt_search_chat_user_container_detail .name {
        font-size: 16px;
    }

    .clt_search_chat_user_container_detail .subtitle {
        font-size: 16px;
    }

    .clt_search_chat_user_result_icon {
        background-color: #eee;
        padding: 8px;
        border-radius: 50%;
    }

    .clt_search_chat_user_result_icon img {
        width: 16px;
        height: 16px;
    }

    .clt_search_chat_user_container_detail {
        padding-top: 20px;
        padding-right: 0px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .clt_search_chat_user_hr {
        border-bottom: 1px solid #dedddd;
        margin-left: 48px;
    }

    .clt_search_chat_user_offcanvas_header {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_search_chat_user_result_box {
        width: 32px;
    }

    .clt_search_chat_user_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_search_chat_user_offcanvas_header .btn-back {
        background: none;
        margin-bottom: 2px;
        margin-top: 0px;
        margin-right: 10px;
        padding: 0.5rem 1rem 1.2rem 0.9rem !important;
    }

    .clt_search_chat_user_offcanvas_header .btn-back img {
        margin-bottom: 0px;
        margin-right: 0px;
    }

    .clt_search_sidebar_container_box_name {
        font-size: 16px;
        margin-bottom: -5px;
    }

    .clt_search_sidebar_container_box_icon {
        background-repeat: no-repeat;
        height: 22px;
        margin-top: 4px;
        width: 20px;
        background-size: contain;
    }


}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_search_chat_user_offcanvas {
        width: 100%;
    }

    .clt_search_chat_user_input {
        display: none;
    }

    .clt_search_chat_user_button {
        display: block;
    }

    .clt_search_chat_user_field_2 {
        padding: 0 ;
    }

    .clt_search_chat_user_container button, .clt_search_chat_user_container button:hover {
        height: 50px;
        border-radius: 45px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 11px;
        color: #70757a !important;
        outline: none;
        border: none;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 15px;
        letter-spacing: .2px;
        box-shadow: none;
    }

    .clt_search_helpcenter_container button, .clt_search_helpcenter_container button:hover {
        height: 50px;
        border-radius: 14px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 11px;
        outline: none;
        border: none;
        text-align: left;
        font-size: 15px;
        letter-spacing: .2px;
        box-shadow: none;
    }

    .clt_search_chat_user_icon_div {
        position: absolute;
        bottom: 5px;
        left: 14px;
    }

    .clt_search_booking_icon_div {
        position: absolute;
        bottom: 5px;
        left: 14px;
    }


    .clt_search_chat_user_icon {
        width: 18px;
        height: 18px;
        margin-bottom: 34.2px;
        margin-left: 5px;
    }

    .clt_search_booking_icon {
        width: 18px;
        height: 18px;
        margin-bottom: 34.2px;
        margin-left: 5px;
    }

    .clt_search_chat_user_container_detail .name {
        font-size: 16px;
    }

    .clt_search_chat_user_container_detail .subtitle {
        font-size: 16px;
    }

    .clt_search_chat_user_result_icon {
        background-color: #eee;
        padding: 8px;
        border-radius: 50%;
    }

    .clt_search_chat_user_result_icon img {
        width: 16px;
        height: 16px;
    }

    .clt_search_chat_user_container_detail {
        padding-top: 20px;
        padding-right: 0px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .clt_search_chat_user_hr {
        border-bottom: 1px solid #dedddd;
        margin-left: 48px;
    }

    .clt_search_chat_user_offcanvas_header {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_search_chat_user_result_box {
        width: 32px;
    }


    .clt_search_chat_user_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_search_chat_user_offcanvas_header .btn-back {
        background: none;
        margin-bottom: 2px;
        margin-top: 0px;
        margin-right: 10px;
        padding: 0.5rem 1rem 1.2rem 0.9rem !important;
    }

    .clt_search_sidebar_container_box_name {
        font-size: 16px;
        margin-bottom: -5px;
    }

    .clt_search_sidebar_container_box_icon {
        background-repeat: no-repeat;
        height: 22px;
        margin-top: 4px;
        width: 20px;
        background-size: contain;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/

    .clt_search_chat_user_icon {
        width: 16px;
        height: 16px;
        margin-bottom: 15px;
        margin-left: 5px;
    }

    .clt_search_booking_icon {
        width: 16px;
        height: 16px;
        margin-bottom: 15px;
        margin-left: 5px;
    }

    .clt_search_sidebar_container_box_name {
        font-size: 15px;
    }

    .clt_search_chat_user_container button {
        height: 50px;
        border-radius: 45px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 11px;
        color: #70757a !important;
        outline: none;
        border: none;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 15px;
        letter-spacing: .2px;
        box-shadow: none;
        cursor: text;
    }

    .clt_search_helpcenter_container button {
        height: 50px;
        border-radius: 15px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 11px;
        outline: none;
        border: none;
        text-align: left;
        font-size: 15px;
        letter-spacing: .2px;
        box-shadow: none;
        cursor: text;
    }
}