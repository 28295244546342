.clt-admin-booking-admin-booking-cp-div1 {
    border-radius: 12px;
    background: #e8f0fe;
    padding: 20px;
}

.clt-admin-booking-admin-booking-cp-div1 img {
    width: 13px;
}

.clt-admin-booking-admin-booking-cp-div1-text {
    margin-left: 20px;
}

.clt-admin-booking-admin-booking-cp-div1-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div1-text p {
    font-size: 14px;
    color: #70757a;
    line-height: 1.44;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-hr-pd {
    padding: 25px 13px;
    color: #727272;
}

.clt-admin-booking-admin-booking-cp-div2 {}

.clt-admin-booking-admin-booking-cp-div2 img {
    width: 62px;
    height: 62px;
    border-radius: 12px;
}

.clt-admin-booking-admin-booking-cp-div2-text {
    margin-left: 20px;
}

.clt-admin-booking-admin-booking-cp-div2-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div2-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}


.clt-admin-booking-admin-booking-cp-div3 {}

.clt-admin-booking-admin-booking-cp-div3 img {
    width: 17px;
    filter: grayscale(1);
}

.clt-admin-booking-admin-booking-cp-div3-text {
    margin-left: 20px;
}

.clt-admin-booking-admin-booking-cp-div3-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div3-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: -2px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 13.5px;
    font-weight: 500;
    padding-top: 6px;
    padding-right: 11px;
    padding-bottom: 7px;
    padding-left: 11px;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    letter-spacing: 0.1px;
}

.clt-admin-booking-admin-booking-cp-div3 button:active {
    background: #d2d2d3 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}


.clt-admin-booking-admin-booking-cp-div3 button:hover {
    background: #d9dce0 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}


.clt-admin-booking-admin-booking-mb {
    margin-top: 25px;
}

.clt-admin-booking-admin-booking-cp-div4 {}

.clt-admin-booking-admin-booking-cp-div4-title {
    font-size: 18px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 15px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div4-inner {
    cursor: pointer;
    border: 1px solid #dedddd;
    border-radius: 12px;
    padding-top: 15px;
    padding-right: 16px;
    padding-bottom: 15px;
    padding-left: 16px;
}

.clt-admin-booking-admin-booking-cp-div4-inner:hover {
    background: #fbfafa;
}

.clt-admin-booking-admin-booking-cp-div4-inner:active {
    background: #eee;
}


.clt-admin-booking-admin-booking-cp-div4-text {
    width: 90%;
}

.clt-admin-booking-admin-booking-cp-div4-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div4-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}


.clt-admin-booking-admin-booking-cp-div5 {}

.clt-admin-booking-admin-booking-cp-div5-title {
    font-size: 18px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 15px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div5-inner h2 {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 18px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div5-inner i {
    background: #70757a;
    border-radius: 50px;
    font-size: 8px;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    margin-left: 7px;
    margin-top: 1.5px;
}

.clt-admin-booking-admin-booking-cp-div5-inner i:hover {
    background: #9a9a9a;
}

.clt-admin-booking-admin-booking-cp-text-bold {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #242424 !important;
}

.clt-admin-booking-admin-booking-cp-tooltip .tooltip-inner {
    background: #fff;
    color: #242424;
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 10px;
    font-size: 14px;
    box-shadow: 0px 0px 3px #9e9e9e;
    font-family: "Inter";
    font-weight: 400;
    text-align: left;
    letter-spacing: -.2px;
}

.clt-admin-booking-admin-booking-cp-tooltip .tooltip-arrow {
    display: none;
}

.clt-admin-booking-admin-booking-cp-div6-title {
    font-size: 20px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 25px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div6 {}

.clt-admin-booking-admin-booking-cp-div6 img {
    width: 18px;
    margin-top: 3px;
}

.clt-admin-booking-admin-booking-cp-div6-text {
    margin-left: 20px;
}

.clt-admin-booking-admin-booking-cp-div6-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div6-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:hover, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:active {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 13.5px;
    font-weight: 500;
    padding: 6px 11px 7px;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    letter-spacing: .35px;
}

.clt-admin-booking-admin-booking-cp-div6 button:hover {
    background: #d9dce0 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}

.clt-admin-booking-admin-booking-cp-div6 button:active {
    background: #d2d2d3 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}

.clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:hover, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:active {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 14px;
    padding-top: 1.5px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border: 0px solid #000;
    position: relative;
    letter-spacing: 0.1px;
    height: 48px;
    width: 100%;
}

.clt-admin-booking-admin-booking-cp-div7-btn button:hover {
    background: #2d2d2d !important;
    border: 0px solid #000;
}

.clt-admin-booking-admin-booking-cp-div7-btn button:active {
    background: #5c5c5c !important;
    border: 0px solid #000;
}

.clt-admin-booking-admin-booking-cp-div7-p {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    text-decoration: underline;
}

.clt-admin-booking-admin-booking-gc-div1 {
    margin-top: 30px;
}

.clt-admin-booking-admin-booking-gc-div1 i {
    background: #eee;
    border-radius: 50px;
    font-size: 12px;
    color: #242424;
    font-weight: 600;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.clt-admin-booking-admin-booking-gc-div1 input, .clt-admin-booking-admin-booking-gc-div1 input:hover, .clt-admin-booking-admin-booking-gc-div1 input:active, .clt-admin-booking-admin-booking-gc-div1 input:focus {
    height: 120px;
    width: 175px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    font-size: 32px;
    font-family: "Inter";
    font-weight: 600;
    color: #242424;
    border: 1px solid #dedddd;
    border-radius: 12px;
}

.clt-admin-booking-admin-booking-gc-div1 input::-webkit-outer-spin-button,
.clt-admin-booking-admin-booking-gc-div1 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.clt-admin-booking-admin-booking-gc-div1 input[type=number] {
    -moz-appearance: textfield;
}

.clt-admin-booking-admin-booking-gc-div2 i {
    background: #878787;
    border-radius: 50px;
    font-size: 8px;
    color: #fff;
    font-weight: 600;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
}

.clt-admin-booking-admin-booking-gc-div2-text {
    margin-left: 20px;
    margin-bottom: 30px;
}

.clt-admin-booking-admin-booking-gc-div2-text h2 {
    font-size: 15px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-gc-div2-text p {
    font-size: 14.8px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: -2px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:hover, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:active {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 14px;
    padding-top: 1.5px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #000;
    position: relative;
    height: 48px;
    width: 100%;
}

.pfr_payment_cards3dots .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pfr_payment_cards3dots .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}

.clt-admin-booking-admin-booking-cb-div1 {
    border-radius: 12px;
    background: #01aa5d;
    padding-top: 18px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
}

.clt-admin-booking-admin-booking-cb-div1 img {
    width: 15px;
}

.clt-admin-booking-admin-booking-cb-div1-text {
    margin-left: 20px;
}

.clt-admin-booking-admin-booking-cb-div1-text h2 {
    font-size: 14px;
    color: #fff;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cb-div2 {
    border-radius: 12px;
    background: #fff;
    padding: 20px;
    border: 1px solid #dddddd;
}

.clt-admin-booking-admin-booking-cb-div2 i {
    font-size: 17px;
    color: #70757a;
}

.clt-admin-booking-admin-booking-cb-div2-text {
    margin-left: 20px;
}

.clt-admin-booking-admin-booking-cb-div2-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-admin-booking-cb-div2-text p {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 1.44;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-admin-booking-payment-plan-div {
    margin-top: 0 !important;
}

.clt-admin-booking-payment-plan-p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 1.44;
    letter-spacing: -0.2px;
}

.clt-admin-booking-payment-plan-more-info {
    font-size: 14px;
    color: #000;
    font-family: 'Inter';
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 4px;
    letter-spacing: -0.2px;
    text-decoration: underline;
    cursor: pointer;
}

.clt-admin-booking-payment-plan-more-info:hover {
    color: #787878;
}

.clt-admin-booking-payment-plan-more-info:active {
    color: #919191;
}



.clt-admin-booking-confirm-booking-top-1-div {
    width: 100%;
    height: 220px;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 15px;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
    background-repeat: no-repeat;
    background-position: right 20px bottom;
    background-size: 22%;
    justify-content: space-around;
    align-items: start;
    flex-direction: column;
}

.clt-admin-booking-confirm-booking-top-1-span {
    font-size: 14px;
    letter-spacing: -.2px;
    font-weight: 400;
    font-family: Inter;
    margin-bottom: 0;
    padding: 0px 20px 20px;
    color: #fff;
}

.clt-admin-booking-confirm-booking-top-1-div h2 {
    font-size: 28px;
    letter-spacing: -.2px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 0;
    padding: 0px 20px 0px;
    color: #fff;
    width: 80%;
    line-height: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.clt-admin-booking-clickable-chevron-div {
    padding: 15px 16px;
    border: 1px solid #dddddd;
    border-radius: 12px;
    margin-top: 15px;
    cursor: pointer;
    position: relative;
}

.clt-admin-booking-clickable-chevron-div:hover {
    background: #fbfafa;
}

.clt-admin-booking-clickable-chevron-div:active {
    background: #eee;
}

.clt-clickable-chevron-div-section-request-pending {
    padding: 23px 18px;
    border-radius: 12px;
    margin-top: 0px;
    cursor: pointer;
    position: relative;
    background: #e8f0fe;
}

.clt-clickable-chevron-div-section-request-pending:hover {
    background: #d1def4;
}

.clt-clickable-chevron-div-section-request-pending:active {
    background: #b9c2d0;
}

.clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending {
    width: 90%;
    margin-left: 13px;
}

.clt-clickable-chevron-div-section-request-pending .inner-div-2-section-request-pending {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.clt-booking-cp-div1-section-request-pending img {
    width: 15px;
}

.clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
}

.clt-admin-booking-clickable-chevron-div .inner-div-1 {
    width: 90%;
}

.clt-admin-booking-clickable-chevron-div .inner-div-2 {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.clt-admin-booking-clickable-chevron-div .inner-div-1 h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-admin-booking-clickable-chevron-div .inner-div-1 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
}

.clt-admin-booking-admin-booking-cp-div1-title {
    font-size: 20px;
    color: #242424;
    font-family: "Inter";
    font-weight: 600;
    margin-bottom: 22px;
    letter-spacing: -.2px;
}


.clt-admin-booking-paymentplan-select-mainDiv {
    border: 1px solid #ddd;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 9px;
    padding-left: 10px;
    border-radius: 12px;
    margin-top: 16px;
    cursor: pointer;
    background: #fff;
}

.clt-admin-booking-paymentplan-select-mainDiv:hover {
    background: transparent !important;
}

.clt-admin-booking-paymentplan-select-mainDiv:active {
    background: #eee;
    border: 1px solid #000;
}

.clt-admin-booking-paymentplan-select-mainDiv .inner-div-text h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 1px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-paymentplan-select-mainDiv .inner-div-text h3 {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 4.6px;
    letter-spacing: -0.2px;
}

.clt-admin-booking-paymentplan-select-mainDiv .inner-div-text span {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-admin-booking-paymentplan-select-mainDiv .inner-div-text span i {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    margin-right: 5px;
}

.clt-admin-booking-paymentplan-select-mainDiv .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-admin-booking-paymentplan-select-mainDiv .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}

.clt-admin-booking-admin-booking-cp-div6-title {
    font-size: 20px;

}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-admin-booking-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-paymentplan-select-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt-admin-booking-paymentplan-select-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }
    .clt-admin-booking-admin-booking-cp-div1 {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 87px;
        height: 87px;
        border-radius: 12px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 img {
        width: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:hover, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:active {
        border-radius: 50px;
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-mb {
        margin-top: 30px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        border-radius: 12px;
        padding: 15px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        border-radius: 50px;
        font-size: 9px;
        width: 17px;
        height: 17px;
        margin-left: 8px;
    }

    .clt-admin-booking-admin-booking-cp-tooltip .tooltip-inner {
        border-radius: 8px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div6 img {
        width: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:hover, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:active {
        border-radius: 50px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:hover, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 h2 {
        font-size: 16px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:hover, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 1.5px;
        font-weight: 600;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div1-title {
        font-size: 22px;
    }

    .clt-admin-booking-payment-plan-more-info, .clt-admin-booking-payment-plan-p {
        font-size: 16px;
        letter-spacing: -.2px;
    }    

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending h2 {
        font-size: 16px;
    }

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending p {
        font-size: 16px;
    }

}

@media only screen and (max-width: 767px) {

    /*mobile*/
    .clt-admin-booking-admin-booking-cp-div1 {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 87px;
        height: 87px;
        border-radius: 12px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 img {
        width: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:hover, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:active {
        border-radius: 50px;
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-mb {
        margin-top: 30px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        border-radius: 12px;
        padding: 15px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        border-radius: 50px;
        font-size: 9px;
        width: 17px;
        height: 17px;
        margin-left: 8px;
    }

    .clt-admin-booking-admin-booking-cp-tooltip .tooltip-inner {
        border-radius: 8px;
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div6 img {
        width: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text {
        margin-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:hover, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:active {
        border-radius: 50px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:hover, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 h2 {
        font-size: 16px;
    }

    .clt-admin-booking-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:hover, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 1.5px;
        font-weight: 600;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div1-title {
        font-size: 22px;
    }

    .clt-admin-booking-payment-plan-more-info, .clt-admin-booking-payment-plan-p {
        font-size: 16px;
        letter-spacing: -.2px;
    }    

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending h2 {
        font-size: 16px;
    }

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-admin-booking-admin-booking-cp-tooltip .tooltip-inner {
        border-radius: 8px;
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 h2 {
        font-size: 16px;
    }

    .clt-admin-booking-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:hover, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 1.5px;
        font-weight: 600;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div1-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:hover, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

    .clt-admin-booking-payment-plan-more-info, .clt-admin-booking-payment-plan-p {
        font-size: 16px;
        letter-spacing: -.2px;
    }    

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending h2 {
        font-size: 16px;
    }

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending p {
        font-size: 16px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt-admin-booking-admin-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2, .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2, .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:active, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2, .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:active, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:active, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:active, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-gc-div1 input, .clt-admin-booking-admin-booking-gc-div1 input:active, .clt-admin-booking-admin-booking-gc-div1 input:focus, .clt-admin-booking-admin-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2, .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-admin-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt-admin-booking-admin-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2, .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2, .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:active, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2, .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:active, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:active, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:active, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-gc-div1 input, .clt-admin-booking-admin-booking-gc-div1 input:active, .clt-admin-booking-admin-booking-gc-div1 input:focus, .clt-admin-booking-admin-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2, .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-admin-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-admin-booking-admin-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2, .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2, .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:active, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2, .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:active, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:active, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:active, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-gc-div1 input, .clt-admin-booking-admin-booking-gc-div1 input:active, .clt-admin-booking-admin-booking-gc-div1 input:focus, .clt-admin-booking-admin-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2, .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-admin-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-admin-booking-admin-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2, .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2, .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:active, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2, .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:active, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:active, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:active, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-gc-div1 input, .clt-admin-booking-admin-booking-gc-div1 input:active, .clt-admin-booking-admin-booking-gc-div1 input:focus, .clt-admin-booking-admin-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2, .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-admin-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-admin-booking-admin-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2, .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2, .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:active, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2, .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:active, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:active, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:active, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-gc-div1 input, .clt-admin-booking-admin-booking-gc-div1 input:active, .clt-admin-booking-admin-booking-gc-div1 input:focus, .clt-admin-booking-admin-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2, .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-admin-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-admin-booking-admin-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div1-text h2, .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div1 img {
        width: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2, .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:active, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2, .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:active, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:active, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p a, .clt-admin-booking-admin-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:active, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-admin-booking-admin-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-gc-div1 input, .clt-admin-booking-admin-booking-gc-div1 input:active, .clt-admin-booking-admin-booking-gc-div1 input:focus, .clt-admin-booking-admin-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-admin-booking-admin-booking-cb-div2-text h2, .clt-admin-booking-admin-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-admin-booking-admin-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-admin-booking-admin-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-admin-booking-admin-booking-cb-div1 img {
        width: 17px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-admin-booking-admin-booking-cp-div1-text h2, .clt-admin-booking-admin-booking-cp-div1-text p {
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2-text h2, .clt-admin-booking-admin-booking-cp-div2-text p {
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div2 img {
        width: 64px;
        height: 64px;
        border-radius: 12px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text h2 {
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div3-text p {
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div3 button, .clt-admin-booking-admin-booking-cp-div3 button:active, .clt-admin-booking-admin-booking-cp-div3 button:focus, .clt-admin-booking-admin-booking-cp-div3 button:hover {
        font-size: 14.5px;
    }

    .clt-admin-booking-admin-booking-cp-div4-title {
        font-size: 20px;
    }

    .clt-admin-booking-admin-booking-cp-div4-text h2, .clt-admin-booking-admin-booking-cp-div4-text p {
        font-size: 14px;
    }

    .clt-admin-booking-admin-booking-cp-div5-title {
        font-size: 19px;
    }

    .clt-admin-booking-admin-booking-cp-div5-inner h2 {
        font-size: 14px;
    }

    .clt-admin-booking-admin-booking-cp-text-bold {
        font-size: 14px !important;
    }

    .clt-admin-booking-admin-booking-cp-div6 button, .clt-admin-booking-admin-booking-cp-div6 button:active, .clt-admin-booking-admin-booking-cp-div6 button:focus, .clt-admin-booking-admin-booking-cp-div6 button:hover {
        font-size: 14.5px;
    }

    .clt-admin-booking-admin-booking-cp-div6-text h2 {
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div7-btn button, .clt-admin-booking-admin-booking-cp-div7-btn button:active, .clt-admin-booking-admin-booking-cp-div7-btn button:focus, .clt-admin-booking-admin-booking-cp-div7-btn button:hover {
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-cp-div7-p {
        font-size: 15px;
    }

    .clt-admin-booking-admin-booking-gc-div2-btn button, .clt-admin-booking-admin-booking-gc-div2-btn button:active, .clt-admin-booking-admin-booking-gc-div2-btn button:focus, .clt-admin-booking-admin-booking-gc-div2-btn button:hover {
        font-size: 15px;
    }

    .clt-admin-booking-payment-plan-p {
        font-size: 15px;
    }

    .clt-admin-booking-payment-plan-more-info {
        font-size: 15px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 h2 {
        font-size: 15px;
    }

    .clt-admin-booking-clickable-chevron-div .inner-div-1 p {
        font-size: 15px;
    }

    .clt-admin-booking-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 15px;
    }

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending h2 {
        font-size: 15px;
    }

    .clt-clickable-chevron-div-section-request-pending .inner-div-1-section-request-pending p {
        font-size: 15px;
    }

}
