.clt-admin-room-div-img {
    border-radius: 10px;
    margin-right: 10px;
    padding-top: 9px;
    padding-right: 9px;
    padding-bottom: 11px;
    padding-left: 9px;
    background-color: #eee;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
}

.clt-admin-gallery-icon {
    width: 17px;
}

.clt-admin-room-button-gallery {
    cursor: pointer;
    border: 1px solid #dedddd;
    border-radius: 12px;
    padding: 17px 20px 22px;
}

.clt-admin-room-button-gallery:hover {
    background: #fbfafa;
}

.clt-admin-room-button-gallery:active {
    background: #eee;
}

.clt-admin-room-button-gallery-text h2 {
    margin-left: 15px;
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    letter-spacing: -0.2px;
}


@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-admin-room-button-gallery {
        border-radius: 12px;
        padding: 21px 20px 26px;
    }

    .clt-admin-gallery-icon {
        width: 18px;
    } 

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-admin-room-button-gallery {
        border-radius: 12px;
        padding: 21px 20px 26px;
    }

    .clt-admin-gallery-icon {
        width: 18px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt-admin-room-button-gallery {
        padding: 21px 20px 26px;
    }

    .clt-admin-gallery-icon {
        width: 18px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt-admin-room-button-gallery {
        padding: 19px 16px;
    }
    .clt-admin-gallery-icon {
        width: 18px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt-admin-room-button-gallery {
        padding: 19px 16px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-admin-room-button-gallery {
        padding: 19px 16px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-admin-room-button-gallery {
        padding: 19px 16px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-admin-room-button-gallery {
        padding: 19px 16px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-admin-room-button-gallery {
        padding: 19px 16px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-admin-room-button-gallery {
        padding: 20px 20px 25px;
    }

    .clt-admin-gallery-icon {
        width: 18px;
    }

    .clt-admin-room-button-gallery-text h2 {
        margin-left: 15px;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 0;
        letter-spacing: -0.2px;
    }
}