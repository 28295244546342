.clt_admin_dash_invoice_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_admin_dash_invoice_offcanvas_header {
    padding: 14px 24px 17px 12.8px;
    border-bottom: 1px solid #dedddd;
}


.clt_admin_dash_invoice_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_admin_dash_invoice_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_admin_dash_invoice_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_admin_dash_invoice_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}

.clt_admin_dash_invoice_offcanvas_screen1_div {}

.clt_admin_dash_invoice_offcanvas_screen1_div h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_offcanvas_screen1_div p {
    font-size: 16px;
    color: #5b5b5b;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 14px;
    margin-top: 7px;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-bottom: 10px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    width: 100px;
    height: 48px;
}

.clt_admin_dash_invoice_offcanvas .form-floating>label {
    padding-left: 18px !important;
    padding-top: 13px !important;
    padding-left: 18px !important;
    padding-top: 13px !important;
    font-size: 13.5px;
    font-weight: 400;
    color: #242424;
}

.clt_admin_dash_invoice_innerdivs {
    margin: 15px 0;
}

.clt_admin_dash_invoice_innerhead {
    font-size: 20px;
    color: #242424;
    font-weight: 600;
    margin-bottom: 0px;
}

.clt_admin_dash_export_innerhead {
    font-size: 20px;
    color: #242424;
    font-weight: 600;
    margin-bottom: 10px;
}

.clt_inputFloatDiv {
    margin-bottom: 0px;
}

.clt_admin_dash_invoice_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 19px 24px;
    border-top: 1px solid #e0e0e0;
    background: #fff;
    z-index: 1;
}

.clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:focus {
    background: #000;
    border: 0px solid #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding-top: 1.5px;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    height: 48px;
    margin-top: 8px;
}

.clt_admin_dash_invoice_footer button:active {
    background: #5c5c5c !important;
}

.clt_admin_dash_invoice_footer button:hover {
    background: #2d2d2d !important;
}

.clt_admin_dash_invoice_header_button {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-bottom: -7px;
    margin-top: -7px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: -.2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    width: 20%;
    height: 36px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    border: none;
}

.clt_admin_dash_invoice_header_button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt_admin_dash_invoice_header_button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt_admin_dash_invoice_header_button_black {
    background: #000 !important;
}

.clt_admin_dash_invoice_cardsdiv {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.clt_admin_dash_invoice_imgdiv {
    background: #eee;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt_admin_dash_invoice_imgdiv img {
    width: 14px;
    padding-bottom: 2px;
}

.clt_admin_dash_invoice_cardnumber {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    color: #242424;
    margin-left: -18px;
    margin-bottom: 0px;
}

.clt_admin_dash_invoice_cardname {
    font-size: 14px;
    font-weight: 400;
    color: #5b5b5b;
    margin-bottom: 0;
    margin-left: -18px;
}

.clt_admin_dash_invoice_card3dot {
    color: #929292 !important;
    border-radius: 50px;
    padding-top: 7px;
    padding-right: 15px;
    padding-bottom: 7px;
    padding-left: 15px;
    margin-right: -12px;
}

.clt_admin_dash_invoice_card3dot:hover {
    background: #f6f6f6;
}

.clt_admin_dash_invoice_card3dot:active {
    background: #eee;
}

.clt_admin_dash_invoice_card3dot:focus {
    background: #eee;
    color: #6d6d6d !important;
}

.clt_admin_dash_export_check {
    background: #f6f6f6;
    border-radius: 12px;
    cursor: default;
}

.clt_admin_dash_export_check:hover {
    background: #eee;
    border-radius: 12px;
}

.clt_admin_dash_export_check:active {
    background: #c7c6c6;
    border-radius: 12px;
}

.clt_admin_dash_export_check .form-check-input {
    margin-top: 13px;
    margin-left: -10px;
}

.clt_admin_dash_invoice_space_bottom {
    height: 30vh;
}

.clt_admin_dash_invoice_offcanvas_body {
    padding-left: 24px;
    padding-right: 24px;
}

.clt_admin_dash_invoice_offcanvas_body_2 {
    padding: 0px;
}

.clt_admin_dash_invoice_refund_booking_box_field {
    margin: 20px 24px;
    position: relative;
    min-height: calc(100% - 130px);
}

.clt_admin_dash_invoice_refund_booking_formButton, .clt_admin_dash_invoice_refund_booking_formButton:hover, .clt_admin_dash_invoice_refund_booking_formButton:focus {
    background: #000000;
    border: 1px solid #000000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    margin: 0px 22px;
    border-radius: 12px;
    font-size: 13.5px;
    padding-top: 2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    height: 48px;
    position: sticky;
    margin-top: 20px;
    margin-bottom: 20px;
    bottom: 0px;
}

.clt_admin_dash_invoice_refund_booking_formButton:disabled {
    opacity: .4;
    border: 1px solid #000000;
    background: #000000;
}

.clt_admin_dash_invoice_cards3dots_options {
    color: #242424;
    font-size: 16px;
    padding: 0.7rem 1rem;
}

.clt_admin_dash_invoice_cards3dots_options:active {
    color: #242424;
    background-color: #d9dce0;
    font-size: 16px;
    padding: 0.7rem 1rem;
}


.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv {
    padding-top: 21px;
    padding-right: 0px;
    padding-bottom: 18px;
    padding-left: 0px;
    margin-top: 0px;
    border-bottom: 1px solid #dedddd;
    cursor: pointer;
    
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv:hover {
    background: #f6f6f6;
    border-radius: 16px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv:focus {
    background: #f6f6f6 !important;
    border-radius: 16px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv:active {
    background: #eee;
    border-radius: 16px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text {
    margin-left: 20px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 5px;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
    font-size: 14px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 4.6px;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    margin-right: 5px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50px;
}



.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
    margin-top: 0px;
    cursor: pointer;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text {
    margin-left: 20px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 5px;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
    font-size: 14px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 4.6px;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span i {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    margin-right: 5px;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50px;
}

.react-google-flight-datepicker .date-picker {
    position: relative;
    border-radius: 10px;
    background: #fff;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 12px;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_admin_dash_invoice_offcanvas {
        width: 100%;
    }


    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-bottom: 3.4px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        margin-bottom: 2px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
        margin-left: -8px;
        margin-bottom: -1px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
        margin-left: -8px;
    }

    .clt_admin_dash_invoice_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        font-weight: 600;
        padding-top: 1.5px;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
    }

    /*mobile*/
    .clt-detail-slider-maindiv {
        padding: 0;
    }


    .clt_admin_dash_invoice_clt-datepicker-sidebar-footer-btn, .clt_admin_dash_invoice_clt-datepicker-sidebar-footer-btn:hover, .clt_admin_dash_invoice_clt-datepicker-sidebar-footer-btn:focus, .clt_admin_dash_invoice_clt-datepicker-sidebar-footer-btn:active {
        background: #28b07d;
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        border: 1px solid #29b07d;
        height: 50px;
        margin-top: 26px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span {
        font-size: 13.5px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_admin_dash_export_check .form-check-input {
        margin-top: 16px;
        margin-left: -10px;
    }

    .clt_admin_dash_invoice_refund_booking_formButton, .clt_admin_dash_invoice_refund_booking_formButton:hover, .clt_admin_dash_invoice_refund_booking_formButton:focus {
        font-size: 16px;
        height: 54px;
    }


}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_admin_dash_invoice_offcanvas {
        width: 100%;
    }

    
    .clt_admin_dash_invoice_refund_booking_formButton, .clt_admin_dash_invoice_refund_booking_formButton:hover, .clt_admin_dash_invoice_refund_booking_formButton:focus {
        font-size: 16px;
        height: 54px;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-bottom: 5px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        margin-bottom: 2px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
        margin-left: -22px;
        margin-bottom: -1px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
        margin-left: -22px;
    }

    .clt_admin_dash_invoice_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        font-weight: 600;
        padding-top: 1.5px;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_admin_dash_export_check .form-check-input {
        margin-top: 16px;
        margin-left: -10px;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_admin_dash_invoice_offcanvas {
        width: 100%;
    }


    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-bottom: 5px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        margin-bottom: 2px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
        margin-left: -22px;
        margin-bottom: -1px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
        margin-left: -22px;
    }

    .clt_admin_dash_invoice_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        font-weight: 600;
        padding-top: 1.5px;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_admin_dash_export_check .form-check-input {
        margin-top: 16px;
        margin-left: -10px;
    }

    .clt_admin_dash_invoice_refund_booking_formButton, .clt_admin_dash_invoice_refund_booking_formButton:hover, .clt_admin_dash_invoice_refund_booking_formButton:focus {
        font-size: 16px;
        height: 54px;
    }

}


@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_admin_dash_invoice_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_header_button {
        font-size: 15.5px;
        width: 17%;
        height: 38px;
        padding-bottom: 2px !important;
        margin-bottom: -7px;
        margin-top: -7px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_admin_dash_invoice_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_export_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }
    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span {
        font-size: 14.5px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_admin_dash_invoice_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_admin_dash_invoice_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_export_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span {
        font-size: 14.5px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_admin_dash_invoice_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_admin_dash_invoice_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_export_innerhead {
        font-size: 22px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_admin_dash_invoice_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_admin_dash_invoice_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_export_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span {
        font-size: 14.5px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_admin_dash_invoice_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_admin_dash_invoice_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_export_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span {
        font-size: 14.5px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_admin_dash_invoice_offcanvas {
        width: 425px;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_admin_dash_invoice_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_admin_dash_invoice_imgdiv img {
        width: 16px;
    }

    .clt_admin_dash_invoice_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_admin_dash_invoice_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_export_innerhead {
        font-size: 22px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
        font-size: 16px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text span {
        font-size: 14.5px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_admin_dash_invoice_offcanvas {
        width: 400px;
    }

    .clt_admin_dash_invoice_offcanvas .form-floating>label {
        padding-left: 17px !important;
        padding-top: 15px !important;
        padding-left: 17px !important;
        padding-top: 15px !important;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
    }

    .clt_admin_dash_invoice_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_admin_dash_invoice_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_admin_dash_invoice_offcanvas_screen1_div button, .clt_admin_dash_invoice_offcanvas_screen1_div button:active, .clt_admin_dash_invoice_offcanvas_screen1_div button:focus, .clt_admin_dash_invoice_offcanvas_screen1_div button:hover {
        font-size: 15px;
    }

    .clt_admin_dash_invoice_footer button, .clt_admin_dash_invoice_footer button:active, .clt_admin_dash_invoice_footer button:focus, .clt_admin_dash_invoice_footer button:hover {
        font-size: 15px;
    }

    .clt_admin_dash_invoice_innerhead {
        font-size: 20px;
    }

    .clt_admin_dash_export_innerhead {
        font-size: 20px;
    }

    .clt_admin_dash_invoice_cardnumber {
        font-size: 15px;
    }

    .clt_admin_dash_invoice_cardname {
        font-size: 15px;
    }

    .clt_admin_dash_invoice_header_button {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 15px !important;
        letter-spacing: -.2px;
        font-weight: 600;
        width: 22%;
        height: 40px;
        padding-bottom: 4px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h2 {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv .inner-div-text h3 {
        font-size: 15px;
        font-weight: 400;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 4.6px;
        letter-spacing: -.2px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv {
        padding-top: 21px;
        padding-right: 0px;
        padding-bottom: 18px;
        padding-left: 0px;
        margin-top: 0px;
        border-bottom: 1px solid #dedddd;
        cursor: pointer;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h2 {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 .inner-div-text h3 {
        font-size: 15px;
        font-weight: 400;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 4.6px;
        letter-spacing: -.2px;
    }

    .clt_admin_dash_invoice_clt-datepicker-sidebar-timeslot-mainDiv2 {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 32px;
        padding-left: 0px;
        margin-top: -1px;
        cursor: pointer;
    }

}