.clt_admin_dash_store_container_outer_wrapper {
    width: 60%;
}

.clt_admin_dash_store_container {
    margin-top: 1.4rem;
}

.clt_admin_dash_store_container_name {
    text-transform: uppercase;
}


.clt_admin_dash_store_container_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.clt_admin_dash_store_container_detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    grid-gap: 15px;
}

.clt_admin_dash_store_container_name {
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    color: #70757a;
    letter-spacing: 0.6px;
    font-size: 12.5px;
    font-weight: 500;
}

.clt_admin_dash_store_container_box_icon {
    background-repeat: no-repeat;
    height: 17px;
    margin-top: 2.5px;
    width: 17px;
    background-size: contain;
}

.clt_admin_dash_store_container_box_name {
    font-weight: 600;
    font-size: 14px;
}

.clt_admin_dash_store_container_box_icon_1 {
    background-image: url(../../../../assets/img/admin_icon_store_1.png);
}

.clt_admin_dash_store_container_box_icon_2 {
    background-image: url(../../../../assets/img/admin_icon_store_2.png);
}

.clt_admin_dash_store_container_box_icon_3 {
    background-image: url(../../../../assets/img/admin_icon_store_3.png);
}


.clt_admin_dash_store_container_wrapper_first {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    width: 100%;
    border-top: 1px solid #dedddd;
    border-left: 1px solid #dedddd;
    border-right: 1px solid #dedddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.clt_admin_dash_store_container_wrapper_first:hover {
    background: #f6f6f6;
    border-top: 1px solid #dedddd;
    border-left: 1px solid #dedddd;
    border-right: 1px solid #dedddd;
}

.clt_admin_dash_store_container_wrapper_first:active {
    background: #eee;
    border-top: 1px solid #dedddd;
    border-left: 1px solid #dedddd;
    border-right: 1px solid #dedddd;
}

.clt_admin_dash_store_container_wrapper_last {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    width: 100%;
    border-bottom: 1px solid #dedddd;
    border-left: 1px solid #dedddd;
    border-right: 1px solid #dedddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}

.clt_admin_dash_store_container_wrapper_last:hover {
    background: #f6f6f6;
}

.clt_admin_dash_store_container_wrapper_last:active {
    background: #eee;
}


.clt_admin_dash_store_container_wrapper_middle {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    width: 100%;
    border: 1px solid #dedddd;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    cursor: pointer;
}

.clt_admin_dash_store_container_wrapper_middle:hover {
    background: #f6f6f6;
    border: 1px solid #dedddd;
}

.clt_admin_dash_store_container_wrapper_middle:active {
    background: #eee;
    border: 1px solid #dedddd;
}


@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_admin_dash_store_container_outer_wrapper {
        width: 100%;
        padding: 0px 20px;
    }


    .clt_admin_dash_store_container_box_name {
        font-size: 16px;
        margin-top: 0px;
    }

    .clt_admin_dash_store_container_box_icon {
        background-repeat: no-repeat;
        height: 22px;
        margin-top: 3.4px;
        width: 20px;
        background-size: contain;
    }

    .clt_admin_dash_store_container_detail {
        width: 100%;
        gap: 15px;
    }

    .clt_admin_dash_store_container_wrapper_first {
        padding: 21px 20px 24px;
    }

    .clt_admin_dash_store_container_wrapper_middle {
        padding: 21px 20px 26px;
    }

    .clt_admin_dash_store_container_wrapper_last {
        padding: 21px 20px 26px;
    }

    .clt_admin_dash_store_container_name {
        font-size: 13.8px;
        letter-spacing: 0.1px;
    }


}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_admin_dash_store_container_outer_wrapper {
        width: 100%;
        padding: 0px 20px;
    }


    .clt_admin_dash_store_container_box_name {
        font-size: 16px;
        margin-top: 0px;
    }

    .clt_admin_dash_store_container_box_icon {
        background-repeat: no-repeat;
        height: 22px;
        margin-top: 3.4px;
        width: 20px;
        background-size: contain;
    }

    .clt_admin_dash_store_container_detail {
        width: 100%;
        gap: 15px;
    }

    .clt_admin_dash_store_container_wrapper_first {
        padding: 21px 20px 24px;
    }

    .clt_admin_dash_store_container_wrapper_middle {
        padding: 21px 20px 26px;
    }

    .clt_admin_dash_store_container_wrapper_last {
        padding: 21px 20px 26px;
    }

    .clt_admin_dash_store_container_name {
        font-size: 13.8px;
        letter-spacing: 0.1px;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_admin_dash_store_container_outer_wrapper {
        width: 100%;
        padding: 0px 20px;
    }


    .clt_admin_dash_store_container_box_name {
        font-size: 16px;
        margin-top: 0px;
    }

    .clt_admin_dash_store_container_box_icon {
        background-repeat: no-repeat;
        height: 22px;
        margin-top: 3.4px;
        width: 20px;
        background-size: contain;
    }

    .clt_admin_dash_store_container_detail {
        width: 100%;
        gap: 15px;
    }

    .clt_admin_dash_store_container_wrapper_first {
        padding: 21px 20px 24px;
    }

    .clt_admin_dash_store_container_wrapper_middle {
        padding: 21px 20px 26px;
    }

    .clt_admin_dash_store_container_wrapper_last {
        padding: 21px 20px 26px;
    }

    .clt_admin_dash_store_container_name {
        font-size: 13.8px;
        letter-spacing: 0.1px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_admin_dash_store_container_box_name {
        font-weight: 600;
        font-size: 15px;
    }

    .clt_admin_dash_store_container_box_icon {
        height: 18px;
        margin-top: 3.5px;
        width: 18px;
    }

    .clt_admin_dash_store_container {
        margin-top: 1.4rem;
    }

    .clt_admin_dash_store_container_wrapper_first {
        padding: 21px 20px 23px;
    }

    .clt_admin_dash_store_container_wrapper_middle {
        padding: 20px 20px 24px;
    }

    .clt_admin_dash_store_container_wrapper_last {
        padding: 20px 20px 24px;
    }

    .clt_admin_dash_store_container_name {
        font-size: 13.5px;
    }



}