.clt_auth_user_offcanvas {
    width: 385px;
    z-index: 1055;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

/* hide re-captcha icon */
.rc-anchor-normal-footer {
    display: none;
    /* height: 0px; */
    /* vertical-align: top; */
    /* width: 0px; */
}

.rc-anchor-invisible {
    display: none;
}

.grecaptcha-badge {
    opacity: 0;
}


.clt_auth_user_offcanvas_header {
    padding-top: 14px;
    padding-right: 24px;
    padding-bottom: 17px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
    
}

.clt_auth_user_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_auth_user_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_auth_user_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_auth_user_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_auth_user_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_auth_user_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}







.clt_price_simulator_offcanvas_header {
    padding-top: 14px;
    padding-right: 24px;
    padding-bottom: 17px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
    
}

.clt_price_simulator_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_price_simulator_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_price_simulator_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_price_simulator_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_price_simulator_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_price_simulator_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}

.clt_auth_user_offcanvas_body {
    padding: 20px 0px 0px 0px;
}

.clt_auth_user_offcanvas_variant_2nd .clt_auth_user_offcanvas_body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.clt_price_simulator_offcanvas .clt_auth_user_offcanvas_body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.clt_auth_user_offcanvas_body_mainrow {
    margin-bottom: 40px;
    margin: 0 25px;
}

.clt_auth_user_offcanvas_body .clt_auth_user_offcanvas_body_mainrow:first-child {
    margin-top: 25px;
}

.clt_auth_user_offcanvas_body_maindiv {
    margin-bottom: 20px;
}

.clt_auth_user_offcanvas_body_secdiv {
    margin-bottom: 30px;
    cursor: pointer;
}

.clt_auth_user_offcanvas_body_category {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin: 20px 0px;
    letter-spacing: -0.2px;
}

.clt_auth_user_offcanvas_body_product {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
}

.clt_auth_user_offcanvas_body_secondary {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 500;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
}

.pfr_loginModalContent {
    border-radius: 20px;
    max-height: 760px;
    height: 760px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 40px;
}

.modal-content::-webkit-scrollbar {
    display: none;
}

.pfr_loginModalDialog {
    max-width: 500px;
}

.pfr_loginModalBody::-webkit-scrollbar {
    display: none;
}

.pfr_loginModalHeader {
    flex-direction: row;
    border: none;
    padding: 0px;
}

.pfr_loginModalHeader .btn-close,
.pfr_loginModalHeader .btn-close:focus {
    padding: 0;
    margin: 0;
    color: #242424;
    border-radius: 10px;
    background-color: #eeeeee;
    background-size: 14px;
    padding: 20px;
    width: 7px;
    height: 7px;
    opacity: 1;
    outline: none;
    box-shadow: none;
}

.pfr_loginModalHeader .btn-back,
.pfr_loginModalHeader .btn-back:focus {
    background: #f3f3f3 url('../../../assets/img/back_arrow.png') center/1em auto no-repeat;
    background-size: 9px;
}

.pfr_loginModalHeadCreer {
    color: #242424;
    background-color: #eeeeee;
    border-radius: 40px;
    padding: 13px 15px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.1px;
    cursor: pointer;
}

.pfr_loginModalBody {
    padding: 0px 0px;
    margin-top: 22px;
}

.pfr_loginModalH2 {
    font-size: 21px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 10px;
    line-height: 27px;
    letter-spacing: -.2px;
    word-break: break-word;
}

.pfr_loginModalH3 {
    font-size: 22px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 8px;
    line-height: 29px;
    letter-spacing: -.2px;
    word-break: break-word;
}



.pfr_loginModalp {
    font-size: 14.5px;
    font-weight: 400;
    color: #242424;
    margin-bottom: 3.5px;
    line-height: 21px;
}

.pfr_loginModalSeparatorDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
}

.pfr_loginModalSeparatorDiv span {
    font-size: 11px;
    font-weight: 500;
    color: #242424;
    padding: 0 11px;
}

.pfr_loginModalSeparator {
    height: 1px;
    width: 100%;
    background: #dedddd;
}

.pfr_loginModalBtn,
.pfr_loginModalBtn:hover,
.pfr_loginModalBtn:active,
.pfr_loginModalBtn:focus,
.pfr_loginModalBtn:active {
    background: #000 !important;
    border: 0px solid #000 !important;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.1px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding-top: 1.5px;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    height: 48px;
    margin-top: 10px;
}

.pfr_loginModalBtn:hover {
    background: #2d2d2d !important;
}

.pfr_loginModalBtn:active {
    background: #5c5c5c !important;
}

.pfr_loginModalBtn:disabled {
    opacity: 0.32 !important;
}

.pfr_loginModalBottomBtnCol div {
    margin-bottom: 20px;
}

.pfr_loginModalBottomBtnCol div:last-child {
    margin-bottom: 0px;
}

.pfr_loginModalIconBtn {
    background: #fff !important;
    color: #242424 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    padding-top: 2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #dedddd !important;
    height: 56px;
    position: relative;
}

.pfr_loginModalIconBtn:hover {
    background: #f6f6f6 !important;
}

.pfr_loginModalIconBtn:active {
    background: #eee !important;
}

.pfr_loginModalIconBtn img {
    width: 18px;
    position: absolute;
    left: 15px;
}

.pfr_loginModalTelInputDiv {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}

.pfr_loginModalTelInput {
    border: 1px solid #dedddd;
    border-radius: 10px;
    height: 56px;
    width: 100%;
}

.pfr_loginModalTelInput:hover {
    background: #f6f6f6 !important;
}

.pfr_loginModalTelInput:active {
    background: #eee !important;
}

.pfr_loginModalTelInput:focus {
    background: #fafafa !important;
}

.pfr_loginModalTelInput .PhoneInputCountry {
    border: 0;
    border-right: 1px solid #dedddd;
    padding: 10px;
    outline: 0;
}



.pfr_loginModalTelInput .PhoneInputCountryIcon--border {
    box-shadow: none !important;
    outline: none !important;
    width: 18px;
    height: 12px;
}

.pfr_loginModalTelInput .PhoneInputCountrySelectArrow {
    outline: 0;
    display: block;
    content: '';
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: #242424;
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.pfr_loginModalTelInput input {
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    z-index: 2;
    padding-left: 9px;
    padding-top: 12px;
}

.pfr_loginModalTelInputInnerDiv {
    position: relative;
}

.pfr_loginModalTelInputLabel {
    position: absolute;
    top: 0;
    left: 65px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
}

.pfr_loginModalTelInputInnerDiv .PhoneInput--focus~.pfr_loginModalTelInputLabel,
.pfr_loginModalTelInputLabel.toFloat {
    opacity: .7;
    transform: scale(.85) translateY(-0.35rem) translateX(0.15rem);
    padding-top: 0.65rem;
    padding-right: 1.01rem;
    padding-bottom: 1.15rem;
    padding-left: 0rem;
    font-size: 14px;
    font-weight: 400;
}

.pfr_inputFloat.form-floating>.form-control:focus~label,
.pfr_inputFloat.form-floating>.form-control:not(:placeholder-shown)~label,
.pfr_inputFloat.form-floating>.form-select~label {
    opacity: .7;
    transform: scale(.85) translateY(-0.35rem) translateX(0.28rem);
    padding-top: 0.8rem !important;
    padding-right: 1.01rem !important;
    padding-bottom: 0.95rem !important;
    padding-left: 1.01rem !important;
    font-size: 14.5px;
}

.pfr_inputFloatDiv {
    margin-bottom: 10px;

}

.pfr_inputFloat input {
    height: 56px !important;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 500;
    color: #242424;
    padding: 10px 17px 0 !important;
    border-radius: 10px;
    border: 1px solid #dedddd;
    outline: none;
    box-shadow: none;
}

.pfr_inputFloat_role_access {
    height: 56px !important;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 500;
    color: #242424;
    padding-top: 0px !important;
    padding-right: 17px !important;
    padding-bottom: 5px !important;
    padding-left: 17px !important;
    border-radius: 10px;
    border: 1px solid #dedddd !important;
    outline: none;
    box-shadow: none;
}

.pfr_inputFloat input:hover {
    background: #f6f6f6;
}

.pfr_inputFloat input:active {
    background: #eee;
}

.pfr_inputFloat input:focus {
    background: #fafafa;
}

.pfr_loginEmail label {
    padding: 17px 18px;
    font-size: 17px;
    font-weight: 400;
    color: #242424;
}

.pfr_loginShowPass {
    width: 18px;
    position: absolute;
    right: 24px;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.pfr_loginShowPass:hover {
    opacity: 0.7;
}

.pfr_loginShowPass:active {
    opacity: 0.4;
}

.pfr_loginShowPass:focus {
    opacity: 0.4;
}

.pfr_loginForgotPassDiv {
    margin: 15px 10px;
    margin-left: 5px;
    font-weight: 500;
    font-size: 14.5px;
    color: #242424;
    cursor: pointer;
    background: #eee;
    border-radius: 50px;
    padding-top: 6px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    width: max-content;
}

.pfr_loginForgotPassDiv:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.pfr_loginForgotPassDiv:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.pfr_loginModal_Body {
    padding: 0px 24px;
}

.clt_auth_user_offcanvas_variant_2nd .pfr_loginModal_Body {
    width: 500px;
}

.clt_auth_user_offcanvas_variant_2nd .pfr_loginModal_footer {
    margin: 0px auto;
    width: 500px;
}

.clt_price_simulator_offcanvas .pfr_loginModal_Body {
    width: 678px;
}

.clt_price_simulator_offcanvas .pfr_loginModal_footer {
    margin: 0px auto;
    width: 500px;
}

.pfr_loginModal_Capitalize {
    text-transform: capitalize;
}

.pfr_loginModal_footer {
    padding-top: 1.5px;
    padding-right: 24px;
    padding-bottom: 17.5px;
    padding-left: 24px;
}

.pfr_loginModalVerify {
    height: calc(100% - 0px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.pfr_loginModalVerifyDiv {
    width: 100%;
}

.pfr_loginModalVerify .pfr_loginModalSeparatorDiv {
    margin: 10px 0px;
    /* width: 90%; */
}

.pfr_loginModalPassword {
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.pfr_loginModalPasswordDiv {
    width: 100%;
}

.pfr_loginModalPassword .pfr_loginModalSeparatorDiv {
    margin: 10px 0px;
    /* width: 90%; */
}

.pfr_signupModalNameDiv {
    margin-top: 5px;
}

.pfr_signUpNumberVerify {
    width: 100% !important;
}

.pfr_signUpNumberVerify div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pfr_signUpNumberVerify input {
    width: 25% !important;
    height: 66px !important;
    border-radius: 10px !important;
    border: 1px solid #dedddd !important;
    margin: 0px 5px;
    font-size: 12px;
    font-weight: 500;
    color: #242424;
    font-family: 'Inter', sans-serif !important;
}

.pfr_signUpNumberVerify input:nth-child(1) {
    margin-left: 0;
}

.pfr_signUpNumberVerify input:last-child {
    margin-right: 0;
}

.pfr_signUpResendDiv {
    margin-top: -15px;
    margin-bottom: 8px;
}

.pfr_signUpResendP {
    font-size: 16px;
    font-weight: 500;
    margin-top: 18px;
    line-height: 20px;
    color: #757575;
}

.pfr_signUpResendP button {
    color: #242424;
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
    margin: 0;
}


/* Error state css*/
.pfr_inputFloat.error {
    border: 1px solid #d52c05;
    border-radius: 10px;
}

.formInput.error {
    background: #faf0ed;
}

.pfr_inputFloatDiv .formLabel.error {
    color: #c61436;

}

/* Error state css */
.clt_auth_user_offcanvas .form-floating>label {
    padding-left: 18px !important;
    padding-top: 14.6px !important;
    font-size: 13.5px;
    font-weight: 400;
    color: #242424;
    letter-spacing: 0.2px;
}

.clt_auth_user_offcanvas_variant_2nd .form-floating>label {
    padding-left: 18px !important;
    padding-top: 14.6px !important;
    font-size: 13.5px;
    font-weight: 400;
    color: #242424;
    letter-spacing: 0.2px;
}

.clt_price_simulator_offcanvas .form-floating>label {
    padding-left: 18px !important;
    padding-top: 14.6px !important;
    font-size: 13.5px;
    font-weight: 400;
    color: #242424;
    letter-spacing: 0.2px;
}
.toast {
    z-index: 1100; /* Adjust the value based on your layout */
}
@media only screen and (max-width: 600px) and (orientation:portrait) {

    .clt_auth_user_offcanvas_variant_2nd {
        height: 100% !important;
        z-index: 1055;
        margin-top: 0px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .clt_auth_user_offcanvas_variant_2nd .pfr_loginModal_Body {
        width: 100%;
    }

    .clt_auth_user_offcanvas_variant_2nd .pfr_loginModal_footer {
        margin: 0px;
        width: 100%;
    }

    .clt_price_simulator_offcanvas {
        height: 98% !important;
        z-index: 1055;
        margin-top: 0px;
        border-top-right-radius: 26px;
        border-top-left-radius: 26px;
    }

    .clt_price_simulator_offcanvas .pfr_loginModal_Body {
        width: 100%;
    }

    .clt_price_simulator_offcanvas .pfr_loginModal_footer {
        margin: 0px;
        width: 100%;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:hover,
    .pfr_loginModalIconBtn:focus {
        border-radius: 12px;
        font-size: 16px;
        height: 64px;
        padding-left: 8px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:hover,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active {
        font-size: 16px;
        padding-top: 2px;
        border-radius: 12px;
        height: 54px;
        margin-top: 10px;
    }


    .pfr_loginModalTelInput {
        border: 1px solid #dedddd;
        border-radius: 12px;
        height: 64px;
        width: 100%;
    }

    .pfr_inputFloat input,
    .pfr_inputFloat input:active,
    .pfr_inputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        margin-top: 0px;
        padding: 10px 18px 0 !important;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        margin-bottom: 5px;
        padding-bottom: 0px;
        letter-spacing: -.2px;
    }

    .clt_auth_user_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
    }



    .clt_price_simulator_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        margin-bottom: 5px;
        padding-bottom: 0px;
        letter-spacing: -.2px;
    }

    .clt_price_simulator_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-bottom: 7px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        font-weight: 500;
        padding-left: 9px;
        padding-top: 13px;
    }

    .pfr_loginModalTelInputInnerDiv .PhoneInput--focus~.pfr_loginModalTelInputLabel,
    .pfr_loginModalTelInputLabel.toFloat {
        opacity: .7;
        transform: scale(.84) translateY(-0.5rem) translateX(0);
        padding: 1.34rem 1.01rem 1.15rem 0;
        font-size: 15px;
    }

    .pfr_loginModalH2 {
        font-size: 22px;
        margin-bottom: 12px;
        line-height: 31px;
        margin-right: 70px;
    }

    .pfr_loginModalH3 {
        font-size: 26px;
        margin-bottom: 12px;
        line-height: 35px;
        margin-right: 70px;
    }

    .pfr_loginModal_footer {
        padding-top: 1.5px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .pfr_loginModalTelInputLabel {
        left: 65px;
        font-size: 15px;
        font-weight: 500;
        height: 60px;
    }

    .pfr_inputFloat.form-floating>.form-control:focus~label,
    .pfr_inputFloat.form-floating>.form-control:not(:placeholder-shown)~label,
    .pfr_inputFloat.form-floating>.form-select~label {
        opacity: .7;
        transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
        padding-top: 1.02rem !important;
        padding-right: 1.01rem !important;
        padding-bottom: 0.95rem !important;
        padding-left: 1.09rem !important;
        font-size: 15px;
    }

    .clt_auth_user_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_auth_user_offcanvas_variant_2nd .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_price_simulator_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .pfr_loginModalp {
        font-size: 17px;
        font-weight: 400;
        color: #242424;
        margin-bottom: 16px;
        line-height: 24px;
    }

    .pfr_loginModalTelInputDiv {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .clt_auth_user_offcanvas_body {
        padding: 24px 0 0;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/

    .clt_auth_user_offcanvas_variant_2nd {
        height: 100% !important;
        z-index: 1055;
        margin-top: 0px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .clt_auth_user_offcanvas_variant_2nd .pfr_loginModal_Body {
        width: 100%;
    }

    .clt_auth_user_offcanvas_variant_2nd .pfr_loginModalBtn {
        margin: 0px;
        width: 100%;
    }

    .clt_price_simulator_offcanvas {
        height: 98% !important;
        z-index: 1055;
        margin-top: 0px;
        border-top-right-radius: 26px;
        border-top-left-radius: 26px;
    }

    .clt_price_simulator_offcanvas .pfr_loginModal_Body {
        width: 100%;
    }

    .clt_price_simulator_offcanvas .pfr_loginModalBtn {
        margin: 0px;
        width: 100%;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .pfr_loginModalContent {
        max-height: 100%;
        height: 100%;
        padding: 30px;
    }

    .pfr_loginModalDialog {
        max-width: 100%;
    }

    .pfr_loginModalHeader {
        padding: 0px;
    }

    .pfr_loginModalHeader .btn-close,
    .pfr_loginModalHeader .btn-close:focus {
        padding: 0;
        margin: 0;
        border-radius: 10px;
        background-size: 12px;
        padding: 16px;
        width: 15px;
        height: 15px;
    }

    .pfr_loginModalHeader .btn-back,
    .pfr_loginModalHeader .btn-back:focus {
        background-size: 9px;
    }

    .pfr_loginModalHeadCreer {
        padding: 16px 16px;
        font-size: 14px;
        font-weight: 500;
    }

    .pfr_loginModalBody {
        padding: 0px 0px;
        overflow-x: hidden;
        margin-top: 20px;
    }

    .pfr_loginModalSeparatorDiv span {
        font-size: 12px;
        font-weight: 500;
        padding: 0 10px;
    }

    .pfr_loginModalBottomBtnCol div {
        margin-bottom: 20px;
    }

    .pfr_loginModalBottomBtnCol div:last-child {
        margin-bottom: 0px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:hover,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active {
        border-radius: 12px;
        font-size: 16px;
        height: 64px;
        padding-left: 8px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput .PhoneInputCountry {
        padding: 10px;
    }

    .pfr_signUpNumberVerify div {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .pfr_signUpNumberVerify input {
        height: 62px !important;
        margin: 0px 5px;
        font-size: 16px;
        font-weight: 500;
    }

    .pfr_signUpResendDiv {
        margin-top: -18px;
        margin-bottom: 3px;
    }

    .pfr_inputFloat.form-floating>.form-control:focus~label,
    .pfr_inputFloat.form-floating>.form-control:not(:placeholder-shown)~label,
    .pfr_inputFloat.form-floating>.form-select~label {
        opacity: .7;
        transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
        padding-top: 1.02rem !important;
        padding-right: 1.01rem !important;
        padding-bottom: 0.95rem !important;
        padding-left: 1.09rem !important;
        font-size: 15px;
    }

    .pfr_signUpResendP {
        font-size: 15px;
        font-weight: 500;
        margin-top: 18px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:hover,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active {
        font-size: 16px;
        padding-top: 2px;
        border-radius: 12px;
        height: 54px;
        margin-top: 10px;
    }

    .pfr_loginModalTelInput {
        border: 1px solid #dedddd;
        border-radius: 12px;
        height: 64px;
        width: 100%;
    }

    .pfr_inputFloat input,
    .pfr_inputFloat input:active,
    .pfr_inputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        margin-top: 0px;
        padding: 10px 18px 0 !important;
    }

    .pfr_loginModalTelInputInnerDiv .PhoneInput--focus~.pfr_loginModalTelInputLabel,
    .pfr_loginModalTelInputLabel.toFloat {
        opacity: .7;
        transform: scale(.84) translateY(-0.5rem) translateX(0);
        padding: 1.34rem 1.01rem 1.15rem 0;
        font-size: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        font-weight: 500;
        padding-left: 9px;
        padding-top: 13px;
    }

    .pfr_loginModalH2 {
        font-size: 22px;
        margin-bottom: 12px;
        line-height: 31px;
        margin-right: 70px;
    }

    .pfr_loginModalH3 {
        font-size: 26px;
        margin-bottom: 12px;
        line-height: 35px;
        margin-right: 70px;
    }

    .pfr_loginModal_footer {
        padding-top: 1.5px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .pfr_loginModalTelInputLabel {
        left: 65px;
        font-size: 15px;
        font-weight: 500;
        height: 60px;
    }


    .clt_auth_user_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_auth_user_offcanvas_variant_2nd .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_price_simulator_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        margin-bottom: 5px;
        padding-bottom: 0px;
        letter-spacing: -.2px;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
    }




    .clt_price_simulator_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        margin-bottom: 5px;
        padding-bottom: 0px;
        letter-spacing: -.2px;
    }

    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-bottom: 9px;
    }

    .pfr_loginModalp {
        font-size: 17px;
        font-weight: 400;
        color: #242424;
        margin-bottom: 16px;
        line-height: 24px;
    }

    .pfr_loginModalTelInputDiv {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .clt_auth_user_offcanvas_body {
        padding: 24px 0 0;
    }

    .clt_auth_user_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_price_simulator_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/

    .clt_auth_user_offcanvas_variant_2nd {
        height: 100% !important;
        z-index: 1055;
        margin-top: 0px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .clt_auth_user_offcanvas_variant_2nd .pfr_loginModal_Body {
        width: 100%;
    }

    .clt_auth_user_offcanvas_variant_2nd .pfr_loginModal_footer {
        margin: 0px;
        width: 100%;
    }

    .clt_price_simulator_offcanvas {
        height: 98% !important;
        z-index: 1055;
        margin-top: 0px;
        border-top-right-radius: 26px;
        border-top-left-radius: 26px;
    }

    .clt_price_simulator_offcanvas .pfr_loginModal_Body {
        width: 100%;
    }

    .clt_price_simulator_offcanvas .pfr_loginModal_footer {
        margin: 0px;
        width: 100%;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .pfr_loginModalContent {
        max-height: 100%;
        height: 100%;
        padding: 30px;
    }

    .pfr_loginModalDialog {
        max-width: 100%;
    }

    .pfr_loginModalHeader {
        padding: 0px;
    }

    .pfr_loginModalHeader .btn-close,
    .pfr_loginModalHeader .btn-close:focus {
        padding: 0;
        margin: 0;
        border-radius: 10px;
        background-size: 12px;
        padding: 16px;
        width: 15px;
        height: 15px;
    }

    .pfr_loginModalHeader .btn-back,
    .pfr_loginModalHeader .btn-back:focus {
        background-size: 9px;
    }

    .pfr_loginModalHeadCreer {
        padding: 16px 16px;
        font-size: 14px;
        font-weight: 500;
    }

    .pfr_loginModalBody {
        padding: 0px 0px;
        overflow-x: hidden;
        margin-top: 20px;
    }

    .pfr_loginModalSeparatorDiv span {
        font-size: 12px;
        font-weight: 500;
        padding: 0 10px;
    }

    .pfr_loginModalBottomBtnCol div {
        margin-bottom: 20px;
    }

    .pfr_loginModalBottomBtnCol div:last-child {
        margin-bottom: 0px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:hover,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active {
        border-radius: 12px;
        font-size: 16px;
        height: 64px;
        padding-left: 8px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput .PhoneInputCountry {
        padding: 10px;
    }

    .pfr_signUpNumberVerify div {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .pfr_signUpNumberVerify input {
        height: 62px !important;
        margin: 0px 5px;
        font-size: 12px;
        font-weight: 500;
    }

    .pfr_signUpResendDiv {
        margin-top: -18px;
        margin-bottom: 3px;
    }

    .pfr_inputFloat.form-floating>.form-control:focus~label,
    .pfr_inputFloat.form-floating>.form-control:not(:placeholder-shown)~label,
    .pfr_inputFloat.form-floating>.form-select~label {
        opacity: .7;
        transform: scale(.84) translateY(-0.4rem) translateX(0.28rem);
        padding-top: 1.02rem !important;
        padding-right: 1.01rem !important;
        padding-bottom: 0.95rem !important;
        padding-left: 1.09rem !important;
        font-size: 15px;
    }

    .pfr_signUpResendP {
        font-size: 15px;
        font-weight: 500;
        margin-top: 18px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:hover,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active {
        font-size: 16px;
        padding-top: 2px;
        border-radius: 12px;
        height: 54px;
        margin-top: 10px;
    }

    .pfr_loginModalTelInput {
        border: 1px solid #dedddd;
        border-radius: 12px;
        height: 64px;
        width: 100%;
    }

    .pfr_inputFloat input,
    .pfr_inputFloat input:active,
    .pfr_inputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        margin-top: 0px;
        padding: 10px 18px 0 !important;
    }

    .pfr_loginModalTelInputInnerDiv .PhoneInput--focus~.pfr_loginModalTelInputLabel,
    .pfr_loginModalTelInputLabel.toFloat {
        opacity: .7;
        transform: scale(.84) translateY(-0.5rem) translateX(0);
        padding: 1.34rem 1.01rem 1.15rem 0;
        font-size: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        font-weight: 500;
        padding-left: 9px;
        padding-top: 13px;
    }

    .pfr_loginModalH2 {
        font-size: 22px;
        margin-bottom: 12px;
        line-height: 31px;
        margin-right: 70px;
    }

    .pfr_loginModalH3 {
        font-size: 26px;
        margin-bottom: 12px;
        line-height: 35px;
        margin-right: 70px;
    }

    .pfr_loginModal_footer {
        padding-top: 1.5px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .pfr_loginModalTelInputLabel {
        left: 65px;
        font-size: 15px;
        font-weight: 500;
        height: 60px;
    }

    .clt_auth_user_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_auth_user_offcanvas_variant_2nd .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_price_simulator_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        margin-bottom: 5px;
        padding-bottom: 0px;
        letter-spacing: -.2px;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
    }



    .clt_price_simulator_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        margin-bottom: 5px;
        padding-bottom: 0px;
        letter-spacing: -.2px;
    }

    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-bottom: 7px;
    }
    .pfr_loginModalp {
        font-size: 17px;
        font-weight: 400;
        color: #242424;
        margin-bottom: 16px;
        line-height: 24px;
    }

    .pfr_loginModalTelInputDiv {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .clt_auth_user_offcanvas_body {
        padding: 24px 0 0;
    }

    .clt_auth_user_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_price_simulator_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }



}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_auth_user_offcanvas {
        width: 400px;
    }

    .clt_auth_user_offcanvas {
        width: 500px;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_price_simulator_offcanvas_header .btn-back img {
        margin-bottom: 25px;
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_price_simulator_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .pfr_loginModal_Body {
        padding: 0px 24px;
    }

    .pfr_loginModal_footer {
        padding: 1.5px 24px 20px;
    }

    .pfr_loginModalH2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 38px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:hover {
        font-size: 16px;
        padding-top: 1.5px;
        height: 52px;
        margin-top: 10px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active,
    .pfr_loginModalIconBtn:hover {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        height: 58px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        padding-left: 9px;
        padding-top: 12px;
    }

    .pfr_loginModalTelInput {
        border-radius: 10px;
        height: 60px;
    }

    .pfr_loginModalTelInputLabel {
        font-size: 15px;
        left: 75px;
        height: 53px;
    }

    .pfr_loginModalTelInput .PhoneInputCountryIcon--border {
        width: 22px;
        margin-left: 2.5px;
        height: 16px;
    }

    .pfr_loginModalTelInput .PhoneInputCountrySelectArrow {
        width: 6px;
        margin-top: -4px;
        margin-left: 9px !important;
        height: 6px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_auth_user_offcanvas {
        width: 400px;
    }

    .clt_auth_user_offcanvas {
        width: 500px;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-bottom: 15px;
        margin-top: -1.8px;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_price_simulator_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .pfr_loginModal_Body {
        padding: 0px 24px;
    }

    .pfr_loginModal_footer {
        padding: 1.5px 24px 20px;
    }

    .pfr_loginModalH2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 38px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:hover {
        font-size: 16px;
        padding-top: 1.5px;
        height: 52px;
        margin-top: 10px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active,
    .pfr_loginModalIconBtn:hover {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        height: 58px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        padding-left: 9px;
        padding-top: 12px;
    }

    .pfr_loginModalTelInput {
        border-radius: 10px;
        height: 60px;
    }

    .pfr_loginModalTelInputLabel {
        font-size: 15px;
        left: 75px;
        height: 53px;
    }

    .pfr_loginModalTelInput .PhoneInputCountryIcon--border {
        width: 22px;
        margin-left: 2.5px;
        height: 16px;
    }

    .pfr_loginModalTelInput .PhoneInputCountrySelectArrow {
        width: 6px;
        margin-top: -4px;
        margin-left: 9px !important;
        height: 6px;
    }

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    /*ipad pro*/
    .clt_auth_user_offcanvas {
        width: 400px;
    }

    .clt_auth_user_offcanvas {
        width: 500px;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_price_simulator_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .pfr_loginModal_Body {
        padding: 0px 24px;
    }

    .pfr_loginModal_footer {
        padding: 1.5px 24px 20px;
    }

    .pfr_loginModalH2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 38px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:hover {
        font-size: 16px;
        padding-top: 1.5px;
        height: 52px;
        margin-top: 10px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active,
    .pfr_loginModalIconBtn:hover {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        height: 58px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        padding-left: 9px;
        padding-top: 12px;
    }

    .pfr_loginModalTelInput {
        border-radius: 10px;
        height: 60px;
    }

    .pfr_loginModalTelInputLabel {
        font-size: 15px;
        left: 75px;
        height: 53px;
    }

    .pfr_loginModalTelInput .PhoneInputCountryIcon--border {
        width: 22px;
        margin-left: 2.5px;
        height: 16px;
    }

    .pfr_loginModalTelInput .PhoneInputCountrySelectArrow {
        width: 6px;
        margin-top: -4px;
        margin-left: 9px !important;
        height: 6px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_auth_user_offcanvas {
        width: 400px;
    }

    .clt_auth_user_offcanvas {
        width: 500px;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }


    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-bottom: 25px;
        margin-top: -2px;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_price_simulator_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }


    .pfr_loginModal_Body {
        padding: 0px 24px;
    }

    .pfr_loginModal_footer {
        padding: 1.5px 24px 20px;
    }

    .pfr_loginModalH2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 38px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:hover {
        font-size: 16px;
        padding-top: 1.5px;
        height: 52px;
        margin-top: 10px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active,
    .pfr_loginModalIconBtn:hover {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        height: 58px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        padding-left: 9px;
        padding-top: 12px;
    }

    .pfr_loginModalTelInput {
        border-radius: 10px;
        height: 60px;
    }

    .pfr_loginModalTelInputLabel {
        font-size: 15px;
        left: 75px;
        height: 53px;
    }

    .pfr_loginModalTelInput .PhoneInputCountryIcon--border {
        width: 22px;
        margin-left: 2.5px;
        height: 16px;
    }

    .pfr_loginModalTelInput .PhoneInputCountrySelectArrow {
        width: 6px;
        margin-top: -4px;
        margin-left: 9px !important;
        height: 6px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }


    .clt_auth_user_offcanvas {
        width: 400px;
    }

    .clt_auth_user_offcanvas {
        width: 500px;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }


    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_price_simulator_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .pfr_loginModal_Body {
        padding: 0px 24px;
    }

    .pfr_loginModal_footer {
        padding: 1.5px 24px 20px;
    }

    .pfr_loginModalH2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 38px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:hover {
        font-size: 16px;
        padding-top: 1.5px;
        height: 52px;
        margin-top: 10px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active,
    .pfr_loginModalIconBtn:hover {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        height: 58px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        padding-left: 9px;
        padding-top: 12px;
    }

    .pfr_loginModalTelInput {
        border-radius: 10px;
        height: 60px;
    }

    .pfr_loginModalTelInputLabel {
        font-size: 15px;
        left: 75px;
        height: 53px;
    }

    .pfr_loginModalTelInput .PhoneInputCountryIcon--border {
        width: 22px;
        margin-left: 2.5px;
        height: 16px;
    }

    .pfr_loginModalTelInput .PhoneInputCountrySelectArrow {
        width: 6px;
        margin-top: -4px;
        margin-left: 9px !important;
        height: 6px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }


    .clt_auth_user_offcanvas {
        width: 400px;
    }

    .clt_auth_user_offcanvas {
        width: 500px;
    }

    .clt_auth_user_offcanvas {
        width: 100%;
    }

    .clt_auth_user_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -1.8px;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_auth_user_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }


    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 8px;
        margin-bottom: 16px;
        margin-top: -1.8px;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        margin-left: 24px;
        font-size: 16px;
        padding-bottom: 2px;
    }

    .clt_price_simulator_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .pfr_loginModal_Body {
        padding: 0px 24px;
    }

    .pfr_loginModal_footer {
        padding: 1.5px 24px 20px;
    }

    .pfr_loginModalH2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: 38px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:hover {
        font-size: 16px;
        padding-top: 1.5px;
        height: 52px;
        margin-top: 10px;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active,
    .pfr_loginModalIconBtn:hover {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 2px;
        height: 58px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 15px;
    }

    .pfr_loginModalTelInput input {
        height: 45px;
        font-size: 16px;
        padding-left: 9px;
        padding-top: 12px;
    }

    .pfr_loginModalTelInput {
        border-radius: 10px;
        height: 60px;
    }

    .pfr_loginModalTelInputLabel {
        font-size: 15px;
        left: 75px;
        height: 53px;
    }

    .pfr_loginModalTelInput .PhoneInputCountryIcon--border {
        width: 22px;
        margin-left: 2.5px;
        height: 16px;
    }

    .pfr_loginModalTelInput .PhoneInputCountrySelectArrow {
        width: 6px;
        margin-top: -4px;
        margin-left: 9px !important;
        height: 6px;
    }

    .pfr_loginModalH3 {
        font-size: 26px;
        margin-bottom: 12px;
        line-height: 35px;
        margin-right: 70px;
    }
}

@media only screen and (min-width: 1400px) {
    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/

    .clt_auth_user_offcanvas_variant_2nd {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas {
        height: calc(100% - 20px) !important;
        z-index: 1055;
        margin-top: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .clt_price_simulator_offcanvas_header .btn-back img {
        width: 7px;
        margin-bottom: 12px;
    }

    .clt_auth_user_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_price_simulator_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }


    .clt_auth_user_offcanvas {
        width: 400px;
    }

    .pfr_loginModalContent {
        border-radius: 20px;
        max-height: 760px;
        height: 760px;
        padding: 40px;
    }

    .pfr_loginModalDialog {
        max-width: 500px;
    }

    .pfr_loginModalHeader {
        padding: 0px;
    }

    .pfr_loginModalHeader .btn-close,
    .pfr_loginModalHeader .btn-close:focus {
        padding: 0;
        margin: 0;
        border-radius: 10px;
        background-size: 12px;
        padding: 20px;
        width: 7px;
        height: 7px;
    }

    .pfr_loginModalHeader .btn-back,
    .pfr_loginModalHeader .btn-back:focus {
        background-size: 9px;
    }

    .pfr_loginModalHeader .btn-back,
    .pfr_loginModalHeader .btn-back:focus {
        background-size: 9px;
    }

    .pfr_loginModalHeadCreer {
        padding: 13px 15px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.1px;
    }

    .pfr_loginModalBody {
        padding: 0px 0px;
        margin-top: 22px;
    }

    .pfr_loginModalSeparatorDiv span {
        font-size: 13px;
        font-weight: 500;
        padding: 0 11px;
    }

    .pfr_loginModalBottomBtnCol div {
        margin-bottom: 22px;
    }

    .pfr_loginModalBottomBtnCol div:last-child {
        margin-bottom: 0px;
    }

    .pfr_loginModalH2 {
        font-size: 24px;
        line-height: 32px;
    }

    .pfr_loginModalTelInputInnerDiv .PhoneInput--focus~.pfr_loginModalTelInputLabel,
    .pfr_loginModalTelInputLabel.toFloat {
        opacity: .7;
        transform: scale(.85) translateY(-0.35rem) translateX(0.15rem);
        padding: 0.65rem 1.01rem 1.15rem 0;
        font-size: 15px;
        font-weight: 400;
    }

    .pfr_loginModalIconBtn,
    .pfr_loginModalIconBtn:hover,
    .pfr_loginModalIconBtn:focus,
    pfr_loginModalIconBtn:active {
        border-radius: 10px;
        font-size: 15px;
        font-weight: 600;
        height: 60px;
    }

    .pfr_loginModalTelInput {
        border-radius: 10px;
        height: 60px;
        width: 100%;
    }

    .pfr_loginModalTelInput input {
        width: 100%;
        height: 45px;
        font-size: 15px;
        padding-left: 9px;
        padding-top: 12px;
    }

    .pfr_inputFloat input,
    .pfr_inputFloat input:active,
    .pfr_inputFloat input:focus {
        height: 60px !important;
        font-size: 15px;
        margin-top: 0px;
        font-weight: 500;
        padding: 10px 17px 0 !important;
        border-radius: 10px;
    }

    .pfr_loginModalIconBtn img {
        width: 20px;
        left: 20px;
    }

    .pfr_loginModalBtn,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:focus,
    .pfr_loginModalBtn:active,
    .pfr_loginModalBtn:hover {
        font-size: 15px;
    }

    .clt_auth_user_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_price_simulator_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }
    .pfr_loginModalTelInput .PhoneInputCountry {
        padding: 10px;
    }

    .pfr_signUpNumberVerify div {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .pfr_signUpNumberVerify input {
        height: 66px !important;
        margin: 0px 5px;
        font-size: 12px;
        font-weight: 500;
    }

    .pfr_signUpResendDiv {
        margin-top: -15px;
        margin-bottom: 8px;
    }

    .pfr_signUpResendP {
        font-size: 16px;
        font-weight: 500;
        margin-top: 18px;
        line-height: 20px;
    }

    .pfr_inputFloat.form-floating>.form-control:focus~label,
    .pfr_inputFloat.form-floating>.form-control:not(:placeholder-shown)~label,
    .pfr_inputFloat.form-floating>.form-select~label {
        opacity: .7;
        transform: scale(.80) translateY(-0.35rem) translateX(0.28rem);
        padding: 1rem 1.01rem 0.95rem !important;
        font-size: 15px;
    }

    .pfr_loginModalH3 {
        font-size: 26px;
        margin-bottom: 12px;
        line-height: 36px;
        margin-right: 70px;
    }
}