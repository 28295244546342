.clt_amount_paid_products2_offcanvas_body {
    padding-right: 24px !important;
    padding-top: 24px !important;
    padding-left: 24px !important;
}

.clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-bottom: -7px;
    margin-top: -7px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: -.2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 0px solid #fff;
    width: 28%;
    height: 36px;
}

.clt_amount_paid_payment_header_button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt_amount_paid_payment_header_button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-bottom: -7px;
    margin-top: -7px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: -.2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 0px solid #fff;
    width: 28%;
    height: 36px;
}

.clt_amount_paid_header_delete_button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt_amount_paid_header_delete_button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-bottom: -7px;
    margin-top: -7px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: -.2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 0px solid #fff;
    width: 17%;
    height: 36px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
}

.clt_amount_paid_header_add_button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt_amount_paid_header_add_button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-bottom: -7px;
    margin-top: -7px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: -.2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 0px solid #fff;
    width: 15%;
    height: 36px;
}

.clt_amount_paid_header_edit_button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt_amount_paid_header_edit_button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}


.clt-amount-paid-admin-event-body-no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

.clt_amount_paid_admin_dash_manage_event_header h2 {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 14px;
    letter-spacing: -0.2px;
}

.clt_amount_paid_admin_dash_manage_event_header h3 {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 10px;
    letter-spacing: -0.2px;
}

.clt_amount_paid_admin_dash_manage_event_header h4 {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-amount-paid-detail-section-6-header p {
    font-size: 15px;
    font-weight: 400;
    margin-top: 6px;
}

.clt-amount-paid-detail-section-6-header h5 {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-amount-paid-admin-event-s1 {
    padding-left: 14px;
    padding-right: 14px;
}

.clt-amount-paid-admin-event-list-div {
    padding: 10px;
}

.clt-amount-paid-admin-event-list-div-body {
    margin-top: 15px !important;
    border: 1px solid #dedddd;
    border-radius: 15px;
    overflow: visible !important;
}

.clt-amount-paid-admin-event-list-div-body .clt-amount-paid-admin-event-list-div:hover {
    border-radius: 15px;
    background: #f6f6f6;
}



.clt-amount-paid-admin-event-list-div:active {
    background: #eee;
}

.clt-amount-paid-admin-event-list-div-body .row:last-child .clt-amount-paid-admin-event-list-div {
    border-bottom: 0px;
}

.clt-amount-paid-admin-event-list-div img.img-dragdrop {
    width: 3%;
}

.clt-amount-paid-admin-event-list-div span.dragdrop-counter {
    font-size: 14px;
    font-weight: 500;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-amount-paid-admin-event-list-div .inner-icon-title-div {
    display: flex;
    width: 75%;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.clt-amount-paid-admin-event-list-div .inner-icon-title-div img {
    width: 17%;
    border: 1px solid #dedddd;
    border-radius: 10px;
    margin-right: 10px;
    padding-top: 9px;
    padding-right: 9px;
    padding-bottom: 11px;
    padding-left: 9px;
}

.clt-amount-paid-admin-event-list-div .inner-icon-title-div h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-amount-paid-admin-event-list-div .inner-icon-title-div p {
    font-size: 15px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-top: 1px;
    margin-bottom: 0;
    line-height: 23px;
    letter-spacing: -0.2px;
}

.clt-amount-paid-admin-event-screen2-tabs {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
    font-size: 13.5px;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-family: 'Inter';
    margin-bottom: 0px;
    padding-bottom: 16.5px;
    padding-top: 5px;
    margin-top: 0px;
    color: #70757a;
    line-height: 16px;
    border: none;
    padding-left: 0;
    padding-right: 0;
    margin-right: 25px;
}

.clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link.active {
    color: rgb(0 114 255);
    border-bottom: 2px solid #007eff;
}

.clt-amount-paid-admin-event-screen2-tab-g-s1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.clt-amount-paid-admin-event-screen2-tab-g-s1 img {
    width: 15%;
    border: 1px solid #dedddd;
    border-radius: 12px;
    padding: 13px 13px 15.5px;
    margin-right: 10px;
}

.clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 2px;
    margin-left: 2px;
    letter-spacing: -.2px;
}

.clt-amount-paid-admin-event-screen2-tabs-body {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-amount-paid-admin-event-screen2-tab-g-s2 p {
    font-size: 14px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-top: 1px;
    margin-bottom: 0;
    line-height: 23px;
    letter-spacing: -0.2px;
}

.clt-amount-paid-admin-event-screen2-info {
    border: 1px solid #dedddd;
    padding: 17px 20px 22px;
    border-radius: 0px;
    cursor: pointer;
    user-select: none;
    border-bottom: 0;
}

.clt-amount-paid-admin-event-screen2-info:hover {
    border: 1px solid #dedddd;
    background: #f6f6f6;
}

.clt-amount-paid-admin-event-screen2-info:active {
    background: #eee;
}

.clt-amount-paid-admin-event-screen2-info .inner-div img {
    width: 17px;
}

.clt-amount-paid-section-hr-pd2 {
    padding: 25px 15px;
}

.clt-amount-paid-admin-event-screen2-info .inner-div h2 {
    margin-left: 15px;
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-amount-paid-admin-event-screen2-info i {
    font-size: 14px;
}

.clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:nth-child(1) .clt-amount-paid-admin-event-screen2-info {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:last-child .clt-amount-paid-admin-event-screen2-info {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid #dedddd;
}

.clt-amount-paid-admin-event-info-input-div {
    margin-top: 0px;
}


.clt-amount-paid-admin-event-input-form-lastchild>div:first-of-type .clt-amount-paid-adminInputFloat input {
    border-top: 1px solid #dedddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.clt-amount-paid-admin-event-input-form-lastchild>div:last-of-type .clt-amount-paid-adminInputFloat input {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.clt-amount-paid-adminInputFloatDiv {
    margin-bottom: 0px;
}

.clt-amount-paid-adminInputFloat input, .clt-amount-paid-adminInputFloat input:active, .clt-amount-paid-adminInputFloat input:focus {
    height: 56px !important;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    padding: 10px 17px 0 !important;
    margin-top: 0px;
    border-radius: 0;
    border: 1px solid #dedddd;
    border-top: none;
    outline: none;
    box-shadow: none;
}

.clt-amount-paid-admin-event-info-input-div .form-floating>label {
    padding-left: 18px !important;
    padding-top: 14.6px !important;
    padding-left: 18px !important;
    padding-top: 14.6px !important;
    font-size: 13.5px;
    font-weight: 400;
    color: #242424;
}

.clt-amount-paid-detail-right-form-contact>div:first-of-type .clt-amount-paid-adminInputFloat input {
    border-top: 1px solid #dedddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.clt-amount-paid-adminInputFloat.form-floating>.form-control:focus~label, .clt-amount-paid-adminInputFloat.form-floating>.form-control:not(:placeholder-shown)~label, .clt-amount-paid-adminInputFloat.form-floating>.form-select~label {
    font-weight: 400;
    opacity: .7;
    transform: scale(.85) translateY(-0.35rem) translateX(0.28rem);
    padding: 0.8rem 1.01rem 0.95rem !important;
    font-size: 14.5px;
}

.clt-amount-paid-admin-event-fileinput-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    flex-direction: column;
    border: 1px dashed #000;
    padding-top: 35px;
    padding-right: 20px;
    padding-bottom: 35px;
    padding-left: 20px;
    position: relative;
    cursor: pointer;
}

.clt-amount-paid-admin-event-fileinput-div:hover {
    background: #fbfafa !important;
    border: 1px dashed #000;
}

.clt-amount-paid-admin-event-fileinput-div:active {
    background: #eee !important;
    border: 1px dashed #000;
}

.clt-amount-paid-admin-event-fileinput-div i {
    color: #fff;
    background: #000;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 11px;
}


.clt-amount-paid-admin-event-fileinput-div span {
    font-size: 14px;
    margin-top: 5px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0;
    padding-bottom: 0;
    letter-spacing: -0.2px;
}


.clt-amount-paid-admin-event-fileinput-div .div-hidden {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    margin: auto;
    bottom: 0;
}

.clt-amount-paid-admin-event-fileinput-div .div-hidden input[type="file"] {
    width: 100%;
    height: 100%;
    cursor: pointer;
}


.clt-amount-paid-admin-event-gallery-div {
    background: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.clt-amount-paid-admin-event-gallery-div:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.clt-amount-paid-admin-event-gallery-div:hover::after {
    cursor: move;
    opacity: 0.7;
}

.clt-amount-paid-admin-event-gallery-file-upload {
    margin-top: 0;
    margin-bottom: 25px;
}

.clt-amount-paid-admin-event-gallery-div span {
    padding-bottom: 0.5px;
    color: #222222;
    background: rgb(255 255 255 / 100%);
    border-radius: 50px;
    position: absolute;
    top: 12px;
    left: 11px;
    font-size: 14px;
    font-weight: 600;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-amount-paid-admin-cards3dots {
    width: 10%;
    position: absolute;
    right: 11px;
    top: 11px;
    z-index: 1;
}

.clt-amount-paid-admin-cards3dots .pfr_payment_card3dot {
    background: rgb(255 255 255 / 1000%);
    border-radius: 50px;
    padding: 5.2px 7.2px;
    color: #fff;
    font-size: 12px;
}

.clt-amount-paid-admin-cards3dots .pfr_payment_card3dot:hover {
    background: #eee;
}

.clt-amount-paid-admin-cards3dots .pfr_payment_card3dot:active {
    background: #cdcdcd;
}

.clt-amount-paid-admin-cards3dots .pfr_payment_card3dot i {
    font-size: 12px;
    color: #242424;
}

.clt-amount-paid-admin-height-imgdiv {
    height: 148px;
}


.clt_amount_paid_admin_dash_store_event_container_wrapper_first {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    width: 100%;
    border-top: 1px solid #dedddd;
    border-left: 1px solid #dedddd;
    border-right: 1px solid #dedddd;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_first:hover {
    background: #fbfafa !important;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_first:active {
    background: #eee !important;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_center {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    width: 100%;
    border-top: 1px solid #dedddd;
    border-left: 1px solid #dedddd;
    border-right: 1px solid #dedddd;
    cursor: pointer;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_center:hover {
    background: #fbfafa !important;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_center:active {
    background: #eee !important;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_last {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    width: 100%;
    border: 1px solid #dedddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_last:hover {
    background: #fbfafa !important;
}

.clt_amount_paid_admin_dash_store_event_container_wrapper_last:active {
    background: #eee !important;
}

.clt_amount_paid_admin_dash_store_event_container_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.clt_amount_paid_admin_dash_store_event_container_detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    grid-gap: 15px;
}

.clt_amount_paid_admin_dash_store_event_container_box_icon {
    background-repeat: no-repeat;
    height: 17px;
    margin-top: 2.5px;
    width: 17px;
    background-size: contain;
}

.clt_amount_paid_admin_dash_store_event_container_box_name {
    font-weight: 600;
    font-size: 14px;
}

.clt_amount_paid_amount_paid_offcanvas_header_two button {
    font-size: 14px;
    opacity: 1;
    padding: 0;
}

.clt_amount_paid_amount_paid_offcanvas_header_two {
    padding-top: 14px;
    padding-right: 24px;
    padding-bottom: 17px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_amount_paid_amount_paid_offcanvas_header_two .btn-back {
    background: none;
    padding: 0.5rem 0.2rem 0.5rem 0.7rem !important;
    border-radius: 100px;
}

.clt_amount_paid_amount_paid_offcanvas_header_two .btn-back:hover {
    background: #eee;
}

.clt_amount_paid_amount_paid_offcanvas_header_two .btn-back:active {
    background: #d9dce0;
}

.clt_amount_paid_amount_paid_offcanvas_header_two .btn-back img {
    width: 7px;
}

.clt_amount_paid_amount_paid_offcanvas_header_two .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}


.selectField .form-select, .selectField .form-select:hover, .selectField .form-select:active, .selectField .form-select:focus {
    height: 58px !important;
    font-size: 16px;
    margin-top: 0px;
    outline: none;
    box-shadow: none;
    border: 1px solid #dedddd;
    border-radius: 10px;
}

.selectField option {
    height: 58px !important;
    font-size: 16px;
    margin-top: 0px;
    padding: 0px 10px !important;
    background: rgb(255, 255, 255);
    color: #242424;
}


.clt_admin_dash_menu_container_name {
    text-transform: none !important;
}

.last-input-indicator {
    position: absolute;
    top: 14px;
    right: 10px;
}

/* Chrome, Safari, Edge, Opera */
.pfr_inputFloat_number input::-webkit-outer-spin-button,
.pfr_inputFloat_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.pfr_inputFloat_number input[type=number] {
    -moz-appearance: textfield;
}

@media only screen and (max-width: 600px) {

    /*mobile*/

    .clt_amount_paid_admin_dash_manage_event_header h2 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h3 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h4 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-top: 6px;
    }

    .clt-amount-paid-detail-section-6-header h5 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-list-div {
        padding-top: 15px;
        padding-right: 18px;
        padding-bottom: 17px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-list-div img.img-dragdrop {
        width: 4%;
    }

    .clt-amount-paid-admin-event-s1 {
        padding-left: 14px;
        padding-right: 14px;
    }

    .clt-amount-paid-admin-event-list-div-body {
        margin-top: 15px !important;
        border-radius: 15px;
        overflow: visible !important;
    }

    .clt-amount-paid-admin-event-list-div-body .row:last-child .clt-amount-paid-admin-event-list-div {
        border-bottom: 0px;
    }

    .clt-amount-paid-admin-event-list-div span.dragdrop-counter {
        font-size: 16px;
        width: 10%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div {
        width: 75%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div img {
        border-radius: 11px;
        margin-right: 10px;
        padding: 10px 10px 12px;
        width: 19%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div p {
        font-size: 15px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 18.5px;
        margin-top: 0;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 30px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link.active {
        border-bottom: 2px solid #007eff;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 15%;
        border-radius: 12px;
        margin-right: 10px;
        padding: 14px 14px 17px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-section-hr-pd2 {
        padding: 28px 13px;
    }

    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-info {
        padding: 21px 20px 26px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div img {
        width: 18px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-info i {
        font-size: 14px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:nth-child(1) .clt-amount-paid-admin-event-screen2-info {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:last-child .clt-amount-paid-admin-event-screen2-info {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-info-input-div {
        margin-top: 0px;
    }

    .clt-amount-paid-admin-event-info-input-div .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:last-of-type .clt-amount-paid-adminInputFloat input {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloatDiv {
        margin-bottom: 0px;
    }

    .clt-amount-paid-adminInputFloat input, .clt-amount-paid-adminInputFloat input:active, .clt-amount-paid-adminInputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 17px 0 !important;
        border-radius: 0;
    }

    .clt-amount-paid-detail-right-form-contact>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloat.form-floating>.form-control:focus~label, .clt-amount-paid-adminInputFloat.form-floating>.form-control:not(:placeholder-shown)~label, .clt-amount-paid-adminInputFloat.form-floating>.form-select~label {
        opacity: .7;
        transform: scale(.8) translateY(-0.35rem) translateX(0.28rem);
        margin-top: 3.1px;
        font-size: 15px;
        padding: 0.8rem 1.01rem 0.95rem !important;
    }

    .clt-amount-paid-admin-event-fileinput-div {
        padding: 46px;
        border-radius: 12px;
        margin-top: 0px;
    }


    .clt-amount-paid-admin-event-fileinput-div i {
        width: 34px;
        height: 34px;
        border-radius: 50px;
        font-size: 12px;
    }

    .pfr_inputFloat input, .pfr_inputFloat input:active, .pfr_inputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        margin-top: 0px;
        padding: 10px 17.5px 0 !important;
    }


    .clt-amount-paid-admin-event-fileinput-div span {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        padding-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-gallery-div {
        width: 100%;
        height: 100%;
        margin-bottom: 25px;
        border-radius: 10px;
    }

    .clt-amount-paid-admin-event-gallery-file-upload {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .clt-amount-paid-admin-event-gallery-div span {
        border-radius: 50px;
        top: 14px;
        left: 15px;
        font-size: 15px;
        font-weight: 500;
        width: 26px;
        height: 26px;
    }

    .clt-amount-paid-admin-cards3dots {
        width: 10%;
        right: 15px;
        top: 13.4px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot {
        border-radius: 50px;
        padding: 5.8px 6.6px;
        font-size: 12px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot i {
        font-size: 14px;
    }

    .clt-amount-paid-admin-height-imgdiv {
        height: 204px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:focus {
        border-radius: 50px;
        font-size: 16px;
        height: 41px;
        margin-bottom: -13px;
        margin-top: -13px;
        padding-bottom: 11px;
        width: 19%;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .btn-back img {
        width: 8px;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/

    .clt_amount_paid_admin_dash_manage_event_header h2 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h3 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h4 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-top: 6px;
    }

    .clt-amount-paid-detail-section-6-header h5 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-list-div {
        padding-top: 15px;
        padding-right: 18px;
        padding-bottom: 17px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-list-div img.img-dragdrop {
        width: 4%;
    }

    .clt-amount-paid-admin-event-s1 {
        padding-left: 14px;
        padding-right: 14px;
    }

    .clt-amount-paid-admin-event-list-div-body {
        margin-top: 15px !important;
        border-radius: 15px;
        overflow: visible !important;
    }

    .clt-amount-paid-admin-event-list-div-body .row:last-child .clt-amount-paid-admin-event-list-div {
        border-bottom: 0px;
    }

    .clt-amount-paid-admin-event-list-div span.dragdrop-counter {
        font-size: 16px;
        width: 10%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div {
        width: 75%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div img {
        border-radius: 11px;
        margin-right: 10px;
        padding: 10px 10px 12px;
        width: 19%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div p {
        font-size: 15px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 18.5px;
        margin-top: 0;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 30px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link.active {
        border-bottom: 2px solid #007eff;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 15%;
        border-radius: 12px;
        margin-right: 10px;
        padding: 14px 14px 17px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-info {
        padding: 21px 20px 26px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div img {
        width: 18px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-info i {
        font-size: 14px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:nth-child(1) .clt-amount-paid-admin-event-screen2-info {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:last-child .clt-amount-paid-admin-event-screen2-info {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-info-input-div {
        margin-top: 0px;
    }

    .clt-amount-paid-admin-event-info-input-div .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:last-of-type .clt-amount-paid-adminInputFloat input {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloatDiv {
        margin-bottom: 0px;
    }

    .clt-amount-paid-adminInputFloat input, .clt-amount-paid-adminInputFloat input:active, .clt-amount-paid-adminInputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 17px 0 !important;
        border-radius: 0;
    }

    .clt-amount-paid-detail-right-form-contact>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloat.form-floating>.form-control:focus~label, .clt-amount-paid-adminInputFloat.form-floating>.form-control:not(:placeholder-shown)~label, .clt-amount-paid-adminInputFloat.form-floating>.form-select~label {
        opacity: .7;
        transform: scale(.8) translateY(-0.35rem) translateX(0.28rem);
        margin-top: 3.1px;
        font-size: 15px;
        padding: 0.8rem 1.01rem 0.95rem !important;
    }

    .clt-amount-paid-admin-event-fileinput-div {
        padding: 46px;
        border-radius: 12px;
        margin-top: 0px;
    }

    .clt-amount-paid-admin-event-fileinput-div i {
        width: 34px;
        height: 34px;
        border-radius: 50px;
        font-size: 12px;
    }

    .pfr_inputFloat input, .pfr_inputFloat input:active, .pfr_inputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        margin-top: 0px;
        padding: 10px 18px 0 !important;
    }


    .clt-amount-paid-admin-event-fileinput-div span {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        padding-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-gallery-div {
        width: 100%;
        height: 100%;
        margin-bottom: 25px;
        border-radius: 10px;
    }

    .clt-amount-paid-admin-event-gallery-file-upload {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .clt-amount-paid-admin-event-gallery-div span {
        border-radius: 50px;
        top: 14px;
        left: 15px;
        font-size: 15px;
        font-weight: 500;
        width: 26px;
        height: 26px;
    }

    .clt-amount-paid-admin-cards3dots {
        width: 10%;
        right: 15px;
        top: 13.4px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot {
        border-radius: 50px;
        padding: 5.8px 6.6px;
        font-size: 12px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot i {
        font-size: 14px;
    }

    .clt-amount-paid-admin-height-imgdiv {
        height: 204px;
    }

    .clt-amount-paid-section-hr-pd2 {
        padding: 28px 13px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:focus {
        border-radius: 50px;
        font-size: 16px;
        height: 41px;
        margin-bottom: -13px;
        margin-top: -13px;
        padding-bottom: 11px;
        width: 19%;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .btn-back img {
        width: 8px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/

    .clt_amount_paid_admin_dash_manage_event_header h2 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h3 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h4 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-detail-section-6-header p {
        font-size: 17px;
        font-weight: 400;
        margin-top: 6px;
    }

    .clt-amount-paid-detail-section-6-header h5 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-list-div {
        padding-top: 15px;
        padding-right: 18px;
        padding-bottom: 17px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-list-div img.img-dragdrop {
        width: 4%;
    }

    .clt-amount-paid-admin-event-s1 {
        padding-left: 14px;
        padding-right: 14px;
    }

    .clt-amount-paid-admin-event-list-div-body {
        margin-top: 15px !important;
        border-radius: 15px;
        overflow: visible !important;
    }

    .clt-amount-paid-admin-event-list-div-body .row:last-child .clt-amount-paid-admin-event-list-div {
        border-bottom: 0px;
    }

    .clt-amount-paid-admin-event-list-div span.dragdrop-counter {
        font-size: 16px;
        width: 10%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div {
        width: 75%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div img {
        border-radius: 11px;
        margin-right: 10px;
        padding: 10px 10px 12px;
        width: 19%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div p {
        font-size: 15px;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 18.5px;
        margin-top: 0;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 30px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link.active {
        border-bottom: 2px solid #007eff;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 15%;
        border-radius: 12px;
        margin-right: 10px;
        padding: 14px 14px 17px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -.2px;
    }

    .clt-amount-paid-admin-event-screen2-info {
        padding: 21px 20px 26px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div img {
        width: 18px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-info i {
        font-size: 14px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:nth-child(1) .clt-amount-paid-admin-event-screen2-info {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:last-child .clt-amount-paid-admin-event-screen2-info {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-info-input-div {
        margin-top: 0px;
    }

    .clt-amount-paid-admin-event-info-input-div .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:last-of-type .clt-amount-paid-adminInputFloat input {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloatDiv {
        margin-bottom: 0px;
    }

    .clt-amount-paid-adminInputFloat input, .clt-amount-paid-adminInputFloat input:active, .clt-amount-paid-adminInputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 17px 0 !important;
        border-radius: 0;
    }

    .clt-amount-paid-detail-right-form-contact>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloat.form-floating>.form-control:focus~label, .clt-amount-paid-adminInputFloat.form-floating>.form-control:not(:placeholder-shown)~label, .clt-amount-paid-adminInputFloat.form-floating>.form-select~label {
        opacity: .7;
        transform: scale(.8) translateY(-0.35rem) translateX(0.28rem);
        margin-top: 3.1px;
        font-size: 15px;
        padding: 0.8rem 1.01rem 0.95rem !important;
    }

    .clt-amount-paid-admin-event-fileinput-div {
        padding: 46px;
        border-radius: 12px;
        margin-top: 0px;
    }

    .clt-amount-paid-admin-event-fileinput-div i {
        width: 34px;
        height: 34px;
        border-radius: 50px;
        font-size: 12px;
    }

    .pfr_inputFloat input, .pfr_inputFloat input:active, .pfr_inputFloat input:focus {
        height: 64px !important;
        font-size: 16px;
        margin-top: 0px;
        padding: 10px 18px 0 !important;
    }

    .clt-amount-paid-admin-event-fileinput-div span {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        padding-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-gallery-div {
        width: 100%;
        height: 100%;
        margin-bottom: 25px;
        border-radius: 10px;
    }

    .clt-amount-paid-admin-event-gallery-file-upload {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .clt-amount-paid-admin-event-gallery-div span {
        border-radius: 50px;
        top: 14px;
        left: 15px;
        font-size: 15px;
        font-weight: 500;
        width: 26px;
        height: 26px;
    }

    .clt-amount-paid-admin-cards3dots {
        width: 10%;
        right: 15px;
        top: 13.4px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot {
        border-radius: 50px;
        padding: 5.8px 6.6px;
        font-size: 12px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot i {
        font-size: 14px;
    }

    .clt-amount-paid-admin-height-imgdiv {
        height: 204px;
    }

    .clt-amount-paid-section-hr-pd2 {
        padding: 28px 13px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 16px;
        width: 30%;
        height: 41px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:active, .clt_amount_paid_header_add_button:focus, .clt_amount_paid_header_add_button:hover {
        border-radius: 50px;
        font-size: 16px;
        height: 41px;
        margin-bottom: -13px;
        margin-top: -13px;
        padding-bottom: 11px;
        width: 19%;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .btn-back img {
        width: 8px;
    }

}


@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

    .clt-amount-paid-admin-event-s1 {
        padding-left: 14px;
        padding-right: 14px;
    }

    .clt-amount-paid-admin-event-list-div-body {
        margin-top: 15px !important;
        border-radius: 15px;
        overflow: visible !important;
    }

    .clt-amount-paid-admin-event-list-div {
        padding-top: 17px;
        padding-right: 20px;
        padding-bottom: 17px;
        padding-left: 20px;
    }

    .clt-amount-paid-admin-event-list-div-body .row:last-child .clt-amount-paid-admin-event-list-div {
        border-bottom: 0px;
    }

    .clt-amount-paid-admin-event-list-div img.img-dragdrop {
        width: 5%;
    }

    .clt-amount-paid-admin-event-list-div span.dragdrop-counter {
        font-size: 14px;
        width: 10%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div {
        width: 75%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div img {
        width: 20%;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 12.8px;
        padding-left: 10px;
        border-radius: 10px;
        margin-right: 10px;
        padding: 10px;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div h2 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div p {
        font-size: 15px;
        font-weight: 400;
        margin-top: 1px;
        margin-bottom: 0;
        line-height: 23px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 20.5px;
        margin-top: 5px;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 28px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link.active {
        border-bottom: 2px solid #007eff;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 14%;
        border-radius: 12px;
        margin-right: 10px;
        padding-top: 13px;
        padding-right: 13px;
        padding-bottom: 16px;
        padding-left: 13px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16x;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-info {
        padding: 20px 20px 25px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div img {
        width: 18px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .clt-amount-paid-admin-event-screen2-info i {
        font-size: 14px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:nth-child(1) .clt-amount-paid-admin-event-screen2-info {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:last-child .clt-amount-paid-admin-event-screen2-info {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-info-input-div {
        margin-top: 0px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-admin-event-input-form-lastchild>div:last-of-type .clt-amount-paid-adminInputFloat input {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloatDiv {
        margin-bottom: 0px;
    }

    .clt-amount-paid-adminInputFloat input, .clt-amount-paid-adminInputFloat input:active, .clt-amount-paid-adminInputFloat input:focus {
        height: 60px !important;
        font-size: 15px;
        font-weight: 500;
        margin-top: 0px;
        padding: 10.5px 17px 0 !important;
        border-radius: 0;
        border: 1px solid #dedddd;
    }

    .clt-amount-paid-adminInputFloat.form-floating>.form-control:focus~label, .clt-amount-paid-adminInputFloat.form-floating>.form-control:not(:placeholder-shown)~label, .clt-amount-paid-adminInputFloat.form-floating>.form-select~label {
        font-weight: 400;
        opacity: .7;
        transform: scale(.85) translateY(-0.35rem) translateX(0.28rem);
        padding: 0.85rem 1.01rem 0.95rem !important;
        font-size: 15px;
    }

    .clt-amount-paid-detail-right-form-contact>div:first-of-type .clt-amount-paid-adminInputFloat input {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .clt-amount-paid-adminInputFloat.form-floating>.form-control:focus~label, .clt-amount-paid-adminInputFloat.form-floating>.form-control:not(:placeholder-shown)~label, .clt-amount-paid-adminInputFloat.form-floating>.form-select~label {
        opacity: .65;
        transform: scale(.8) translateY(-0.35rem) translateX(0.28rem);
        padding-top: 0.5rem;
        padding-right: 1.1rem;
        padding-bottom: 0.5rem;
        padding-left: 1.1rem;
        margin-top: 1.1px;
        font-size: 14px;
    }

    .clt-amount-paid-admin-event-fileinput-div {
        padding: 20px;
        border-radius: 10px;
        margin-top: 10px;
    }

    .clt-amount-paid-admin-event-fileinput-div i {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        font-size: 12px;
    }

    .clt-amount-paid-admin-event-fileinput-div span {
        font-size: 13.5px;
        font-weight: 400;
        margin-bottom: 0;
        padding-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-gallery-div {
        width: 100%;
        height: 100%;
        margin-bottom: 25px;
        border-radius: 10px;
    }

    .clt-amount-paid-admin-event-gallery-file-upload {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .clt-amount-paid-admin-event-gallery-div span {
        border-radius: 50px;
        top: 14px;
        left: 15px;
        font-size: 12px;
        font-weight: 500;
        width: 20px;
        height: 20px;
    }

    .clt-amount-paid-admin-cards3dots {
        width: 10%;
        right: 15px;
        top: 14px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot {
        border-radius: 50px;
        padding: 4px 6px;
        font-size: 12px;
    }

    .clt-amount-paid-admin-cards3dots .pfr_payment_card3dot i {
        font-size: 10px;
    }

    .clt-amount-paid-admin-height-imgdiv {
        height: 120px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:active, .clt_amount_paid_header_add_button:focus, .clt_amount_paid_header_add_button:hover {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 14%;
        border-radius: 12px;
        margin-right: 10px;
        padding-top: 13px;
        padding-right: 13px;
        padding-bottom: 16px;
        padding-left: 13px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 20.5px;
        margin-top: 5px;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 28px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16x;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-info {
        padding: 20px 20px 25px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 14%;
        border-radius: 12px;
        margin-right: 10px;
        padding-top: 13px;
        padding-right: 13px;
        padding-bottom: 16px;
        padding-left: 13px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 20.5px;
        margin-top: 5px;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 28px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16x;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
    }


    .clt-amount-paid-admin-event-screen2-info {
        padding: 20px 20px 25px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:active, .clt_amount_paid_header_add_button:focus, .clt_amount_paid_header_add_button:hover {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 14%;
        border-radius: 12px;
        margin-right: 10px;
        padding-top: 13px;
        padding-right: 13px;
        padding-bottom: 16px;
        padding-left: 13px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 20.5px;
        margin-top: 5px;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 28px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16x;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
    }


    .clt-amount-paid-admin-event-screen2-info {
        padding: 20px 20px 25px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 14%;
        border-radius: 12px;
        margin-right: 10px;
        padding-top: 13px;
        padding-right: 13px;
        padding-bottom: 16px;
        padding-left: 13px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 20.5px;
        margin-top: 5px;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 28px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16x;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
    }


    .clt-amount-paid-admin-event-screen2-info {
        padding: 20px 20px 25px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-amount-paid-admin-event-screen2-tabs-body {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:active, .clt_amount_paid_header_add_button:focus, .clt_amount_paid_header_add_button:hover {
        border-radius: 50px;
        margin-bottom: -6px;
        margin-top: -6px;
        font-size: 16px;
        letter-spacing: -.2px;
        width: 20%;
        height: 42px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 14%;
        border-radius: 12px;
        margin-right: 10px;
        padding-top: 13px;
        padding-right: 13px;
        padding-bottom: 16px;
        padding-left: 13px;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 0;
        padding-bottom: 20.5px;
        margin-top: 5px;
        line-height: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 28px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 16x;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 16px;
    }


    .clt-amount-paid-admin-event-screen2-info {
        padding: 20px 20px 25px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/

    .clt_amount_paid_payment_header_button, .clt_amount_paid_payment_header_button:focus {
        border-radius: 50px;
        margin-bottom: -8px;
        margin-top: -8px;
        font-size: 15px;
        letter-spacing: -.2px;
        font-weight: 600;
        width: 30%;
        height: 40px;
        padding-bottom: 4px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        border-radius: 50px;
        margin-bottom: -8px;
        margin-top: -8px;
        font-size: 15px;
        letter-spacing: -.2px;
        font-weight: 600;
        width: 30%;
        height: 40px;
        padding-bottom: 4px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_delete_button, .clt_amount_paid_header_delete_button:focus {
        border-radius: 50px;
        margin-bottom: -8px;
        margin-top: -8px;
        font-size: 15px;
        letter-spacing: -.2px;
        font-weight: 600;
        width: 30%;
        height: 40px;
        padding-bottom: 4px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_header_add_button, .clt_amount_paid_header_add_button:focus {
        border-radius: 50px;
        margin-bottom: -10px;
        margin-top: -10px;
        font-size: 15px;
        letter-spacing: -.2px;
        font-weight: 600;
        width: 19%;
        height: 40px;
        padding-bottom: 4px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h2 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 15px;
        letter-spacing: -0.2px;
        padding-top: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h3 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt_amount_paid_admin_dash_manage_event_header h4 {
        font-size: 20px;
        font-weight: 600;
        color: #242424;
        font-family: "Inter";
        margin-bottom: 10px;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-detail-section-6-header p {
        font-size: 16px;
        font-weight: 400;
        margin-top: 6px;
    }
    
    .clt-amount-paid-detail-section-6-header h5 {
        font-size: 20px;
        font-weight: 600;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div h2 {
        font-size: 15px;
    }

    .clt-amount-paid-admin-event-list-div span.dragdrop-counter {
        font-size: 16px;
    }

    .clt-amount-paid-admin-event-list-div img.img-dragdrop {
        width: 4%;
    }

    .clt-amount-paid-admin-event-list-div .inner-icon-title-div img {
        width: 18%;
        border: 1px solid #dedddd;
        border-radius: 10px;
        margin-right: 10px;
        padding-top: 9px;
        padding-right: 10px;
        padding-bottom: 12px;
        padding-left: 10px;
    }

    .clt-amount-paid-admin-event-list-div {
        padding: 19px 18px 22px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-amount-paid-admin-event-screen2-tabs.nav-tabs .nav-link {
        font-size: 14.5px !important;
        padding: 7px 0 17.5px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 h2 {
        font-size: 15px !important;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s1 img {
        width: 16%;
        padding: 14px 15px 16.5px;
    }

    .clt-amount-paid-section-hr-pd2 {
        padding-top: 32px;
        padding-right: 14px;
        padding-bottom: 28px;
        padding-left: 14px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 h2 {
        font-size: 15px;
    }

    .clt-amount-paid-admin-event-screen2-tab-g-s2 p {
        font-size: 15px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div img {
        width: 18px;
    }

    .clt-amount-paid-admin-event-screen2-info .inner-div h2 {
        margin-left: 15px;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
        padding-bottom: 0;
        letter-spacing: -0.2px;
    }

    .clt-amount-paid-admin-event-screen2-info {
        border: 1px solid #dedddd;
        padding: 21px 20px 23px;
        border-radius: 0px;
        cursor: pointer;
        user-select: none;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:nth-child(1) .clt-amount-paid-admin-event-screen2-info {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .clt-amount-paid-admin-event-screen2-tab-info .clt-amount-paid-admin-event-screen2-tab-col:last-child .clt-amount-paid-admin-event-screen2-info {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .clt-amount-paid-admin-event-fileinput-div {
        padding-top: 35px;
        padding-right: 20px;
        padding-bottom: 35px;
        padding-left: 20px;
    }

    .clt-amount-paid-admin-event-fileinput-div span {
        font-size: 15px;
    }

    .clt-amount-paid-admin-event-fileinput-div i {
        width: 34px;
        height: 34px;
        font-size: 12px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two {
        padding-top: 15px;
        padding-bottom: 18px;
    }

    .clt_amount_paid_amount_paid_offcanvas_header_two .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_amount_paid_header_edit_button, .clt_amount_paid_header_edit_button:focus {
        margin-bottom: -8px;
        margin-top: -8px;
        font-size: 15px;
        width: 16%;
        height: 36px;
    }

}