.cookie-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: fixed;
    bottom: 20px;
    right: 10%; */
    z-index: 9998;
}

.cookie-container-wrapper {
    position: fixed;
    bottom: 160px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    z-index: 1030;
    height: 0px;
}

.clt_notification_confirm_booking_badge_container {
    position: relative;
    right: -45px;
    top: -7px;
    background: #eee;
    width: 23px;
    height: 22px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.clt_notification_confirm_booking_badge_container_img {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0px;
    background-color: transparent;
}

.clt_notification_confirm_booking_profile_img {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 50%;
    margin-right: 16px;
}

.clt_toast_message_confirm_booking_badge_container {
    position: relative; 
    right: -40px; 
    top: -5px; 
    background: #eee; 
    width: 20px; 
    height: 20px; 
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center; 
}


.clt_toast_message_confirm_booking_badge_container_img {
    width: 10px;
    height: 10px; 
    border-radius: 50%;
    margin: 0px;
    background-color: transparent;
}

.clt_toast_message_confirm_booking_profile_img {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 50%;
    margin-right: 16px;
}

.clt_notification_avatar {
    display: flex;
}



@media only screen and (max-width: 600px) {}




@media only screen and (max-width: 767px) {}


@media only screen and (max-width: 767px) and (orientation:portrait) {}


@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}


@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}


@media (max-width: 1200px) and (min-width: 992px) {

    /*ipad pro*/
    .cookie-container {
        right: 100px;
    }

}


@media only screen and (max-width: 1400px) and (min-width: 1200px) {

    /*ipad pro*/
    .cookie-container {
        right: 100px;
    }
}


@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*large desktop*/
    
    .cookie-container {
        right: 100px;
    }
}


@media only screen and (max-width: 1400px) and (min-width: 1200px) {

    .cookie-container {
        right: 100px;
    }

}

@media only screen and (min-width: 1600px) {
    .cookie-container {
        right: 10%;
    }
}


body:has(.clt_message_wrapper) {
    overflow: hidden;
}