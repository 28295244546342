.footer-sec-nav {
    display: none;
}

@media (min-width: 767px) {

    .clt_admin_sidebar {
        background-color: white;
        width: 233px;
        position: relative;
        top: -3px;
        height: calc(100vh - -10px);
        border-right: 1px solid #dedddd;
    }
}

.clt_admin_sidebar_header {
    /* border-bottom: 1px solid #dedddd; */
    padding: 5.3px;
    padding-top: 9px;
}

.clt_admin_sidebar_header p {
    padding: 0px;
    margin: 0px;
    text-align: center;
}

.clt_admin_sidebar_header p:first-child {
    font-size: 9.5px;
    font-weight: 500;
    letter-spacing: 0.6px;
    margin-top: 11px !important;
    color: #959697;
    text-transform: uppercase;
}

.clt_admin_sidebar_header p:last-child {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    margin-top: -4px;
}



.clt_admin_sidebar_footer-sec-nav {
    position: absolute;
    bottom: -3px;
    width: 99%;
    background-color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.clt_admin_sidebar_f-txt {
    font-size: 13px !important;
    margin-left: 20px;
    color: #444644;
}

.clt_admin_links {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
}

.clt_admin_sidebar_section-par:hover {
    background-color: #f6f6f6;
}

.clt_admin_sidebar_section-par {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 12px 10px 17px 30px;
    cursor: pointer;
}


.clt_admin_sidebar_section-par-active {
    background: #287aff1f;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 12px 10px 17px 30px;
    cursor: pointer;
}

.clt_admin_sidebar_section-par:hover .clt_admin_sidebar_f-txt {
    color: #444644;
}

.clt_admin_sidebar_admin-span-text-active {
    color: #287AFF !important;
    font-weight: 600;
}

.clt_admin_sidebar_drawermenuitem {
    background-repeat: no-repeat;
    height: 15px;
    margin-top: 1px;
    width: 15px;
    background-size: contain;
}


.clt_admin_sidebar_drawermenuitema1, .clt_admin_sidebar_section-par:hover .clt_admin_sidebar_drawermenuitema1  {
    background-image: url(../../../../assets/img/admin_icon_bar_1_not_active.png);
}

.clt_admin_sidebar_drawermenuitema2, .clt_admin_sidebar_section-par:hover .clt_admin_sidebar_drawermenuitema2 {
    background-image: url(../../../../assets/img/admin_icon_bar_2_not_active.png);
}

.clt_admin_sidebar_drawermenuitema3, .clt_admin_sidebar_section-par:hover .clt_admin_sidebar_drawermenuitema3 {
    background-image: url(../../../../assets/img/admin_icon_bar_3_not_active.png);
}

.clt_admin_sidebar_drawermenuitema4, .clt_admin_sidebar_section-par:hover .clt_admin_sidebar_drawermenuitema4 {
    background-image: url(../../../../assets/img/admin_icon_bar_4_not_active.png);
}

.clt_admin_sidebar_drawermenuitema5, .clt_admin_sidebar_section-par:hover .clt_admin_sidebar_drawermenuitema5 {
    background-image: url(../../../../assets/img/admin_icon_bar_5_not_active.png);
}

/* active */

.clt_admin_sidebar_drawermenuitema1a {
    background-image: url(../../../../assets/img/admin_icon_bar_1_active.png);
}

.clt_admin_sidebar_drawermenuitema2a {
    background-image: url(../../../../assets/img/admin_icon_bar_2_active.png);
}

.clt_admin_sidebar_drawermenuitema3a {
    background-image: url(../../../../assets/img/admin_icon_bar_3_active.png);
}

.clt_admin_sidebar_drawermenuitema4a {
    background-image: url(../../../../assets/img/admin_icon_bar_4_active.png);
}

.clt_admin_sidebar_drawermenuitema5a {
    background-image: url(../../../../assets/img/admin_icon_bar_5_active.png);
}



@media (min-width:0px) and (max-width:767px) {

    .clt_admin_sidebar_header {
        display: none;
    }

    .clt_admin_sidebar_drawermenuitem {
        background-repeat: no-repeat;
        height: 22px;
        margin-top: 1px;
        width: 20px;
        background-size: contain;
    }
    
    .footer-sec-nav {
        position: absolute;
        bottom: -3px;
        width: 99%;
        background-color: white;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }

    .f-txt {
        font-size: 13px !important;
    }

    .clt_admin_sidebar {
        background-color: white;
        z-index: 1000;
    }

    .clt_admin_links {
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        padding-top: 6px;
        box-shadow: -1px -1px 3px 0 #d9d5d5;
    }

    .clt_admin_sidebar {
        background-color: white;
        width: 100%;
        position: fixed;
        bottom: 0px;
    }

    .clt_admin_sidebar_section-par:hover {
        background-color: transparent;
    }

    .clt_admin_sidebar_section-par {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5px 5px 10px;
        cursor: pointer;
    }


    .clt_admin_sidebar_section-par-active {
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5px 5px 10px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 11px !important;
    }

    .clt_admin_sidebar_f-txt {
        font-size: 9.5px !important;
        font-weight: 700;
        margin-left: 0px;
        letter-spacing: -0.18px;
        text-transform: uppercase;
    }

}


@media only screen and (min-width: 1600px) {

    /*large desktop*/

    .clt_admin_sidebar_f-txt {
        font-size: 15px!important;
        margin-left: 20px;
        color: #444644;
    }

    .clt_admin_sidebar_drawermenuitem {
        height: 16px;
        margin-top: 0px;
        width: 16px;
    }

}