body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --toastify-color-success: #ffffff !important;
  --toastify-toast-width: 350px !important;
  --toastify-toast-min-height: 45px !important;
}

.shadow-none:active {
}

.w-fit-content {
  width: fit-content !important;
}
.Toastify__toast-body{
  white-space: pre-line;
  font-family: 'Inter' !important ;
  letter-spacing: -0.2px !important;
  font-weight: 500 !important;
}

.Toastify__toast {
  height: fit-content !important;
}

.toast-container {
  top: 2px !important;
  position: relative;
  min-height: var(--toastify-toast-min-height);
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
  margin-right: 17px;
  padding-top: 4px !important;
  padding-right: 8px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
  border-radius: 4px;
  box-shadow: none;
  z-index: 9999 !important;
}

.toast-error {
  border-radius: 12px !important;
  font-size: 14.5px !important;
  background: #fff !important;
  box-shadow: 0 2px 14px rgba(0,0,0,.12)!important;
  padding-top: 12px !important;
  padding-right: 8px !important;
  padding-bottom: 12px !important;
  padding-left: 8px !important;
  margin-bottom: 10px !important;
}

.toast-icon {
  width: 18px;
  height: 18px;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.mt-0 {
  margin-top: 0 !important;
}
.ql-toolbar.ql-snow {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.ql-container.ql-snow {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  min-height: 100px;
}
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

@media only screen and (max-width: 600px) {
  /*mobile*/

  .toast-error {
    top: 5px !important;
    padding: 12px 10px !important;
    font-size: 16px !important;
  }

  .toast-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  /*mobile*/
  .toast-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 0px !important;
  }
  .toast-error {
    top: 5px !important;
    padding: 12px 10px !important;
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  /*mobile*/
  .toast-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 0px !important;
  }
  .toast-error {
    top: 5px !important;
    padding: 12px 10px !important;
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  /*ipad*/
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  /*ipad*/
}

@media (max-width: 1200px) and (min-width: 992px) {
  /*ipad pro*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  /*ipad pro*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation: landscape) {
  /*large desktop*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  /*ipad pro*/
}

@media only screen and (min-width: 1600px) {
  /*large desktop*/
  .toast-container {
    top: 2px !important;
    margin-bottom: 1rem;
    margin-right: 17px;
    padding-top: 6px !important;
    padding-right: 8px !important;
    padding-bottom: 0px !important;
    padding-left: 8px !important;
    border-radius: 4px;
  }
  .toast-error {
    font-size: 15px !important;
  }
}
