.clt_cookie_box {
    margin: 0px 24px;
}

.clt_cookie_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_cookie_modal {
    /* position: fixed !important; */
    width: 415px;
    z-index: 1040 !important;
    border-radius: 20px !important;
    background-color: white !important;
    margin-bottom: 34px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, .12) !important;
    /* padding: 20px !important; */
}

.clt_cookie_modal .clt_cookie_modal_header {
    padding: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0px;
}

.clt_cookie_modal .clt_cookie_modal_title {
    font-size: 13.5px !important;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}

.clt_cookie_modal_body {
    padding: 20px;
}

.image-transition {
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
  
  .image-transition.hide {
    opacity: 0;
  }

.clt_cookie_modal_body p a {
    color: #242424 !important;
    font-weight: 600 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.clt_cookie_modal_body p a:hover {
    color: #828282 !important;
}

.clt_cookie_modal_body p a:active {
    color: #b9b9b9 !important;
}

.clt_cookie_modal_body p {
    padding: 0px;
    color: #70757a;
    margin: 0px;
    font-size: 13.5px;
}

.clt_cookie_modal_body .main button, .clt_cookie_modal_body .main button:hover, .clt_cookie_modal_body .main button:focus {
    background: black;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 13.5px;
    padding-top: 3px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border: 0px solid black;
    height: 35px;
    width: 80px;
}

.clt_cookie_modal_body .main button:hover {
    background: #2d2d2d;
}

.clt_cookie_modal_body .main button:active {
    background: #5c5c5c;
}

.clt_cookie_modal_body .second button {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 13.5px;
    padding-top: 3px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border: 0px solid #eee;
    height: 35px;
    width: 80px;
}

.clt_cookie_modal_body .second button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt_cookie_modal_body .second button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt_cookie_link {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 15px;
    letter-spacing: 0.2px;
    padding-top: 7px;
    padding-right: 12px;
    padding-bottom: 9px;
    padding-left: 12px;
    font-weight: 500;
    border: 0px solid #eee;
    width: fit-content;
    text-decoration: none !important;
}

.clt_cookie_link:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.clt_cookie_link:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}



.clt_cookie_modal_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 13px;
    width: 100%;
    margin-top: 20px;
}

.clt_cookie_modal_button_wrapper2 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 13px;
    width: 100%;
    margin-top: 20px;
}

.clt_cookie_offcanvas_header {
    padding-top: 14px;
    padding-right: 24px;
    padding-bottom: 17px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_cookie_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_cookie_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_cookie_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_cookie_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_cookie_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_cookie_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}

.clt_cookie_offcanvas_body {
    padding: 0px;
    padding-bottom: 105px;
}



.clt-account-detail {}


.clt_cookie_box_field {
    padding: 20px 24px;
}



.FooterButton {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 19px 24px;
    border-top: 1px solid #e0e0e0;
    background: #fff;
    z-index: 1;
}

.clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
    background: black;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    padding-top: 2px;
    font-weight: 500;
    box-shadow: none;
    letter-spacing: 0.2px;
    outline: none;
    border: 1px solid black;
    height: 48px;
    bottom: 0px;
}

.clt_cookie_formButton:disabled {
    opacity: .4;
    border: 1px solid #000000;
    background: #000000;
}

.clt_cookie_offcanvas_body_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

.clt_cookie_offcanvas_body_cookie_box {}

.clt_cookie_offcanvas_body_cookie_box p {
    padding: 0px !important;
    margin: 0px !important;
}

.clt_cookie_offcanvas_body_cookie_box .title {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 8px;
}

.clt_cookie_offcanvas_body_cookie_box .desc {
    font-size: 14px;
    color: #70757a;
    font-family: "Inter";
    font-weight: 400;
    line-height: 20px;
}

.clt_cookie_radio_group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 22px;
    margin-top: 20px;
}

.clt_cookie_radio_group_force {
    display: flex;
    opacity: 0.35;
    align-items: center;
    justify-content: flex-end;
    gap: 22px;
    margin-top: 20px;
}

.clt_cookie_hr {
    height: 0.5px !important;
    color: #515151;
}


.clt_cookie_radio {
    cursor: pointer;
}

.clt_cookie_radio .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt_cookie_radio .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50px;

}

.clt_cookie_radio span {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-left: 10px;
    margin-bottom: 0px;
    margin-top: 1px;
    letter-spacing: -.2px;
    line-height: 15px;
}


@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_cookie_offcanvas {
        width: 100%;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_modal {
        width: 100%;
        border-radius: 16px !important;
        margin-top: 0px;
        margin-right: 12px;
        margin-bottom: 78px;
        margin-left: 12px;
    }

    .clt_cookie_item3 {
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }

    .clt_cookie_item_title {
        font-size: 16px;
        font-weight: 500;
        color: #242424;
        margin: 0 0 -4px;
        padding: 0;
        /* border-bottom: 1px solid black; */
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item_detail {
        font-size: 16px;
        font-weight: 400;
        color: #70757a;
        margin: 0;
        padding: 0 0 10px;
    }

    .clt_cookie_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }


    .clt_cookie_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_cookie_modal_body p {
        padding: 0px;
        margin: 0px;
        font-size: 15.5px;
    }

    .clt_cookie_modal .clt_cookie_modal_title {
        font-size: 15.5px !important;
    }

    .clt_cookie_modal_body .main button, .clt_cookie_modal_body .main button:hover, .clt_cookie_modal_body .main button:focus {
        font-size: 15px;
    }

    .clt_cookie_modal_body .second button, .clt_cookie_modal_body .second button:hover, .clt_cookie_modal_body .second button:focus {
        font-size: 15px;
    }

    .clt_cookie_offcanvas_body_cookie_box .title {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .clt_cookie_offcanvas_body_cookie_box .desc {
        font-size: 16px;
        line-height: 24px;
    }

    .clt_cookie_radio span {
        font-size: 16px;
    }

    .clt_cookie_offcanvas_body_title {
        font-size: 22px;
        line-height: 1.2;
    }

    .clt_cookie_link {
        font-size: 15px;
    }

    .clt_cookie_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }


}



@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_cookie_offcanvas {
        width: 100%;
    }

    .clt_cookie_modal {
        border-radius: 16px !important;
        margin-top: 0px;
        margin-right: 12px;
        margin-bottom: 78px;
        margin-left: 12px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_item3 {
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }

    .clt_cookie_item_title {
        font-size: 16px;
        font-weight: 500;
        color: #242424;
        margin: 0 0 -4px;
        padding: 0;
        /* border-bottom: 1px solid black; */
    }

    .clt_cookie_item_detail {
        font-size: 16px;
        font-weight: 400;
        color: #70757a;
        margin: 0;
        padding: 0 0 10px;
    }

    .clt_cookie_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }


    .clt_cookie_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_cookie_modal_body p {
        padding: 0px;
        margin: 0px;
        font-size: 15.5px;
    }

    .clt_cookie_modal .clt_cookie_modal_title {
        font-size: 15.5px !important;
    }

    .clt_cookie_modal_body .main button, .clt_cookie_modal_body .main button:hover, .clt_cookie_modal_body .main button:focus {
        font-size: 15px;
    }

    .clt_cookie_modal_body .second button, .clt_cookie_modal_body .second button:hover, .clt_cookie_modal_body .second button:focus {
        font-size: 15px;
    }

    .clt_cookie_offcanvas_body_cookie_box .title {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .clt_cookie_offcanvas_body_cookie_box .desc {
        font-size: 16px;
        line-height: 24px;
    }

    .clt_cookie_radio span {
        font-size: 16px;
    }

    .clt_cookie_offcanvas_body_title {
        font-size: 22px;
        line-height: 1.2;
    }

    .clt_cookie_link {
        font-size: 15px;
    }

    .clt_cookie_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_cookie_offcanvas {
        width: 100%;
    }

    .clt_cookie_modal {
        border-radius: 16px !important;
        margin-top: 0px;
        margin-right: 12px;
        margin-bottom: 78px;
        margin-left: 12px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_item3 {
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }

    .clt_cookie_item_title {
        font-size: 16px;
        font-weight: 500;
        color: #242424;
        margin: 0 0 -4px;
        padding: 0;
        /* border-bottom: 1px solid black; */
    }

    .clt_cookie_item_detail {
        font-size: 16px;
        font-weight: 400;
        color: #70757a;
        margin: 0;
        padding: 0 0 10px;
    }

    .clt_cookie_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }


    .clt_cookie_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_cookie_modal_body p {
        padding: 0px;
        margin: 0px;
        font-size: 15.5px;
    }

    .clt_cookie_modal .clt_cookie_modal_title {
        font-size: 15.5px !important;
    }

    .clt_cookie_modal_body .main button, .clt_cookie_modal_body .main button:hover, .clt_cookie_modal_body .main button:focus {
        font-size: 15px;
    }

    .clt_cookie_modal_body .second button, .clt_cookie_modal_body .second button:hover, .clt_cookie_modal_body .second button:focus {
        font-size: 15px;
    }

    .clt_cookie_offcanvas_body_cookie_box .title {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .clt_cookie_offcanvas_body_cookie_box .desc {
        font-size: 16px;
        line-height: 24px;
    }

    .clt_cookie_radio span {
        font-size: 16px;
    }

    .clt_cookie_offcanvas_body_title {
        font-size: 22px;
        line-height: 1.2;
    }

    .clt_cookie_link {
        font-size: 15px;
    }

    .clt_cookie_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_cookie_offcanvas {
        width: 425px;
    }

    .clt_cookie_offcanvas_header .offcanvas-title {
        font-size: 16px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item_title {
        font-size: 16px;
    }

    .clt_cookie_item_detail {
        font-size: 16px;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:focus, .clt_cookie_formButton:hover {
        height: 52px;
        font-size: 16px;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_cookie_offcanvas {
        width: 400px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_cookie_offcanvas {
        width: 425px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_cookie_offcanvas {
        width: 400px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_cookie_offcanvas {
        width: 425px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_cookie_offcanvas {
        width: 400px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 22px;
    }

    .clt_cookie_item {
        height: 78px !important;
    }

    .clt_cookie_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_cookie_inputFloat input {
        height: 64px !important;
        font-size: 16px;
        padding: 10.5px 17.5px 0 !important;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:hover, .clt_cookie_formButton:focus {
        font-size: 16px;
        padding-top: 2px;
        height: 50px;
        bottom: 0px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_cookie_link {
        font-size: 14.5px;
    }

    .clt_cookie_modal {
        width: 414px;
        margin-bottom: 34px;
        border-radius: 15px !important;
    }

    .clt_cookie_modal .clt_cookie_modal_title {
        font-size: 15px !important;
    }

    .clt_cookie_modal_body p {
        padding: 0;
        margin: 0;
        font-size: 15px;
    }

    .clt_cookie_modal_body .second button {
        border-radius: 12px;
        font-size: 14.5px;
    }

    .clt_cookie_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
    }

    .clt_cookie_offcanvas {
        width: 400px;
    }

    .clt_cookie_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_cookie_offcanvas_body_category {
        font-size: 20px;
    }

    .clt_cookie_item_title {
        font-size: 15px;
    }


    .clt_cookie_item_detail {
        font-size: 15px;
    }

    .clt_cookie_formButton, .clt_cookie_formButton:focus, .clt_cookie_formButton:hover {
        font-size: 15px;
    }

    .clt_cookie_inputFloat input {
        border-radius: 12px;
        height: 60px !important;
        font-size: 15px;
        padding: 12px 17px 0 !important;
        border: 1px solid #dedddd;
    }

    .clt_cookie_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 15.63px !important;
        font-size: 15px;
    }

    .clt_cookie_offcanvas_body_cookie_box .title {
        font-size: 15px;
        margin-bottom: 8px;
    }

    .clt_cookie_offcanvas_body_cookie_box .desc {
        font-size: 15px;
        line-height: 20px;
    }

    .clt_cookie_radio span {
        font-size: 15px;
    }





}