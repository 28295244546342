.clt_messages_container_images_container_preview img {
    width: 78px;
    height: 78px;
    cursor: pointer;
}

.clt_messages_container_images_container_preview img:hover {
    opacity: .7;
    width: 78px;
    height: 78px;
}

.clt_messages_container_images_container_preview img:active {
    opacity: .6;
    width: 78px;
    height: 78px;
}

.clt_message_pre_accountDetail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 3px 12px;
    margin-left: 10px;
}

.clt_message_pre_accountDetail img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.clt_message_pre_accountSubDetail {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 3px;

}

.clt_message_pre_accountSubDetail h2 {
    font-size: 15px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin: 0px;
    letter-spacing: -0.2px;
    text-align: left;
}

.clt_message_pre_accountSubDetail p {
    font-size: 13.5px;
    font-family: 'Inter';
    margin-bottom: 2.5px;
    letter-spacing: -0.2px;
    text-align: left;
    line-height: 100%;
    margin: 0px;
    color: #5b5b5b;
}





@media only screen and (max-width: 600px) {

    /*mobile*/

    .clt_message_pre_accountDetail {
        grid-gap: 10px;
        gap: 14px;
        padding: 3px 7px;
        margin-left: 10px;
    }

    .clt_message_pre_accountDetail img {
        width: 35px;
        height: 35px;
    }

    .clt_message_pre_accountSubDetail h2 {
        font-size: 16px;
    }

    .clt_message_pre_accountSubDetail p {
        font-size: 14px;
    }

    .clt_message_offcanvas_body img {
        object-fit: cover;
        height: 242px;
        border-radius: 0px;
    }

    .clt_message_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_message_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/


    .clt_message_pre_accountDetail {
        grid-gap: 10px;
        gap: 14px;
        padding: 3px 7px;
        margin-left: 10px;
    }

    .clt_message_pre_accountDetail img {
        width: 35px;
        height: 35px;
    }

    .clt_message_pre_accountSubDetail h2 {
        font-size: 16px;
    }

    .clt_message_pre_accountSubDetail p {
        font-size: 14px;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/

    .clt_message_pre_accountDetail {
        grid-gap: 10px;
        gap: 14px;
        padding: 3px 7px;
        margin-left: 10px;
    }

    .clt_message_pre_accountDetail img {
        width: 35px;
        height: 35px;
    }

    .clt_message_pre_accountSubDetail h2 {
        font-size: 16px;
    }

    .clt_message_pre_accountSubDetail p {
        font-size: 14px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_messages_container_images_container_preview img {
        width: 80px;
        height: 80px;
        cursor: pointer;
    }
    
    .clt_messages_container_images_container_preview img:hover {
        opacity: .7;
        width: 80px;
        height: 80px;
    }
    
    .clt_messages_container_images_container_preview img:active {
        opacity: .6;
        width: 80px;
        height: 80px;
    }

}