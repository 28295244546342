.clt_payment_offcanvas {
    width: 385px;
    z-index: 1055;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.clt_payment_offcanvas_header {
    padding-top: 15px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_payment_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_payment_offcanvas_header .btn-back {
    background: none;
    border-radius: 100px;
    padding: 0.5rem 0.2rem 0.5rem 0.7rem!important;
}

.clt_payment_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_payment_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_payment_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_payment_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}

.clt_payment_offcanvas_screen1_div {}

.clt_payment_offcanvas_screen1_div h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt_payment_offcanvas_screen1_div p {
    font-size: 16px;
    color: #5b5b5b;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 14px;
    margin-top: 7px;
    letter-spacing: -0.2px;
}

.clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-bottom: 10px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    width: 100px;
    height: 48px;
}

.clt_payment_offcanvas_screen1_div button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #fff !important;
}

.clt_payment_offcanvas_screen1_div button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #fff !important;
}

.clt_payment_offcanvas .form-floating>label {
    padding-left: 18px !important;
    padding-top: 13px !important;
    padding-left: 18px !important;
    padding-top: 13px !important;
    font-size: 13.5px;
    font-weight: 400;
    color: #242424;
}

.clt_payment_innerdivs {
    margin: 0px 0;
}

.clt_payment_innerhead {
    font-size: 20px;
    color: #242424;
    font-weight: 600;
    margin-bottom: 10px;
}

.clt_inputFloatDiv {
    margin-bottom: 0px;
}

.clt_payment_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 19px 24px;
    border-top: 1px solid #e0e0e0;
    background: #fff;
    z-index: 1;
}

.clt_payment_footer button {
    background: #000;
    border: 0px solid #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding-top: 1.5px;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    height: 48px;
    margin-top: 8px;
}

.clt_payment_footer button:active {
    background: #646464 !important;
}

.clt_payment_footer button:hover {
    background: #2d2d2d !important;
}

.clt_payment_header_button_black {
    background: #000 !important;
}

.pfr_payment_cardsdiv {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.pfr_payment_imgdiv {
    background: #1a73e9;
    width: 31px;
    height: 31px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pfr_payment_imgdiv img {
    width: 14px;
    padding-bottom: 2px;
}

.pfr_payment_cardnumber {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    color: #242424;
    margin-left: -18px;
    margin-bottom: -2px;
}

.pfr_payment_cardname {
    font-size: 14px;
    font-weight: 400;
    color: #5b5b5b;
    margin-bottom: 0;
    margin-left: -18px;
}

.pfr_payment_card3dot, .pfr_payment_card3dot:hover {
    color: #929292;
}

.pfr_payment_space_bottom {
    height: 30vh;
}

.clt_payment_offcanvas_body {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
}

.pfr_payment_cards3dots_options {
    color: #242424;
    font-size: 14px;
    padding: 0.7rem 1rem;
}

.pfr_payment_cards3dots_options:focus, .pfr_payment_cards3dots_options:hover {
    border-radius: 10px;
}

.pfr_payment_cards3dots_options:active {
    color: #242424;
    background-color: #d9dce0;
    font-size: 14px;
    padding: 0.7rem 1rem;
}

.clt-after-btn-p {
    margin-top: 10px;
    font-size: 14px;
    font-family: "Inter";
    margin-bottom: 5px;
    letter-spacing: -.2px;
    color: #70757a;
    font-weight: 400;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_payment_offcanvas {
        width: 100%;
    }


    .clt_payment_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt_payment_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }
    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        margin-bottom: 2px;
    }

    .pfr_payment_imgdiv img {
        width: 16px;
        padding-bottom: 2px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
        margin-left: -25px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
        margin-left: -22px;
    }

    .clt_payment_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        font-weight: 600;
        padding-top: 1.5px;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
        margin-bottom: 24px;
    }

    .pfr_payment_cards3dots_options {
        font-size: 16px !important;
        padding: 0.7rem 1rem;
    }
    
    .pfr_payment_cards3dots_options:active {
        font-size: 16px;
        padding: 0.7rem 1rem;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_payment_offcanvas {
        width: 100%;
    }


    .clt_payment_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt_payment_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        margin-bottom: 2px;
    }

    .pfr_payment_imgdiv img {
        width: 16px;
        padding-bottom: 2px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
        margin-left: -25px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
        margin-left: -22px;
    }

    .clt_payment_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        font-weight: 600;
        padding-top: 1.5px;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
        margin-bottom: 24px;
    }

    .pfr_payment_cards3dots_options {
        font-size: 16px !important;
        padding: 0.7rem 1rem;
    }
    
    .pfr_payment_cards3dots_options:active {
        font-size: 16px;
        padding: 0.7rem 1rem;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_payment_offcanvas {
        width: 100%;
    }


    .clt_payment_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -.2px;
    }

    .clt_payment_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
        border-radius: 100px;
        margin-bottom: 2px;
    }

    .pfr_payment_imgdiv img {
        width: 16px;
        padding-bottom: 2px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
        margin-left: -25px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
        margin-left: -22px;
    }

    .clt_payment_offcanvas .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        font-weight: 600;   
        padding-top: 1.5px;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
        margin-bottom: 24px;
    }

    .pfr_payment_cards3dots_options {
        font-size: 16px !important;
        padding: 0.7rem 1rem;
    }
    
    .pfr_payment_cards3dots_options:active {
        font-size: 16px;
        padding: 0.7rem 1rem;
    }

}


@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_payment_offcanvas {
        width: 425px;
    }

    .clt_payment_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        height: 52px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
    }

    .pfr_payment_imgdiv img {
        width: 16px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_payment_offcanvas {
        width: 425px;
    }

    .clt_payment_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        height: 52px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
    }

    .pfr_payment_imgdiv img {
        width: 16px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_payment_offcanvas {
        width: 425px;
    }

    .clt_payment_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        height: 52px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
    }

    .pfr_payment_imgdiv img {
        width: 16px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_payment_offcanvas {
        width: 425px;
    }

    .clt_payment_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        height: 52px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
    }

    .pfr_payment_imgdiv img {
        width: 16px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_payment_offcanvas {
        width: 425px;
    }

    .clt_payment_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        height: 52px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
    }


    .pfr_payment_imgdiv img {
        width: 16px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_payment_offcanvas {
        width: 425px;
    }

    .clt_payment_offcanvas_header {
        padding-top: 18.2px;
        padding-bottom: 20.2px;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        margin-left: 30px;
        font-size: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
    }

    .clt_payment_offcanvas_header .btn-back {
        background: none;
        padding: 0.5rem 0.5rem 0.5rem 0;
    }

    .clt_payment_offcanvas_header .btn-back img {
        width: 8px;
        margin-top: -2px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt_payment_footer button {
        font-size: 16px;
        height: 52px;
    }

    .pfr_payment_cardnumber {
        font-size: 16px;
    }

    .pfr_payment_cardname {
        font-size: 16px;
    }



    .pfr_payment_imgdiv img {
        width: 16px;
    }

    .pfr_payment_imgdiv {
        width: 36px;
        height: 36px;
    }

    .clt_payment_innerhead {
        font-size: 22px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_payment_offcanvas {
        width: 400px;
    }

    .clt_payment_offcanvas .form-floating>label {
        padding-left: 17px !important;
        padding-top: 15px !important;
        padding-left: 17px !important;
        padding-top: 15px !important;
        font-size: 15px;
        font-weight: 400;
        color: #242424;
    }

    .clt_payment_offcanvas_header {
        padding-top: 15px;
        padding-bottom: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_payment_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_payment_offcanvas_screen1_div button, .clt_payment_offcanvas_screen1_div button:active, .clt_payment_offcanvas_screen1_div button:focus, .clt_payment_offcanvas_screen1_div button:hover {
        font-size: 15px;
    }

    .clt_payment_footer button {
        font-size: 15px;
    }

    .clt_payment_innerhead {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .pfr_payment_cardnumber {
        font-size: 15px;
    }

    .pfr_payment_cardname {
        font-size: 15px;
    }


    .pfr_payment_cards3dots_options, .pfr_payment_cards3dots_options:active {
        color: #242424;
        font-size: 15px;
        padding: 0.7rem 1rem;
    }

}