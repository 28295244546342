.clt_message_wrapper {
    background-color: white;
    height: 100%;
    width: 100%;
}

.clt_message_inner_wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
}

.clt_inbox {
    width: 100%;
    min-width: 333px;
    height: 100%;
    max-width: 333px;
    border-right: 1px solid #dedddd;
}


.clt_message_inbox_detail {
    width: 100%;
    height: 100%;
    height: calc(100vh - 70px);
    z-index: 1012;
    background-color: white;
}

.clt_message_inbox_user_detail {

    z-index: 1012;
    width: 100%;
    min-width: 300px;
    height: 100%;
    border-left: 1px solid #dedddd;
   
}

.back-button {
    border: none;
    background-color: transparent;
    width: fit-content;
    min-width: fit-content;
    max-width: fit-content;
    margin: 0px;
    padding: 0px;
}

.back-button img {
    width: 11px !important;
    height: 18px !important;
    margin-bottom: 7px !important;
}

.back-button img:hover {
    opacity: 0.6;
}

.back-button img:active {
    opacity: 0.45;
}

.back-button-2 {
    position: absolute;
    left: 24px;
}

.back-button-2 img {
    width: 12px !important;
    height: 12px !important;
    margin-right: 10px;
}



.slideOut {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


@-webkit-keyframes slideOutRight {
    0% {

        width: 35%;
        min-width: 300px;
    }

    100% {
        width: 0%;
        min-width: 0px;
    }
}

@keyframes slideOutRight {
    0% {
        width: 35%;
        min-width: 300px;
    }

    100% {
        width: 0%;
        min-width: 0px;
    }
}

.slideIn {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    min-width: 333px !important;
    max-width: 333px !important;
}

@-webkit-keyframes slideInRight {
    0% {
        width: 0%;
        min-width: 0px;
    }

    100% {
        width: 35%;
        min-width: 300px;
    }
}

@keyframes slideInRight {
    0% {
        width: 0%;
        min-width: 0px;
    }

    100% {
        width: 35%;
        min-width: 300px;
    }
}

.slideOut2 {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


@-webkit-keyframes slideOutRight {
    0% {

        width: 30%;
        min-width: 300px;
    }

    100% {
        width: 0%;
        min-width: 0px;
    }
}

@keyframes slideOutRight {
    0% {
        width: 30%;
        min-width: 300px;
    }

    100% {
        width: 0%;
        min-width: 0px;
    }
}

.slideIn2 {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInRight {
    0% {
        width: 0%;
        min-width: 0px;
    }

    100% {
        width: 30%;
        min-width: 300px;
    }
}

@keyframes slideInRight {
    0% {
        width: 0%;
        min-width: 0px;
    }

    100% {
        width: 30%;
        min-width: 300px;
    }
}


@media only screen and (max-width: 600px) {

    .clt_message_inbox_detail {
        position: absolute;
        top: 0px;
        /* height: calc(100vh - 18px); */
        height: 100vh;
        width: 100%;
    }

    /*mobile*/
    .clt_inbox {
        min-width: 100%;
        width: 100%;
    }

    .clt_message_inbox_user_detail {
        min-width: 100%;
        width: 100%;
    }

    .back-button img {
        width: 7.8px !important;
        height: 14px !important;
        margin-bottom: 5px !important;
    }

    .back-button-2 img {
        width: 12px !important;
        height: 12px !important;
        margin-right: 10px;
    }

    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_inbox {
        min-width: 100%;
        width: 100%;
    }

    .clt_message_inbox_user_detail {
        min-width: 100%;
        width: 100%;
    }

    .back-button img {
        width: 7.8px !important;
        height: 14px !important;
        margin-bottom: 5px !important;
    }

    .back-button-2 img {
        width: 12px !important;
        height: 12px !important;
        margin-right: 10px;
    }

    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_inbox {
        min-width: 100%;
        width: 100%;
        max-width: 392px;
    }

    .clt_message_inbox_user_detail {
        min-width: 100%;
        width: 100%;
    }

    .back-button img {
        width: 7.8px !important;
        height: 14px !important;
        margin-bottom: 5px !important;
    }

    .back-button-2 img {
        width: 12px !important;
        height: 12px !important;
        margin-right: 10px;
    }

    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_message_inbox_detail {
        width: 100%;
    }
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_inbox {
        min-width: 380px;
        max-width: 380px;
        width: 100%;
    }

    .clt_message_inbox_detail {
        width: 100%;
    }

    .slideIn {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        min-width: 380px !important;
        max-width: 380px !important;
    }

    .back-button-2 img {
        width: 12px !important;
        height: 12px !important;
        margin-right: 10px;
    }
    
    .back-button img {
        width: 12px !important;
        height: 12px !important;
        margin-bottom: 6px !important;
    }
}