.clt-room-divs {
    margin-top: 15px;
    margin-bottom: 20px;
    border-radius: 12px;
    border: 1px solid #dedddd;
}

.clt-room-slider {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.clt-room-slider .react-multi-carousel-dot-list {
    bottom: 8px;
}

.clt-room-slider .react-multi-carousel-dot button {
    border: none !important;
    opacity: 0.6;
    width: 8px;
    height: 8px;
}

.clt-room-slider .react-multi-carousel-dot--active button {
    opacity: 1;
    background: #fff;
}

.clt-room-slider-img-div {
    background: #EEE;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 178px;
    overflow: hidden;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
}


.clt-room-slider-img-div2 {
    background: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 10px;
}
.clt-room-text-div {
    padding: 15px;
}

.clt-room-text-h2 {
    font-size: 15px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
}

.clt-room-text-p {
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 500;
    color: #70757a;
    margin-bottom: 7px;
    font-family: 'Inter';
}

.clt-room-text-tags {
    flex-wrap: wrap;
}

.clt-room-text-tags span {
    font-size: 12px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    background: #eee;
    padding: 3px 10px;
    border-radius: 10px;
    margin-right: 13px;
    margin-top: 0px;
    margin-bottom: 10px;
    letter-spacing: -0.2px;
}

.clt-room-text-tags span img {
    margin-right: 5px;
    width: 9px;
    margin-bottom: 3px;
}

.clt-products-text-tags {
    flex-wrap: wrap;
    margin-top: 5px;
}

.clt-products-text-tags span {
    font-size: 11.8px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    background: #eee;
    padding: 3px 8px;
    border-radius: 10px;
    margin-right: 6px;
    margin-top: 0px;
    margin-bottom: 10px;
    letter-spacing: -0.4px;
}

.clt-products-text-tags span img {
    margin-right: 5px;
    width: 9px;
    margin-bottom: 3px;
}

.clt-room-dropdown-btn-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100% !important;
}

.clt-room-dropdown-btn-div .line-through {
    text-decoration: line-through;
}



.clt-room-dropdown-btn {
    background-color: #fff !important;
    border: 1px solid #dedddd !important;
    border-radius: 10px;
    color: #242424 !important;
    font-size: 14px;
    font-weight: 500;
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 6px;
    padding-left: 10px;
}

.clt-room-dropdown-btn:hover {
    background: #f6f6f6 !important;
    border: 1px solid #dedddd !important;
    color: #242424 !important;
}

.clt-room-dropdown-btn:focus {
    background: #d6d6d6 !important;
    border: 1px solid #dedddd !important;
    color: #242424 !important;
}

.clt-room-dropdown-items {
    padding-top: 5px;
}

.clt-room-dropdown-items:active, .clt-room-dropdown-items:hover, .clt-room-dropdown-items:focus {
    color: #000;
    background-color: #eee;
    overflow: hidden;
    border-radius: 10px;
}

.clt-room-dropdown-items .inner-div-circle {
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 50px;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-bottom: 8px;
    margin-right: 14px;
    width: 18px;
}

.clt-room-dropdown-items .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}

.clt-room-dropdown-items .inner-div-text h2 {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.2px;
    margin-bottom: 0px;
}

.clt-room-dropdown-items .inner-div-text span {
    color: #70757a;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.2px;
    margin-bottom: 0;
}

.clt-room-slider .react-multiple-carousel__arrow:before {
    color: #242424;
    display: block;
    font-weight: 900;
    font-family: revicons;
    font-size: 16px;
    position: relative;
    text-align: center;
    z-index: 2;
}

.clt-room-slider .react-multiple-carousel__arrow:hover {
    background: #eee !important;
    color: #242424 !important;
}

.clt-room-slider .react-multiple-carousel__arrow:active {
    background: #bdbdbd !important;
    color: #242424 !important;
}

.clt-room-slider .react-multiple-carousel__arrow {
    background: rgb(255 255 255);
    border: 0;
    border-radius: 35px;
    cursor: pointer;
    min-height: 36px;
    min-width: 36px;
    opacity: 1;
    outline: 0;
    position: absolute;
    transition: all .5s;
    z-index: 1000;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-room-text-h2 {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .clt-room-text-p {
        font-size: 16px;
    }

    .clt-room-text-tags span {
        border-radius: 10px;
        font-size: 13px;
        margin-bottom: 10px;
        margin-right: 13px;
        margin-top: 0;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
    }

    .clt-room-text-tags span img {
        margin-bottom: 1px;
        margin-right: 5px;
        width: 10px;
    }

    .clt-products-text-tags span {
        border-radius: 10px;
        font-size: 13px;
        margin-bottom: 10px;
        margin-right: 13px;
        margin-top: 0;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
    }

    .clt-products-text-tags span img {
        margin-bottom: 1px;
        margin-right: 5px;
        width: 10px;
    }

    .clt-room-dropdown-items .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -.2px;
        margin-bottom: 0;
    }

    .clt-room-dropdown-items .inner-div-text span {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-room-dropdown-items .inner-div-circle {
        height: 20px;
        width: 20px;
    }

    .clt-room-dropdown-btn, .clt-room-dropdown-btn:focus {
        font-size: 16px;
        font-weight: 500;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 6px;
        padding-left: 10px;
    }

    .clt-room-slider-img-div2 {
        height: auto;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-room-text-h2 {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .clt-room-slider-img-div2 {
        height: auto;
    }

    .clt-room-text-p {
        font-size: 16px;
    }

    .clt-room-text-tags span {
        border-radius: 10px;
        font-size: 13px;
        margin-bottom: 10px;
        margin-right: 13px;
        margin-top: 0;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
    }

    .clt-room-text-tags span img {
        margin-bottom: 1px;
        margin-right: 5px;
        width: 10px;
    }

    .clt-products-text-tags span {
        border-radius: 10px;
        font-size: 13px;
        margin-bottom: 10px;
        margin-right: 13px;
        margin-top: 0;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
    }

    .clt-products-text-tags span img {
        margin-bottom: 1px;
        margin-right: 5px;
        width: 10px;
    }

    .clt-room-dropdown-items .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -.2px;
        margin-bottom: 0;
    }

    .clt-room-dropdown-items .inner-div-text span {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-room-dropdown-items .inner-div-circle {
        height: 20px;
        width: 20px;
    }

    .clt-room-dropdown-btn, .clt-room-dropdown-btn:focus {
        font-size: 16px;
        font-weight: 500;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 6px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt-room-text-h2 {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .clt-room-text-p {
        font-size: 16px;
    }

    .clt-room-text-tags span {
        border-radius: 10px;
        font-size: 13px;
        margin-bottom: 10px;
        margin-right: 13px;
        margin-top: 0;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
    }

    .clt-room-text-tags span img {
        margin-bottom: 1px;
        margin-right: 5px;
        width: 10px;
    }

    .clt-products-text-tags span {
        border-radius: 10px;
        font-size: 13px;
        margin-bottom: 10px;
        margin-right: 13px;
        margin-top: 0;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 5px;
        padding-left: 10px;
    }

    .clt-products-text-tags span img {
        margin-bottom: 1px;
        margin-right: 5px;
        width: 10px;
    }

    .clt-room-dropdown-items .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -.2px;
        margin-bottom: 0;
    }

    .clt-room-dropdown-items .inner-div-text span {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-room-dropdown-items .inner-div-circle {
        height: 20px;
        width: 20px;
    }

    .clt-room-dropdown-btn, .clt-room-dropdown-btn:focus {
        font-size: 16px;
        font-weight: 500;
        padding-top: 3px;
        padding-right: 10px;
        padding-bottom: 6px;
        padding-left: 10px;
    }

    .clt-room-slider-img-div2 {
        height: auto;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-room-text-h2 {
        font-size: 15px;
    }

    .clt-room-text-p {
        font-size: 15px;
    }

    .clt-room-slider-img-div {
        height: 178px;
    }

    .clt-room-dropdown-items {
        padding-top: 8px;
    }

    .clt-room-dropdown-items .inner-div-text h2 {
        font-size: 15px;
    }

    .clt-room-dropdown-items .inner-div-text span {
        font-size: 15px;
    }

    .clt-room-text-tags span {
        font-size: 13px;
    }

    .clt-products-text-tags span {
        font-size: 13px;
    }
}