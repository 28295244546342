.pfr_navbarNav {
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020; */
    padding-bottom: 0.08rem !important;
    padding-top: 0.08rem !important;
}


.pfr_navbarNavInnerPages {
    box-shadow: 0px 0px 5px #ddd;
    background: #fff !important;
    position: relative;
    /* top: 0;
    left: 0;
    right: 0;
    z-index: 1020; */
}

.pfr_navbarRow {
    width: 100vw;
}

.pfr_navbarToggle,
.pfr_navbarToggle:focus {
    border: none;
    box-shadow: none;
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 9px;
    border-radius: 100px;
    padding: 0.5rem 0.7rem 0.25rem 0.7rem;
}

.pfr_navbarToggle:hover {
    background: #eee;
}

.pfr_navbarToggle:active {
    background: #d9dce0;
}

.pfr_navbarToggle1 img {
    width: 93px;
    margin-bottom: 0px;
    margin-top: 1px;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.pfr_navbarLogo {
    align-items: center;
    margin-left: 12px;
    margin-bottom: 5px;
    font-size: 18px;
    letter-spacing: -.2px;
    font-weight: 600;
}

.pfr_navbarLogo img {
    padding-top: 8px;
    width: 220px;
}

.pfr_navbarLogo h2 {
    font-size: 24px;
    color: #242424;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 4px;
}

.pfr_navbarRightTxt {
    color: #fff !important;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
}

.pfr_navbarRightTxt i {
    font-size: 9px;
    color: #242424;
    padding-left: 5px;
    padding-top: 1px;
}

.pfr_navbarRightTxt a,
.pfr_navbarRightTxt a:hover {
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    background: #000;
    border-radius: 50px;
    color: #fff !important;
    padding: 10px 20px;
}


.pfr_navbarRightTxt a:nth-child(1) {
    margin-right: 5px;
}

.pfr_navbarRightTxt a:last-child {
    margin-left: 5px;
}


.pfr_navbarDropDownMenu button {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    border-color: #eee !important;
    background: #eee !important;
    border-radius: 50px;
    color: #242424 !important;
    padding-top: 6px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.2px;
}

.pfr_navbarDropDownMenu button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.pfr_navbarDropDownMenu button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}


.pfr_navbarDropDownMenu button:nth-child(1) {
    margin-right: 0px;
}

.pfr_navbarDropDownMenu button:last-child {
    margin-left: 5px;
    font-weight: 600;
}


.pfr_navbarRightTxt span {
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    background: #eee;
    border-radius: 50px;
    color: #242424 !important;
    padding-top: 7px;
    padding-right: 16px;
    padding-bottom: 9px;
    padding-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.2px;
    margin-left: 5px;
}

.pfr_navbarRightTxt span:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.pfr_navbarRightTxt span:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}


.pfr_navbarRightTxt span:nth-child(1) {
    margin-right: 0px;
}

/*
.pfr_navbarRightTxt span:last-child {
    margin-left: 5px;
    font-weight: 600;
} */

.pfr_navBarInnerPageH2 {
    font-size: 24px;
    color: #242424;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 5px;
}

/* Drawer css */
.pfr_navOffCanvas {
    width: 280px !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.pfr_navusernotactive {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.pfr_navusernotactive button {
    background: #eee;
    border: none;
    border-radius: 8px;
    color: #242424;
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    width: 128%;
    height: 53px;
    padding-bottom: 11px;
    margin-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pfr_navusernotactive button:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.pfr_navusernotactive button:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.pfr_navusernotactive button:last-child {
    margin-right: 0px;
    margin-left: 10px;
}

.pfr_navuseractive {
    padding: 20px 24px;
    justify-content: flex-start;
}

.pfr_navuseractive span {
    font-weight: 600;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
    background: #eee;
    border-radius: 12px;
    color: #242424 !important;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.pfr_navuseractive h2 {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    margin: 0px 0px;
}

.pfr_navOffCanvasBody {
    padding: 0;
    position: relative;
    overflow: scroll;
}

/* width */
.pfr_navOffCanvasBody::-webkit-scrollbar {
    width: 0px;
}

/* Track */
.pfr_navOffCanvasBody::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.pfr_navOffCanvasBody::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
.pfr_navOffCanvasBody::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

.pfr_drawermenuitem,
.pfr_drawermenuitem:hover,
.pfr_drawermenuitem:focus {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 23px;
    padding-top: 21px;
    width: 100%;
    cursor: pointer;
    background-position: center;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    text-decoration: none;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pfr_drawermenuitem.activeNav {
    background-color: #eee;
    background-size: 100%;
    font-weight: 600;
}

.pfr_drawermenuitem:hover {
    background-color: #f6f6f6;
    background-size: 100%;
}

.pfr_drawermenuitem:active {
    background-color: #eee;
    background-size: 100%;
}

.pfr_drawermenuitemdiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pfr_drawermenuitemdivs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pfr_drawermenuitemdiv div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 15px;
    height: 15px;
    margin-bottom: 4.8px;
}

.pfr_drawermenuitemdiv-div {
    padding: 1rem;
    margin: 0.2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.clt_left_menu_section_user_divContent {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 23px;
    padding-top: 21px;
    width: 100%;
    cursor: pointer;
    background-position: center;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    text-decoration: none;
    /* height: 50px; */
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
}

.clt_left_menu_section_user_span span,
.pfr_drawermenuitemdiv span {
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #242424;
    font-weight: 400;
    padding-left: 18px;
    padding-bottom: 3px;
    white-space: nowrap;
}

.pfr_drawermenuitem.activeNav .pfr_drawermenuitemdiv span {
    color: black;
    font-weight: 600;
}

.pfr_drawermenuitemdivred {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pfr_drawermenuitemdivred div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 15px;
    height: 15px;
    margin-left: 1.1px;
    margin-bottom: 4.8px;
}

.pfr_drawermenuitemdivred span {
    font-size: 14px;
    color: #ef3737;
    font-weight: 500;
    white-space: nowrap;
    padding-left: 18px;
    padding-bottom: 3px;
    text-transform: capitalize;
}

.pfr_drawermenuitem.activeNav .pfr_drawermenuitemdivred span {
    color: #eee;
    font-weight: 500;
}

.pfr_drawermenuitem1 {
    /* background-image: url(../img/drawer_m1.png); */
}

.pfr_drawermenuitem.activeNav .pfr_drawermenuitem1 {
    /* background-image: url(../img/drawer_m1_active.png); */
}

.pfr_drawermenuitem2 {
    /* background-image: url(../img/drawer_m2.png); */
}

.pfr_drawermenuitem.activeNav .pfr_drawermenuitem2 {
    /* background-image: url(../img/drawer_m2_active.png); */
}

.pfr_drawermenuitem3 {
    /* background-image: url(../img/drawer_m3.png); */
}

.pfr_drawermenuitem.activeNav .pfr_drawermenuitem3 {
    /* background-image: url(../img/drawer_m3_active.png); */
}

.pfr_drawermenuitem4 {
    /* background-image: url(../img/drawer_m4.png); */
}

.pfr_drawermenuitem.activeNav .pfr_drawermenuitem4 {
    /* background-image: url(../img/drawer_m4_active.png); */
}

.pfr_drawermenuitem5 {
    /* background-image: url(../img/drawer_m5.png); */
}

.pfr_drawermenuitem.activeNav .pfr_drawermenuitem5 {
    /* background-image: url(../img/drawer_m5_active.png); */
}

.pfr_drawermenuitem6 {
    /* background-image: url(../img/drawer_m6.png); */
}

.pfr_drawermenuitem11 {
    /* background-image: url(../img/drawer_m11.png); */
}

.pfr_drawermenuitem12 {
    /* background-image: url(../img/drawer_m12.png); */
}


.pfr_drawermenuitem.activeNav .pfr_drawermenuitem6 {
    /* background-image: url(../img/drawer_m6_active.png); */
}

.pfr_drawermenuitem1 {
    background-image: url(../img/drawer_m1.png);
}

.pfr_drawermenuitem2 {
    background-image: url(../img/drawer_m2.png);
}

.pfr_drawermenuitem3 {
    background-image: url(../img/drawer_m3.png);
}

.pfr_drawermenuitem4 {
    background-image: url(../img/drawer_m4.png);
}

.pfr_drawermenuitem5 {
    background-image: url(../img/drawer_m5.png);
}

.pfr_drawermenuitem6 {
    background-image: url(../img/drawer_m6.png);
}

.pfr_drawermenuitem7 {
    background-image: url(../img/drawer_m7.png);
}


.pfr_drawermenuitem8 {
    background-image: url(../img/drawer_m8.png);
}

.pfr_drawermenuitem9 {
    background-image: url(../img/drawer_m9.png);
}

.pfr_drawermenuitem10 {
    background-image: url(../img/drawer_m10.png);
}

.pfr_drawermenuitem11 {
    background-image: url(../img/drawer_m11.png);
}

.pfr_drawermenuitem12 {
    background-image: url(../img/drawer_m12.png);
}

.pfr_drawermenuitem12Active {
    background-image: url(../img/drawer_m12_active_notification.png);
}

.pfr_drawermenuitem13 {
    background-image: url(../img/drawer_m13.png);
}

.pfr_drawermenuitem14 {
    background-image: url(../img/home_searchbar_icon.png);
}

.pfr_drawermenuitem15 {
    background-image: url(../img/drawer_m15.png);
}

.pfr_drawermenuline {
    height: 1px;
    width: 100%;
    margin: 10px 0px;
    background: rgb(0 0 0 / 10%);
}

.pfr_drawermenulogoutdiv {
    margin: 30px 30px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pfr_drawermenulogout,
.pfr_drawermenulogout:hover,
.pfr_drawermenulogout:active,
.pfr_drawermenulogout:focus {
    background: #fff;
    color: red;
    border: 1px solid red;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    width: 100%;
    border-radius: 5px;
}

.pfr_drawermenufooter {
    position: fixed;
    bottom: 0;
    color: #fff;
    width: 328px;
}

.pfr_drawermenufooterblue {
    padding: 20px 20px;
    background: #eee;
    position: relative;
    overflow: hidden;
}

.pfr_drawermenufooterblue h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #fff;
    line-height: 26px;
    width: 95%;
}

.pfr_drawermenufooterblue button,
.pfr_drawermenufooterblue button:active,
.pfr_drawermenufooterblue button:hover,
.pfr_drawermenufooterblue button:focus {
    border-radius: 50px;
    background: #fff;
    color: #242424;
    font-size: 15.5px;
    font-weight: 700;
    padding-bottom: 3px;
    padding-top: 3px;
    padding-right: 11px;
    padding-left: 11px;
    margin-bottom: 8px;
}

.pfr_drawermenufooterblue img {
    position: absolute;
    bottom: 0;
    right: -10px;
    width: 100px;
}

.pfr_drawermenufooterblack {
    padding: 20px 20px;
    background: #000;
}

.pfr_drawermenufooter span {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 10px;
}

.pfr_drawermenufooter p {
    font-size: 15.5px;
    line-height: 1.45;
    font-weight: 600;
    color: #fff;
    margin: 0;
}

.pfr_navbarinput .input-group-text {
    background: #eee !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}

.pfr_navbar_select_parking {
    background: #eee !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    height: 58px !important;
    padding-bottom: 2px !important;
}

.pfr_navbar_to_show_desktop {
    display: block;
}

/* Inner Navbar */
.pfr_navbarRightShare {
    cursor: pointer;
}

.pfr_navbarRightShare img {
    border: 1px solid #dedddd;
    border-radius: 8px;
    padding: 10px;
    width: 40px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dropdown-menu.show.dropdown-menu-end {
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 13px;
    border: 0px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, .12) !important;
    width: 343px;
    align-items: center;
    justify-content: center;
    z-index: 1055;
    margin-top: 13px;
}

.pfr_drawermenuHead {
    font-size: 17px;
    font-weight: 600;
    color: #242424;
    text-transform: capitalize;
    margin-left: 28px;
    margin-bottom: 5px;
    margin-top: 0px;
}

.clt-divLogin {
    font-size: 16px;
    font-weight: 500;
    color: #242424;
    text-transform: capitalize;
    /* margin-left: 28px;
    margin-bottom: 5px;
    margin-top: 0px; */
}

.pfr_drawermenuHead2 {
    font-size: 22px;
    font-weight: 600;
    color: #242424;
    text-transform: capitalize;
    margin-left: 28px;
    margin-bottom: 5px;
    margin-top: 0px;
}

.pfr_navusernotactive button,
.pfr_navusernotactive button:active,
.pfr_navusernotactive button:hover,
.pfr_navusernotactive button:focus {
    background: #eee;
    border: none;
    border-radius: 8px;
    color: #242424;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    height: 38px;
    padding-bottom: 8px;
    margin-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    letter-spacing: 0.3px;
}

.pfr_innerNavbarNav {
    border-bottom: 1px solid #dedddd;
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
}

.pfr_innerNav_container {
    padding-left: 17px;
    padding-right: 24px;
}

.pfr_innerNavbarRow {
    width: 100vw;
}

.pfr_innerNavbarToggle,
.pfr_innerNavbarToggle:focus {
    border: none;
    box-shadow: none;
    background: #fff;
    margin-bottom: 10px;
    margin-top: 7px;
    border-radius: 100px;
    padding-top: 0.4rem !important;
    padding-right: 0.86rem !important;
    padding-bottom: 0.36rem !important;
    padding-left: 0.86rem !important;
}


.pfr_innerNavbarToggle:hover {
    background: #eee;
}

.pfr_innerNavbarToggle:active {
    background: #e4e4e4;
}

.pfr_innerNavbarToggle img {
    width: 18px;
    margin-bottom: 3.5px;
}

.pfr_products_offcanvas_header .fa-light fa-chevron-left {
    width: 7px;
}

.pfr_products_offcanvas_header .fa-light fa-chevron-left {
    width: 7px;
}

.fa-light fa-chevron-left {
    font-size: 16px;
    margin-bottom: 2px;
}

.pfr_innerNavbarLogo {
    align-items: center;
    margin-left: 0px;
    color: #242424 !important;
    margin-bottom: 2px;
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 600;
    margin-bottom: 4px;
}

.pfr_innerNavbarRightShare img {
    border: 1px solid #dedddd;
    border-radius: 80px;
    padding: 10px;
    width: 35px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pfr_innerNavbarRightShare img:hover {
    background: #eee;
}

.pfr_innerNavbarRightShare img:active {
    background: #e4e4e4;
}


.pfr_innerNavbarLogo img {
    padding-top: 8px;
    width: 220px;
}

.pfr_innerNavbarLogo h2 {
    font-size: 24px;
    color: #242424;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 4px;
}

.pfr_innerNavbarRightTxt {
    color: #fff !important;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
}

.pfr_innerNavbarRightTxt i {
    font-size: 10px;
    color: #242424;
    padding-left: 5px;
}

.pfr_innerNavbarRightTxt a,
.pfr_innerNavbarRightTxt a:hover {
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    background: #000;
    border-radius: 50px;
    color: #fff !important;
    padding: 10px 20px;
}


.pfr_innerNavbarRightTxt a:nth-child(1) {
    margin-right: 5px;
}

.pfr_innerNavbarRightTxt a:last-child {
    margin-left: 5px;
}

.pfr_innerNavbarRightTxt span {
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    background: #eee;
    border-radius: 50px;
    color: #242424 !important;
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pfr_innerNavbarRightTxt span:nth-child(1) {
    margin-right: 5px;
}

.pfr_innerNavbarRightTxt span:last-child {
    margin-left: 5px;
    font-weight: 600;
}

.pfr_innerNavbarInnerPageH2 {
    font-size: 24px;
    color: #242424;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 5px;
}

.pfr_navbarToggle img {
    margin-bottom: 6px;
    width: 15px;
}



@media only screen and (max-width: 600px) {

    /*mobile*/
    .pfr_navbarNav {
        padding-bottom: 0.25rem !important;
        padding-left: 0px;
        padding-right: 10px;
        padding-top: 0.15rem !important;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 17px;
        margin-bottom: 3px;
    }

    .pfr_navbarRightShare img {
        width: 37px;
        height: 39px;
        border: 1px solid #ffffff;
    }

    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: -.1px;
        padding: 7px 11px 10.4px 10px;
    }

    .pfr_navbarLogo {
        margin-left: 3px;
        font-size: 18px;
        letter-spacing: -0.5px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .pfr_innerNav_container {
        padding-left: 13px;
        padding-right: 15px;
    }

    .pfr_innerNavbar_to_show_desktop {
        display: none;
    }

    .pfr_innerNavbarNav {
        padding-bottom: 0.03rem !important;
        padding-left: 0;
        padding-right: 9px;
        padding-top: 0.02rem !important;
    }

    .pfr_innerNavbarNavInnerPages {
        box-shadow: none;
    }

    .pfr_innerNavbarInnerPageH2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .pfr_innerNavbarRow {
        width: 100vw;
    }

    .pfr_innerNavbarToggle img {
        width: 18px;
    }


    .pfr_innerNavbarRightTxt span {
        font-weight: 500;
        font-size: 13px;
        letter-spacing: -0.2px;
        padding-bottom: 6px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
    }

    .pfr_innerNavbarLogo {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 7px;
        margin-left: -5px;
    }

    .pfr_innerNavbarLogo img {
        width: 135px;
    }

    .pfr_innerNavbarRightTxt span:nth-child(1) {
        margin-right: 0;
    }

    .pfr_navbarToggle,
    .pfr_navbarToggle:focus {
        border-radius: 100px;
        margin-bottom: 8px;
        margin-top: 7px;
        padding-top: 0.5rem;
        padding-right: 0.76rem;
        padding-left: 0.82rem;
    }

    .pfr_drawermenuHead {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 28px;
    }

    .clt-divLogin {
        font-size: 18px;
        font-weight: 500;
        color: #242424;
        text-transform: capitalize;
        /* margin-left: 28px; */
    }

    .pfr_drawermenuHead2 {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 28px;
    }

    .pfr_navbarDropDownMenu button {
        font-size: 16px;
        border-radius: 50px;
        padding-top: 6px;
        padding-right: 16px;
        padding-bottom: 10px;
        padding-left: 16px;
        letter-spacing: 0.2px;
    }

    .dropdown-menu.show.dropdown-menu-end {
        border-radius: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 12px;
        right: -1px;
        width: calc(98.5vw - 40px);
    }

    .pfr_drawermenuitemdivred div {
        width: 15px;
        height: 15px;
        margin-left: 2.2px;
        margin-bottom: 4.8px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 16px;
        font-weight: 500;
        padding-left: 20px;
        padding-bottom: 6px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        font-size: 15px;
        font-weight: 600;
        width: 100%;
        letter-spacing: 0.1px;
        padding-bottom: 8px;
        height: 46px;
        margin-right: 10px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 16px;
        font-weight: 400;
        padding-left: 20px;
    }

    .pfr_drawermenuitemdiv div {
        width: 17px;
        height: 17px;
        margin-bottom: 3px;
    }

    .pfr_drawermenuitemdiv-div {
        padding: 0.8rem;
        width: 18px;
        height: 18px;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 18px;
        padding-top: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 48px;
    }

    .pfr_innerNavbarRightShare img {
        width: 39px;
        height: 41px;
        margin-bottom: 5px;
        border-radius: 0px;
        border: 1px solid #ffffff;
    }

    .pfr_innerNavbarRightShare img:hover {
        background: #ffffff;
    }

    .pfr_innerNavbarRightShare img:active {
        background: #fff;
    }

    .pfr_innerNavbarToggle,
    .pfr_innerNavbarToggle:focus {
        margin-bottom: 13px;
        margin-top: 10px;
        padding-top: 0.4rem !important;
        padding-right: 0.86rem !important;
        padding-bottom: 0.36rem !important;
        padding-left: 0.86rem !important;
    }

    .clt_left_menu_section_user_divContent {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 6px;
        padding-top: 6px;
    }

    .pfr_navbarToggle1 img {
        width: 97px;
        margin-bottom: 0px;
        margin-top: 2px;
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 767px) and (orientation: portrait) {

    /*mobile*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_innerNav_container {
        padding-left: 13px;
        padding-right: 15px;
    }

    .pfr_innerNavbar_to_show_desktop {
        display: none;
    }

    .pfr_innerNavbarNav {
        padding-bottom: 0.03rem !important;
        padding-left: 0;
        padding-right: 9px;
        padding-top: 0.02rem !important;
    }

    .pfr_innerNavbarNavInnerPages {
        box-shadow: none;
    }

    .pfr_innerNavbarInnerPageH2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .pfr_innerNavbarRow {
        width: 100vw;
    }

    .pfr_innerNavbarToggle img {
        width: 18px;
    }

    .pfr_innerNavbarRightTxt span {
        font-weight: 500;
        font-size: 13px;
        letter-spacing: -0.2px;
        padding-bottom: 6px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
    }

    .pfr_innerNavbarLogo {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 7px;
        margin-left: -5px;
    }

    .pfr_innerNavbarLogo img {
        width: 135px;
    }

    .pfr_innerNavbarRightTxt span:nth-child(1) {
        margin-right: 0;
    }

    .pfr_navbarNav {
        padding-bottom: 0.25rem !important;
        padding-left: 0px;
        padding-right: 10px;
        padding-top: 0.15rem !important;
    }

    .pfr_navbarNavInnerPages {
        box-shadow: none;
    }

    .pfr_navBarInnerPageH2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 18px;
        margin-bottom: 3px;
    }


    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: -.1px;
        padding: 7px 11px 10.4px 10px;
    }

    .pfr_navbarLogo {
        margin-left: 3px;
        font-size: 20px;
        letter-spacing: -0.5px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .pfr_navbarLogo img {
        width: 135px;
    }

    .pfr_navbarRightTxt span:nth-child(1) {
        margin-right: 0;
    }

    .pfr_navOffCanvas {
        width: 300px !important;
    }

    .pfr_navusernotactive {
        padding-top: 18px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 2px;
    }

    .pfr_navuseractive {
        padding-right: 24px;
        padding-left: 24px;
        padding-bottom: 10px;
        padding-top: 18px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
        height: 49px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 18px;
        padding-top: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 48px;
    }

    .pfr_drawermenuitemdiv div {
        width: 17px;
        height: 17px;
        margin-bottom: 3px;
    }

    .pfr_drawermenuitemdiv-div {
        padding: 0.8rem;
        width: 18px;
        height: 18px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 16px;
        font-weight: 400;
        padding-left: 20px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 16px;
        font-weight: 500;
        padding-left: 20px;
        padding-bottom: 6px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 300px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooterblue h2 {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #fff;
        line-height: 20px;
        width: 95%;
    }

    .pfr_drawermenufooterblue button,
    .pfr_drawermenufooterblue button:active,
    .pfr_drawermenufooterblue button:focus,
    .pfr_drawermenufooterblue button:hover {
        font-size: 12px;
        padding-bottom: 2px;
        padding-top: 2px;
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 0px;
    }

    .pfr_drawermenufooterblue img {
        width: 80px;
    }

    .pfr_drawermenufooter p {
        font-size: 13px;
        margin: 0;
    }

    .pfr_navbarToggle,
    .pfr_navbarToggle:focus {
        border-radius: 100px;
        margin-bottom: 8px;
        margin-top: 7px;
        padding-top: 0.5rem;
        padding-right: 0.76rem;
        padding-left: 0.82rem;
    }

    .pfr_drawermenuHead {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 28px;
    }

    .clt-divLogin {
        font-size: 18px;
        font-weight: 500;
        color: #242424;
        text-transform: capitalize;
    }

    .pfr_drawermenuHead2 {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 28px;
    }

    .pfr_navbarDropDownMenu button {
        font-size: 16px;
        border-radius: 50px;
        padding-top: 6px;
        padding-right: 16px;
        padding-bottom: 10px;
        padding-left: 16px;
        letter-spacing: 0.2px;
    }

    .dropdown-menu.show.dropdown-menu-end {
        border-radius: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 12px;
        right: -1px;
    }

    .pfr_drawermenuitemdivred div {
        width: 15px;
        height: 15px;
        margin-left: 2.2px;
        margin-bottom: 4.8px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        font-size: 15px;
        font-weight: 600;
        width: 100%;
        letter-spacing: 0.1px;
        padding-bottom: 8px;
        height: 46px;
        margin-right: 10px;
    }

    .pfr_innerNavbarRightShare img {
        width: 39px;
        height: 41px;
        margin-bottom: 5px;
        border-radius: 0px;
        border: 1px solid #ffffff;
    }

    .pfr_innerNavbarRightShare img:hover {
        background: #ffffff;
    }

    .pfr_innerNavbarRightShare img:active {
        background: #fff;
    }

    .pfr_innerNavbarToggle,
    .pfr_innerNavbarToggle:focus {
        margin-bottom: 13px;
        margin-top: 10px;
        padding-top: 0.4rem !important;
        padding-right: 0.86rem !important;
        padding-bottom: 0.36rem !important;
        padding-left: 0.86rem !important;
    }

    .clt_left_menu_section_user_divContent {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 6px;
        padding-top: 6px;
    }

    .pfr_navbarToggle1 img {
        width: 97px;
        margin-bottom: 0px;
        margin-top: 2px;
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 767px) and (orientation: landscape) {

    /*mobile*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_innerNav_container {
        padding-left: 13px;
        padding-right: 15px;
    }

    .pfr_innerNavbar_to_show_desktop {
        display: none;
    }

    .pfr_innerNavbarNav {
        padding-bottom: 0.03rem !important;
        padding-left: 0;
        padding-right: 9px;
        padding-top: 0.02rem !important;
    }

    .pfr_innerNavbarNavInnerPages {
        box-shadow: none;
    }

    .pfr_innerNavbarInnerPageH2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .pfr_innerNavbarRow {
        width: 100vw;
    }

    .pfr_innerNavbarToggle img {
        width: 18px;
    }

    .pfr_innerNavbarRightTxt span {
        font-weight: 500;
        font-size: 13px;
        letter-spacing: -0.2px;
        padding-bottom: 6px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
    }

    .pfr_innerNavbarLogo {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 7px;
        margin-left: -5px;
    }

    .pfr_innerNavbarLogo img {
        width: 135px;
    }

    .pfr_innerNavbarRightTxt span:nth-child(1) {
        margin-right: 0;
    }

    .pfr_navbarNav {
        padding-bottom: 0.25rem !important;
        padding-left: 0px;
        padding-right: 10px;
        padding-top: 0.15rem !important;
    }

    .pfr_navbarNavInnerPages {
        box-shadow: none;
    }

    .pfr_navBarInnerPageH2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 18px;
        margin-bottom: 3px;
    }


    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: -.1px;
        padding: 7px 11px 10.4px 10px;
    }

    .pfr_navbarLogo {
        margin-left: 3px;
        font-size: 20px;
        letter-spacing: -0.5px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .pfr_navbarLogo img {
        width: 135px;
    }

    .pfr_navbarRightTxt span:nth-child(1) {
        margin-right: 0;
    }

    .pfr_navOffCanvas {
        width: 300px !important;
    }

    .pfr_navusernotactive {
        padding-top: 18px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 10px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        font-size: 15px;
        font-weight: 600;
        width: 100%;
        letter-spacing: 0.1px;
        padding-bottom: 8px;
        height: 46px;
        margin-right: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 2px;
    }

    .pfr_navuseractive {
        padding-right: 24px;
        padding-left: 24px;
        padding-bottom: 10px;
        padding-top: 18px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
        height: 49px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 18px;
        padding-top: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 48px;
    }

    .pfr_drawermenuitemdiv div {
        width: 17px;
        height: 17px;
        margin-bottom: 3px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 16px;
        font-weight: 400;
        padding-left: 20px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 16px;
        font-weight: 500;
        padding-left: 20px;
        padding-bottom: 6px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 300px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooterblue h2 {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #fff;
        line-height: 20px;
        width: 95%;
    }

    .pfr_drawermenufooterblue button,
    .pfr_drawermenufooterblue button:active,
    .pfr_drawermenufooterblue button:focus,
    .pfr_drawermenufooterblue button:hover {
        font-size: 12px;
        padding-bottom: 2px;
        padding-top: 2px;
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 0px;
    }

    .pfr_drawermenufooterblue img {
        width: 80px;
    }

    .pfr_drawermenufooter p {
        font-size: 13px;
        margin: 0;
    }

    .pfr_navbarToggle,
    .pfr_navbarToggle:focus {
        border-radius: 100px;
        margin-bottom: 8px;
        margin-top: 7px;
        padding-top: 0.5rem;
        padding-right: 0.76rem;
        padding-left: 0.82rem;
    }


    .pfr_drawermenuHead {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 28px;
    }

    .clt-divLogin {
        font-size: 18px;
        font-weight: 500;
        color: #242424;
        text-transform: capitalize;
    }

    .pfr_drawermenuHead2 {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 28px;
    }

    .pfr_navbarDropDownMenu button {
        font-size: 16px;
        border-radius: 50px;
        padding-top: 6px;
        padding-right: 16px;
        padding-bottom: 10px;
        padding-left: 16px;
        letter-spacing: 0.2px;
    }

    .dropdown-menu.show.dropdown-menu-end {
        border-radius: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 12px;
        right: -1px;
    }

    .pfr_drawermenuitemdivred div {
        width: 15px;
        height: 15px;
        margin-left: 2.2px;
        margin-bottom: 4.8px;
    }

    .pfr_innerNavbarRightShare img {
        width: 39px;
        height: 41px;
        margin-bottom: 5px;
        border-radius: 0px;
        border: 1px solid #ffffff;
    }

    .pfr_innerNavbarRightShare img:hover {
        background: #ffffff;
    }

    .pfr_innerNavbarRightShare img:active {
        background: #fff;
    }

    .pfr_innerNavbarToggle,
    .pfr_innerNavbarToggle:focus {
        margin-bottom: 13px;
        margin-top: 10px;
        padding-top: 0.4rem !important;
        padding-right: 0.86rem !important;
        padding-bottom: 0.36rem !important;
        padding-left: 0.86rem !important;
    }

    .clt_left_menu_section_user_divContent {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 6px;
        padding-top: 6px;
    }

    .pfr_navbarToggle1 img {
        width: 97px;
        margin-bottom: 0px;
        margin-top: 2px;
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {

    /*ipad*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_navbarNav {
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 16px;
        margin-bottom: -1.5px;
    }

    .pfr_navbarLogo {
        margin-left: 12px;
        font-size: 20px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .pfr_navbarLogo img {
        padding-top: 8px;
        width: 130px;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: 0;
        padding: 8px 15px 11px 13px;
    }

    .pfr_navOffCanvas {
        width: 400px !important;
    }

    .pfr_navusernotactive {
        padding: 20px 20px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        height: 50px;
        margin-right: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 10px;
    }

    .pfr_navuseractive {
        padding: 20px 24px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding: 30px 30px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuitemdiv div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv-div {
        width: 18px;
        height: 18px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 400px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooter p {
        font-size: 15.5px;
        line-height: 1.45;
        margin: 0;
    }
    .pfr_navbarToggle1 img {
        width: 106px;
        margin-bottom: 0px;
        margin-top: 0px;
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {

    /*ipad*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_navbarNav {
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 16px;
        margin-bottom: -1.5px;
    }

    .pfr_navbarLogo {
        margin-left: 13.5px;
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .pfr_navbarLogo img {
        padding-top: 8px;
        width: 130px;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: 0;
        padding: 8px 15px 11px 13px;
    }

    .pfr_navOffCanvas {
        width: 400px !important;
    }

    .pfr_navusernotactive {
        padding: 20px 20px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        height: 50px;
        margin-right: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 10px;
    }

    .pfr_navuseractive {
        padding: 20px 24px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding: 30px 30px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuitemdiv div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 400px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooter p {
        font-size: 15.5px;
        line-height: 1.45;
        margin: 0;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation: portrait) {

    /*ipad pro*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_navbarNav {
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 16px;
        margin-bottom: -1.5px;
    }

    .pfr_navbarLogo {
        margin-left: 13.5px;
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .pfr_navbarLogo img {
        padding-top: 8px;
        width: 130px;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: 0;
        padding: 8px 15px 11px 13px;
    }

    .pfr_navOffCanvas {
        width: 400px !important;
    }

    .pfr_navusernotactive {
        padding: 20px 20px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        height: 50px;
        margin-right: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 10px;
    }

    .pfr_navuseractive {
        padding: 20px 24px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding: 30px 30px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuitemdiv div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv-div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 400px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooter p {
        font-size: 15.5px;
        line-height: 1.45;
        margin: 0;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation: landscape) {

    /*ipad pro*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_navbarNav {
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 16px;
        margin-bottom: -1.5px;
    }

    .pfr_navbarLogo {
        margin-left: 13.5px;
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .pfr_navbarLogo img {
        padding-top: 8px;
        width: 130px;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: 0;
        padding: 8px 15px 11px 13px;
    }

    .pfr_navOffCanvas {
        width: 400px !important;
    }

    .pfr_navusernotactive {
        padding: 20px 20px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        height: 50px;
        margin-right: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 10px;
    }

    .pfr_navuseractive {
        padding: 20px 24px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding: 30px 30px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuitemdiv div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv-div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 400px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooter p {
        font-size: 15.5px;
        line-height: 1.45;
        margin: 0;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation: portrait) {

    /*ipad pro*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_navbarNav {
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle img {
        width: 16px;
        margin-bottom: -1.5px;
    }

    .pfr_navbarLogo {
        margin-left: 13.5px;
        font-size: 18px;
        letter-spacing: -0.2px;
        font-weight: 600;
    }

    .pfr_navbarLogo img {
        padding-top: 8px;
        width: 130px;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: 0;
        padding: 8px 15px 11px 13px;
    }

    .pfr_navOffCanvas {
        width: 400px !important;
    }

    .pfr_navusernotactive {
        padding: 20px 20px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        height: 50px;
        margin-right: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 10px;
    }

    .pfr_navuseractive {
        padding: 20px 24px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding: 30px 30px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuitemdiv div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv-div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 400px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooter p {
        font-size: 15.5px;
        line-height: 1.45;
        margin: 0;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation: landscape) {

    /*ipad pro*/
    .pfr_navbar_to_show_desktop {
        display: none;
    }

    .pfr_navbarNav {
        padding-top: 0.2rem !important;
        padding-bottom: 0.3rem !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .pfr_navbarRightShare img {
        width: 39px;
        height: 41px;
        border: 1px solid #ffffff;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_navbarToggle, .pfr_navbarToggle:focus {
        border: none;
        box-shadow: none;
        cursor: pointer;
        margin-top: 3px;
        margin-bottom: 9px;
        border-radius: 100px;
        padding: 0.5rem 0.7rem 0.25rem 0.7rem;
    }

    .pfr_navbarToggle img {
        width: 16px;
        margin-bottom: -1.5px;
    }

    .pfr_navbarLogo {
        margin-left: 13.5px;
        font-size: 22px;
        letter-spacing: -0.2px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    .pfr_navbarLogo img {
        padding-top: 8px;
        width: 130px;
    }

    .pfr_navbarRightTxt span {
        font-size: 16px;
        letter-spacing: 0;
        padding: 8px 15px 11px 13px;
    }

    .pfr_navOffCanvas {
        width: 400px !important;
    }

    .pfr_navusernotactive {
        padding: 20px 20px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        height: 50px;
        margin-right: 10px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 10px;
    }

    .pfr_navuseractive {
        padding: 20px 24px;
    }

    .pfr_navuseractive span {
        font-size: 15px;
        padding: 10px 20px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding: 30px 30px;
        font-size: 14px;
        font-weight: 400;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuitemdiv div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv-div {
        width: 20px;
        height: 20px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 18px;
        font-weight: 400;
        padding-left: 30px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 400px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooter p {
        font-size: 15.5px;
        line-height: 1.45;
        margin: 0;
    }


}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .pfr_navbarNav {
        padding-bottom: 0.19rem !important;
        padding-top: 0.14rem !important;
        padding-left: 0.24rem !important;
        padding-right: 0.24rem !important;
    }

    .pfr_navbarToggle,
    .pfr_navbarToggle:focus {
        border: none;
        box-shadow: none;
        cursor: pointer;
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 100px;
        padding: 0.5rem 0.7rem 0.3rem 0.7rem !important;
    }

    .pfr_navbarRightTxt {
        font-weight: 400;
        font-size: 15px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pfr_navbarRow {
        width: 100vw;
    }

    .pfr_innerNavbarNav {
        border-bottom: 1px solid #dedddd;
        padding-top: 0.1rem !important;
        padding-bottom: 0.1rem !important;
    }

    .pfr_innerNav_container {
        padding-left: 18px;
        padding-right: 24px;
    }

    .pfr_innerNavbarRow {
        width: 100vw;
    }

    .pfr_innerNavbarToggle,
    .pfr_innerNavbarToggle:focus {
        margin-bottom: 11px;
        margin-top: 7px;
        border-radius: 100px;
        padding-top: 0.4rem !important;
        padding-right: 0.86rem !important;
        padding-bottom: 0.36rem !important;
        padding-left: 0.86rem !important;
    }

    .pfr_innerNavbarToggle img {
        width: 18px;
        margin-bottom: 3.5px;
    }

    .pfr_innerNavbarLogo {
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-left: 3px;
        margin-bottom: 4px;
    }

    .pfr_innerNavbarLogo img {
        padding-top: 8px;
        width: 240px;
    }

    .pfr_innerNavbarRightTxt a,
    .pfr_innerNavbarRightTxt a:hover {
        font-weight: 500;
        font-size: 15px;
    }

    .pfr_innerNavbarRightTxt a:nth-child(1) {
        margin-right: 5px;
    }

    .pfr_innerNavbarRightTxt a:last-child {
        margin-left: 5px;
    }

    .pfr_innerNavbarRightTxt span {
        font-weight: 500;
        font-size: 15px;
    }

    .pfr_innerNavbarRightTxt span:nth-child(1) {
        margin-right: 5px;
    }

    .pfr_innerNavbarRightTxt span:last-child {
        margin-left: 5px;
    }

    .pfr_navbarToggle img {
        width: 16px;
        margin-bottom: 9px;
    }

    .pfr_navbarLogo {
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 4.1px !important;
        margin-left: 12px;
    }

    .pfr_navbarLogo img {
        padding-top: 8px;
        width: 240px;
    }

    .pfr_navbarRightTxt a,
    .pfr_navbarRightTxt a:hover {
        font-weight: 500;
        font-size: 15px;
    }

    .pfr_navbarRightTxt a:nth-child(1) {
        margin-right: 5px;
    }

    .pfr_navbarRightTxt a:last-child {
        margin-left: 5px;
    }

    .pfr_navbarRightTxt span {
        font-size: 14px;
        letter-spacing: 0.1px;
    }

    .pfr_navbarRightTxt span:nth-child(1) {
        margin-right: 0px;
    }

    /*
    .pfr_navbarRightTxt span:last-child {
        font-size: 15px;
        margin-left: 5px;
    }*/

    .clt-section-3-h2 {
        font-size: 28px;
    }

    .pfr_navOffCanvas {
        width: 328px !important;
    }

    .pfr_navusernotactive {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .pfr_navusernotactive button,
    .pfr_navusernotactive button:active,
    .pfr_navusernotactive button:hover,
    .pfr_navusernotactive button:focus {
        font-size: 17px;
        font-weight: 600;
        width: 100%;
        height: 50px;
        padding-bottom: 11.5px;
        margin-right: 3px;
    }

    .pfr_navusernotactive button:last-child {
        margin-right: 0px;
        margin-left: 10px;
    }

    .pfr_navuseractive {
        padding: 20px 24px;
    }

    .pfr_navuseractive span {
        font-size: 17px;
        padding: 10px 20px;
    }

    .pfr_navuseractive h2 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px;
    }

    .pfr_navOffCanvasBody {
        padding: 0;
    }

    .pfr_drawermenuitem,
    .pfr_drawermenuitem:hover,
    .pfr_drawermenuitem:focus {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 26px;
        padding-top: 20px;
        font-weight: 500;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuitemdiv div {
        width: 18px;
        height: 18px;
        margin-bottom: 1.4px;
    }

    .pfr_drawermenuitemdiv-div {
        width: 22px;
        height: 22px;
    }

    .pfr_drawermenuitemdivred div {
        width: 16px;
        height: 16px;
        margin-bottom: 1.4px;
        margin-left: 2.2px;
    }

    .pfr_drawermenuitemdiv span {
        font-size: 15px;
        font-weight: 400;
        padding-left: 22px;
        padding-bottom: 0px;
        padding-top: 1px;
    }

    .pfr_drawermenuitemdivred span {
        font-size: 15px;
        font-weight: 400;
        padding-left: 22px;
        padding-bottom: 0px;
        padding-top: 1px;
    }



    .pfr_drawermenuitemred,
    .pfr_drawermenuitemred:hover,
    .pfr_drawermenuitemred:focus {
        padding-left: 41px;
        padding-right: 30px;
        padding-bottom: 36px;
        padding-top: 30px;
        font-size: 14px;
        font-weight: 500;
        color: #242424;
        height: 50px;
    }

    .pfr_drawermenuline {
        margin: 10px 0px;
    }

    .pfr_drawermenulogoutdiv {
        margin: 30px 30px 0 30px;
    }

    .pfr_drawermenulogout,
    .pfr_drawermenulogout:hover,
    .pfr_drawermenulogout:active,
    .pfr_drawermenulogout:focus {
        font-size: 18px;
        font-weight: 500;
        height: 50px;
    }

    .pfr_drawermenufooter {
        width: 328px;
    }

    .pfr_drawermenufooterblue {
        padding: 20px 20px;
    }

    .pfr_drawermenufooterblack {
        padding: 20px 20px;
    }

    .pfr_drawermenufooter span {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    .pfr_drawermenufooter p {
        font-size: 15.5px;
        line-height: 1.45;
        margin: 0;
    }

    .pfr_drawermenuHead {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 29px;
    }

    .clt-divLogin {
        font-size: 18px;
        font-weight: 500;
        color: #242424;
        text-transform: capitalize;
    }

    .pfr_drawermenuHead2 {
        font-size: 19px;
        font-weight: 600;
        color: #242424;
        text-transform: capitalize;
        margin-left: 29px;
    }

    .pfr_innerNavbarRightShare img {
        border-radius: 8px;
        padding: 10px;
        width: 39px;
        height: 40px;
    }

    .fa-light fa-chevron-left {
        margin-bottom: 5px;
    }

    .dropdown-menu.show.dropdown-menu-end {
        margin-top: 13px;
    }
}

.clt_left_menu_section_user_div {
    width: 100%;
}

.clt_left_menu_section_user_divLogin {
    border: 1px solid #dedddd;
    border-radius: 14px;
    padding: 0.7rem;
    /* box-shadow: 0px 0px 5px #ddd; */
    box-shadow: 0 2px 16px rgba(0, 0, 0, .12) !important;
}

.clt_left_menu_section_user_divLogin_img {
    background-image: url(../img/drawer_m6.png);
    /* flex: 0 0 auto !important;
    width: 50% !important; */
}

.clt_left_menu_section_user_Box {
    flex: 0 0 auto !important;
    width: 10% !important;
}

.clt_left_menu_section_user_content {
    display: flex !important;
    flex-direction: column !important;
}

.clt_left_menu_section_user_divLogin.activeNav {
    background-color: #eee;
    background-size: 100%;
    font-weight: 600;
}

.clt_left_menu_section_user_divLogin:hover {
    background-color: #f6f6f6;
    background-size: 100%;
}

.clt_left_menu_section_user_divLogin:active {
    background-color: #eee;
    background-size: 100%;
}

.clt_left_menu_section_user_span {
    padding-left: 0px !important;
    white-space: normal !important;
    text-transform: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clt_left_menu_section_user_divText {
    display: flex;
    flex-direction: column !important;
    margin: 0.4rem;
}