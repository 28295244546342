.clt_admin_sidebar_navbarNav {
    background-color: #fff;
    border-bottom: 0px solid #dedddd;
    padding-bottom: 7.5px;
    padding-top: 5px;
    width: 100%;
}
.clt-section-row-col-pd2 {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.clt_admin_sidebar_innerNav_container {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    width: 100%;
    padding-left: 18px!important;
    padding-right: 18px!important;
}

.clt_admin_sidebar_user {
    background-color: #287AFF;
    border: 3px solid #dedddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
}

.clt_admin_sidebar_user:hover {
    background: #2172c9;
    border: 3px solid #cccccc;
}

.clt_admin_sidebar_user:active {
    border: 3px solid #9a9a9a;
    background-color: #287aff;
}

.clt_admin_sidebar_user_letter {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding-top: 1px;
}

.clt_admin_sidebar_navbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.clt_admin_navbarLogo {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 13px;
    margin-bottom: 1px;
}



.clt_admin_navbarToggle, .clt_admin_navbarToggle:focus {
    border: none;
    box-shadow: none;
    border-radius: 100px;
    padding: 0.7rem 0.65rem 0.75rem 0.6rem!important;
    cursor: pointer;
    background: #fff;
}

.clt_admin_navbarToggle:active {
    background: #d9dce0;
}


.clt_admin_navbarToggle:hover {
    background: #eee;
}

.clt_admin_navbarToggle img {
    width: 15px;
}

@media (min-width:0px) and (max-width:767px) {}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_admin_sidebar_navbarNav {
        border-bottom: 0px solid #dedddd;
        padding-bottom: 0px;
        padding-top: 11px;
    }

    .clt_admin_sidebar_innerNav_container {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }


    .clt_admin_sidebar_navbar {
        /* border-bottom: 1px solid #dedddd; */
        width: 100%;
    }

    .clt_admin_sidebar_user {
        background-color: #287AFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 40px;
    }

    .clt_admin_sidebar_user_letter {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 0.65px;
        padding-right: 0.5px;
        padding-top: 0;
    }

    .clt_admin_sidebar_navbar p {
        padding: 0px;
        margin: 0px;
        text-align: center;
    }

    .clt_admin_sidebar_navbar p:first-child {
        font-size: 0px;
        font-weight: 500;
        letter-spacing: 0.6px;
        color: #959697;
        text-transform: uppercase;
    }

    .clt_admin_sidebar_navbar p:last-child {
        font-size: 0px;
        text-transform: capitalize;
        font-weight: 600;
        margin-top: -4px;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_admin_sidebar_navbarNav {
        border-bottom: 0px solid #dedddd;
        padding-bottom: 0px;
        padding-top: 11px;
    }

    .clt_admin_sidebar_innerNav_container {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }


    .clt_admin_sidebar_navbar {
        width: 100%;
    }

    .clt_admin_sidebar_user {
        background-color: #287AFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 40px;
    }

    .clt_admin_sidebar_user_letter {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 0.65px;
        padding-right: 0.5px;
        padding-top: 0;
    }

    .clt_admin_sidebar_navbar p {
        padding: 0px;
        margin: 0px;
        text-align: center;
    }

    .clt_admin_sidebar_navbar p:first-child {
        font-size: 0px;
        font-weight: 500;
        letter-spacing: 0.6px;
        color: #959697;
        text-transform: uppercase;
    }

    .clt_admin_sidebar_navbar p:last-child {
        font-size: 0px;
        text-transform: capitalize;
        font-weight: 600;
        margin-top: -4px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_admin_sidebar_navbarNav {
        border-bottom: 0px solid #dedddd;
        padding-bottom: 0px;
        padding-top: 11px;
    }

    .clt_admin_sidebar_innerNav_container {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }


    .clt_admin_sidebar_navbar {
        width: 100%;
    }

    .clt_admin_sidebar_user {
        background-color: #287AFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 40px;
    }

    .clt_admin_sidebar_user_letter {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 0.65px;
        padding-right: 0.5px;
        padding-top: 0;
    }

    .clt_admin_sidebar_navbar p {
        padding: 0px;
        margin: 0px;
        text-align: center;
    }

    .clt_admin_sidebar_navbar p:first-child {
        font-size: 0px;
        font-weight: 500;
        letter-spacing: 0.6px;
        color: #959697;
        text-transform: uppercase;
    }

    .clt_admin_sidebar_navbar p:last-child {
        font-size: 0px;
        text-transform: capitalize;
        font-weight: 600;
        margin-top: -4px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_admin_sidebar_navbarNav {
        padding-bottom: 6px;
        padding-top: 4px;
    }

    .clt_admin_sidebar_user {
        height: 40px;
        width: 40px;
    }

    .clt_admin_sidebar_user_letter {
        font-size: 16px;
        padding-top: 0px;
        padding-bottom: 2px;
    }

    .clt_admin_navbarToggle img {
        width: 16px;
        margin-bottom: 0px;
    }

    .clt_admin_navbarLogo {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -.2px;
        margin-bottom: 2px!important;
        margin-left: 13px;
    }

    .clt_admin_sidebar_innerNav_container {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    
    .clt_admin_navbarToggle, .clt_admin_navbarToggle:focus {
        background: #fff;
        border: none;
        border-radius: 100px;
        box-shadow: none;
        margin-bottom: 1px;
        cursor: pointer;
        padding: 0.7rem 0.65rem 0.7rem 0.6rem!important;
    }

}