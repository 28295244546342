.clt-continue-confirm-pay {
    height: 100%;
}

.clt-continue-confirm-pay h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}
.clt-toast-success-title {
    padding: 0px;
    margin: 0px;
    margin-bottom: 0px !important;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-family: 'Inter';
    color: #242424;
    font-size: 14.5px;
    line-height: 18px;
 }

 .clt-toast-success-title-dark {
    padding: 0px;
    margin: 0px;
    margin-bottom: -2px !important;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-family: 'Inter';
    font-size: 14.5px;
    color: #ffffff;
 }

 .clt-toast-success-subtitle {
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    color: #70757a;
    line-height: 20px;
    opacity: 1;
    letter-spacing: 0.2px;
    font-family: 'Inter';
    font-size: 14.5px;
 }

 .clt-toast-success-subtitle-dark {
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    color: #bdbdbd;
    line-height: 20px;
    opacity: 1;
    letter-spacing: 0.2px;
    font-family: 'Inter';
    font-size: 14.5px;
 }
.clt-continue-confirm-pay p {
    font-size: 16px;
    color: #5b5b5b;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 14px;
    margin-top: 7px;
    letter-spacing: -0.2px;
}

.clt-continue-confirm-pay img {
    height: 100px;
    width: 100px;
    margin-bottom: 8px;
}

.clt-continue-confirm-pay-space_bottom {
    height: 20vh;
}

.clt-continue-confirm-pay-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 19px 24px;
    border-top: 1px solid #e0e0e0;
    background: #fff;
    z-index: 1;
}

.clt-continue-confirm-pay-btn button, .clt-continue-confirm-pay-btn button:active, .clt-continue-confirm-pay-btn button:focus, .clt-continue-confirm-pay-btn button:hover {
    background: #000;
    border: 1px solid #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding-top: 1.5px;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    height: 48px;
    margin-top: 0px;
}




@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-confirm-pay img {
        height: 100px;
        width: 100px;
        margin-bottom: 8px;
    }

    .clt-toast-success-title {
        padding: 0px;
        margin: 0px;
        margin-bottom: -2px !important;
        font-size: 16px;
    }

    .clt-toast-success-title-dark {
        padding: 0px;
        margin: 0px;
        margin-bottom: -2px !important;
        font-size: 16px;
    }
    
    .clt-toast-success-subtitle {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 16px;
    }

    .clt-toast-success-subtitle-dark {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-confirm-pay img {
        height: 100px;
        width: 100px;
        margin-bottom: 8px;
    }

    .clt-toast-success-title {
        padding: 0px;
        margin: 0px;
        margin-bottom: -2px !important;
        font-size: 16px;
    }
    
    .clt-toast-success-title-dark {
        padding: 0px;
        margin: 0px;
        margin-bottom: -2px !important;
        font-size: 16px;
    }

    .clt-toast-success-subtitle {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 16px;
    }

    .clt-toast-success-subtitle-dark {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt-confirm-pay img {
        height: 100px;
        width: 100px;
        margin-bottom: 8px;
    }

    .clt-toast-success-title {
        padding: 0px;
        margin: 0px;
        margin-bottom: -2px !important;
        font-size: 16px;
    }

    .clt-toast-success-title-dark {
        padding: 0px;
        margin: 0px;
        margin-bottom: -2px !important;
        font-size: 16px;
    }
    
    .clt-toast-success-subtitle {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 16px;
    }

    .clt-toast-success-subtitle-dark {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 16px;
    }
}


@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    
    
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-continue-confirm-pay img {
        height: 100px;
        width: 100px;
        margin-bottom: 8px;
    }

    .clt-toast-success-title {
        padding: 0px;
        margin: 0px;
        margin-bottom: 0px !important;
        font-size: 15px;
    }

    .clt-toast-success-title-dark {
        padding: 0px;
        margin: 0px;
        margin-bottom: -2px !important;
        font-size: 15px;
    }
    
    .clt-toast-success-subtitle {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 15px;
    }

    .clt-toast-success-subtitle-dark {
        padding: 0px;
        margin: 0px;
        opacity: 1;
        font-size: 15px;
    }
}