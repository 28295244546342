.clt_admin_dash_timeslot_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_admin_dash_timeslot_offcanvas_header {
    padding-top: 15px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}


.clt_admin_dash_timeslot_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_admin_dash_timeslot_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.7rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_admin_dash_timeslot_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_admin_dash_timeslot_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_admin_dash_timeslot_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_admin_dash_timeslot_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}


.clt_admin_dash_timeslot_offcanvas_body {
    padding-left: 24px;
    padding-right: 24px;
}


.clt_admin_dash_timeslot_offcanvas_screen1_div {}

.clt_admin_dash_timeslot_offcanvas_screen1_div h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt_admin_dash_timeslot_offcanvas_screen1_div p {
    font-size: 16px;
    color: #5b5b5b;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 14px;
    margin-top: 7px;
    letter-spacing: -0.2px;
}

.clt_admin_dash_timeslot_offcanvas_screen1_div button, .clt_admin_dash_timeslot_offcanvas_screen1_div button:focus, .clt_admin_dash_timeslot_offcanvas_screen1_div button:active, .clt_admin_dash_timeslot_offcanvas_screen1_div button:hover {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-bottom: 10px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    width: 100px;
    height: 48px;
}

.clt_admin_dash_timeslot_RightTxt {
    min-width: 100px;
}

.clt_admin_dash_timeslot_RightTxt_add {
    width: 150px;
}

.clt_admin_dash_timeslot_RightTxt span {
    text-decoration: none;
    font-size: 12px;
    color: #242424;
    margin-bottom: -1px;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: -7px;
    margin-top: -7px;
}

.clt_admin_dash_timeslot_RightTxt_3 span {
    text-decoration: none;
    font-size: 12px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 5px 13px 7px !important;
}

.clt_admin_dash_timeslot_RightTxt_3 i {
    font-size: 12px;
    color: #242424;
    padding-left: 5px;
    padding-top: 2px;
}

.clt_admin_dash_timeslot_box {
    width: 100%;
}

.clt_admin_dash_timeslot_event_detail {
    margin-bottom: 36px;
}

.clt_admin_dash_timeslot_event_detail_heading {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #242424;
}

.clt_admin_dash_timeslot_event_detail_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 19px 20px;
    border-top: 1px solid #dedddd;
    border-left: 1px solid #dedddd;
    border-right: 1px solid #dedddd;
    border-bottom: 1px solid #dedddd;
    border-radius: 12px;
    cursor: pointer;
}

.clt_admin_dash_timeslot_event_detail_container:hover {
    background: #f6f6f6;
}

.clt_admin_dash_timeslot_event_detail_container:active {
    background: #eee;
}

.first_container {

    border-top: 1px solid #dedddd !important;
    border-left: 1px solid #dedddd !important;
    border-right: 1px solid #dedddd !important;
    border-bottom: 1px solid #dedddd !important;
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

}

.center_container {

    border-left: 1px solid #dedddd !important;
    border-right: 1px solid #dedddd !important;
    border-bottom: 1px solid #dedddd !important;
    border-top: 0px solid #dedddd !important;
    border-radius: 0px !important;

}

.last_container {
    border-left: 1px solid #dedddd !important;
    border-right: 1px solid #dedddd !important;
    border-bottom: 1px solid #dedddd !important;
    border-top: 0px solid #dedddd !important;
    border-radius: none;
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}




.clt_admin_dash_timeslot_event_detail_container .text {
    font-weight: 500;
    color: black;
    padding: 0px 0px;
    margin: 0px;
    font-size: 14px;
}

.clt_admin_dash_timeslot_event_detail_container .description {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 6px 3px 0px;
    margin: 0px;
}

.clt_admin_dash_timeslot_event_detail_container .description .detail {
    margin: 0;
    font-size: 12.5px;
    letter-spacing: -0.5px;
    color: #70757a;
    padding-top: 0.4px;
    padding-bottom: 3px;
}

.clt_admin_dash_timeslot_event_detail_container .description .icon {
    width: 13px;
    height: 16.25px;
    display: inline-block;
    background-size: 11px 11px;
    background-repeat: no-repeat;
    margin-right: 3px;
    margin-top: 2px;
    background-image: url(../../../../../assets/img/admin_icon_timeslot_1.png);
}

.clt_admin_dash_timeslot_event_detail_subdetail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    margin-left: 0px;

}

.clt_admin_dash_timeslot_event_detail_subdetail .subtitle {
    font-size: 10.9px;
    font-weight: 500;
    border-radius: 6px;
    padding: 0 5px 2px;
}

.clt_admin_dash_timeslot_event_detail_subdetail .wedding {
    background-color: #e6f5f0;
    color: #248c0f;
}

.clt_admin_dash_timeslot_event_detail_subdetail .professional {
    background-color: #ecf3fe;
    color: #2d65d1;
}

.clt_admin_dash_timeslot_event_detail_subdetail .birthday {
    background-color: #f9e5fa;
    color: #c703ce;
}

.clt_admin_dash_timeslot_event_detail_subdetail .religious {
    background-color: #fae7de;
    color: #d2997e;
}

.clt_admin_dash_timeslot_edit_box {
    margin-bottom: 21px;
}

.clt_admin_dash_timeslot_edit_box_heading {
    font-weight: 600;
    font-size: 20px;
    color: #242424;
    margin-bottom: 14px;
}

.clt_admin_dash_timeslot_edit_box_heading_2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
}

.clt_admin_dash_timeslot_edit_box_subheading {
    font-size: 15.5px;
    line-height: 20px;
    padding: 0;
    margin-top: -8px;
    font-weight: 400    ;
    margin-right: 0px;
    margin-bottom: 12px;
    margin-left: 0px;
}

.aclt_admin_dash_timeslot_edit_box_subheading {
    font-size: 15.5px;
    color: #242424;
    padding: 0;
    margin-top: -8px;
    font-family: inter;
    font-weight: 400    ;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.abaclt_admin_dash_timeslot_edit_box_subheading {
    font-size: 19.5px;
    line-height: 20px;
    padding: 0;
    margin-top: -8px;
    font-family: inter;
    font-weight: 400    ;
    margin-right: 0px;
    margin-top: 8px !important;
    margin-bottom: 0px;
    margin-left: 0px;
}

.abaclt_admin_dash_timeslot_edit_box_subheading174 {
    font-size: 19.5px;
    line-height: 20px;
    padding: 0;
    margin-top: -8px;
    font-family: inter;
    font-weight: 400    ;
    margin-right: 0px;
    margin-top: 8px !important;
    margin-bottom: 0px;
    margin-left: 0px;
}

.abaclt_admin_dash_timeslot_edit_box_subheading17584 {
    font-size: 19.5px;
    line-height: 20px;
    padding: 0;
    margin-top: -8px;
    font-family: inter;
    font-weight: 400    ;
    margin-right: 0px;
    margin-top: 8px !important;
    margin-bottom: 0px;
    margin-left: 0px;
}

.aaclt_admin_dash_timeslot_edit_box_subheading {
    font-size: 18.5px;
    line-height: 20px;
    padding: 0;
    margin-top: -16px !important;
    font-weight: 400;
    font-family: inter;
    margin-bottom: -2px;
    margin-left: 0px;
    margin-right: 30px;
}

.aaclt_admin_dash_timeslot_edit_box_subheading1801 {
    font-size: 17px;
    line-height: 22px;
    padding: 0;
    margin-top: 6px !important;
    font-weight: 400;
    font-family: inter;
    margin-bottom: 9px;
    margin-left: 0px;
    margin-right: 30px;
}

.aaclt_admin_dash_timeslot_edit_box_subheading5421 {
    font-size: 17px;
    line-height: 22px;
    padding: 0;
    margin-top: -17px !important;
    font-weight: 400;
    font-family: inter;
    margin-bottom: 21px;
    margin-left: 0px;
    margin-right: 30px;
}

.aaclt_admin_dash_timeslot_edit_box_subheading1744 {
    font-size: 18.5px;
    line-height: 20px;
    padding: 0;
    margin-top: 8px !important;
    font-weight: 400;
    font-family: inter;
    margin-bottom: -2px;
    margin-left: 0px;
    margin-right: 30px;
}

.clt_admin_dash_timeslot_edit_box_subheading2 {
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    margin-top: -10px;
    font-weight: 400    ;
    margin-right: 0px;
    margin-bottom: 12px;
    margin-left: 0px;
}

/* input float */
.clt_admin_dash_timeslot_inputFloat {
    width: 100%;
}

.clt_admin_dash_timeslot_inputFloat input, .clt_admin_dash_timeslot_policy_box input {
    border-radius: 12px;
    height: 54px !important;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    padding-top: 7px !important;
    padding-right: 17.5px !important;
    padding-bottom: 0px !important;
    padding-left: 17.5px !important;
    border: 1px solid #dedddd;
    outline: none;
    box-shadow: none;
}

.clt_admin_dash_timeslot_inputFloat input:focus, .clt_admin_dash_timeslot_policy_box input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #dedddd;
    font-size: 14px;
}


.clt_admin_dash_timeslot_offcanvas .form-floating>label, .clt_admin_dash_timeslot_policy_box .form-floating>label {
    padding-left: 18px !important;
    padding-top: 13.4px !important;
    padding-left: 18px !important;
    padding-top: 13.4px !important;
    font-size: 14px;
    font-weight: 400;
    color: #242424;
}

.clt_admin_dash_timeslot_edit_box .form-floating>label {
    padding-left: 18px !important;
    padding-top: 13.4px !important;
    padding-left: 18px !important;
    padding-top: 13.4px !important;
    font-size: 14px;
    font-weight: 400;
    color: #242424;
}

.clt_admin_dash_timeslot_formButton, .clt_admin_dash_timeslot_formButton:hover, .clt_admin_dash_timeslot_formButton:focus {
    background: black;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-top: 2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid black;
    height: 48px;
    margin-top: 20px;
    bottom: 0px;
}

.clt_admin_dash_timeslot_formButton:disabled {
    background: #dedddd;
    border: #dedddd;
    color: black;
}

.clt_admin_dash_timeslot_addlineButton, .clt_admin_dash_timeslot_addlineButton:hover, .clt_admin_dash_timeslot_addlineButton:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-top: 2px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #eee;
    height: 48px;
    margin-top: 20px;
    bottom: 0px;
}


.clt_admin_dash_timeslot_addlineButton:disabled {
    background: #dedddd;
    border: #dedddd;
    color: black;
}

.clt_admin_dash_timeslot_inputFloat .form-select, .clt_admin_dash_timeslot_policy_box .form-select {
    border-radius: 12px;
    height: 54px !important;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    padding-top: 7px;
    padding-right: 17.5px;
    padding-bottom: 0px;
    padding-left: 17.5px;
    border: 1px solid #dedddd;
    outline: none;
    box-shadow: none;
}

.clt_admin_dash_timeslot_edit_sub_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.clt_admin_dash_timeslot_hr {
    border-bottom: 1px solid #dedddd;
    margin-top: 1.65rem;
}

.clt_admin_dash_saveButton {
    border-top: 1px solid #dedddd;
    padding: 0px 24px;
    /* padding-top: .5rem; */
}

.clt_admin_dash_footer {
    border-top: 1px solid #dedddd;
    padding-top: 0px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    /* padding-top: .5rem; */
}

.clt_admin_dash_timeslot_policy_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
}

.clt_admin_dash_timeslot_policy_box input {
    border-right: none;
}

.clt_admin_dash_timeslot_policy_box input:focus {
    border-right: none;
}

.clt_admin_dash_timeslot_policy_box .input-group-text {
    background-color: white !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}


.clt_admin_dash_timeslot_policy_box .form-select {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-top: 0px !important;
    padding-right: 17.5px !important;
    padding-bottom: 3px !important;
}

.clt_admin_dash_timeslot_policy_box input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-top: 0px !important;
    padding-right: 14.5px !important;
    padding-bottom: 0px !important;
    padding-left: 14.5px !important;
    /* border-left: 0px; */
}

.clt_admin_dash_timeslot_policy_box_center .input-group-text {
    background-color: white !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-bottom: 0px; */
    border-top: 0px;
}

.clt_admin_dash_timeslot_policy_box_center .form-select, .clt_admin_dash_timeslot_policy_box_center .form-select:focus {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-bottom: 0px; */
    border-top: 0px;
}

.clt_admin_dash_timeslot_policy_box_center input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border-bottom: 0px; */
    border-top: 0px;
    /* border-left: 0px; */
}

.clt_admin_dash_timeslot_policy_box_center input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #dedddd;
    border-bottom: 0px;
    border-top: 0px;
    border-right: 0px;
}

.clt_admin_dash_timeslot_policy_box_first .input-group-text {
    background-color: white !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 0px;
}

.clt_admin_dash_timeslot_policy_box_first .form-select, .clt_admin_dash_timeslot_policy_box_first .form-select:focus {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

}


.clt_admin_dash_timeslot_policy_box_first input, .clt_admin_dash_timeslot_policy_box_first input:focus {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border-left: 0px; */
}


.clt_admin_dash_timeslot_policy_box_last .input-group-text {
    background-color: white !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 12px;
    border-top: 0px;
}

.clt_admin_dash_timeslot_policy_box_last .form-select, .clt_admin_dash_timeslot_policy_box_last .form-select:focus {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 12px;
    border-top: 0px;
}

.clt_admin_dash_timeslot_policy_box_last input, .clt_admin_dash_timeslot_policy_box_last input:focus {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: 0px;
    /* border-left: 0px; */
}

.clt_admin_dash_timeslot_edit_box input[type="time"]::-webkit-calendar-picker-indicator {
    background: url('../../../../../assets/img/back_arrow.png') no-repeat;
    transform: rotate(-90deg);
    background-position: center center;
    background-size: 6px 11.3px;
    margin-left: 10px;
}

.clt_admin_dash_timeslot_cancel {
    padding-left: 10px;
    background-color: transparent;
}

.clt_admin_dash_timeslot_cancel img {
    width: 10px;
    height: 9px;
    margin-left: 10px;
    cursor: pointer;
}

.clt-checkbox-option-title {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
}
.clt-checkbox-option-subtitle {
    font-size: 13px;
    font-weight: 400;
    color: #242424;
}

.FloatingInputCancellationReason {
    padding-left: 10px;
}


@media only screen and (max-width: 600px) {

    /*mobile*/

    .clt_admin_dash_timeslot_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_admin_dash_timeslot_offcanvas {
        width: 100%;
    }

    .clt_admin_dash_timeslot_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }


    .clt_admin_dash_timeslot_offcanvas_screen1_div button, .clt_admin_dash_timeslot_offcanvas_screen1_div button:focus, .clt_admin_dash_timeslot_offcanvas_screen1_div button:active, .clt_admin_dash_timeslot_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }


    .clt_admin_dash_timeslot_header_button, .clt_admin_dash_timeslot_header_button:focus, .clt_admin_dash_timeslot_header_button:active, .clt_admin_dash_timeslot_header_button:hover {
        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_admin_dash_timeslot_RightTxt {
        width: 150px;
    }


    .clt_admin_dash_timeslot_inputFloat .form-select, .clt_admin_dash_timeslot_policy_box .form-select {
        height: 64px !important;
        font-size: 16px;
        padding-top: 10px;
        padding-right: 17.5px;
        padding-bottom: 0px;
        padding-left: 17.5px;
    }

    .clt_admin_dash_timeslot_inputFloat input, .clt_admin_dash_timeslot_policy_box input {
        height: 64px !important;
        font-size: 16px;
        padding: 8px 17.5px 0 !important;
    }

    .clt_admin_dash_timeslot_offcanvas .form-floating>label, .clt_admin_dash_timeslot_policy_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_edit_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        line-height: 22px;
        margin: -6px 0 10px;
        padding: 0;
    }

    .aclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 17px;
        margin: -6px 0 0px;
        margin-top: -3px !important;
        margin-bottom: 24px !important;
        padding: 0;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 19.5px;
        line-height: 26px;
        margin: 10px 0 0px;
        margin-top: 8px !important;
        padding: 0;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading174 {
        font-size: 17px;
        line-height: 26px;
        margin-top: -3px !important;
        padding: 0;
        margin-right: 0px;
        margin-bottom: 18px;
        margin-left: 0px;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading17584 {
        font-size: 17px;
    line-height: 26px;
    margin-top: 3px !important;
    padding: 0;
    margin-right: 0px;
    margin-bottom: -3px;
    margin-left: 0px;
    }


    

    .aaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        margin-top: -6px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;

    }

    .aaclt_admin_dash_timeslot_edit_box_subheading1801 {
        font-size: 16.5px;
        line-height: 22px;
        margin-top: 6px !important;
        margin-right: 0px !important;
        margin-bottom: 4px !important;
        margin-left: 0px !important;
        padding: 0;
    }
    .aaclt_admin_dash_timeslot_edit_box_subheading1744 {
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        margin-top: -6px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;

    }

    .clt_admin_dash_timeslot_edit_box_subheading2 {
        font-size: 16px;
        line-height: 22px;
        margin: -6px 0 10px;
        padding: 0;
    }

    .clt_admin_dash_timeslot_edit_box_heading {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_edit_box_heading_2 {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_RightTxt_3 span {
        font-size: 13.5px;
        font-weight: 600;
        margin-top: -5px;
        margin-bottom: -5px;
        padding: 5px 13px 7px !important;
    }

    .clt_admin_dash_timeslot_RightTxt_3 i {
        font-size: 13.5px;
        padding-left: 5px;
        margin-top: -5px;
        margin-bottom: -5px;
        padding-top: 2px;
    }

    .clt_admin_dash_timeslot_event_detail_container {
        padding: 16px 20px 19px;
        border: 1px solid #dedddd;
        border-radius: 12px;
    }

    .clt_admin_dash_timeslot_event_detail_container .text {
        margin-bottom: -1px;
        font-size: 16px;
    }

    .clt_admin_dash_timeslot_event_detail_container .description .detail {
        font-size: 14.5px;
        letter-spacing: -.5px;
        padding-top: 0.4px;
        margin: 0 0 -1.5px;
        padding-bottom: 5px;
    }

    .clt_admin_dash_timeslot_event_detail_subdetail .subtitle {
        font-size: 12.9px;
        font-weight: 500;
        margin-top: 1px;
        border-radius: 6px;
        padding: 0 6px 2px;
    }

    .clt_admin_dash_timeslot_formButton, .clt_admin_dash_timeslot_formButton:hover, .clt_admin_dash_timeslot_formButton:focus {
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding-top: 2px;
        font-weight: 600;
        height: 54px;
        margin-top: 20px;
    }

    .clt_admin_dash_timeslot_addlineButton, .clt_admin_dash_timeslot_addlineButton:hover, .clt_admin_dash_timeslot_addlineButton:focus {
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding-top: 2px;
        font-weight: 600;
        height: 48px;
        margin-top: 20px;
    }

    .clt_admin_dash_timeslot_RightTxt span {
        font-size: 14px;
        margin-bottom: -5px;
        margin-top: -5px;
    }

    .clt_admin_dash_timeslot_event_detail_container .description .icon {
        width: 12px;
        height: 13.7px;
        background-size: 9.5px 9.5px;
        margin-right: 3px;
        margin-top: 2px;
    }

    .clt_admin_dash_timeslot_event_detail_heading {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px
    }

    .clt_admin_dash_timeslot_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_admin_dash_timeslot_offcanvas_header .btn-back img {
        width: 8px;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_admin_dash_timeslot_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_admin_dash_timeslot_offcanvas {
        width: 100%;
    }

    .clt_admin_dash_timeslot_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }


    .clt_admin_dash_timeslot_offcanvas_screen1_div button, .clt_admin_dash_timeslot_offcanvas_screen1_div button:focus, .clt_admin_dash_timeslot_offcanvas_screen1_div button:active, .clt_admin_dash_timeslot_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }


    .clt_admin_dash_timeslot_header_button, .clt_admin_dash_timeslot_header_button:focus, .clt_admin_dash_timeslot_header_button:active, .clt_admin_dash_timeslot_header_button:hover {
        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_admin_dash_timeslot_RightTxt {
        width: 150px;
    }

    .clt_admin_dash_timeslot_inputFloat .form-select, .clt_admin_dash_timeslot_policy_box .form-select {
        height: 64px !important;
        font-size: 16px;
        padding-top: 10px;
        padding-right: 17.5px;
        padding-bottom: 0px;
        padding-left: 17.5px;
    }

    .clt_admin_dash_timeslot_inputFloat input, .clt_admin_dash_timeslot_policy_box input {
        height: 64px !important;
        font-size: 16px;
        padding: 8px 17.5px 0 !important;
    }

    .clt_admin_dash_timeslot_offcanvas .form-floating>label, .clt_admin_dash_timeslot_policy_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_edit_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        line-height: 22px;
        margin: -6px 0 10px;
        padding: 0;
    }

    .aclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 17px;
        margin: -6px 0 0px;
        padding: 0;
        margin-top: -3px !important;
        margin-bottom: 24px !important;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 19.5px;
        line-height: 26px;
        margin: 10px 0 0px;
        margin-top: 8px !important;
        padding: 0;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading174 {
        font-size: 17px;
        line-height: 26px;
        margin-top: -3px !important;
        padding: 0;
        margin-right: 0px;
        margin-bottom: 18px;
        margin-left: 0px;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading17584 {
        font-size: 17px;
    line-height: 26px;
    margin-top: 3px !important;
    padding: 0;
    margin-right: 0px;
    margin-bottom: -3px;
    margin-left: 0px;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        line-height: 22px;
        margin-top: -6px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;
        padding: 0;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading1801 {
        font-size: 16.5px;
        line-height: 22px;
        margin-top: 6px !important;
        margin-right: 0px !important;
        margin-bottom: 4px !important;
        margin-left: 0px !important;
        padding: 0;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading1744 {
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        margin-top: -6px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;

    }

    .clt_admin_dash_timeslot_edit_box_subheading2 {
        font-size: 16px;
        line-height: 22px;
        margin: -6px 0 10px;
        padding: 0;
    }

    .clt_admin_dash_timeslot_edit_box_heading {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_edit_box_heading_2 {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_RightTxt_3 span {
        font-size: 13.5px;
        font-weight: 600;
        margin-top: -5px;
        margin-bottom: -5px;
        padding: 5px 13px 7px !important;
    }

    .clt_admin_dash_timeslot_RightTxt_3 i {
        font-size: 13.5px;
        padding-left: 5px;
        margin-top: -5px;
        margin-bottom: -5px;
        padding-top: 2px;
    }

    .clt_admin_dash_timeslot_event_detail_container {
        padding: 16px 20px 19px;
        border: 1px solid #dedddd;
        border-radius: 12px;
    }

    .clt_admin_dash_timeslot_event_detail_container .text {
        margin-bottom: -1px;
        font-size: 16px;
    }

    .clt_admin_dash_timeslot_event_detail_container .description .detail {
        font-size: 14.5px;
        letter-spacing: -.5px;
        padding-top: 0.4px;
        margin: 0 0 -1.5px;
        padding-bottom: 5px;
    }

    .clt_admin_dash_timeslot_event_detail_subdetail .subtitle {
        font-size: 12.9px;
        font-weight: 500;
        margin-top: 1px;
        border-radius: 6px;
        padding: 0 6px 2px;
    }

    .clt_admin_dash_timeslot_formButton, .clt_admin_dash_timeslot_formButton:hover, .clt_admin_dash_timeslot_formButton:focus {
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding-top: 2px;
        font-weight: 600;
        height: 54px;
        margin-top: 20px;
    }

    .clt_admin_dash_timeslot_addlineButton, .clt_admin_dash_timeslot_addlineButton:hover, .clt_admin_dash_timeslot_addlineButton:focus {
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding-top: 2px;
        font-weight: 600;
        height: 48px;
        margin-top: 20px;
    }

    .clt_admin_dash_timeslot_RightTxt span {
        font-size: 14px;
        margin-bottom: -5px;
        margin-top: -5px;
    }

    .clt_admin_dash_timeslot_event_detail_container .description .icon {
        width: 12px;
        height: 13.7px;
        background-size: 9.5px 9.5px;
        margin-right: 3px;
        margin-top: 2px;
    }

    .clt_admin_dash_timeslot_event_detail_heading {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px
    }

    .clt_admin_dash_timeslot_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_admin_dash_timeslot_offcanvas_header .btn-back img {
        width: 8px;
    }

}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_admin_dash_timeslot_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
    }

    .clt_admin_dash_timeslot_offcanvas {
        width: 100%;
    }

    .clt_admin_dash_timeslot_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }


    .clt_admin_dash_timeslot_offcanvas_screen1_div button, .clt_admin_dash_timeslot_offcanvas_screen1_div button:focus, .clt_admin_dash_timeslot_offcanvas_screen1_div button:active, .clt_admin_dash_timeslot_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }


    .clt_admin_dash_timeslot_header_button, .clt_admin_dash_timeslot_header_button:focus, .clt_admin_dash_timeslot_header_button:active, .clt_admin_dash_timeslot_header_button:hover {
        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_admin_dash_timeslot_RightTxt {
        width: 150px;
    }

    .clt_admin_dash_timeslot_inputFloat .form-select, .clt_admin_dash_timeslot_policy_box .form-select {
        height: 64px !important;
        font-size: 16px;
        padding-top: 10px;
        padding-right: 17.5px;
        padding-bottom: 0px;
        padding-left: 17.5px;
    }

    .clt_admin_dash_timeslot_inputFloat input, .clt_admin_dash_timeslot_policy_box input {
        height: 64px !important;
        font-size: 16px;
        padding: 8px 17.5px 0 !important;
    }
    .clt_admin_dash_timeslot_offcanvas .form-floating>label, .clt_admin_dash_timeslot_policy_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_edit_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 16px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        line-height: 22px;
        margin: -6px 0 10px;
        padding: 0;
    }

    .aclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 17px;
        margin: -6px 0 0px;
        padding: 0;
        margin-top: -3px !important;
        margin-bottom: 24px !important;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 19.5px;
        line-height: 26px;
        margin: 10px 0 0px;
        margin-top: 8px !important;
        padding: 0;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading174 {
        font-size: 17px;
        line-height: 26px;
        margin-top: -3px !important;
        padding: 0;
        margin-right: 0px;
        margin-bottom: 18px;
        margin-left: 0px;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading17584 {
        font-size: 17px;
    line-height: 26px;
    margin-top: 3px !important;
    padding: 0;
    margin-right: 0px;
    margin-bottom: -3px;
    margin-left: 0px;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        line-height: 22px;
        margin-top: -6px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;
        padding: 0;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading1801 {
        font-size: 16.5px;
        line-height: 22px;
        margin-top: 6px !important;
        margin-right: 0px !important;
        margin-bottom: 4px !important;
        margin-left: 0px !important;
        padding: 0;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading1744 {
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        margin-top: -6px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;

    }

    .clt_admin_dash_timeslot_edit_box_subheading2 {
        font-size: 16px;
        line-height: 22px;
        margin: -6px 0 10px;
        padding: 0;
    }

    .clt_admin_dash_timeslot_edit_box_heading {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_edit_box_heading_2 {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_RightTxt_3 span {
        font-size: 13.5px;
        font-weight: 600;
        margin-top: -5px;
        margin-bottom: -5px;
        padding: 5px 13px 7px !important;
    }

    .clt_admin_dash_timeslot_RightTxt_3 i {
        font-size: 13.5px;
        padding-left: 5px;
        margin-top: -5px;
        margin-bottom: -5px;
        padding-top: 2px;
    }

    .clt_admin_dash_timeslot_event_detail_container {
        padding: 16px 20px 19px;
        border: 1px solid #dedddd;
        border-radius: 12px;
    }

    .clt_admin_dash_timeslot_event_detail_container .text {
        margin-bottom: -1px;
        font-size: 16px;
    }

    .clt_admin_dash_timeslot_event_detail_container .description .detail {
        font-size: 14.5px;
        letter-spacing: -.5px;
        padding-top: 0.4px;
        margin: 0 0 -1.5px;
        padding-bottom: 5px;
    }

    .clt_admin_dash_timeslot_event_detail_subdetail .subtitle {
        font-size: 12.9px;
        font-weight: 500;
        margin-top: 1px;
        border-radius: 6px;
        padding: 0 6px 2px;
    }

    .clt_admin_dash_timeslot_formButton, .clt_admin_dash_timeslot_formButton:hover, .clt_admin_dash_timeslot_formButton:focus {
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding-top: 2px;
        font-weight: 600;
        height: 54px;
        margin-top: 20px;
    }

    .clt_admin_dash_timeslot_addlineButton, .clt_admin_dash_timeslot_addlineButton:hover, .clt_admin_dash_timeslot_addlineButton:focus {
        width: 100%;
        border-radius: 12px;
        font-size: 16px;
        letter-spacing: 0.1px;
        padding-top: 2px;
        font-weight: 600;
        height: 48px;
        margin-top: 20px;
    }

    .clt_admin_dash_timeslot_RightTxt span {
        font-size: 14px;
        margin-bottom: -5px;
        margin-top: -5px;
    }

    .clt_admin_dash_timeslot_event_detail_container .description .icon {
        width: 12px;
        height: 13.7px;
        background-size: 9.5px 9.5px;
        margin-right: 3px;
        margin-top: 2px;
    }

    .clt_admin_dash_timeslot_event_detail_heading {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px
    }

    .clt_admin_dash_timeslot_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem !important;
    }

    .clt_admin_dash_timeslot_offcanvas_header .btn-back img {
        width: 8px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_admin_dash_timeslot_offcanvas {
        width: 425px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_admin_dash_timeslot_offcanvas {
        width: 400px;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 30px;
        margin-top: -19px!important;
        padding: 0;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading1801 {
        font-size: 17px;
        font-weight: 400;
        line-height: 23px;
        margin-bottom: 1px;
        margin-left: 0;
        margin-right: 30px;
        margin-top: 7px!important;
        padding: 0;
    }
    .aaclt_admin_dash_timeslot_edit_box_subheading1744 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 30px;
        margin-top: 8px!important;
        padding: 0;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_admin_dash_timeslot_offcanvas {
        width: 425px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_admin_dash_timeslot_offcanvas {
        width: 400px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_admin_dash_timeslot_offcanvas {
        width: 425px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_admin_dash_timeslot_offcanvas {
        width: 400px;
    }
}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_admin_dash_timeslot_offcanvas {
        width: 400px;
    }

    .clt_admin_dash_timeslot_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_admin_dash_timeslot_edit_box_heading {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_inputFloat .form-select, .clt_admin_dash_timeslot_policy_box .form-select {
        height: 56px !important;
        font-size: 15px;
        padding: 8px 17.5px 0;
    }

    .clt_admin_dash_timeslot_inputFloat input, .clt_admin_dash_timeslot_policy_box input {
        height: 56px !important;
        font-size: 15px;
        padding: 8px 17.5px 0;
    }

    .clt_admin_dash_timeslot_edit_box_heading_2 {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        line-height: 22px;
        margin: -3px 0 14px;
    }

    .aclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 16px;
        margin-top: -4px !important;
        margin-bottom: 23px !important;
        margin: -12px 0 0px;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 19.5px;
        line-height: 22px;
        margin: 10px 0 0px;
        margin-top: 8px !important;
        padding: 0;
    }

    .abaclt_admin_dash_timeslot_edit_box_subheading174 {
        font-size: 19.5px;
        line-height: 22px;
        margin: 10px 0 0px;
        margin-top: 8px !important;
        padding: 0;
    }


    .abaclt_admin_dash_timeslot_edit_box_subheading17584 {
        font-size: 19.5px;
        line-height: 22px;
        margin: 10px 0 0px;
        margin-top: 8px !important;
        padding: 0;
    }

    .aaclt_admin_dash_timeslot_edit_box_subheading {
        font-size: 19px;
        line-height: 22px;
        margin: -17px 0 -4px !important;
    }


    .aaclt_admin_dash_timeslot_edit_box_subheading1801 {
        font-size: 16px;
        line-height: 22px;
        margin: 3px 0 -4px !important;
    }
    .aaclt_admin_dash_timeslot_edit_box_subheading1744 {
        font-size: 19px;
        line-height: 22px;
        margin-bottom: -2px;
        margin-left: 0px;
        margin-right: 30px;
        margin-top: 12px !important;
    }

    .clt_admin_dash_timeslot_edit_box_subheading2 {
        font-size: 16px;
        line-height: 22px;
    }

    .clt_admin_dash_timeslot_offcanvas .form-floating>label, .clt_admin_dash_timeslot_policy_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 13.4px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_edit_box .form-floating>label {
        padding-left: 18px !important;
        padding-top: 13.4px !important;
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_addlineButton, .clt_admin_dash_timeslot_addlineButton:focus, .clt_admin_dash_timeslot_addlineButton:hover {
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_formButton, .clt_admin_dash_timeslot_formButton:focus, .clt_admin_dash_timeslot_formButton:hover {
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_RightTxt_3 span, .clt_admin_dash_timeslot_RightTxt span {
        font-size: 14px;
        margin-left: 5px !important;
        margin-bottom: -10px;
        margin-top: -10px;
    }

    .clt_admin_dash_timeslot_event_detail_heading {
        font-size: 20px;
    }

    .clt_admin_dash_timeslot_event_detail_container .text {
        font-size: 15px;
    }

    .clt_admin_dash_timeslot_event_detail_container .description .detail {
        font-size: 14px;
        padding-top: 0.4px;
        padding-bottom: 6px;
    }

    .clt_admin_dash_timeslot_event_detail_subdetail .subtitle {
        font-size: 12px;
        padding: 0 5px 2px;
    }

    .clt_admin_dash_timeslot_offcanvas_header {
        padding-bottom: 13px;
        padding-top: 12px;
    }


}