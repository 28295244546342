/* contact section  */

.notranslate {
    transform: none !important;
}

.contactSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.contactSection .contactBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 75px;
    background-color: #eee;
    padding: 9px 11px 11px;
    border-radius: 12px;
    cursor: pointer;
    text-decoration: none;
}

.contactSection .contactBox:hover {
    background-color: #d9dce0 !important;
    color: #242424 !important;
}

.contactSection .contactBox:active {
    background-color: #d2d2d3 !important;
    color: #242424 !important;
}

.contactSection .icon {
    height: 17px;
}

.contactSection .icon2 {
    height: 14px;
}


.contactSection .contactBox p {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt_message_my_booking_title_header {
    font-size: 13.5px;
    font-weight: 600;
    padding-bottom: 2px;
}

.clt-booking-cp-div4-text h2,
.clt-booking-cp-div4-text p {
    font-size: 14px;
}

.clt-booking-cp-div4-title {
    font-size: 18px;
}

.clt-booking-cp-div5-title {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    letter-spacing: -0.2px;
}

.clt-booking-price-detail-right-column {
    margin-left: 52px;
}


/* sections deposit refund  */

.deposit_refund .h3 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}



.deposit_refund p {
    font-size: 14px;
    color: #70757a;
    font-family: "Inter";
    font-weight: 300;
    margin-top: 1px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    letter-spacing: -.2px;
    line-height: 1.44;
}

.clt_deposit_dash_timeslot_inputFloat {
    width: 100%;
}

.clt_deposit_dash_timeslot_inputFloat input,
.clt_deposit_timeslot_policy_box input {
    border-radius: 12px;
    height: 54px !important;
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    padding-top: 7px !important;
    padding-right: 17.5px !important;
    padding-bottom: 12px !important;
    padding-left: 17.5px !important;
    border: 1px solid #dedddd;
    border-right: none;
    outline: none;
    box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
.clt_deposit_dash_timeslot_inputFloat input::-webkit-outer-spin-button,
.clt_deposit_dash_timeslot_inputFloat input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.clt_deposit_dash_timeslot_inputFloat input[type=number] {
    -moz-appearance: textfield;
}


.clt_deposit_dash_timeslot_inputFloat input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #dedddd;
}

.clt_deposit_dash_timeslot_inputFloat .input-group-text {
    background-color: white !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-top: 7px;
    padding-right: 17.5px;
    padding-bottom: 10.6px;
    padding-left: 17.5px;
    font-size: 14px;
    font-weight: 500;
}

/* slot refund  */

.slot_refund .form-check-input:checked {
    background-color: black !important;
    border-color: black !important;
}

.slot_refund .h3 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    margin-left: 10px;

}

.slot_refund p {
    margin: 0px;
    margin-left: 10px;
    margin-top: 1px;
    font-size: 14px;
    color: #70757a;
    font-family: "Inter";
    font-weight: 300;
    letter-spacing: -.2px;
    line-height: 1.44;
}



@media only screen and (max-width: 600px) {

    /*mobile*/

    .contactSection .contactBox {
        width: 100%;
        height: 86px;
        background-color: #eee;
        padding: 12px 12px 12px 13px;
        border-radius: 12px;
    }

    .contactSection .contactBox p {
        font-size: 16px;
        margin-bottom: 0px;
    }


    .contactSection .icon {
        height: 20px;
    }

    .contactSection .icon2 {
        height: 16px;
    }

    .deposit_refund .h3 {
        font-size: 16px;
    }

    .deposit_refund p {
        font-size: 16px;
    }

    .slot_refund .h3 {
        font-size: 16px;

    }

    .slot_refund p {
        font-size: 16px;
    }

    .clt-booking-cp-div4-title,
    .clt-booking-cp-div5-title {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }


}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .contactSection .contactBox {
        width: 100%;
        height: 86px;
        background-color: #eee;
        padding: 12px 12px 12px 13px;
        border-radius: 12px;
    }

    .contactSection .contactBox p {
        font-size: 16px;
        margin-bottom: 0px;
    }


    .contactSection .icon {
        height: 20px;
    }

    .contactSection .icon2 {
        height: 16px;
    }

    .deposit_refund .h3 {
        font-size: 16px;
    }

    .deposit_refund p {
        font-size: 16px;
    }

    .slot_refund .h3 {
        font-size: 16px;

    }

    .slot_refund p {
        font-size: 16px;
    }

    .clt-booking-cp-div4-title,
    .clt-booking-cp-div5-title {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }


}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .contactSection .contactBox {
        width: 100%;
        height: 86px;
        background-color: #eee;
        padding: 12px 12px 12px 13px;
        border-radius: 12px;
    }

    .contactSection .contactBox p {
        font-size: 16px;
        margin-bottom: 0px;
    }


    .contactSection .icon {
        height: 20px;
    }

    .contactSection .icon2 {
        height: 16px;
    }

    .deposit_refund .h3 {
        font-size: 16px;
    }

    .deposit_refund p {
        font-size: 16px;
    }

    .slot_refund .h3 {
        font-size: 16px;

    }

    .slot_refund p {
        font-size: 16px;
    }


    .clt-booking-cp-div4-title,
    .clt-booking-cp-div5-title {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}



@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 15px;
    }

    .contactSection .contactBox p {
        font-size: 15px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 15px;
    }


    .clt-booking-cp-div4-title {
        font-size: 20px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 15px;
    }

    .clt-booking-cp-div5-title {
        font-size: 20px;
        font-weight: 600;
    }

    .deposit_refund .h3 {
        font-size: 15px;
    }

    .deposit_refund p {
        font-size: 15px;
    }

    .slot_refund .h3 {
        font-size: 15px;

    }

    .slot_refund p {
        font-size: 15px;
    }

    .clt_deposit_dash_timeslot_inputFloat input,
    .clt_deposit_timeslot_policy_box input {
        font-size: 15px;
        padding-top: 7px;
        padding-right: 17.5px;
        padding-bottom: 12px !important;
        padding-left: 17.5px;
    }

    .clt_deposit_dash_timeslot_inputFloat .input-group-text {
        padding-top: 7px;
        padding-right: 17.5px;
        padding-left: 17.5px;
        font-size: 15x;
        padding-bottom: 12px !important;
    }

    .clt_message_my_booking_title_header {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 2px;
    }

}