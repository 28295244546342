.clt_contact_box {
    margin: 0px 25px;
}

.clt_contact_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_contact_offcanvas_header {
    padding-top: 15px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_contact_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_contact_us_screen_custom_hr {
    padding-top: 20px;
    padding-bottom: 20px;
}

.clt_contact_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_contact_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_contact_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_contact_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}

.clt_contact_offcanvas_body {
    padding: 0px;
    overflow-x: hidden;
}

.article-image {
    max-width: 100%; /* Ensures the image does not exceed the width of its container */
    height: auto; /* Allows the image to scale proportionally */
    border-radius: 10px;
    margin-top: 5px;
}

.article-image:hover {
    max-width: 100%; /* Ensures the image does not exceed the width of its container */
    height: auto; /* Allows the image to scale proportionally */
    border-radius: 10px;
    margin-top: 5px;
    opacity: 80%;
}

.article-image:active {
    max-width: 100%; /* Ensures the image does not exceed the width of its container */
    height: auto; /* Allows the image to scale proportionally */
    border-radius: 10px;
    margin-top: 5px;
    opacity: 70%;
}

.nkjnezkjdnze {
    text-align: -webkit-center;
}
  
.jcnzdcjkldc {
    font-weight: 600 !important; 
    color: #222;
    font-family: 'Lora';
}

.clt_contact_offcanvas_body_mainrow {
    margin-bottom: 40px;
    margin: 0 25px;
}

.clt_contact_offcanvas_body .clt_contact_offcanvas_body_mainrow:first-child {
    margin-top: 25px;
}

.clt-button-text2125 {
    margin-top: 13px;
    line-height: 35px;
    margin-bottom: 11px;
    letter-spacing: -0.2px;
    font-size: 25px;
}

.clt_contact_offcanvas_body_maindiv {
    margin-bottom: 20px;
}

.clt_contact_offcanvas_body_secdiv {
    margin-bottom: 30px;
    cursor: pointer;
}

.clt_contact_offcanvas_body_category {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin: 20px 0px;
    letter-spacing: -0.2px;
}

.clt_contact_offcanvas_body_product {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
}

.clt_contact_offcanvas_body_secondary {
    font-size: 16px;
    letter-spacing: -0.2px;
    font-weight: 500;
    color: #242424;
    margin-bottom: 5px;
    font-family: 'Inter';
}

.clt_contact_detail {
    padding: 0px 0px 0px 24px;
}

.clt_contact_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.clt_contact_icon img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
}

.clt_contact_item_detail {
    width: 100%;
    /* height: 70px !important; */
    border-radius: 0;
    border-bottom: 0px solid #dedddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 6px;
    padding-right: 20px;
}

.clt_contact_item_detail_2 {
    width: 100%;
    /* height: 70px !important; */
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 6px;
    padding-right: 20px;
}

.clt_contact_item_detail_data {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.clt_contact_item_title {
    font-size: 15px;
    font-weight: 500;
    color: #242424;
    margin: 0 0 -3px;
    padding: 0;
    /* border-bottom: 1px solid black; */
}

.clt_contact_item_subDetail {
    font-size: 15px;
    font-weight: 400;
    color: #70757a;
    margin: 0;
}

.pfr_copy_Txt span {
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    background: #eee;
    border-radius: 50px;
    color: #242424 !important;
    padding-top: 7px;
    padding-right: 12px;
    padding-bottom: 9px;
    padding-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pfr_copy_Txt span:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
}

.pfr_copy_Txt span:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
}

.clt-button-text2124 {
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    background: #eee;
    border-radius: 50px;
    color: #242424 !important;
    padding-top: 7px;
    padding-right: 12px;
    padding-bottom: 9px;
    padding-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee !important;
}

.clt-button-text2124:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}

.clt-button-text2124:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}

.clt-button-text2124:focus {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}


.clt-button-text21245 {
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    background: #eee;
    border-radius: 50px;
    color: #242424 !important;
    padding-top: 7px;
    padding-right: 12px;
    padding-bottom: 9px;
    padding-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee !important;
}

.clt-button-text21245:hover {
    background: #d9dce0 !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}

.clt-button-text21245:active {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}

.clt-button-text21245:focus {
    background: #d2d2d3 !important;
    color: #242424 !important;
    border: 1px solid #eee !important;
}



.clt_contact_offcanvas_header .btn-back img {
    width: 7px;
}

.toast-icon {
    width: 18px;
    height: 18px;
}

.toast-success {
    border-radius: 12px !important;
    font-size: 14.5px !important;
    top: 2px !important;
    margin-bottom: 8px !important;
    padding-top: 12px !important;
    padding-right: 8px !important;
    padding-bottom: 12px !important;
    padding-left: 8px !important;
    margin-bottom: 10px !important;
}

.toast-success-booking {
    border-radius: 12px !important;
    font-size: 14.5px !important;
    top: 2px !important;
    margin-bottom: 8px !important;
    padding-top: 12px !important;
    padding-right: 8px !important;
    padding-bottom: 12px !important;
    padding-left: 0px !important;
    margin-bottom: 10px !important;
}

.czlcezccac{
    margin-top: 6px !important;
}

.clt-div-histoire-2124 {
    margin-top: 30px;
    border-top: 1px solid #dedddd;
}
@media only screen and (max-width: 600px) {
    /*mobile*/ 
    .clt_contact_offcanvas {
        width: 100%;
    }

    .clt-div-histoire-2124 {
        margin-top: 30px;
        margin-bottom: -30px;
        border-top: 1px solid #dedddd;
    }

    .czlcezccac{
        margin-top: 6px !important;
    }

    .clt_contact_item_title {
        font-size: 15px;
    }

    .clt_contact_item_subDetail {
        font-size: 16px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }


    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }

    .toast-success {
        top: 5px !important;
        padding: 0px 10px;
        height: 54px;
        font-size: 16px!important;
        padding: 0px !important;
        margin-bottom: 8px !important;
        padding-top: 11px !important;
        padding-right: 6px !important;
        padding-bottom: 14px !important;
        padding-left: 10px !important;
    }

    .toast-success-booking {
        top: 5px !important;
        padding: 0px 10px;
        height: 54px;
        font-size: 16px!important;
        padding: 0px !important;
        margin-bottom: 8px !important;
        padding-top: 11px !important;
        padding-right: 6px !important;
        padding-bottom: 14px !important;
        padding-left: 10px !important;
    }

    .clt_contact_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_contact_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt-button-text2124 {
        font-size: 15px;
    }

    .clt-button-text21245 {
        font-size: 15px;
    }



    .clt-button-text2125 {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 16px !important;
        opacity: 70%;
        line-height: 24px;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {
    
    /*mobile*/
    .clt_contact_offcanvas {
        width: 100%;
    }

    .clt-button-text2125 {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 16px !important;
        opacity: 70%;
        line-height: 24px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_contact_item_subDetail {
        font-size: 16px;
    }

    .toast-success {
        top: 5px !important;
        padding: 0px 10px;
        height: 54px;
        font-size: 16px!important;
        padding: 0px !important;
        margin-bottom: 8px !important;
        padding-top: 11px !important;
        padding-right: 6px !important;
        padding-bottom: 14px !important;
        padding-left: 10px !important;
    }

    .toast-success-booking {
        top: 5px !important;
        padding: 0px 10px;
        height: 54px;
        font-size: 16px!important;
        padding: 0px !important;
        margin-bottom: 8px !important;
        padding-top: 11px !important;
        padding-right: 6px !important;
        padding-bottom: 14px !important;
        padding-left: 10px !important;
    }

    .clt_contact_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_contact_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }
    .czlcezccac{
        margin-top: 6px !important;
    }

    .clt-button-text2124 {
        font-size: 15px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }
    .clt-div-histoire-2124 {
        margin-top: 30px;
        margin-bottom: -30px;
        border-top: 1px solid #dedddd;
    }



}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_contact_offcanvas {
        width: 100%;
    }

    .clt-div-histoire-2124 {
        margin-top: 30px;
        margin-bottom: -30px;
        border-top: 1px solid #dedddd;
    }


    .clt-button-text2125 {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 16px !important;
        opacity: 70%;
        line-height: 24px;
    }
    .czlcezccac{
        margin-top: 6px !important;
    }
    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_contact_item_subDetail {
        font-size: 16px;
    }

    .toast-success {
        top: 5px !important;
        padding: 0px 10px;
        height: 54px;
        font-size: 16px!important;
        margin-bottom: 8px !important;
        padding-top: 11px !important;
        padding-right: 6px !important;
        padding-bottom: 14px !important;
        padding-left: 0px !important;
    }

    .toast-success-booking {
        top: 5px !important;
        padding: 0px 10px;
        height: 54px;
        font-size: 16px!important;
        margin-bottom: 8px !important;
        padding-top: 11px !important;
        padding-right: 6px !important;
        padding-bottom: 14px !important;
        padding-left: 10px !important;
    }

    .clt_contact_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_contact_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

    .clt-button-text2124 {
        font-size: 15px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }
}



@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {
    
    /*ipad*/

    .clt_contact_offcanvas {
        width: 425px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }
    .clt_contact_item_title {
        font-size: 16px;
    }
    .pfr_copy_Txt span {
        font-size: 16px;
    }

    .clt-button-text2124 {
        font-size: 18px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }

    .clt_contact_item_title {
        font-size: 16px;
    }
    .clt_contact_item_subDetail {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {
    
    /*ipad*/
    .clt_contact_offcanvas {
        width: 425px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        font-size: 16px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }
    .clt_contact_item_title {
        font-size: 16px;
    }
    .clt_contact_item_subDetail {
        font-size: 16px;
    }
    .pfr_copy_Txt span {
        font-size: 16px;
    }

    .clt-button-text2124 {
        font-size: 18px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_contact_offcanvas {
        width: 425px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        font-size: 16px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }
    .clt_contact_item_title {
        font-size: 16px;
    }
    .clt_contact_item_subDetail {
        font-size: 16px;
    }
    .pfr_copy_Txt span {
        font-size: 16px;
    }

    .clt-button-text2124 {
        font-size: 18px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_contact_offcanvas {
        width: 425px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        font-size: 16px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }
    .clt_contact_item_title {
        font-size: 16px;
    }
    .clt_contact_item_subDetail {
        font-size: 16px;
    }
    .pfr_copy_Txt span {
        font-size: 16px;
    }

    .clt-button-text2124 {
        font-size: 18px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_contact_offcanvas {
        width: 425px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        font-size: 16px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }
    .clt_contact_item_title {
        font-size: 16px;
    }
    .clt_contact_item_subDetail {
        font-size: 16px;
    }
    .pfr_copy_Txt span {
        font-size: 16px;
    }

    .clt-button-text2124 {
        font-size: 18px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_contact_offcanvas {
        width: 425px;
    }

    .clt_contact_detail {
        padding: 0px 0px 0px 22px;
    }

    .clt_contact_offcanvas_header .offcanvas-title {
        font-size: 16px;
    }

    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }
    .clt_contact_item_title {
        font-size: 16px;
    }
    .clt_contact_item_subDetail {
        font-size: 16px;
    }
    .pfr_copy_Txt span {
        font-size: 16px;
    }

    .clt-button-text2124 {
        font-size: 18px;
    }
    .clt-button-text21245 {
        font-size: 15px;
    }

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_contact_offcanvas_header .btn-back img {
        width: 8px;
    }
    .clt_contact_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }
    .clt_contact_offcanvas {
        width: 400px;
    }

    .clt_contact_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }
    .pfr_copy_Txt span {
        font-size: 14.5px;
    }

    .clt-button-text2124 {
        font-size: 18px;
    }

    .clt-button-text21245 {
        font-size: 15px;
    }
}