.clt_message_admin_offcanvas {
    z-index: 1055;
    max-height: calc(100vh - 94px);
    overflow-y: scroll;
}

.clt_message_admin_offcanvas_header {
    padding-top: 15px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 12.8px;
    border-bottom: 1px solid #dedddd;
}

.clt_message_admin_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_message_admin_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_message_admin_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_message_admin_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_message_admin_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_message_admin_offcanvas_header .offcanvas-title {
    color: #242424;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -.2px;
    margin-left: 20px;
    margin-bottom: 3px;
}


.clt_message_admin_offcanvas_body {
    padding: 24px;
}

.clt_message_admin_offcanvas_screen1_div {}


.clt_message_admin_booking_title_header {
    font-size: 14px;
    font-weight: 600;
    margin-top: -1.45px;
    padding-bottom: 3.45px;
}

.clt_message_admin_offcanvas_screen1_div h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt_message_admin_offcanvas_screen1_div p {
    font-size: 16px;
    color: #5b5b5b;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 14px;
    margin-top: 7px;
    letter-spacing: -0.2px;
}

.clt_message_admin_offcanvas_screen1_div button, .clt_message_admin_offcanvas_screen1_div button:focus, .clt_message_admin_offcanvas_screen1_div button:active, .clt_message_admin_offcanvas_screen1_div button:hover {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: "Inter";
    font-size: 14px;
    letter-spacing: 0.1px;
    padding-bottom: 10px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    width: 100px;
    height: 48px;
}

.clt_message_RightTxt {
    width: 150px;
}

.clt_message_RightTxt span {
    text-decoration: none;
    font-size: 13px;
    margin-left: 68px !important;
    color: #242424;
    font-family: "Inter";
    padding: 6px 10px 10px;
    font-weight: 500 !important;
    margin-bottom: -8px;
    margin-top: -8px;
}

.clt_message_box {
    width: 100%;
}

.clt_message_bookingImage {
    background-color: #242424;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    position: relative;
}

.clt_message_bookingImage img {
    height: 100px;
}

.clt_message_bookingImage .text {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: white;
    color: black;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 5px;
}

.clt_message_detail {
    margin-top: 6px;
    margin-bottom: 25px;
}

.clt_message_detail .detail_text {
    padding: 0;
    margin: 0px;
    margin-top: 1px;
}

.clt_message_detail .title {
    font-size: 14px;
    font-weight: 500;
}

.clt_message_detail .subtitle {
    font-size: 14px;
    font-weight: 600;
}

.clt_message_detail .secondary {
    font-size: 13px;
    color: #70757a;
}

.clt_admin_dash_btn_white, .clt_admin_dash_btn_white:hover, .clt_admin_dash_btn_white:focus, .clt_admin_dash_btn_white:active {
    background: #fff !important;
    color: #242424 !important;
    border: 1px solid #000 !important;
}

.clt-cancel-booking i {
    border: 4px solid #9a9a9a;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-bottom: 20px;
    color: #9a9a9a;
}

.clt-cancel-booking h2 {
    font-size: 22px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-cancel-booking p {
    font-size: 14px;
    color: #000000;
    font-family: "Inter";
    font-weight: 300;
    margin-bottom: 101px;
    margin-top: 8px;
    letter-spacing: -.2px;
}

.clt-cancel-booking a, .clt-cancel-booking a:hover {
    font-size: 15px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    text-decoration: underline;
}

.clt_admin_dash_hr_pd {
    padding: 25px 7px;
}

.clt_message_top_section img {
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 12px;
}

.clt-chevron-down-button-1 {
    margin-left: 5px;
    font-family: fontAwesome;
    font-size: 12px;
    margin-bottom: 2px;
    font-style: inherit;
    opacity: 0.9;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_message_admin_offcanvas {
        width: 100%;
        position: absolute;
        top: 0px;
        background-color: white;
        max-height: 100vh;
    }

    .clt_message_admin_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }


    .clt_message_admin_offcanvas_screen1_div button, .clt_message_admin_offcanvas_screen1_div button:focus, .clt_message_admin_offcanvas_screen1_div button:active, .clt_message_admin_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }


    .clt_message_header_button, .clt_message_header_button:focus, .clt_message_header_button:active, .clt_message_header_button:hover {
        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_message_RightTxt {
        width: 150px;
    }

    .clt_message_detail .secondary {
        font-size: 15.5px;
    }

    .clt_message_detail .title {
        font-size: 16px;
    }

    .clt_message_detail .subtitle {
        font-size: 16px;
    }

    .clt_message_bookingImage .text {
        font-size: 14px;
    }

    .clt_message_RightTxt span {
        font-size: 14px;
        margin-left: 45px !important;
        padding: 6px 10px 10px;
        font-weight: 500 !important;
    }

    .clt_message_admin_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }


    .clt_message_admin_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }

    .clt_message_admin_booking_title_header {
        font-size: 16px;
        font-weight: 600;
        margin-top: -1.45px;
        padding-bottom: 3.45px;
    }

    .clt_message_admin_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

}

@media only screen and (max-width: 767px) {

    /*mobile*/
    .clt_message_admin_offcanvas {
        width: 100%;
    }

    .clt_message_admin_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_message_admin_offcanvas_screen1_div button, .clt_message_admin_offcanvas_screen1_div button:focus, .clt_message_admin_offcanvas_screen1_div button:active, .clt_message_admin_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }

    .clt_message_header_button, .clt_message_header_button:focus, .clt_message_header_button:active, .clt_message_header_button:hover {

        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_message_RightTxt {
        width: 130px;
    }

    .clt_message_detail .secondary {
        font-size: 15.5px;
    }

    .clt_message_detail .title {
        font-size: 16px;
    }

    .clt_message_detail .subtitle {
        font-size: 16px;
    }

    .clt_message_bookingImage .text {
        font-size: 14px;
    }

    .clt_message_RightTxt span {
        font-size: 14px;
        margin-left: 45px !important;
        padding: 6px 10px 10px;
        font-weight: 500 !important;
    }

    .clt_message_admin_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_message_admin_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }
    .clt_message_admin_booking_title_header {
        font-size: 16px;
        font-weight: 600;
        margin-top: -1.45px;
        padding-bottom: 3.45px;
    }

    .clt_message_admin_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_message_admin_offcanvas {
        width: 100%;
    }

    .clt_message_admin_offcanvas_header .offcanvas-title {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt_message_admin_offcanvas_screen1_div button, .clt_message_admin_offcanvas_screen1_div button:focus, .clt_message_admin_offcanvas_screen1_div button:active, .clt_message_admin_offcanvas_screen1_div button:hover {
        font-size: 16px;
        letter-spacing: -.2px;
        padding-bottom: 10px;
        font-weight: 500;
        width: 75%;
        height: 50px;
    }

    .clt_message_header_button, .clt_message_header_button:focus, .clt_message_header_button:active, .clt_message_header_button:hover {
        font-size: 13.5px !important;
        letter-spacing: 0.5px;
        font-size: 13.5px !important;
        letter-spacing: 0.1px;
        width: 15%;
        height: 34px;
    }

    .clt_message_detail .secondary {
        font-size: 15.5px;
    }

    .clt_message_detail .title {
        font-size: 16px;
    }

    .clt_message_detail .subtitle {
        font-size: 16px;
    }

    .clt_message_bookingImage .text {
        font-size: 14px;
    }

    .clt_message_RightTxt span {
        font-size: 14px;
        margin-left: 45px !important;
        padding: 6px 10px 10px;
        font-weight: 500 !important;
    }

    .clt_message_admin_offcanvas_header .btn-back {
        background: none;
        margin-bottom: -5px;
        padding: 0.8rem 0.7rem 0.88rem 1rem!important;
    }

    .clt_message_admin_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt-cancel-booking p {
        font-size: 16px;
    }
    .clt_message_admin_booking_title_header {
        font-size: 16px;
        font-weight: 600;
        margin-top: -1.45px;
        padding-bottom: 3.45px;
    }

    .clt_message_admin_offcanvas_header {
        padding-top: 16px;
        padding-right: 24px;
        padding-bottom: 19px;
        padding-left: 12px;
        border-bottom: 1px solid #dedddd;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_message_admin_offcanvas {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_message_admin_offcanvas {
        width: 100%;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_message_admin_offcanvas {
        width: 350px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_message_admin_offcanvas {
        width: 350px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_message_admin_offcanvas {
        width: 100%;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_message_admin_offcanvas {
        width: 100%;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_message_admin_offcanvas_header .offcanvas-title {
        font-size: 14.5px;
    }

    .clt_message_admin_offcanvas_screen1_div button, .clt_message_admin_offcanvas_screen1_div button:active, .clt_message_admin_offcanvas_screen1_div button:focus, .clt_message_admin_offcanvas_screen1_div button:hover {
        font-size: 15px;
    }

    .clt_message_admin_offcanvas_header .offcanvas-title {
        font-size: 15px;
        margin-bottom: 2px;
    }

    .clt_message_RightTxt span {
        font-size: 14px;
        margin-left: 49px !important;
    }

    .clt_message_bookingImage .text {
        font-size: 13px;
    }

    .clt_message_detail .secondary {
        font-size: 14.5px;
        color: #70757a;
    }

    .clt_message_detail .title {
        font-size: 15px;
    }

    .clt_message_detail .subtitle {
        font-size: 15px;
    }

    .clt-cancel-booking p {
        font-size: 15px;
    }

    .clt_message_admin_booking_title_header {
        font-size: 15px;
        margin-top: -1.45px;
        padding-bottom: 3.45px;
    }

    .clt_message_accountDetail_wrapper {
        width: 100%;
        grid-gap: 10px;
        gap: 10px;
        padding: 1.4px 18px 4px;
        height: 53px;
        min-height: 53px;
    }

    .clt_message_admin_offcanvas_header {
        padding-bottom: 18px;
        padding-top: 15px;
        border-bottom: 1px solid #dedddd;
    }
}