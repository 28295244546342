.clt_searchbar_container {
    width: 100%;
}

.clt_searchbar_field {
    position: relative;
    width: 100%;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.clt_searchbar_icon_div {
    position: absolute;
    bottom: 12px;
    left: 14px;
}


.clt_searchbar_icon {
    width: 14.5px;
    height: 14.5px;
    margin-bottom: 4px;
    margin-left: 5px;
}

.clt_searchbar_container input {
    height: 46px;
    border-radius: 45px;
    background-color: #eee;
    padding-left: 46px;
    padding-bottom: 11px;
    color: #70757a;
    outline: none;
    font-weight: 500;
    width: 100%;
    text-align: left;
    font-size: 13px;
    border: #eee;
    box-shadow: none;
    border-color: #eee;
}

.form-control:focus {
    color: #242424;
    border-color: #dedddd !important;
    outline: 0;
    box-shadow: 0 0 0 0.05rem rgba(255, 255, 255, 0.25);
}


.clt_searchbar_container input:hover {
    background: #d9dce0 !important;
    border-color: #eee;
}

.clt_searchbar_container input:focus {
    background: #eee !important;
    border-color: #eee;
}

.clt_searchbar_container input:active {
    background: #eee !important;
    border-color: #eee;
}

.clt_searchbar_datalist {
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    width: inherit;
    top: 66px;
    z-index: 1200;
}

.clt_searchbar_result_box {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.clt_searchbar_result_icon {
    background-color: #eee;
    padding: 7px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clt_searchbar_result_icon img {
    width: 14px;
    height: 14px;
}


.clt_searchbar_result {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
}

.clt_searchbar_result_detail {
    width: 100%;
}

.clt_searchbar_container_detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3px;
    margin-left: 0px;
    padding: 20px 0px 20px 0px;
}

.clt_searchbar_hr {
    border-bottom: 1.5px solid #dedddd;
    margin-left: 60px;
}

.clt_searchbar_container_detail .name {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.2px;
    margin-bottom: -4px;
    padding-bottom: 0px;
}

.clt_searchbar_container_detail .subtitle {
    font-size: 13.999px;
    font-weight: 500;
    border-radius: 6px;
    letter-spacing: -0.3px;
    padding-top: 0px;
    color: #70757a;
    padding-bottom: 0px;
    margin: 0;
}


.clt_searchbar_field_2 input, .clt_searchbar_field_2 input:hover, .clt_searchbar_field_2 .form-control:focus {
    height: 42px;
    border-radius: 12px;
    background-color: #eee;
    padding-left: 14px;
    padding-bottom: 9px;
    color: #242424;
    outline: none;
    border: none;
    box-shadow: none;
    font-weight: 600;
    width: 100%;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.2px;
    box-shadow: none;
    margin: 12px 0px;
}

.clt_searchbar_offcanvas {
    width: 385px;
    z-index: 1055;
}

.clt_searchbar_offcanvas_header {
    padding: 0px 0px 0px 16px;
    border-bottom: 1px solid #dedddd;
}


.clt_searchbar_offcanvas_header button {
    font-size: 14px;
    opacity: 1;
    padding: 0 !important;
}

.clt_searchbar_offcanvas_header .btn-back {
    background: none;
    padding-top: 0.5rem !important;
    padding-right: 0.3rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.7rem !important;
    border-radius: 100px;
}

.clt_searchbar_offcanvas_header .btn-back:hover {
    background: #eee;
}

.clt_searchbar_offcanvas_header .btn-back:active {
    background: #d9dce0;
}

.clt_searchbar_offcanvas_header .btn-back img {
    width: 7px;
}

.clt_searchbar_datalist_2 {}

.clt_searchbar_input {
    display: block;
}

.clt_searchbar_button {
    display: none;
}

.clt_searchbar_field_2 .form-control:active {
    background: #d2d2d3 !important;
}

.clt_admin_dash_booking_container_arrow {
    /* padding-right: 17px; */
}


@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_searchbar_offcanvas {
        width: 100%;
    }

    .clt_searchbar_input {
        display: none;
    }

    .clt_searchbar_button {
        display: block;
    }

    .clt_searchbar_container button {
        height: 54px;
        border-radius: 45px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 11px;
        color: #70757a !important;
        outline: none;
        border: none;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 16px;
        letter-spacing: .2px;
        box-shadow: none;
    }

    .clt_searchbar_container button:hover {
        background: #d9dce0 !important;
    }

    .clt_searchbar_container button:focus {
        background: #eee !important;
    }

    .clt_searchbar_container button:active {
        background: #eee !important;
    }

    .clt_searchbar_icon_div {
        position: absolute;
        bottom: 9px;
        left: 14px;
    }


    .clt_searchbar_icon {
        width: 16px;
        height: 16px;
        margin-bottom: 12.2px;
        margin-left: 5px;
    }

    .clt_searchbar_container_detail .name {
        font-size: 16px;
    }

    .clt_searchbar_container_detail .subtitle {
        font-size: 16px;
    }

    .clt_searchbar_result_icon {
        background-color: #eee;
        padding: 8px;
        border-radius: 50%;
    }

    .clt_searchbar_result_icon img {
        width: 16px;
        height: 16px;
    }

    .clt_searchbar_container_detail {
        padding-top: 20px;
        padding-right: 0px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .clt_searchbar_hr {
        border-bottom: 1px solid #dedddd;
        margin-left: 78px;
    }

    .clt_searchbar_offcanvas_header {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_searchbar_offcanvas_body {
        padding-right: 12px !important;
        padding-left: 8px !important;
        padding-top: 0px !important;
    }

    .clt_searchbar_result_box {
        width: 32px;
    }

    .clt_searchbar_field_2 .form-control:focus, .clt_searchbar_field_2 input, .clt_searchbar_field_2 input:hover {
        height: 48px;
        padding-left: 25px;
        padding-bottom: 11px;
        font-size: 15px;
        letter-spacing: .2px;
        margin: -7.5px 0;
    }

    .clt_searchbar_field {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .clt_searchbar_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_searchbar_offcanvas_header .btn-back {
        background: none;
        margin-bottom: 2px;
        margin-top: 0px;
        margin-right: 10px;
        padding: 0.5rem 1rem 1.2rem 0.9rem !important;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_searchbar_offcanvas {
        width: 100%;
    }

    .clt_searchbar_input {
        display: none;
    }

    .clt_searchbar_button {
        display: block;
    }

    .clt_searchbar_container button, clt_searchbar_container button:hover {
        height: 54px;
        border-radius: 45px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 11px;
        color: #70757a !important;
        outline: none;
        border: none;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 16px;
        letter-spacing: .2px;
        border-color: #eee !important;
        box-shadow: none;
    }

    .clt_searchbar_icon_div {
        position: absolute;
        bottom: 9px;
        left: 14px;
    }


    .clt_searchbar_icon {
        width: 16px;
        height: 16px;
        margin-bottom: 12.2px;
        margin-left: 5px;
    }

    .clt_searchbar_container_detail .name {
        font-size: 16px;
    }

    .clt_searchbar_container_detail .subtitle {
        font-size: 16px;
    }

    .clt_searchbar_result_icon {
        background-color: #eee;
        padding: 8px;
        border-radius: 50%;
    }

    .clt_searchbar_result_icon img {
        width: 16px;
        height: 16px;
    }

    .clt_searchbar_container_detail {
        padding-top: 20px;
        padding-right: 0px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .clt_searchbar_hr {
        border-bottom: 1px solid #dedddd;
        margin-left: 78px;
    }

    .clt_searchbar_offcanvas_header {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_searchbar_offcanvas_body {
        padding-right: 12px !important;
        padding-left: 8px !important;
        padding-top: 0px !important;
    }

    .clt_searchbar_result_box {
        width: 32px;
    }

    .clt_searchbar_field_2 .form-control:focus, .clt_searchbar_field_2 input, .clt_searchbar_field_2 input:hover {
        height: 48px;
        padding-left: 25px;
        padding-bottom: 11px;
        font-size: 15px;
        letter-spacing: .2px;
        margin: -7.5px 0;
    }

    .clt_searchbar_field {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .clt_searchbar_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_searchbar_offcanvas_header .btn-back {
        background: none;
        margin-bottom: 2px;
        margin-top: 0px;
        margin-right: 10px;
        padding: 0.5rem 1rem 1.2rem 0.9rem !important;
    }


}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_searchbar_offcanvas {
        width: 100%;
    }

    .clt_searchbar_input {
        display: none;
    }

    .clt_searchbar_button {
        display: block;
    }

    .clt_searchbar_container button, clt_searchbar_container button:hover {
        height: 54px;
        border-radius: 45px;
        background-color: #eee !important;
        padding-left: 48px;
        padding-bottom: 11px;
        color: #70757a !important;
        outline: none;
        border: none;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 16px;
        letter-spacing: .2px;
        border-color: #eee !important;
        box-shadow: none;
    }

    .clt_searchbar_icon_div {
        position: absolute;
        bottom: 9px;
        left: 14px;
    }


    .clt_searchbar_icon {
        width: 16px;
        height: 16px;
        margin-bottom: 12.2px;
        margin-left: 5px;
    }

    .clt_searchbar_container_detail .name {
        font-size: 16px;
    }

    .clt_searchbar_container_detail .subtitle {
        font-size: 16px;
    }

    .clt_searchbar_result_icon {
        background-color: #eee;
        padding: 8px;
        border-radius: 50%;
    }

    .clt_searchbar_result_icon img {
        width: 16px;
        height: 16px;
    }

    .clt_searchbar_container_detail {
        padding-top: 20px;
        padding-right: 0px;
        padding-bottom: 20px;
        padding-left: 24px;
    }

    .clt_searchbar_hr {
        border-bottom: 1px solid #dedddd;
        margin-left: 78px;
    }

    .clt_searchbar_offcanvas_header {
        padding-top: 0px;
        padding-right: 24px;
        padding-bottom: 0px;
        padding-left: 18px;
        border-bottom: 1px solid #dedddd;
    }

    .clt_searchbar_offcanvas_body {
        padding-right: 12px !important;
        padding-left: 8px !important;
        padding-top: 0px !important;
    }

    .clt_searchbar_result_box {
        width: 32px;
    }

    .clt_searchbar_field_2 .form-control:focus, .clt_searchbar_field_2 input, .clt_searchbar_field_2 input:hover {
        height: 48px;
        padding-left: 25px;
        padding-bottom: 11px;
        font-size: 15px;
        letter-spacing: .2px;
        margin: -7.5px 0;
    }

    .clt_searchbar_field {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .clt_searchbar_offcanvas_header .btn-back img {
        width: 8px;
    }

    .clt_searchbar_offcanvas_header .btn-back {
        background: none;
        margin-bottom: 2px;
        margin-top: 0px;
        margin-right: 10px;
        padding: 0.5rem 1rem 1.2rem 0.9rem !important;
    }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_searchbar_container input, .clt_searchbar_field .form-control:focus, .clt_searchbar_container input:hover {
        height: 50px;
        font-size: 15px;
    }

    .clt_searchbar_field {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .clt_searchbar_icon {
        width: 14.5px;
        height: 14.5px;
        margin-bottom: 8px;
        margin-left: 5px;
    }
}