.clt-booking-cp-div1 {
    border-radius: 12px;
    background: #e8f0fe;
    padding: 20px;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
    text-transform: none !important;
}

.notranslate {
    transform: none !important;
}

.clt-booking-cp-div1 img {
    width: 13px;
}

.clt-booking-cp-div1-text {
    margin-left: 20px;
}

.clt-booking-cp-div1-text h2 {
    font-size: 18px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div1-text p {
    font-size: 18px;
    color: #70757a;
    line-height: 1.44;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-booking-hr-pd {
    padding: 25px 12px;
}

.clt-booking-hr-pd-0 {
    padding: 0px 12px;
}

.clt-right-form-hr-pd {
    padding: 16px 12px;
}

.clt-right-form-second-hr-pd {
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 16px;
    padding-left: 12px;
}



.clt-booking-cp-div2 {}

.clt-booking-cp-div2 img {
    width: 80px;
    height: 80px;
    border-radius: 12px;
}

.clt-booking-cp-div2-text {
    margin-left: 20px;
}

.clt-booking-cp-div2-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div2-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}


.clt-booking-cp-div3 {}

.clt-booking-cp-div3 img {
    width: 17px;
    filter: grayscale(1);
    margin-top: 3px;
}

.clt-booking-cp-div3-text {
    margin-left: 20px;
}

.clt-admin-managebooking-text-custom-refund {
    font-size: 14px;
    color: #016ad3;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: -1px;
    padding-top: 6px;
    letter-spacing: -0.2px;
    padding-bottom: 25px;
}

.clt-booking-cp-div3-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: -2px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div3-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: -2px;
    letter-spacing: -0.2px;
    /* Remove these conflicting styles */
    white-space: normal;
    /* Set to normal to allow text to wrap */
    overflow: visible;
    /* Set to visible to allow text to wrap */
}

.clt-booking-cp-div3-textt {
    margin-left: 0px !important;
}

.clt-booking-cp-div3-textt p {
    text-align: center !important;
    margin-top: 3px !important;
    line-height: 20px !important;
}



.clt-booking-cp-div3 button,
.clt-booking-cp-div3 button:focus {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 13.5px;
    font-weight: 500;
    padding-top: 6px;
    padding-right: 11px;
    padding-bottom: 7px;
    padding-left: 11px;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    letter-spacing: 0.1px;
    white-space: nowrap;
}

.clt-booking-cp-div3 button:active {
    background: #d2d2d3 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}


.clt-booking-cp-div3 button:hover {
    background: #d9dce0 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}


.clt-booking-mb {
    margin-top: 18px;
}

.clt-booking-mb2 {
    margin-top: 8px;
}

.clt-booking-cp-div4 {}

.clt-booking-cp-div4-title {
    font-size: 18px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 15px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div4-inner {
    cursor: pointer;
    border: 1px solid #dedddd;
    border-radius: 12px;
    padding: 15px 16px;
}

.clt-booking-cp-div4-divIcon {
    border: none;
    border-bottom: 1px solid #dedddd !important;
    border-radius: 0px !important;
    padding: 15px 4px !important;
}

.clt-booking-cp-div4-inner:hover {
    background: #f6f6f6;
}

.clt-booking-cp-div4-inner:active {
    background: #eee;
}


.clt-booking-cp-div4-text {
    width: 100%;
}

.clt-booking-cp-div4-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div4-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}


.clt-booking-cp-div5 {}

.clt-booking-cp-div5-title {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 24px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div7-title {
    font-size: 15.5px;
    line-height: 20px;
    padding: 0;
    margin-top: -8px;
    margin-right: 0px;
    margin-bottom: 12px;
    margin-left: 0px;
    font-weight: 400;
}

.clt-booking-cp-div5-inner h2 {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 25px;
    letter-spacing: -0.2px;
    line-height: 16px;
}

.clt-booking-cp-div5-inner i {
    background: #70757a;
    border-radius: 50px;
    font-size: 8px;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    margin-left: 7px;
    margin-top: 1.5px;
}

.clt-booking-cp-div5-inner i:hover {
    background: #9a9a9a;
}

.clt-booking-cp-text-bold {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #242424 !important;
    margin-bottom: 15px !important;
}

.clt-booking-cp-text-bold2 {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #242424 !important;
    margin-bottom: 15px !important;
}

.clt-booking-cp-text-green {
    font-weight: 400 !important;
    font-size: 14px;
    color: #1b711e !important;
}

.clt-booking-cp-text-red {
    font-weight: 400 !important;
    font-size: 14px;
    color: #ef3737 !important;
}

.clt-booking-cp-tooltip .tooltip-inner {
    background: #fff;
    color: #242424;
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 10px;
    font-size: 14px;
    box-shadow: 0px 0px 3px #9e9e9e;
    font-family: "Inter";
    font-weight: 400;
    text-align: left;
    letter-spacing: -.2px;
}

.clt-booking-cp-tooltip .tooltip-arrow {
    display: none;
}

.clt-booking-cp-div6-title {
    font-size: 20px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 25px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div6 {}

.clt-booking-cp-div6 img {
    width: 18px;
    margin-top: 3px;
}

.clt-booking-cp-div6-text {
    margin-left: 20px;
}

.clt-booking-cp-div6-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div6-text p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-booking-cp-div6 button,
.clt-booking-cp-div6 button:hover,
.clt-booking-cp-div6 button:focus,
.clt-booking-cp-div6 button:active {
    background: #eee;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 13.5px;
    font-weight: 500;
    padding: 6px 11px 7px;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    position: relative;
    letter-spacing: .35px;
}

.clt-booking-cp-div6 button:hover {
    background: #d9dce0 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}

.clt-booking-cp-div6 button:active {
    background: #d2d2d3 !important;
    border: 1px solid #fff !important;
    color: #242424 !important;
}

.clt-booking-cp-div7-btn button,
.clt-booking-cp-div7-btn button:hover,
.clt-booking-cp-div7-btn button:focus,
.clt-booking-cp-div7-btn button:active {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 14px;
    padding-top: 1.5px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border: 0px solid #000;
    position: relative;
    letter-spacing: 0.1px;
    height: 48px;
    width: 100%;
}

.clt-booking-cp-div7-btn button:hover {
    background: #2d2d2d !important;
    border: 0px solid #000;
}

.clt-booking-cp-div7-btn button:active {
    background: #5c5c5c !important;
    border: 0px solid #000;
}

.clt-booking-cp-div7-p {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.clt-booking-cp-div7-p a,
.clt-booking-cp-div7-p a:hover {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    text-decoration: underline;
}

.clt-right-form-p {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 300;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    margin-top: 15px;
    margin-bottom: 3px;
}

.clt-right-form-p a,
.clt-right-form-p a:hover {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: -0.2px;
    text-decoration: underline;
}

.clt-booking-gc-div1 {
    margin-top: 30px;
}

.clt-booking-gc-div1 i {
    background: #eee;
    border-radius: 50px;
    font-size: 12px;
    color: #242424;
    font-weight: 600;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.clt-booking-gc-div1 input,
.clt-booking-gc-div1 input:hover,
.clt-booking-gc-div1 input:active,
.clt-booking-gc-div1 input:focus {
    height: 120px;
    width: 175px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    font-size: 32px;
    font-family: "Inter";
    font-weight: 600;
    color: #242424;
    border: 1px solid #dedddd;
    border-radius: 12px;
}

.clt-booking-gc-div1 input::-webkit-outer-spin-button,
.clt-booking-gc-div1 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.clt-booking-gc-div1 input[type=number] {
    -moz-appearance: textfield;
}

.clt-booking-gc-div2 i {
    background: #878787;
    border-radius: 50px;
    font-size: 8px;
    color: #fff;
    font-weight: 600;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
}

.clt-booking-gc-div2-text {
    margin-left: 20px;
    margin-bottom: 30px;
}

.clt-booking-gc-div2-text h2 {
    font-size: 15px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-booking-gc-div2-text p {
    font-size: 14.8px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: -2px;
    letter-spacing: -0.2px;
}

.clt-booking-gc-div2-btn button,
.clt-booking-gc-div2-btn button:hover,
.clt-booking-gc-div2-btn button:focus,
.clt-booking-gc-div2-btn button:active {
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 14px;
    padding-top: 1.5px;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid #000;
    position: relative;
    height: 48px;
    width: 100%;
}

.clt-booking-gc-div2-btn button:hover {
    background: #2d2d2d !important;
    border: 1px solid #2d2d2d !important;
}

.clt-booking-gc-div2-btn button:active {
    background: #5c5c5c !important;
    border: 1px solid #5c5c5c !important;
}

.pfr_payment_cards3dots .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pfr_payment_cards3dots .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}

.clt-booking-cb-div1 {
    border-radius: 12px;
    background: #01aa5d;
    padding-top: 18px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
}

.clt-booking-cb-div1 img {
    width: 15px;
}

.clt-booking-cb-div1-text {
    margin-left: 20px;
}

.clt-booking-cb-div1-text h2 {
    font-size: 14px;
    color: #fff;
    font-family: 'Inter';
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-booking-cb-div2 {
    border-radius: 12px;
    background: #fff;
    padding: 20px;
    border: 1px solid #dddddd;
}

.clt-booking-cb-div2 i {
    font-size: 17px;
    color: #70757a;
}

.clt-booking-cb-div2-text {
    margin-left: 20px;
}

.clt-booking-cb-div2-text h2 {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: -0.2px;
}

.clt-booking-cb-div2-text p {
    font-size: 14px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 1.44;
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-payment-plan-div {
    margin-top: 0 !important;
}

.clt-payment-plan-p {
    font-size: 14px;
    color: #70757a;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 1.44;
    letter-spacing: -0.2px;
}

.clt-payment-plan-more-info {
    font-size: 14px;
    color: #000;
    font-family: 'Inter';
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 4px;
    letter-spacing: -0.2px;
    text-decoration: underline;
    cursor: pointer;
}

.clt-payment-plan-more-info:hover {
    color: #787878;
}

.clt-payment-plan-more-info:active {
    color: #919191;
}



.clt-confirm-booking-top-1-div {
    width: 100%;
    height: 220px;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 15px;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
    background-repeat: no-repeat;
    background-position: right 20px bottom;
    background-size: 22%;
    justify-content: space-around;
    align-items: start;
    flex-direction: column;
}

.clt-confirm-booking-top-1-span {
    font-size: 14px;
    letter-spacing: -.2px;
    font-weight: 400;
    font-family: Inter;
    margin-bottom: 0;
    padding: 0px 20px 20px;
    color: #fff;
}

.clt-confirm-booking-top-1-div h2 {
    font-size: 28px;
    letter-spacing: -.2px;
    font-weight: 700;
    font-family: Mulish;
    margin-bottom: 0;
    padding: 0px 20px 0px;
    color: #fff;
    width: 80%;
    line-height: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.clt-clickable-chevron-div {
    padding: 15px 4px;
    border-bottom: 1px solid #dddddd;
    margin-top: 0px;
    cursor: pointer;
    position: relative;
}

.clt-clickable-chevron-div:hover {
    background: #fbfafa;
}

.clt-clickable-chevron-div:active {
    background: #eee;
}

.clt-booking-hoverActive:hover {
    background: #fbfafa;
}

.clt-booking-hoverActive:active {
    background: #eee;
}

.clt-clickable-chevron-div .inner-div-1 {
    width: 90%;
}

.clt-clickable-chevron-div .inner-div-2 {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.clt-clickable-chevron-div .inner-div-1 h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-clickable-chevron-div .inner-div-1 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
}

.clt-booking-cp-div1-title {
    font-size: 20px;
    color: #242424;
    font-family: "Inter";
    font-weight: 600;
    margin-bottom: 22px;
    letter-spacing: -.2px;
}


.clt-paymentplan-select-mainDiv {
    border: 1px solid #ddd;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 19px;
    padding-left: 15px;
    border-radius: 12px;
    margin-top: 16px;
    cursor: pointer;
    background: #fff;
}

.clt-paymentplan-select-mainDiv:hover {
    background: #f6f6f6 !important;
}

.clt-paymentplan-select-mainDiv:active {
    background: #eee;
    border: 1px solid #000;
}

.clt-paymentplan-select-mainDiv .inner-div-text h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 1px;
    letter-spacing: -0.2px;
}

.clt-paymentplan-select-mainDiv .inner-div-text h3 {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 4.6px;
    letter-spacing: -0.2px;
}

.clt-paymentplan-select-mainDiv .inner-div-text span {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-paymentplan-select-mainDiv .inner-div-text span i {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    margin-right: 5px;
}

.clt-paymentplan-select-mainDiv .inner-div-circle {
    border: 1px solid #ddd;
    width: 19px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-paymentplan-select-mainDiv .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}

.clt-select-option-mainDiv {
    border: 1px solid #ddd;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 19px;
    padding-left: 5px;
    border-radius: 12px;
    margin-top: 16px;
    cursor: pointer;
    background: #fff;
}

.clt-select-option-mainDiv:hover {
    background: #f6f6f6 !important;
}

.clt-select-option-mainDiv:active {
    background: #eee;
    border: 1px solid #000;
}

.clt-select-option-mainDiv .inner-div-text h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 1px;
    letter-spacing: -0.2px;
}

.clt-select-option-mainDiv .inner-div-text h3 {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 4.6px;
    letter-spacing: -0.2px;
}

.clt-select-option-mainDiv .inner-div-text span {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-select-option-mainDiv .inner-div-text span i {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    margin-right: 5px;
}

.clt-select-option-mainDiv .inner-div-circle {
    border: 1px solid #ddd;
    width: 19px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-select-option-mainDiv .inner-div-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #ddd;
    width: 15px;
    min-width: 15px;
    height: 15px;
    /* border-radius: 50px; */
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.clt-select-option-mainDiv .inner-div-checkbox:checked::before {
    content: "✓";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #fff;
}

.clt-select-option-mainDiv .inner-div-checkbox:checked {
    background-color: #000;
}

.clt-select-option-mainDiv .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}










.clt-booking-cp-div1-request-pending {
    border-radius: 12px;
    background: #fff3ec;
    padding: 20px;
}

.clt-booking-cp-div1-request-pending img {
    width: 17px;
}

.clt-booking-cp-div1-request-cancel {
    border-radius: 12px;
    background: #ffdada;
    padding: 20px;
}

.clt-booking-cp-div1-request-cancel img {
    width: 17px;
}

.clt-booking-cp-div1-request-sent {
    border-radius: 12px;
    background: #e4f1ff;
    padding: 20px;
    margin-top: 5px;
}

.clt-booking-cp-div1-text22 p {
    font-size: 14px !important;
    line-height: 1.34 !important;
}

.clt-booking-cp-div1-request-sent22 {
    padding: 16px !important;
}

.clt-booking-cp-div1-request-sent img {
    width: 20px;
}

.clt-booking-cp-div1-request-sent22 img {
    width: 15px !important;
    margin-right: -5px !important;
}

.clt_section_information_contact {
    border-radius: 18px;
    background: #ffffff;
    box-shadow: 0 2px 14px rgba(0, 0, 0, .1) !important;
    padding-top: 6px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 6px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.clt_section_information_contact img {
    width: 66px;
    margin-top: 9px;
}

.clt_section_information_contact2 {
    border-radius: 18px;
    background: #ffffff;
    box-shadow: 0 2px 14px rgba(0, 0, 0, .1) !important;
    padding-top: 18px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 19px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.clt_section_information_contact2 img {
    width: 66px;
    margin-top: 9px;
}

.clt_section_information_contact2124 {
    background: #ffffff;
    padding-top: 6px;
    border-top: 1px solid #dedddd;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 6px;
    margin-top: 30px;
    margin-bottom: -8px;
}

.clt_section_information_contact2124 img {
    width: 66px;
    margin-top: 9px;
}

.clt_section_information_contact2124img {
    width: 246px;
    margin-top: 9px;
}


.clt-booking-cp-div1-request-accept {
    border-radius: 12px;
    background: #e7f0e6;
    padding: 20px;
}

.clt-booking-cp-div1-request-accept img {
    width: 17px;
}

.clt-contact-request-box-answer-message {
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid #dedddd;
    padding: 20px;
}

.clt-contact-request-box-answer-message img {
    width: 17px;
}

.clt-no-slot-error {
    font-size: 20px;
    margin-top: 20px;
    color: red;
}

.clt-admin-dash-booking-slider-text {
    margin-left: 0px !important;
}

.clt-admin-dash-booking-slider-text h2 {
    font-size: 14px !important;
    margin-bottom: 0px !important;
}

.clt-admin-dash-booking-slider-text p {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}

.clt-admin-dash-booking-navigation-cancel-slider-text h2 {
    font-size: 14px !important;
    color: #c80e0e !important;
    margin-bottom: 0px !important;
}

.clt-admin-dash-booking-slider-subtext {
    margin-left: 0px !important;
}

.clt-admin-dash-booking-slider-subtext h2 {
    font-size: 24px !important;
    margin-bottom: 0px !important;
    margin-bottom: 3px !important;
}

.clt-admin-dash-booking-slider-subtext p {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}

.no-data-found {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-data-found p {
    font-size: 14px;
}

.clt_select_timeslot_accordion_body {
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 11px !important;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-paymentplan-select-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt-paymentplan-select-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-booking-cp-div1 {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }


    .clt-select-option-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-select-option-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt-select-option-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div1-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div1-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div1-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-booking-cp-div2 img {
        width: 87px;
        height: 87px;
        border-radius: 12px;
    }

    .clt-booking-cp-div2-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div2-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div2-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-booking-cb-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3 img {
        width: 20px;
        margin-top: 0px;
    }

    .clt-booking-cp-div3-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-admin-dash-booking-slider-text {
        margin-left: 0px !important;
    }

    .clt-admin-dash-booking-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-admin-dash-booking-slider-text p {
        font-size: 16px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-navigation-cancel-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-admin-dash-booking-slider-subtext {
        margin-left: 0px !important;
    }

    .clt-admin-dash-booking-slider-subtext h2 {
        font-size: 24px !important;
        margin-bottom: 0px !important;
        margin-bottom: 3px !important;
    }

    .clt-admin-dash-booking-slider-subtext p {
        font-size: 16px !important;
        margin-bottom: 0 !important;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:hover,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:active {
        border-radius: 50px;
        font-size: 16px;
    }

    .clt-booking-mb {
        margin-top: 25px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div4-inner {
        border-radius: 12px;
        padding: 15px 16px;
    }

    .clt-booking-divBox {
        margin: 0;
    }

    .clt-booking-cp-div4-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div4-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div7-title {
        font-size: 16px;
        line-height: 22px;
        margin: -15px 0 17px;
    }


    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .clt-booking-cp-div5-inner i {
        border-radius: 50px;
        font-size: 9px;
        width: 17px;
        height: 17px;
        margin-left: 8px;
    }

    .clt-booking-cp-tooltip .tooltip-inner {
        border-radius: 8px;
        font-size: 15px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div6 img {
        width: 20px;
    }

    .clt-booking-cp-div6-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:hover,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:active {
        border-radius: 50px;
        font-size: 15px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:hover,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-right-form-p {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-clickable-chevron-div .inner-div-1 h2 {
        font-size: 16px;
    }

    .clt-clickable-chevron-div .inner-div-1 p {
        font-size: 16px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:hover,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 1.5px;
        font-weight: 600;
        height: 52px;
    }

    .clt-booking-cp-div1-title {
        font-size: 22px;
    }

    .clt-payment-plan-more-info,
    .clt-payment-plan-p {
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-booking-cp-div1-request-pending {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-pending img {
        width: 15px;
    }

    .clt-booking-cp-div1-request-cancel {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-cancel img {
        width: 15px;
    }

    .clt-booking-cp-div1-request-sent {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-sent img {
        width: 15px;
    }

    .clt_section_information_contact {
        border-radius: 16px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt_section_information_contact img {
        width: 64px;
    }

    .clt_section_information_contact2124 img {
        width: 110px;
    }

    .clt_section_information_contact2124img {
        width: 150px;
    }

    .clt_section_information_contact2124 {
        background: #ffffff;
        padding-top: 6px;
        border-top: 1px solid #dedddd;
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 6px;
        margin-top: 30px;
        margin-bottom: -30px;
    }

    .clt_section_information_contact2 {
        border-radius: 16px;
        padding: 28px 20px;
    }

    
    .clt_section_information_contact2 img {
        width: 64px;
    }

    .clt-admin-dash-booking-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-admin-dash-booking-slider-text p {
        font-size: 16px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-slider-subtext p {
        font-size: 14px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-navigation-cancel-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-right-form-hr-pd {
        padding: 24px 12px;
    }

    .clt-right-form-second-hr-pd {
        padding-top: 16px;
        padding-right: 12px;
        padding-bottom: 24px;
        padding-left: 12px;
    }

    .clt-booking-innner-div1 {
        background-repeat: no-repeat;
        height: 19px !important;
        margin-top: 2px !important;
        width: 17px !important;
        background-size: contain;
    }

    .clt-admin-managebooking-text-custom-refund {
        font-size: 16px;
    }


}

@media only screen and (max-width: 767px) {

    /*mobile*/
    .clt-booking-cp-div1 {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div1-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div1-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div1-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-booking-cp-div2 img {
        width: 87px;
        height: 87px;
        border-radius: 12px;
    }

    .clt-booking-cp-div2-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div2-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div2-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3 img {
        width: 20px;
        margin-top: 0px;
    }

    .clt-booking-cp-div3-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:hover,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:active {
        border-radius: 50px;
        font-size: 16px;
    }

    .clt-booking-mb {
        margin-top: 25px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div4-inner {
        border-radius: 12px;
        padding: 15px 16px;
    }

    .clt-booking-cp-div4-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div4-text p {
        font-size: 16px;
        margin-bottom: 0;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div7-title {
        font-size: 16px;
        line-height: 22px;
        margin: -15px 0 17px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .clt-booking-cp-div5-inner i {
        border-radius: 50px;
        font-size: 9px;
        width: 17px;
        height: 17px;
        margin-left: 8px;
    }

    .clt-booking-cp-tooltip .tooltip-inner {
        border-radius: 8px;
        font-size: 16px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div6 img {
        width: 20px;
    }

    .clt-booking-cp-div6-text {
        margin-left: 20px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:hover,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:active {
        border-radius: 50px;
        font-size: 15px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:hover,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .clt-right-form-p {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-clickable-chevron-div .inner-div-1 h2 {
        font-size: 16px;
    }

    .clt-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-select-option-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-clickable-chevron-div .inner-div-1 p {
        font-size: 16px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:hover,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 1.5px;
        font-weight: 600;
        height: 52px;
    }

    .clt-booking-cp-div1-title {
        font-size: 22px;
    }

    .clt-payment-plan-more-info,
    .clt-payment-plan-p {
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-booking-cp-div1-request-pending {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-pending img {
        width: 15px;
    }

    .clt-booking-cp-div1-request-cancel {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-cancel img {
        width: 15px;
    }

    .clt-booking-cp-div1-request-sent {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-sent img {
        width: 15px;
    }

    .clt_section_information_contact {
        border-radius: 16px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt_section_information_contact2124 img {
        width: 110px;
    }

    .clt_section_information_contact2124img {
        width: 150px;
    }

    .clt_section_information_contact2124 {
        background: #ffffff;
        padding-top: 6px;
        border-top: 1px solid #dedddd;
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 6px;
        margin-top: 30px;
        margin-bottom: -30px;
    }
    .clt_section_information_contact img {
        width: 64px;
    }

    .clt_section_information_contact2 {
        border-radius: 16px;
        padding: 28px 20px;
    }

    .clt_section_information_contact2 img {
        width: 64px;
    }


    .clt-admin-dash-booking-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-admin-dash-booking-slider-text p {
        font-size: 16px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-slider-subtext p {
        font-size: 14px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-navigation-cancel-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-right-form-hr-pd {
        padding: 24px 12px;
    }

    .clt-right-form-second-hr-pd {
        padding-top: 16px;
        padding-right: 12px;
        padding-bottom: 24px;
        padding-left: 12px;
    }

    .clt-booking-innner-div1 {
        background-repeat: no-repeat;
        height: 19px !important;
        margin-top: 2px !important;
        width: 17px !important;
        background-size: contain;
    }


    .clt-admin-managebooking-text-custom-refund {
        font-size: 16px;
    }

}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt-booking-cp-tooltip .tooltip-inner {
        border-radius: 8px;
        font-size: 16px;
    }

    .clt-booking-divBox {
        margin: 0;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .clt-booking-cp-div7-title {
        font-size: 16px;
        line-height: 22px;
        margin: -15px 0 17px;
    }

    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-clickable-chevron-div .inner-div-1 h2 {
        font-size: 16px;
    }

    .clt-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-select-option-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-clickable-chevron-div .inner-div-1 p {
        font-size: 16px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:hover,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        padding-top: 1.5px;
        font-weight: 600;
        height: 52px;
    }

    .clt-booking-cp-div1-title {
        font-size: 22px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:hover,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:active {
        border-radius: 12px;
        font-size: 16px;
        height: 54px;
        letter-spacing: 0.1px;
        padding-top: 2px;
    }

    .clt-payment-plan-more-info,
    .clt-payment-plan-p {
        font-size: 16px;
        letter-spacing: -.2px;
    }

    .clt-booking-cp-div1-request-pending {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-pending img {
        width: 15px;
    }

    .clt-booking-cp-div1-request-cancel {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-cancel img {
        width: 15px;
    }

    .clt-booking-cp-div1-request-sent {
        border-radius: 12px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-request-sent img {
        width: 15px;
    }

    .clt_section_information_contact {
        border-radius: 16px;
        padding-top: 25px;
        padding-right: 20px;
        padding-bottom: 25px;
        padding-left: 20px;
    }

    .clt_section_information_contact img {
        width: 64px;
    }

    .clt_section_information_contact2124 img {
        width: 110px;
    }

    .clt_section_information_contact2124img {
        width: 150px;
    }

    .clt_section_information_contact2124 {
        background: #ffffff;
        padding-top: 6px;
        border-top: 1px solid #dedddd;
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 6px;
        margin-top: 30px;
        margin-bottom: -30px;
    }

    .clt_section_information_contact2 {
        border-radius: 16px;
        padding: 28px 20px;
    }

    .clt_section_information_contact2 img {
        width: 64px;
    }


    .clt-admin-dash-booking-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-admin-dash-booking-slider-text p {
        font-size: 16px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-slider-subtext p {
        font-size: 14px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-navigation-cancel-slider-text h2 {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }

    .clt-right-form-hr-pd {
        padding: 24px 12px;
    }

    .clt-right-form-second-hr-pd {
        padding-top: 16px;
        padding-right: 12px;
        padding-bottom: 24px;
        padding-left: 12px;
    }

    .clt-booking-innner-div1 {
        background-repeat: no-repeat;
        height: 19px !important;
        margin-top: 2px !important;
        width: 17px !important;
        background-size: contain;
    }

    .clt-admin-managebooking-text-custom-refund {
        font-size: 16px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-text h2,
    .clt-booking-cp-div1-text p {
        font-size: 18px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:active,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:active,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:active,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-right-form-p {
        font-size: 16px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:active,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-booking-gc-div1 input,
    .clt-booking-gc-div1 input:active,
    .clt-booking-gc-div1 input:focus,
    .clt-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-booking-cb-div2-text h2,
    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-text h2,
    .clt-booking-cp-div1-text p {
        font-size: 18px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:active,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:active,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:active,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-right-form-p {
        font-size: 16px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:active,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-booking-gc-div1 input,
    .clt-booking-gc-div1 input:active,
    .clt-booking-gc-div1 input:focus,
    .clt-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-booking-cb-div2-text h2,
    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-text h2,
    .clt-booking-cp-div1-text p {
        font-size: 18px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:active,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:active,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:active,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-right-form-p {
        font-size: 16px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:active,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-booking-gc-div1 input,
    .clt-booking-gc-div1 input:active,
    .clt-booking-gc-div1 input:focus,
    .clt-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-booking-cb-div2-text h2,
    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-text h2,
    .clt-booking-cp-div1-text p {
        font-size: 18px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:active,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:active,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:active,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-right-form-p {
        font-size: 16px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:active,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-booking-gc-div1 input,
    .clt-booking-gc-div1 input:active,
    .clt-booking-gc-div1 input:focus,
    .clt-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-booking-cb-div2-text h2,
    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-text h2,
    .clt-booking-cp-div1-text p {
        font-size: 18px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:active,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:active,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:active,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-right-form-p {
        font-size: 16px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:active,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-booking-gc-div1 input,
    .clt-booking-gc-div1 input:active,
    .clt-booking-gc-div1 input:focus,
    .clt-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-booking-cb-div2-text h2,
    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt-booking-cp-div1 {
        padding-top: 24px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
    }

    .clt-booking-cp-div1-text h2,
    .clt-booking-cp-div1-text p {
        font-size: 18px;
    }

    .clt-booking-cp-div1 img {
        width: 15px;
    }

    .clt-booking-cp-div2 img {
        width: 82px;
        height: 82px;
        border-radius: 10px;
    }

    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:active,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div4-title {
        font-size: 22px;
    }

    .clt-booking-cp-div4-inner {
        padding: 19px 16px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div5-title {
        font-size: 22px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 16px;
    }

    .clt-booking-cp-text-bold {
        font-size: 16px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 16px !important;
    }

    .clt-booking-cp-div5-inner i {
        width: 16px;
        height: 16px;
    }

    .clt-booking-cp-div6-title {
        font-size: 22px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 16px;
    }

    .clt-booking-cp-div6-text p {
        font-size: 16px;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:active,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:hover {
        font-size: 15.5px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:active,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-cp-div7-p {
        font-size: 16px;
    }

    .clt-booking-cp-div7-p a,
    .clt-booking-cp-div7-p a:hover {
        font-size: 16px;
    }

    .clt-right-form-p {
        font-size: 15px;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 15px;
    }

    .clt-booking-gc-div2-text h2 {
        font-size: 16px;
    }

    .clt-booking-gc-div2-text p {
        font-size: 16px;
    }

    .clt-booking-gc-div2 i {
        width: 18px;
        height: 18px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:active,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:hover {
        font-size: 16px;
        height: 52px;
    }

    .clt-booking-gc-div1 i {
        width: 52px;
        height: 52px;
        font-size: 15px;
    }

    .clt-booking-gc-div1 input,
    .clt-booking-gc-div1 input:active,
    .clt-booking-gc-div1 input:focus,
    .clt-booking-gc-div1 input:hover {
        height: 140px;
        width: 200px;
        font-size: 38px;
    }

    .clt-booking-cb-div2-text h2,
    .clt-booking-cb-div2-text p {
        font-size: 16px;
    }

    .clt-booking-cb-div2 i {
        font-size: 18px;
    }

    .clt-booking-cb-div2 {
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
    }

    .clt-booking-cb-div1-text h2 {
        font-size: 16px;
    }

    .clt-booking-cb-div1 img {
        width: 17px;
    }
}


@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-booking-cp-div1-text h2,
    .clt-booking-cp-div1-text p {
        font-size: 18px;
    }

    .clt-booking-cp-div2-text h2,
    .clt-booking-cp-div2-text p {
        font-size: 15px;
    }

    .clt-booking-cp-div2 img {
        width: 84px;
        height: 84px;
        border-radius: 16px;
    }

    .clt-booking-cp-div3-text h2 {
        font-size: 15px;
    }

    .clt-booking-cp-div3-text p {
        font-size: 15px;
    }

    .clt-booking-cp-div3 button,
    .clt-booking-cp-div3 button:active,
    .clt-booking-cp-div3 button:focus,
    .clt-booking-cp-div3 button:hover {
        font-size: 14.5px;
    }

    .clt-booking-cp-div4-title {
        font-size: 20px;
    }

    .clt-booking-cp-div4-text h2,
    .clt-booking-cp-div4-text p {
        font-size: 14px;
    }

    .clt-booking-cp-div5-title {
        font-size: 20px;
        font-weight: 600;
    }

    .clt-booking-cp-div6-text p {
        font-size: 15px;
    }

    .clt-booking-cp-div7-title {
        font-size: 16px;
        line-height: 22px;
        margin: -10px 0 14px;
    }

    .clt-booking-cp-div5-inner h2 {
        font-size: 15px;
    }

    .clt-booking-cp-text-bold {
        font-size: 15px !important;
    }

    .clt-booking-cp-text-bold2 {
        font-size: 15px !important;
    }

    .clt-booking-cp-text-green {
        font-size: 15px;
    }

    .clt-booking-cp-div6 button,
    .clt-booking-cp-div6 button:active,
    .clt-booking-cp-div6 button:focus,
    .clt-booking-cp-div6 button:hover {
        font-size: 14.5px;
    }

    .clt-booking-cp-div6-text h2 {
        font-size: 15px;
    }

    .clt-booking-cp-div7-btn button,
    .clt-booking-cp-div7-btn button:active,
    .clt-booking-cp-div7-btn button:focus,
    .clt-booking-cp-div7-btn button:hover {
        font-size: 15px;
    }

    .clt-booking-cp-div7-p {
        font-size: 15px;
    }

    .clt-right-form-p {
        font-size: 15px;
    }

    .clt-booking-gc-div2-btn button,
    .clt-booking-gc-div2-btn button:active,
    .clt-booking-gc-div2-btn button:focus,
    .clt-booking-gc-div2-btn button:hover {
        font-size: 15px;
    }

    .clt-payment-plan-p {
        font-size: 15px;
    }

    .clt-payment-plan-more-info {
        font-size: 15px;
    }

    .clt-clickable-chevron-div .inner-div-1 h2 {
        font-size: 15px;
    }

    .clt-clickable-chevron-div .inner-div-1 p {
        font-size: 15px;
    }

    .clt-paymentplan-select-mainDiv .inner-div-text h2 {
        font-size: 15px;
    }

    .clt-select-option-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1px;
        letter-spacing: -0.2px;
    }

    .clt-booking-cp-div6-title {
        font-size: 20px;

    }

    .clt-admin-dash-booking-slider-text h2 {
        font-size: 15px !important;
        margin-bottom: 0px !important;
    }

    .clt-admin-dash-booking-slider-text p {
        font-size: 15px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-slider-subtext p {
        font-size: 15px !important;
        margin-bottom: 0 !important;
    }

    .clt-admin-dash-booking-navigation-cancel-slider-text h2 {
        font-size: 15px !important;
        margin-bottom: 0px !important;
    }

    .clt-right-form-p a,
    .clt-right-form-p a:hover {
        font-size: 15px;
    }

}

.clt-booking-cp-div1Box {
    width: 100%;
    border-bottom: 1px solid #dedddd;
    border-radius: 0px;
    padding: 15px 4px;
    cursor: pointer;
}

.clt-booking-cp-div1Box-Extension {
    border: 1px solid #dedddd !important;
    padding: 15px 15px !important;
    border-radius: 12px !important;
}

.clt_admin_dash_menu_container_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.clt-booking-div1 {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: row;
    width: 100%;
    gap: 15px;
}

.clt-booking-innner-div1 {
    background-repeat: no-repeat;
    height: 20px !important;
    margin-top: 1px !important;
    width: 20px !important;
    background-size: contain;
}

.clt-booking-bgImg1 {
    background-image: url(../../../../assets/img/admin_icon_menu_1.png);
    opacity: 97%;
}

.clt-booking-bgImg2 {
    background-image: url(../../../../assets/img/icon_euro.png);
    opacity: 97%;
}

.clt-booking-bgImg3 {
    background-image: url(../../../../assets/img/remaining_payment.png);
    opacity: 97%;
}

.clt-booking-bgImg4 {
    background-image: url(../../../../assets/img/icon_search_result_1.png);
    opacity: 97%;
}

.clt-booking-bgImg5 {
    background-image: url(../../../../assets/img/menu_black.png);
    opacity: 97%;
}

.clt-booking-bgImg6 {
    background-image: url(../../../../assets/img/icon_key.png);
    opacity: 97%;
}

.clt-booking-bgImg7 {
    background-image: url(../../../../assets/img/drawer_m8.png);
    opacity: 97%;
}

.clt-booking-bgImg8 {
    background-image: url(../../../../assets/img/icon_card_identity.png);
    opacity: 97%;
}


.clt-account-screen-inner-div-InnerDiv {
    /* width: 18px;
    height: 18px; */
    background: #000;
    color: white;
    border-radius: 50px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-admin-managebooking-text-custom-refund {
    font-size: 15px;
}