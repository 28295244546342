.clt-event-sidebar-eventSelect-mainDiv {
    border: 1px solid #ddd;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 19px;
    padding-left: 20px;
    border-radius: 12px;
    margin-top: 16px;
    cursor: pointer;
}

.clt-event-sidebar-eventSelect-mainDiv .inner-div-text h2 {
    font-size: 14px;
    font-weight: 500;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 5px;
    letter-spacing: -0.2px;
}

.clt-event-sidebar-eventSelect-mainDiv .inner-div-text h3 {
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    font-family: "Inter";
    margin-bottom: 4.6px;
    letter-spacing: -0.2px;
}

.clt-event-sidebar-eventSelect-mainDiv .inner-div-text span {
    font-size: 13px;
    font-weight: 400;
    color: #70757a;
    font-family: "Inter";
    margin-bottom: 0;
    letter-spacing: -0.2px;
}

.clt-event-sidebar-eventSelect-mainDiv .inner-div-text span i {
    font-size: 12px;
    margin: 0;
    color: #70757a;
    margin-right: 5px;
}

.clt-event-sidebar-eventSelect-mainDiv .inner-div-circle {
    border: 1px solid #ddd;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clt-event-sidebar-eventSelect-mainDiv .inner-div-circle .subDiv {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50px;
}

@media only screen and (max-width: 600px) {
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }

    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) and (orientation:portrait) {
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }
}
@media only screen and (max-width: 767px) and (orientation:landscape) {
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        letter-spacing: -0.2px;
    }

    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }

    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text span {
        font-size: 13.5px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h2 {
        font-size: 16px;
    }
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h3 {
        font-size: 16px;
    }
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text span i {
        font-size: 13.1px;
        margin: 0.16px 5px 0 0;
    }
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text span {
        font-size: 14.5px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt-event-sidebar-eventSelect-mainDiv .inner-div-text h2 {
        font-size: 15px;
        margin-bottom: 6px;
    }

}