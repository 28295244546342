.pfr_dropdown_menu2 {
    /* position: absolute; */
    /* z-index: 1024; */
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: center;
    border-radius: 12px;
    padding: 0.3rem;
    margin: 5px;
    font-size: 1rem;
    color: #242424;
    text-align: left;
    list-style: none;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px !important;
    border: 0px solid rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    border-radius: 12px;
    height: fit-content;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}