.clt_admin_dash_booking_container_outer_wrapper {
  /* width: calc(100% - 400px); */
  width: 60%;
  /* padding: 0px 80px; */
}

/* React loading skull */

.clt_booking_list_skeleton_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  border-bottom: 1px solid #eeeeee;
}

.clt_booking_list_skeleton_profile_circle {
  width: 30px !important;
  height: 30px !important;
}

.clt_booking_list_skeleton_profile_grand_line {
  width: 35% !important;
}

.clt_booking_list_skeleton_profile_small_line {
  width: 15% !important;
}


.row-span-booking-list {
  flex-direction: row;
  display: flex;
  gap: 8px;
}


.clt_admin_dash_booking_container {
  margin-top: 1.4rem;
}

.clt_admin_dash_booking_container_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.clt_admin_dash_booking_container_box:hover {
  background: #f6f6f6 !important;
}

.clt_admin_dash_booking_container_box:active {
  background: #eee !important;
}

.clt_admin_dash_booking_container_name {
  padding: 0;
  margin: 0 0 10px;
  font-size: 23px;
  color: #242424;
  font-weight: 600;
}

.clt_admin_dash_booking_container_box_icon {
  background-repeat: no-repeat;
  height: 17px;
  margin-top: 2.5px;
  width: 17px;
  background-size: contain;
}

.clt_admin_dash_booking_container_box_name {
  font-weight: 600;
  font-size: 13.3999px;
  letter-spacing: -0.1px;
  margin-bottom: -4.5px;
}

.clt_admin_dash_booking_container_wrapper {
  padding-top: 17px;
  padding-right: 20px;
  padding-bottom: 22px;
  padding-left: 20px;
  width: 100%;
  border: 1px solid #dedddd;
  border-radius: 10px;
  cursor: pointer;
}

.clt_admin_dash_booking_container_wrapper:hover {
  background: #f6f6f6 !important;
}

.clt_admin_dash_booking_container_wrapper:active {
  background: #eee !important;
}

.clt_admin_dash_booking_container_wrapper_first {
  padding: 15px 20px 19px;
  width: 100%;
  border-top: 1px solid #dedddd;
  border-left: 1px solid #dedddd;
  border-right: 1px solid #dedddd;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  cursor: pointer;
  font-size: 16px;
}

.clt_admin_dash_booking_container_wrapper_first:hover {
  background: #f6f6f6;
  border-top: 1px solid #dedddd;
  border-left: 1px solid #dedddd;
  border-right: 1px solid #dedddd;
}

.clt_admin_dash_booking_container_wrapper_first:active {
  background: #eee;
  border-top: 1px solid #dedddd;
  border-left: 1px solid #dedddd;
  border-right: 1px solid #dedddd;
}

.clt_admin_dash_booking_container_wrapper_last {
  padding: 15px 20px 19px;
  width: 100%;
  border: 1px solid #dedddd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.clt_admin_dash_booking_container_wrapper_last:hover {
  background: #f6f6f6;
  border: 1px solid #dedddd;
}

.clt_admin_dash_booking_container_wrapper_last:active {
  background: #eee;
  border: 1px solid #dedddd;
}

.clt_admin_dash_booking_container_wrapper_center {
  padding: 15px 20px 19px;
  width: 100%;
  border: 1px solid #dedddd;
  cursor: pointer;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
  /* border-top: 0px; */
  font-size: 16px;
}

.clt_admin_dash_booking_container_wrapper_center:hover {
  background: #f6f6f6;
  border: 1px solid #dedddd;
  border-bottom: 0px solid #dedddd;
}

.clt_admin_dash_booking_container_wrapper_center:active {
  background: #eee;
  border: 1px solid #dedddd;
  border-bottom: 0px solid #dedddd;
}

.clt_admin_dash_booking_container_detail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3px;
  margin-left: 0px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: break-word;
}

.clt_admin_dash_booking_container_detail .subtitle {
  font-size: 13.5px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: -0.1px;
  color: #70757a;
  padding-bottom: 0.8px;
  margin-bottom: -5px;
}

.clt_admin_dash_booking_container_detail .subtitle2 {
  font-size: 13.5px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: -0.1px;
  color: #70757a;
  padding-bottom: 0.8px;
  margin: 0;
}

.clt_admin_dash_booking_container_detail .event {
  font-size: 11.9px;
  font-weight: 500;
  border-radius: 6px;
  padding-top: 0;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
  margin: 0;
}

.clt_admin_dash_booking_container_detail .wedding {
  background-color: #e6f5f0;
  color: #248c0f;
  font-size: 11.9px;
  font-weight: 500;
  border-radius: 6px;
  padding-top: 0;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
  margin: 0;
}

.clt_admin_dash_booking_cancel_container_detail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 3px;
  margin-left: 0px;
  width: auto;
}

.clt_admin_dash_booking_cancel_container_detail .subtitle {
  font-size: 13.5px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: -0.1px;
  color: #70757a;
  padding-bottom: 0.8px;
  margin-bottom: -5px;
}

.clt_admin_dash_booking_cancel_container_detail .subtitle2 {
  font-size: 13.5px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: -0.1px;
  color: #70757a;
  padding-bottom: 0.8px;
  margin: 0;
}

.clt_admin_dash_booking_cancel_container_detail .wedding {
  background-color: #f6f6f6;
  color: #353535;
  font-size: 11.9px;
  font-weight: 500;
  border-radius: 6px;
  padding-top: 0;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
  margin: 0;
}

.clt_search_sidebar_container_detail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3px;
  margin-left: 0px;
  width: 100%;
}

.clt_search_sidebar_container_detail .subtitle {
  font-size: 13.5px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: -0.1px;
  color: #70757a;
  padding-bottom: 0.8px;
  margin-bottom: -5px;
}

.clt_search_sidebar_container_detail .subtitle2 {
  font-size: 13.5px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: -0.1px;
  color: #70757a;
  padding-bottom: 0.8px;
  margin: 0;
}

.clt_search_sidebar_container_detail .wedding {
  background-color: #e6f5f0;
  color: #248c0f;
  font-size: 11.9px;
  font-weight: 500;
  border-radius: 6px;
  padding-top: 0;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
  margin: 0;
}

.clt_admin_dash_booking_hr {
  border-bottom: 1px solid #dedddd;
  margin-top: 24px;
  margin-bottom: 24px;
}

.clt_admin_dash_booking_filter_chip_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  margin-top: 0px;
  margin-bottom: 24px;
}

.clt_admin_dash_booking_filter_chip_container .selected {
  background-color: black;
  color: white;
}

.clt_admin_dash_booking_navigation_request_filter_chip_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.clt_admin_dash_booking_navigation_request_filter_chip_container .selected {
  background-color: black;
  color: white;
}

.clt_admin_dash_booking_filter_chip {
  margin-top: 0px;
  margin-right: 6px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #242424;
  background-color: #eee;
  font-size: 13px;
  font-weight: 500;
  padding-top: 4.6px;
  padding-right: 12px;
  padding-bottom: 6.8px;
  padding-left: 12px;
  border-radius: 50px;
}

.clt_admin_dash_booking_navigation_request_filter_chip {
  margin-top: 2px;
  margin-right: 6px;
  margin-bottom: 11px;
  margin-left: 0px;
  color: #242424;
  background-color: #eee;
  font-size: 13px;
  font-weight: 500;
  padding-top: 4.6px;
  padding-right: 12px;
  padding-bottom: 6.8px;
  padding-left: 12px;
  border-radius: 50px;
  cursor: pointer;
}

.clt_admin_dash_booking_navigation_request_filter_chip .down {
  transform: rotate(90deg);
  margin-left: 6px;
  margin-top: 4px;
}

.clt_admin_dash_booking_navigation_request_filter_chip .clt_admin_arrow {
  width: 5px !important;
}

.clt_admin_dash_booking_navigation_request_filter_chip:hover {
  background: #d9dce0 !important;
  color: #242424 !important;
}

.clt_admin_dash_booking_navigation_request_filter_chip:active {
  background: #d2d2d3 !important;
  color: #242424 !important;
}

.clt_right_form_filter_chip {
  margin-top: 0px;
  margin-right: 6px;
  margin-bottom: 8px;
  margin-left: 0px;
  color: #242424;
  background-color: #eee;
  font-size: 14px;
  font-weight: 500;
  padding-top: 4.6px;
  padding-right: 12px;
  padding-bottom: 6.8px;
  padding-left: 12px;
  border-radius: 50px;
  cursor: pointer;
}

.clt_right_form_filter_chip .down {
  transform: rotate(90deg);
  margin-left: 6px;
  margin-top: 4px;
}

.clt_right_form_filter_chip .clt_admin_arrow {
  width: 5px !important;
}

.clt_right_form_filter_chip:hover {
  background: #d9dce0 !important;
  color: #242424 !important;
}

.clt_right_form_filter_chip:active {
  background: #d2d2d3 !important;
  color: #242424 !important;
}

.clt_right_form_filter_chip_active {
  margin-top: 0px;
  margin-right: 6px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #ffffff;
  background-color: #000000;
  font-size: 14px;
  font-weight: 500;
  padding-top: 4.6px;
  padding-right: 12px;
  padding-bottom: 6.8px;
  padding-left: 12px;
  border-radius: 50px;
  cursor: pointer;
}


.clt_right_form_filter_chip_active:hover {
  background-color: #3f3f3f !important;
}

.active.dropdown p {
  background-color: #000000;
  color: #ffffff;
}

.active.dropdown:hover p {
  background-color: #434343 !important;
  color: #ffffff !important;
}

.active.dropdown:active p {
  background-color: #434343 !important;
  color: #ffffff !important;
}

.active.dropdown:focus p {
  background-color: #434343 !important;
  color: #ffffff !important;
}

.clt_right_form_filter_chip_active .down {
  transform: rotate(90deg);
  margin-left: 6px;
  margin-top: 4px;
}

.clt_right_form_filter_chip_active .clt_admin_arrow {
  width: 5px !important;
}

.clt_right_form_filter_chip_active:hover {
  background: #000000 !important;
  color: #ffffff !important;
}

.clt_right_form_filter_chip_active:active {
  background: #000000 !important;
  color: #ffffff !important;
}

.clt_form_information_request_filter_chip_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.clt_form_information_request_filter_chip_container .selected {
  background-color: black;
  color: white;
}

.clt_right_form_filter_chip_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  margin-bottom: 0px;
  margin-top: 0px;
  display: inline-block;
}

.clt_right_form_filter_chip_container .selected {
  background-color: black;
  color: white;
}

.clt_form_information_request_filter_chip {
  margin-top: 2px;
  margin-right: 6px;
  margin-bottom: 11px;
  margin-left: 0px;
  color: #242424;
  background-color: #eee;
  font-size: 13px;
  font-weight: 500;
  padding-top: 4.6px;
  padding-right: 12px;
  padding-bottom: 6.8px;
  padding-left: 12px;
  border-radius: 50px;
}

.clt_form_information_request_filter_chip .down {
  transform: rotate(90deg);
  margin-left: 6px;
  margin-top: 4px;
}

.clt_form_information_request_filter_chip .clt_admin_arrow {
  width: 5px !important;
}

.clt_form_information_request_filter_chip:hover {
  background: #d9dce0 !important;
  color: #242424 !important;
}

.clt_form_information_request_filter_chip:active {
  background: #d2d2d3 !important;
  color: #242424 !important;
}

.clt_admin_dash_booking_filter_chip:hover {
  background: #d9dce0 !important;
  color: #242424 !important;
}

.clt_admin_dash_booking_filter_chip:active {
  background: #d2d2d3 !important;
  color: #242424 !important;
}

.clt_admin_dash_booking_avatar_wrapper {
  align-self: flex-start;
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clt_admin_dash_booking_avatar {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 50%;
  margin-right: 16px;
}

.clt_admin_dash_booking_image img {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 50%;
  margin-right: 16px;
}

.clt_admin_dash_booking_avatar_name {
  color: #fff;
}

.clt_admin_dash_booking_filter_name {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  color: #70757a;
  font-size: 12.5px;
  font-weight: 400;
  text-transform: uppercase;
}

.clt_admin_dash_booking_speeddial_container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.clt_admin_dash_booking_speeddial_container .speeddial-btn {
  align-items: center;
  background-color: #000;
  border: none;
  border-radius: 18px;
  bottom: 32px;
  color: #fff;
  display: flex;
  gap: 4px;
  justify-content: center;
  min-height: 58px;
  min-width: 63px;
  outline: none;
  overflow: hidden;
  /* padding: 0 10px; */
  padding-right: 10px;
  padding-bottom: 3px;
  padding-left: 10px;
  position: fixed;
  right: 17%;
  white-space: nowrap;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.72) !important;
  z-index: 5;
}

.speeddial-btn:hover {
  background-color: #2d2d2d !important;
}

.speeddial-btn:active {
  background-color: #5c5c5c !important;
}

.speeddial-btn span:first-child {
  font-size: 34px;
  margin-bottom: 8px;
  margin-left: 3px;
}

.speeddial-btn span:last-child {
  transition: width 0.6s;
  font-size: 16.5px;
  font-weight: 500;
  overflow: hidden;
  margin-bottom: 3px;
}

.clt-admin-booking-tabs {
  padding-left: 0px !important;
  padding-right: 24px !important;
  flex-flow: row !important;
  overflow: auto;
  display: -webkit-inline-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.clt-admin-booking-tabs::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.clt-admin-booking-tabs.nav-tabs .nav-link {
  font-size: 13.5px;
  font-weight: 600;
  letter-spacing: -0.2px;
  font-family: "Inter";
  margin-bottom: 0px;
  padding-bottom: 16.5px;
  padding-top: 5px;
  margin-top: 0px;
  color: #70757a;
  line-height: 16px;
  border: none;
  padding-left: 0;
  padding-right: 0;
  margin-right: 25px;
}

.clt-admin-booking-tabs.nav-tabs .nav-link.active {
  color: #000;
  border-bottom: 2px solid #000;
}

.clt-admin-booking-tabs-body {
  padding-top: 24px;
}

.clt_admin_dash_booking_filter_chip {
  cursor: pointer;
}

.clt_admin_dash_booking_filter_chip .down {
  transform: rotate(90deg);
  margin-left: 6px;
  margin-top: 4px;
}

.clt_admin_dash_booking_filter_chip .clt_admin_arrow {
  width: 5px !important;
}

.pfr_dropdown_menu {
  position: absolute;
  z-index: 1024;
  display: none;
  min-width: 10rem;
  border-radius: 12px;
  padding: 0.3rem 0;
  margin: 0;
  font-size: 1rem;
  color: #242424;
  text-align: left;
  list-style: none;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px !important;
  border: 0px solid rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  border-radius: 12px;
  top: 8px !important;
  height: fit-content;
  overflow-y: scroll;
}

.pfr_dropdown_menu_month_year {
  position: absolute;
  z-index: 1024;
  display: none;
  min-width: 10rem;
  border-radius: 12px;
  padding: 0.3rem 0;
  margin: 0;
  font-size: 1rem;
  color: #242424;
  text-align: left;
  list-style: none;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px !important;
  border: 0px solid rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  border-radius: 12px;
  top: 8px !important;
  height: fit-content;
  overflow-y: scroll;
  height: 200px !important;
}

.clt_booking_list_skeleton_container_shadow {
  flex: 1;
  margin: 10px;
  padding-left: 10px
}



@media only screen and (max-width: 600px) {

  /*mobile*/
  .clt_admin_dash_booking_container_outer_wrapper {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ctl_admin_search_container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ctl_admin_search_containerdzadzad {
    text-align: left !important;
    justify-content: start !important;
    align-content: start !important;
    display: flex;
    align-self: start;
  }

  .clt_admin_dash_booking_container_box_icon {
    background-repeat: no-repeat;
    height: 22px;
    margin-top: 4px;
    width: 20px;
    background-size: contain;
  }

  .clt_admin_dash_booking_filter_chip {
    margin-top: 0px;
    margin-right: 6px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #242424;
    background-color: #eee;
    font-size: 16px;
    font-weight: 500;
    padding: 4.6px 12px 6.8px;
    border-radius: 50px;
  }

  .clt_admin_dash_booking_navigation_request_filter_chip {
    margin-top: -4px;
    margin-right: 6px;
    margin-bottom: 14px;
    margin-left: 0px;
    color: #242424;
    background-color: #eee;
    font-size: 16px;
    font-weight: 500;
    padding: 4.6px 12px 6.8px;
    border-radius: 50px;
  }

  .clt_admin_dash_booking_container_wrapper_first {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_wrapper_center {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_wrapper_last {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_box_name {
    font-size: 16px;
    margin-bottom: -5px;
    margin-top: 4px;
  }

  .clt_admin_dash_booking_avatar_wrapper {
    margin-top: 26px;
  }

  .clt_admin_dash_booking_container_name {
    font-size: 25px;
    margin: 0 0 0px;
  }

  .clt_admin_dash_booking_filter_name {
    font-size: 13.8px;
    letter-spacing: 0.1px;
  }

  .clt_admin_dash_booking_container {
    margin-top: 0rem;
  }

  .clt-admin-booking-tabs {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .clt-admin-booking-tabs.nav-tabs .nav-link {
    font-size: 16px;
    padding-bottom: 24.5px;
    padding-top: 13px;
  }

  .clt_admin_dash_booking_avatar {
    margin-bottom: 18px;
    margin-right: 18px;
  }

  .clt_admin_dash_booking_container_detail {
    width: 100%;
    margin-bottom: 0px;
    grid-gap: 15px;
    gap: 3px;
  }

  .clt_admin_dash_booking_container_detail .wedding {
    padding: 0 5px 2px;
    font-size: 13.999px;
  }

  .clt_admin_dash_booking_container_detail .subtitle {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_container_detail .subtitle2 {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_cancel_container_detail {
    width: 100%;
    margin-bottom: 0px;
    grid-gap: 15px;
    gap: 3px;
  }

  .clt_admin_dash_booking_cancel_container_detail .wedding {
    padding: 0 5px 2px;
    font-size: 13.999px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle2 {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_container_name {
    font-size: 25px;
    margin: 0 0 0px;
  }

  .clt_right_form_filter_chip {
    font-size: 16px;
    padding-top: 3.6px;
    padding-right: 12px;
    padding-bottom: 7.8px;
    padding-left: 12px;
  }

  .clt_admin_dash_booking_image img {
    width: 40px;
    height: 40px;
  }

  .clt_admin_dash_booking_avatar {
    width: 40px;
    height: 40px;
  }

  .clt_booking_list_skeleton_profile_grand_line {
    width: 55% !important;
  }

  .clt_booking_list_skeleton_profile_small_line {
    width: 25% !important;
  }

  .clt_admin_dash_booking_container_wrapper {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 28px;
    padding-left: 20px;
    width: 100%;
    border: 1px solid #dedddd;
    border-radius: 10px;
    cursor: pointer;
  }






}

@media only screen and (max-width: 767px) and (orientation: portrait) {

  /*mobile*/
  .clt_admin_dash_booking_container_outer_wrapper {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ctl_admin_search_container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .clt_admin_dash_booking_container_box_icon {
    background-repeat: no-repeat;
    height: 22px;
    margin-top: 4px;
    width: 20px;
    background-size: contain;
  }

  .clt_admin_dash_booking_filter_chip {
    margin-top: 0px;
    margin-right: 6px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #242424;
    background-color: #eee;
    font-size: 16px;
    font-weight: 500;
    padding: 4.6px 12px 6.8px;
    border-radius: 50px;
  }

  .clt_admin_dash_booking_navigation_request_filter_chip {
    margin-top: -4px;
    margin-right: 6px;
    margin-bottom: 14px;
    margin-left: 0px;
    color: #242424;
    background-color: #eee;
    font-size: 16px;
    font-weight: 500;
    padding: 4.6px 12px 6.8px;
    border-radius: 50px;
  }

  .clt_admin_dash_booking_container_detail {
    width: 100%;
    margin-bottom: 0px;
    grid-gap: 15px;
    gap: 3px;
  }

  .clt_admin_dash_booking_container_detail .wedding {
    padding: 0 5px 2px;
    font-size: 13.999px;
  }

  .clt_admin_dash_booking_container_detail .subtitle {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_container_detail .subtitle2 {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_cancel_container_detail {
    width: 100%;
    margin-bottom: 0px;
    grid-gap: 15px;
    gap: 3px;
  }

  .clt_admin_dash_booking_cancel_container_detail .wedding {
    padding: 0 5px 2px;
    font-size: 13.999px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle2 {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_container_name {
    font-size: 25px;
    margin: 0 0 0px;
  }

  .clt_admin_dash_booking_filter_name {
    font-size: 13.8px;
    letter-spacing: 0.1px;
  }

  .clt_admin_dash_booking_container {
    margin-top: 0rem;
  }

  .clt-admin-booking-tabs {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .clt-admin-booking-tabs.nav-tabs .nav-link {
    font-size: 16px;
    padding-bottom: 24.5px;
    padding-top: 13px;
  }

  .clt_admin_dash_booking_avatar {
    margin-bottom: 18px;
    margin-right: 18px;
  }

  .clt_right_form_filter_chip {
    font-size: 16px;
    padding-top: 3.6px;
    padding-right: 12px;
    padding-bottom: 7.8px;
    padding-left: 12px;
  }

  .clt_admin_dash_booking_image img {
    width: 40px;
    height: 40px;
  }

  .clt_admin_dash_booking_avatar {
    width: 40px;
    height: 40px;
  }

  .clt_booking_list_skeleton_profile_grand_line {
    width: 55% !important;
  }

  .clt_booking_list_skeleton_profile_small_line {
    width: 25% !important;
  }

  .clt_admin_dash_booking_container_wrapper_first {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_wrapper_center {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_wrapper_last {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_box_name {
    font-size: 16px;
    margin-bottom: -5px;
    margin-top: 4px;
  }

  .clt_admin_dash_booking_avatar_wrapper {
    margin-top: 26px;
  }

  .clt_admin_dash_booking_container_wrapper {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 28px;
    padding-left: 20px;
    width: 100%;
    border: 1px solid #dedddd;
    border-radius: 10px;
    cursor: pointer;
  }






}

@media only screen and (max-width: 767px) and (orientation: landscape) {

  /*mobile*/
  .clt_admin_dash_booking_container_outer_wrapper {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ctl_admin_search_container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .clt_admin_dash_booking_container_box_icon {
    background-repeat: no-repeat;
    height: 22px;
    margin-top: 4px;
    width: 20px;
    background-size: contain;
  }

  .clt_admin_dash_booking_filter_chip {
    margin-top: 0px;
    margin-right: 6px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #242424;
    background-color: #eee;
    font-size: 16px;
    font-weight: 500;
    padding: 4.6px 12px 6.8px;
    border-radius: 50px;
  }

  .clt_admin_dash_booking_navigation_request_filter_chip {
    margin-top: -4px;
    margin-right: 6px;
    margin-bottom: 14px;
    margin-left: 0px;
    color: #242424;
    background-color: #eee;
    font-size: 16px;
    font-weight: 500;
    padding: 4.6px 12px 6.8px;
    border-radius: 50px;
  }

  .clt_admin_dash_booking_container_detail {
    width: 100%;
    margin-bottom: 0px;
    grid-gap: 15px;
    gap: 5px;
  }

  .clt_admin_dash_booking_container_detail .wedding {
    padding: 0 5px 2px;
    font-size: 13.999px;
  }

  .clt_admin_dash_booking_container_detail .subtitle {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_container_detail .subtitle2 {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_cancel_container_detail {
    width: 100%;
    margin-bottom: 0px;
    grid-gap: 15px;
    gap: 5px;
  }

  .clt_admin_dash_booking_cancel_container_detail .wedding {
    padding: 0 5px 2px;
    font-size: 13.999px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle2 {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .clt_admin_dash_booking_container_name {
    font-size: 25px;
    margin: 0 0 0px;
  }

  .clt_admin_dash_booking_filter_name {
    font-size: 13.8px;
    letter-spacing: 0.1px;
  }

  .clt_admin_dash_booking_container {
    margin-top: 0rem;
  }

  .clt-admin-booking-tabs {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .clt-admin-booking-tabs.nav-tabs .nav-link {
    font-size: 16px;
    padding-bottom: 24.5px;
    padding-top: 13px;
  }

  .clt_admin_dash_booking_avatar {
    margin-bottom: 18px;
    margin-right: 18px;
  }

  .clt_right_form_filter_chip {
    font-size: 16px;
    padding-top: 3.6px;
    padding-right: 12px;
    padding-bottom: 7.8px;
    padding-left: 12px;
  }

  .clt_admin_dash_booking_image img {
    width: 40px;
    height: 40px;
  }

  .clt_admin_dash_booking_avatar {
    width: 40px;
    height: 40px;
  }

  .clt_booking_list_skeleton_profile_grand_line {
    width: 55% !important;
  }

  .clt_booking_list_skeleton_profile_small_line {
    width: 25% !important;
  }

  .clt_admin_dash_booking_container_wrapper_first {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_wrapper_center {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_wrapper_last {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 22px;
  }

  .clt_admin_dash_booking_container_box_name {
    font-size: 16px;
    margin-bottom: -5px;
    margin-top: 4px;
  }

  .clt_admin_dash_booking_avatar_wrapper {
    margin-top: 26px;
  }

  .clt_admin_dash_booking_container_wrapper {
    padding-top: 17px;
    padding-right: 20px;
    padding-bottom: 28px;
    padding-left: 20px;
    width: 100%;
    border: 1px solid #dedddd;
    border-radius: 10px;
    cursor: pointer;
  }






}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {

  /*ipad*/
  .clt_admin_dash_booking_container_outer_wrapper {
    width: calc(100% - 200px);
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {

  /*ipad*/
  .clt_admin_dash_booking_container_outer_wrapper {
    width: calc(100% - 200px);
  }
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation: portrait) {
  /*ipad pro*/
}

@media (max-width: 1200px) and (min-width: 992px) and (orientation: landscape) {
  /*ipad pro*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation: portrait) {
  /*ipad pro*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation: landscape) {
  /*ipad pro*/
}

@media only screen and (min-width: 1600px) {

  /*large desktop*/
  .clt_admin_dash_booking_filter_chip {
    font-size: 14px;
    margin-top: 0px;
    margin-right: 6px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .clt_admin_dash_booking_navigation_request_filter_chip {
    font-size: 14px;
    margin-top: 0px;
    margin-right: 6px;
    margin-bottom: 8px;
    margin-left: 0px;
  }

  .clt_admin_dash_booking_filter_name {
    font-size: 13.5px;
  }

  .clt_admin_dash_booking_container_box_name {
    font-size: 15px;
  }

  .clt_admin_dash_booking_container_detail .subtitle {
    font-size: 14.5px;
  }

  .clt_admin_dash_booking_container_detail .subtitle2 {
    font-size: 14.5px;
  }

  .clt_admin_dash_booking_container_detail .wedding {
    font-size: 12.9px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle {
    font-size: 14.5px;
  }

  .clt_admin_dash_booking_cancel_container_detail .subtitle2 {
    font-size: 14.5px;
  }

  .clt_admin_dash_booking_cancel_container_detail .wedding {
    font-size: 12.9px;
  }

  .clt_admin_dash_booking_container {
    margin-top: 1.4rem;
  }

  .clt-admin-booking-tabs.nav-tabs .nav-link {
    font-size: 15px;
    margin-bottom: 0px;
    padding-bottom: 20.5px;
    padding-top: 6px;
    margin-top: 0px;
    line-height: 16px;
    border: none;
    padding-left: 0;
    padding-right: 0;
    margin-right: 25px;
  }

  .clt_right_form_filter_chip {
    font-size: 14px;
    padding-top: 3.6px;
    padding-bottom: 7.8px;
  }
}