.clt_page_not_found .clt_page_not_found_navbar {
    padding: 15px 24px 17px;
    border-bottom: 1px solid #dedddd;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
}

.clt_page_not_found .navbar_container {
    background-color: #808080;
    padding-top: 13px;
}

.clt_page_not_found .clt_page_not_found_return_btn {
    background: #000;
    border: 1px solid #000;
    color: #fff;
    justify-content: center;
    align-items: center;
    width: 458px;
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    height: 48px;
    margin-top: 0px;
}

.clt_page_not_found_main_title {
    font-size: 18px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    letter-spacing: -0.2px;
    margin-top: 12px;
    margin-bottom: 12px;

}

.clt_page_not_found_sub_title {
    font-size: 14px;
    color: #70757a;
    font-family: "Inter";
    font-weight: 400;
    margin-bottom: 26px;
    margin-top: 12px;
    letter-spacing: -.2px;
}

.clt_page_not_found strong {
    font-size: 13.5px;
    color: #242424;
    font-family: 'Inter';
    font-weight: 600;
    letter-spacing: -0.2px;
}

@media only screen and (max-width: 600px) {

    /*mobile*/
    .clt_page_not_found_content {
        padding: 24px;
    }
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 20px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
    }

    
}

@media only screen and (max-width: 767px) and (orientation:portrait) {

    /*mobile*/
    .clt_page_not_found_content {
        padding: 24px;
    }
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 25px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5.2em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        font-size: 16px;
        font-weight: 600;
        border-radius: 12px;
        width: 100%;
        height: 52px;
        margin-top: 8px;
    }
}

@media only screen and (max-width: 767px) and (orientation:landscape) {

    /*mobile*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 25px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5.2em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        font-size: 16px;
        font-weight: 600;
        border-radius: 12px;
        height: 52px;
        margin-top: 8px;
        width: 100%;
    }

    .clt_page_not_found_content {
        padding: 24px;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:portrait) {

    /*ipad*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 16px;
        height: 52px;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation:landscape) {

    /*ipad*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 16px;
        height: 52px;
    }

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 16px;
        height: 52px;
    }

}

@media (max-width: 1200px) and (min-width: 992px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 16px;
        height: 52px;
    }

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:portrait) {

    /*ipad pro*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 16px;
        height: 52px;
    }

}

@media only screen and (max-width: 1400px) and (min-width: 1200px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 16px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 16px;
        height: 52px;
    }

}


@media only screen and (min-width: 1400px) and (max-width: 1599px) and (orientation:landscape) {

    /*ipad pro*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 17px;
        padding-right: 24px;
        padding-bottom: 21px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 14.5px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 5em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 16px;
        height: 52px;
    }

}

@media only screen and (min-width: 1600px) {

    /*large desktop*/
    .clt_page_not_found {
        text-align: center;
        margin-bottom: 3rem;
    }

    .clt_page_not_found .navbar_container {
        padding-top: 13px;
    }

    .clt_page_not_found .clt_page_not_found_navbar {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-top: 15px;
        padding-right: 24px;
        padding-bottom: 16px;
        padding-left: 24px;
    }

    .clt_page_not_found strong {
        font-size: 15px;
    }

    .clt_page_not_found img {
        width: 160px;
        margin-top: 12em;
    }

    .clt_page_not_found_main_title {
        font-size: 22px;
        font-weight: 600;
    }

    .clt_page_not_found_sub_title {
        font-size: 16px;
    }

    .clt_page_not_found .clt_page_not_found_return_btn {
        width: 458px;
        font-size: 15px;
    }
}